// src/store/modules/userActions.js
import {
  doc,
  getDoc,
  getDocs,
  collection,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "@/firebase"; // Make sure this path is correct

export default {
  state: {
    otherUserData: null,
    orgDetails: {
      totalOrgCoins: 0,
    },
    coinSymbol: null,
    buyerOrgUID: null,
    sellerOrgUID: null,
    buyerOrgDetails: {},
    sellerOrgDetails: {},
    currentUserOrgUID: null,
    currentUserWallet: null,
    profileUserData: null,
    profileUserOrgDetails: {},
  },
  getters: {
    otherUserData: (state) => state.otherUserData,
    coinSymbol: (state) => state.coinSymbol,
    getBuyerOrgDetails: (state) => (orgUID) => state.buyerOrgDetails[orgUID],
    getSellerOrgDetails: (state) => (orgUID) => state.sellerOrgDetails[orgUID],
    getBuyerOrgTotalCoins: (state) => (orgUID) =>
      state.buyerOrgDetails[orgUID]?.totalOrgCoins,
    getSellerOrgTotalCoins: (state) => (orgUID) =>
      state.sellerOrgDetails[orgUID]?.totalOrgCoins,
    buyerOrgUID: (state) => state.buyerOrgUID,
    sellerOrgUID: (state) => state.sellerOrgUID,
    currentUserOrgUID: (state) => state.currentUserOrgUID,
    currentUserWallet: (state) => state.currentUserWallet,
    profileUserData: (state) => state.profileUserData,
    getProfileUserOrgDetails: (state) => (orgUID) => {
      return state.profileUserOrgDetails || {};
    },
  },
  actions: {
    async fetchProfileUserData({ commit }, userUID) {
      try {
        const userDoc = await getDoc(doc(db, "users", userUID));
        if (userDoc.exists()) {
          const profileUserData = { uid: userDoc.id, ...userDoc.data() };
          commit("SET_PROFILE_USER_DATA", profileUserData); // Mutation to set profile user data
        } else {
          console.error("No such user!");
        }
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    },

    async fetchAndSetProfileUserOrgUID({ commit }, userUID) {
      try {
        commit("CLEAR_PROFILE_USER_ORG_DETAILS");

        const unitsRef = collection(db, "users", userUID, "Units");
        const querySnapshot = await getDocs(unitsRef);

        let profileUserOrgUID = null;
        querySnapshot.forEach((doc) => {
          if (!profileUserOrgUID) {
            profileUserOrgUID = doc.id;
            const data = doc.data();

            // Commit all relevant details
            commit("SET_PROFILE_USER_ORG_DETAILS", {
              orgUID: profileUserOrgUID,
              details: {
                organisationName: data.organisationName,
                roleTitle: data.roleTitle,
                currentRole: data.currentRole,
                organisationUID: data.organisationUID,
                joined: data.joined, // Including the joined timestamp
              },
            });
          }
        });

        if (!profileUserOrgUID) {
          console.error("No Units found for user:", userUID);
        }
      } catch (error) {
        console.error("Error fetching and setting profileUserOrgUID:", error);
      }
    },

    async fetchCurrentUserOrgUID({ commit }, userUID) {
      if (!userUID) return;

      const unitsRef = collection(db, "users", userUID, "Units");
      const querySnapshot = await getDocs(unitsRef);

      let orgUID = null;
      querySnapshot.forEach((doc) => {
        if (!orgUID) {
          orgUID = doc.id;
        }
      });

      if (orgUID) {
        commit("SET_CURRENT_USER_ORG_UID", orgUID);
      }
    },

    async fetchCurrentUserWallet({ commit, state }) {
      if (!state.userCredentials || !state.userCredentials.data) {
        return;
      }

      const userUID = state.userCredentials.data.uid;
      const orgUID = state.currentUserOrgUID;

      if (!userUID || !orgUID) return;

      try {
        const walletDocRef = doc(db, "users", userUID, "Wallets", orgUID);
        const walletDocSnap = await getDoc(walletDocRef);

        if (walletDocSnap.exists()) {
          commit("SET_CURRENT_USER_WALLET", walletDocSnap.data());
        } else {
          console.error("No wallet found for user:", userUID);
        }
      } catch (error) {
        console.error("Error fetching current user wallet:", error);
      }
    },

    // Action to fetch user data from Firestore
    async fetchotherUserData({ commit }, userUID) {
      try {
        const userDoc = await getDoc(doc(db, "users", userUID));
        if (userDoc.exists()) {
          const otherUserData = { uid: userDoc.id, ...userDoc.data() };
          commit("SET_USER_DATA", otherUserData); // Mutation to set user data
        } else {
          console.error("No such user!");
        }
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    },
    async fetchItemOwnerWallet({ commit }, { ownerUID, productOrgUID }) {
      return new Promise(async (resolve, reject) => {
        try {
          const walletDocRef = doc(
            db,
            "users",
            ownerUID,
            "Wallets",
            productOrgUID
          );
          const walletDocSnap = await getDoc(walletDocRef);

          if (walletDocSnap.exists()) {
            const walletData = walletDocSnap.data();
            const currentAccountBalance =
              walletData.currentAccount?.balance || 0;
            const savingsAccountBalance =
              walletData.savingsAccount?.balance || 0;

            const walletDetails = {
              membersCount: 1, // Assuming a default of 1 member
              totalOrgCoins: currentAccountBalance + savingsAccountBalance,
            };

            resolve(walletDetails); // Resolve the promise with wallet details
          } else {
            reject(
              new Error(
                "Wallet document does not exist:" +
                  ownerUID +
                  " " +
                  productOrgUID
              )
            ); // Reject the promise
          }
        } catch (error) {
          reject(error); // Reject the promise on error
        }
      });
    },
    async fetchOrgMembersCount({ commit }, orgUID) {
      if (!orgUID) {
        return 0; // Immediately return 0 if orgUID is not provided
      }

      try {
        const membersCollectionRef = collection(
          db,
          "custom-orgs",
          orgUID,
          "Members"
        );
        const membersSnapshot = await getDocs(membersCollectionRef);
        return membersSnapshot.size; // The count of members
      } catch (error) {
        // console.error("Error fetching organization members count:", error);
        return 0; // Return 0 in case of an error
      }
    },

    async subscribeToWalletUpdates({ commit, state }, uid) {
      if (!uid) {
        console.error("UID is null or undefined.");
        return;
      }

      const walletsRef = collection(db, "users", uid, "Wallets");

      onSnapshot(walletsRef, async (snapshot) => {
        for (const change of snapshot.docChanges()) {
          if (change.type === "modified") {
            const orgUID = change.doc.id; // Assuming the doc.id is the organization UID

            // Fetch the totalOrgCoins from the correct organization document
            const orgDocRef = doc(db, "custom-orgs", orgUID);
            const orgDocSnap = await getDoc(orgDocRef);

            if (orgDocSnap.exists()) {
              const { totalOrgCoins } = orgDocSnap.data();

              // Check and update seller's org details
              if (
                state.sellerOrgDetails[orgUID] &&
                state.sellerOrgDetails[orgUID].totalOrgCoins !== totalOrgCoins
              ) {
                commit("SET_SELLER_TOTAL_ORG_COINS", { orgUID, totalOrgCoins });
              }

              // Similarly, check and update buyer's org details if necessary
              if (
                state.buyerOrgDetails[orgUID] &&
                state.buyerOrgDetails[orgUID].totalOrgCoins !== totalOrgCoins
              ) {
                commit("SET_BUYER_TOTAL_ORG_COINS", { orgUID, totalOrgCoins });
              }
            }
          }
        }
      });
    },

    async fetchAndCommitOrgMembersCount(
      { commit, dispatch },
      { orgUID, context }
    ) {
      if (!orgUID) {
        console.error("fetchAndCommitOrgMembersCount: orgUID is undefined.");
        return; // Do not proceed if orgUID is not provided
      }

      const membersCount = await dispatch("fetchOrgMembersCount", orgUID);

      if (context === "buyer") {
        commit("SET_BUYER_MEMBERS_COUNT", { orgUID, membersCount });
      } else if (context === "seller") {
        commit("SET_SELLER_MEMBERS_COUNT", { orgUID, membersCount });
      } else {
        console.error(
          "fetchAndCommitOrgMembersCount: context is not defined or invalid."
        );
      }
    },

    async fetchAndCommitOrgTotalCoins({ commit }, { orgUID, context }) {
      try {
        const orgDocRef = doc(db, "custom-orgs", orgUID);
        const orgDocSnap = await getDoc(orgDocRef);

        let totalOrgCoins = 0; // Assume 0 if data is missing
        if (
          orgDocSnap.exists() &&
          orgDocSnap.data().totalOrgCoins !== undefined
        ) {
          totalOrgCoins = orgDocSnap.data().totalOrgCoins;
        }

        if (context === "buyer") {
          commit("SET_BUYER_TOTAL_ORG_COINS", { orgUID, totalOrgCoins });
        } else if (context === "seller") {
          commit("SET_SELLER_TOTAL_ORG_COINS", { orgUID, totalOrgCoins });
        }
      } catch (error) {
        console.error("Error fetching organization total coins:", error);
      }
    },
    async fetchUserWalletDetails({ commit, state }) {
      const userUID = state.userCredentials.data.uid;
      const walletsRef = collection(db, "users", userUID, "Wallets");
      const querySnapshot = await getDocs(walletsRef);
      let orgUID = null;

      querySnapshot.forEach((doc) => {
        if (!orgUID) {
          orgUID = doc.id;
        }
      });

      if (orgUID) {
        commit("SET_CURRENT_ORG_UID", orgUID);
      }
    },
    async fetchUserOrgCoinSymbol({ commit, state, dispatch }, userUID) {
      try {
        const walletsRef = collection(db, "users", userUID, "Wallets");

        const activeWalletsQuery = query(
          walletsRef,
          where("isActiveMember", "==", true)
        );
        const querySnapshot = await getDocs(activeWalletsQuery);
        let coinSymbol = "Ʉ"; // Fallback coin symbol

        const activeWallet = querySnapshot.docs.find((doc) => doc.exists());
        if (activeWallet) {
          coinSymbol =
            activeWallet.data().monetary_parameters?.coin_symbol || "Ʉ";
          dispatch("updateCoinSymbol", {
            symbol: coinSymbol,
            name: activeWallet.data().monetary_parameters?.coin_name,
          });
        } else {
          dispatch("updateCoinSymbol", {
            symbol: "Ʉ",
            name: "Unit",
          });
        }

        commit("SET_COIN_SYMBOL", coinSymbol); // Commit the coin symbol to your Vuex state
      } catch (error) {
        commit("SET_COIN_SYMBOL", "Ʉ"); // Commit fallback coin symbol to your Vuex state
      }
    },
    async fetchAndSetBuyerOrgUID({ commit, dispatch }, userUID) {
      try {
        const unitsRef = collection(db, "users", userUID, "Units");
        const querySnapshot = await getDocs(unitsRef);

        let buyerOrgUID = null;
        querySnapshot.forEach((doc) => {
          if (!buyerOrgUID) {
            buyerOrgUID = doc.id;
          }
        });

        if (buyerOrgUID) {
          const orgDocRef = doc(db, "custom-orgs", buyerOrgUID);
          const orgDocSnap = await getDoc(orgDocRef);

          if (orgDocSnap.exists()) {
            const orgData = orgDocSnap.data();

            const {
              name: organisationName,
              monetary_parameters: { coin_symbol: coinSymbol } = {},
              totalOrgCoins,
            } = orgData;

            // Fetch members count using fetchOrgMembersCount
            const membersCount = await dispatch(
              "fetchOrgMembersCount",
              buyerOrgUID
            );

            commit("SET_BUYER_ORG_DETAILS", {
              orgUID: buyerOrgUID,
              details: {
                organisationName,
                coinSymbol,
                totalOrgCoins,
                membersCount,
              },
            });
          } else {
            console.error("Buyer Org document does not exist in Firestore.");
          }

          commit("SET_BUYER_ORG_UID", buyerOrgUID);
        } else {
          console.error("No Units found for user:", userUID);
        }
      } catch (error) {
        console.error("Error fetching and setting buyerOrgUID:", error);
      }
    },
    async fetchAndSetSellerOrgUID({ commit }, userUID) {
      try {
        const unitsRef = collection(db, "users", userUID, "Units");
        const querySnapshot = await getDocs(unitsRef);

        let sellerOrgUID = null;
        querySnapshot.forEach((doc) => {
          if (!sellerOrgUID) {
            sellerOrgUID = doc.id;
          }
        });

        if (sellerOrgUID) {
          // Fetch additional org details
          const orgDocRef = doc(db, "custom-orgs", sellerOrgUID);
          const orgDocSnap = await getDoc(orgDocRef);

          if (orgDocSnap.exists()) {
            const { organisationName, coinSymbol, totalOrgCoins } =
              orgDocSnap.data();

            // Commit the organisation details
            commit("SET_SELLER_ORG_DETAILS", {
              orgUID: sellerOrgUID,
              details: {
                organisationName,
                coinSymbol,
                totalOrgCoins,
              },
            });
          }

          commit("SET_SELLER_ORG_UID", sellerOrgUID);
        } else {
          console.error("No Units found for user:", userUID);
        }
      } catch (error) {
        console.error("Error fetching and setting sellerOrgUID:", error);
      }
    },
  },
  mutations: {
    SET_CURRENT_USER_ORG_UID(state, orgUID) {
      state.currentUserOrgUID = orgUID;
    },

    SET_CURRENT_USER_WALLET(state, walletData) {
      state.currentUserWallet = walletData;
    },
    // Mutation to set user data
    SET_USER_DATA(state, otherUserData) {
      state.otherUserData = otherUserData;
    },
    SET_MEMBERS_COUNT(state, { orgUID, membersCount }) {
      if (!state.orgDetails[orgUID]) {
        state.orgDetails[orgUID] = { membersCount: 1, totalOrgCoins: 0 }; // Initialize with default values
      }
      state.orgDetails[orgUID].membersCount = membersCount;
    },
    SET_TOTAL_ORG_COINS(state, { orgUID, totalOrgCoins }) {
      if (!state.orgDetails[orgUID]) {
        state.orgDetails[orgUID] = { membersCount: 0, totalOrgCoins: 0 };
      }
      state.orgDetails[orgUID].totalOrgCoins = totalOrgCoins;
    },
    SET_COIN_NAME(state, coinName) {
      state.coinName = coinName;
    },
    SET_COIN_SYMBOL(state, coinSymbol) {
      state.coinSymbol = coinSymbol; // Add coinSymbol to your state
    },
    SET_BUYER_MEMBERS_COUNT(state, { orgUID, membersCount }) {
      state.buyerOrgDetails[orgUID] = {
        ...state.buyerOrgDetails[orgUID],
        membersCount,
      };
    },
    SET_SELLER_MEMBERS_COUNT(state, { orgUID, membersCount }) {
      state.sellerOrgDetails[orgUID] = {
        ...state.sellerOrgDetails[orgUID],
        membersCount,
      };
    },
    SET_BUYER_TOTAL_ORG_COINS(state, { orgUID, totalOrgCoins }) {
      if (!state.buyerOrgDetails[orgUID]) {
        state.buyerOrgDetails[orgUID] = {};
      }
      state.buyerOrgDetails[orgUID].totalOrgCoins = totalOrgCoins;
    },
    SET_SELLER_TOTAL_ORG_COINS(state, { orgUID, totalOrgCoins }) {
      if (!state.sellerOrgDetails[orgUID]) {
        state.sellerOrgDetails[orgUID] = {};
      }
      if (totalOrgCoins === 0) {
        totalOrgCoins = 1;
      }
      state.sellerOrgDetails[orgUID].totalOrgCoins = totalOrgCoins;
    },
    SET_BUYER_ORG_UID(state, orgUID) {
      state.buyerOrgUID = orgUID;
    },
    SET_SELLER_ORG_UID(state, orgUID) {
      state.sellerOrgUID = orgUID;
    },

    SET_BUYER_ORG_DETAILS(state, { orgUID, details }) {
      state.buyerOrgDetails[orgUID] = {
        ...state.buyerOrgDetails[orgUID],
        ...details,
      };
    },

    SET_SELLER_ORG_DETAILS(state, { orgUID, details }) {
      state.sellerOrgDetails[orgUID] = {
        ...state.sellerOrgDetails[orgUID],
        ...details,
      };
    },

    SET_PROFILE_USER_DATA(state, profileUserData) {
      state.profileUserData = profileUserData;
    },

    SET_PROFILE_USER_ORG_DETAILS(state, { orgUID, details }) {
      state.profileUserOrgDetails[orgUID] = {
        ...details,
        organisationUID: orgUID, // Ensure orgUID is included
      };
    },
    CLEAR_PROFILE_USER_ORG_DETAILS(state) {
      state.profileUserOrgDetails = {};
    },
  },
};
