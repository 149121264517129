import { createRouter, createWebHistory } from "vue-router";
import EcosystemView from "../views/EcosystemView.vue"; // Import the EcosystemView component
import DashboardView from "../views/DashboardView.vue"; // Import the DashboardView component
import store from "../store";

const routes = [
  {
    path: "/",
    name: "home",
    component: EcosystemView, // Set EcosystemView as the home component
  },
  {
    path: "/invite/:orgUID/:inviteUID?",
    name: "accept-invite",
    component: () =>
      import(/* webpackChunkName: "invite" */ "../views/AcceptInviteView.vue"),
    meta: {
      requiresInvite: true,
    },
  },
  {
    path: "/invites",
    name: "invites",
    component: () =>
      import(/* webpackChunkName: "invite" */ "../views/InvitesView.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/account",
    name: "account",
    component: () =>
      import(/* webpackChunkName: "account" */ "../views/AccountView.vue"),
  },
  {
    path: "/wallet",
    name: "wallet",
    component: () =>
      import(/* webpackChunkName: "wallet" */ "../views/WalletView.vue"),
  },
  /** Auth routes */
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../components/AuthComponents/Login.vue"
      ),
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../components/AuthComponents/Register.vue"
      ),
  },
  {
    path: "/ecosystem",
    name: "ecosystem",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/EcosystemView.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardView, // Add the new DashboardView route
  },
  {
    path: "/org/:orgUID",
    name: "OrganisationView",
    component: () =>
      import(
        /* webpackChunkName: "userprofile" */ "../views/OrganisationView.vue"
      ),
  },
  {
    path: "/userprofile/:userUID",
    name: "UserProfile",
    component: () =>
      import(
        /* webpackChunkName: "userprofile" */ "../views/UserProfileView.vue"
      ),
  },
  {
    path: "/role/:roleUID/:orgUID",
    name: "RoleView",
    component: () =>
      import(/* webpackChunkName: "roleview" */ "../views/RoleView.vue"),
  },
  {
    path: "/product/:id",
    name: "ProductView",
    component: () =>
      import(/* webpackChunkName: "product-view" */ "../views/ProductView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const restrictedRoutes = ["wallet"];
  const userLoggedIn = store.state.user.loggedIn;

  if (restrictedRoutes.includes(to.name) && !userLoggedIn) {
    next({ name: "home" });
    return;
  }

  sessionStorage.setItem("lastRoute", to.name);

  if (to.name === "exchangerate" || to.name === "about") {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  if (to.path.includes("ecosystem") || to.path === "/") {
    store.dispatch("changeActiveTab", "ecosystem");
  } else if (to.name === "wallet") {
    store.dispatch("changeActiveTab", "wallet");
  } else if (to.name === "dashboard") {
    store.dispatch("changeActiveTab", "dashboard");
  } else {
    store.dispatch("changeActiveTab", "yourUnitverse");
  }

  const lastRoute = sessionStorage.getItem("lastRoute");
  const routesRequiringUID = [
    "OrganisationView",
    "UserProfile",
    "RoleView",
    "accept-invite",
  ];

  if (lastRoute && to.name !== lastRoute) {
    if (routesRequiringUID.includes(lastRoute)) {
      next({ name: "home" });
      store.dispatch("changeActiveTab", "ecosystem");
    } else {
      switch (lastRoute) {
        case "home":
        case "/":
          next({ name: "home" });
          store.dispatch("changeActiveTab", "ecosystem");
          break;
        case "ecosystem":
        case "wallet":
        case "dashboard":
          next({ name: lastRoute });
          store.dispatch("changeActiveTab", lastRoute);
          break;
        default:
          next({ name: lastRoute });
          store.dispatch("changeActiveTab", null);
          break;
      }
    }
  } else {
    next();
  }
});

export default router;
