<template>
  <div class="monetaryParameters">
    <div class="innerSticker" :style="{ 'background-color': backgroundColor }">
      <div class="row card-header alignCentreRow">
        <div class="row alignCentreRow">
          <div class="circle">{{ monetaryParameters.coin_symbol }}</div>
          <h4 class="nomargintopbottom">
            {{ monetaryParameters.coin_name }}
          </h4>
          <button
            v-if="toggleSwitchState === true"
            @click="editField('coinName')"
            class="microButton purpleText"
            v-haptic
          >
            {{ $t("buttons.edit") }}
          </button>
        </div>
        <ToggleSwitch
          v-if="toggleSwitchVisible"
          label="Edit"
          v-model="toggleSwitchState"
          :fullWidth="false"
          :hideStatusText="true"
        />
      </div>

      <div class="separator_sticker noMarginTop"></div>

      <section>
        <TablePanel
          :rows="tableRows"
          :toggleSwitchState="toggleSwitchState"
          :hideFirstRowTopBorder="true"
          :monetaryParameters="monetaryParameters"
          :showEditButton="showEditButton"
          :showEditButtonState="toggleSwitchState"
          @edit-row="editField($event.field)"
        />
        <Coinlifecycle
          v-if="basicOrgDetails && orgUID"
          :basicOrgDetails="basicOrgDetails"
          :viewedOrgUID="orgUID"
          :currentUserOrgUID="orgUID"
        />
      </section>
    </div>
    <!-- OVERLAYS -->
    <!-- Coin name  -->

    <Overlay
      :visible="isCoinNameOverlayVisible"
      :title="`Editing Coin Name`"
      :smallTextAfterTitle="`Cuurent coin name: ${monetaryParameters.coin_name}`"
      @close="isCoinNameOverlayVisible = false"
    >
      <input
        class="alignCentre"
        v-model="newCoinName"
        placeholder="Enter new coin name"
      />
      <button @click="updateCoinName" class="overlay-save-btn">Save</button>
    </Overlay>
    <!-- Coin lifespan in current account -->
    <Overlay
      :visible="isCoinLifespanOverlayVisible"
      :title="`Editing coin lifespan`"
      :smallTextAfterTitle="`Current coin lifespan: ${monetaryParameters.current_account.lifespan}`"
      @close="isCoinLifespanOverlayVisible = false"
      ><div class="row alignCentre fullWidth">
        <input
          class="alignCentre"
          type="number"
          placeholder="Type new value"
          v-model="coinLifespan"
        />
        <button @click="handleCoinLifespanOverlay(coinLifespan)" v-haptic>
          Save
        </button>
      </div>
    </Overlay>
    <!-- Current account cap -->
    <Overlay
      :visible="isMaxAccCurrentOverlayVisible"
      :title="`Edit max accumulation in current accounts`"
      :smallTextAfterTitle="`Current max accumulation: ${monetaryParameters.current_account.cap} ${monetaryParameters.coin_symbol}`"
      @close="isMaxAccCurrentOverlayVisible = false"
      ><div class="row alignCentre fullWidth">
        <input
          class="alignCentre"
          type="number"
          placeholder="Type new value"
          v-model="maxAccumulationCurrent"
        />
        <button
          @click="handleMaxAccCurrentOverlay(maxAccumulationCurrent)"
          v-haptic
        >
          Save
        </button>
      </div>
    </Overlay>
    <!-- Savings account cap -->
    <Overlay
      :visible="isMaxAccSavingsOverlayVisible"
      :title="`Edit max accumulation in Savings accounts`"
      :smallTextAfterTitle="`Current max accumulation: ${monetaryParameters.savings_account.cap} ${monetaryParameters.coin_symbol}`"
      @close="isMaxAccSavingsOverlayVisible = false"
      ><div class="row alignCentre fullWidth">
        <input
          class="alignCentre"
          type="number"
          placeholder="Type new value"
          v-model="maxAccumulationSavings"
        />
        <button
          @click="handleMaxAccSavingsOverlay(maxAccumulationSavings)"
          v-haptic
        >
          Save
        </button>
      </div>
    </Overlay>
    <!-- Basic income -->
    <Overlay
      :visible="isBasicIncomeOverlayVisible"
      :title="`Edit basic income`"
      :smallTextAfterTitle="`Current basic income: ${monetaryParameters.basic_income} ${monetaryParameters.coin_symbol}`"
      @close="isBasicIncomeOverlayVisible = false"
      ><div class="row alignCentre fullWidth">
        <input
          class="alignCentre"
          type="number"
          placeholder="Type new value"
          v-model="basicIncome"
        />
        <button @click="handleBasicIncomeOverlay(basicIncome)" v-haptic>
          Save
        </button>
      </div>
    </Overlay>
    <!-- Regularity of Basic income -->
    <Overlay
      :visible="isRegularityOverlayVisible"
      :title="`Editing income regularity`"
      :smallTextAfterTitle="`Current regularity: ${monetaryParameters.regularity} days`"
      @close="isRegularityOverlayVisible = false"
      ><div class="row alignCentre fullWidth">
        <input
          class="alignCentre"
          type="number"
          placeholder="Type new value"
          v-model="regularity"
        />
        <button @click="handleRegularityOverlay(regularity)" v-haptic>
          Save
        </button>
      </div>
    </Overlay>
    <Overlay
      :visible="isInitialCoinsOverlayVisible"
      :title="`Edit initial coins per user`"
      :smallTextAfterTitle="`Current initial coins: ${monetaryParameters.initialCoins} ${monetaryParameters.coin_symbol}`"
      @close="isInitialCoinsOverlayVisible = false"
    >
      <div class="row alignCentre fullWidth">
        <input
          class="alignCentre"
          type="number"
          placeholder="Enter new value"
          v-model="initialCoins"
        />
        <button @click="handleInitialCoinsOverlay(initialCoins)" v-haptic>
          Save
        </button>
      </div>
    </Overlay>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { getAuth } from "firebase/auth";
import { db, auth } from "@/firebase";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { toast } from "vue3-toastify";
import { formatNumberTo5Digits } from "@/utils/numberFormattingTo5digits";

import ToggleSwitch from "@/components/GenericComponents/ToggleSwitch.vue";
import TablePanel from "@/components/GenericComponents/TablePanel.vue";
import Overlay from "@/components/GenericComponents/Overlay.vue";
import Coinlifecycle from "@/components/DashboardComponents/MonateryParametersComponents/Coinlifecycle.vue";
import { createOverlayHandler } from "@/utils/overlayFactory.js";

export default {
  name: "MonetaryParametersPanel",
  components: {
    ToggleSwitch,
    TablePanel,
    Overlay,
    Coinlifecycle,
  },
  props: {
    unitData: {
      type: Object,
      required: true,
    },
    orgUID: {
      type: String,
    },
    monetaryParameters: {
      type: Object,
    },
    getParameterDisplayString: {
      type: Function,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: "",
    },
    userRolePrivileges: {
      type: Object,
      required: false,
    },
  },

  setup(props) {
    const store = useStore();
    const currentUser = ref(null);

    const basicOrgDetails = computed(() =>
      store.getters.getBasicOrgDetails(props.orgUID)
    );
    const isCoinLifespanOverlayVisible = ref(false);
    let initialCoins = ref(props.monetaryParameters.initialCoins || 0);
    const isInitialCoinsOverlayVisible = ref(false);

    let coinLifespan = ref(props.monetaryParameters.current_account.lifespan);
    let formattedCoinLifespan = computed(() => {
      return formatNumberTo5Digits(coinLifespan.value);
    });

    const isMaxAccCurrentOverlayVisible = ref(false);
    let maxAccumulationCurrent = ref(
      props.monetaryParameters.current_account.cap
    );
    let formattedMaxAccCurrent = computed(() => {
      return formatNumberTo5Digits(maxAccumulationCurrent.value);
    });
    const isMaxAccSavingsOverlayVisible = ref(false);
    let maxAccumulationSavings = ref(
      props.monetaryParameters.savings_account.cap
    );
    let formattedMaxAccSavings = computed(() => {
      return formatNumberTo5Digits(maxAccumulationSavings.value);
    });

    const isBasicIncomeOverlayVisible = ref(false);
    let basicIncome = ref(props.monetaryParameters.basic_income);
    let formattedBasicIncome = computed(() => {
      return formatNumberTo5Digits(basicIncome.value);
    });

    const isRegularityOverlayVisible = ref(false);
    let regularity = ref(props.monetaryParameters.regularity || 30); // Default value or from props
    let formattedRegularity = computed(() => {
      return formatNumberTo5Digits(regularity.value);
    });

    const isCoinNameOverlayVisible = ref(false);
    const newCoinName = ref(props.monetaryParameters.coin_name || "");

    const formattedNextTriggerTime = computed(() => {
      return props.monetaryParameters.nextTriggerTime
        ? new Date(
            props.monetaryParameters.nextTriggerTime.seconds * 1000
          ).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })
        : "Not set";
    });

    const tableRows = computed(() => {
      const rows = [
        {
          col1: "Coin lifespan in current accounts",
          col2: `${coinLifespan.value} days`,
          tooltip:
            "How much can a coin remain in a current account before being eliminated",
          conditional: false,
        },
        {
          col1: "Max accumulation in current accounts",
          col2: `${formattedMaxAccCurrent.value} ${props.monetaryParameters.coin_symbol}`,
          tooltip: "",
          conditional: false,
        },
        {
          col1: "Initial coins per user",
          col2: `${props.monetaryParameters.initialCoins} ${props.monetaryParameters.coin_symbol}`,
          tooltip:
            "The initial amount of coins created for each user when they join",
          conditional: false,
        },
      ];

      // **Only add the savings row if allowSavings is true**
      if (props.monetaryParameters.savings_account.allowSavings) {
        rows.push({
          col1: "Max accumulation in savings accounts",
          col2: `${formattedMaxAccSavings.value} ${props.monetaryParameters.coin_symbol}`,
          tooltip: "",
          conditional: false, // Set to false to allow editing if needed
        });
      }

      // Optionally, show basic income and regularity of basic income if basic income is greater than 0
      if (formattedBasicIncome.value > 0 || toggleSwitchState.value) {
        console.log("formattedBasicIncome.value", formattedBasicIncome.value);
        rows.push({
          col1: "Basic income",
          col2: `${formattedBasicIncome.value} ${props.monetaryParameters.coin_symbol}`,
          tooltip: "Amount all members periodically receive.",
          conditional: true,
        });
        rows.push({
          col1: "Regularity of Basic Income",
          col2: `Every ${formattedRegularity.value} days | Next: ${formattedNextTriggerTime.value}`,
          tooltip: "Defines how often the basic income is distributed.",
          conditional: true,
        });
      }

      return rows;
    });

    const editField = (row) => {
      if (row === "coinName") {
        isCoinNameOverlayVisible.value = true;
      }
      if (row === "Coin lifespan in current accounts") {
        isCoinLifespanOverlayVisible.value = true;
      }
      if (row === "Max accumulation in current accounts") {
        isMaxAccCurrentOverlayVisible.value = true;
      }
      if (row === "Max accumulation in savings accounts") {
        isMaxAccSavingsOverlayVisible.value = true;
      }
      if (row === "Basic income") {
        isBasicIncomeOverlayVisible.value = true;
      }
      if (row === "Regularity of Basic Income") {
        isRegularityOverlayVisible.value = true;
      }
      if (row === "Initial coins per user") {
        isInitialCoinsOverlayVisible.value = true; // Add this for initialCoins
      }
    };

    const coinSymbolMap = {
      A: "₳",
      B: "฿",
      C: "Ċ",
      D: "Đ",
      E: "Ē",
      F: "₣",
      G: "₲",
      H: "Ħ",
      I: "Ī",
      J: "Ĵ",
      K: "₭",
      L: "Ł",
      M: "₼",
      N: "₦",
      O: "Ɵ",
      P: "₱",
      Q: "Ƣ",
      R: "Ɍ",
      S: "Ŝ",
      T: "₸",
      U: "Ʉ",
      V: "∀",
      W: "₩",
      X: "Ẋ",
      Y: "¥",
      Z: "Ƶ",
    };

    const coinSymbol = computed(() => {
      const firstLetter = newCoinName.value.charAt(0).toUpperCase();
      return coinSymbolMap[firstLetter] || "Ʉ";
    });

    watch(newCoinName, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        // Dispatch an action or commit a mutation to update the Vuex state
        store.dispatch("updateCoinSymbol", {
          symbol: coinSymbol.value,
          name: newValue,
        });

        // toast.success("Coin name and symbol updated successfully.");
      }
    });

    watch(
      regularity,
      async (newVal) => {
        if (!newVal) return;

        const newNextTriggerTime = calculateNextTriggerTime(newVal);

        try {
          const orgDocRef = doc(db, "custom-orgs", props.orgUID);
          await updateDoc(orgDocRef, {
            "monetary_parameters.nextTriggerTime": newNextTriggerTime,
          });

          // Refetch the updated document to get the latest nextTriggerTime
          const updatedDoc = await getDoc(orgDocRef);
          const updatedData = updatedDoc.data();

          // Update the local monetaryParameters object with the fetched nextTriggerTime
          props.monetaryParameters.nextTriggerTime =
            updatedData.monetary_parameters.nextTriggerTime;
        } catch (error) {
          console.error("Error updating next trigger time:", error);
          toast.error("Failed to update next trigger time.");
        }
      },
      { immediate: false }
    );

    function calculateNextTriggerTime(regularity) {
      const now = new Date();
      return new Date(now.getTime() + regularity * 24 * 60 * 60 * 1000);
    }

    const updateCoinName = async () => {
      if (!newCoinName.value.trim()) {
        toast.error("Coin name cannot be empty.");
        return;
      }
      const initial = newCoinName.value.trim()[0].toUpperCase(); // Get the first letter and make it uppercase
      const newSymbol = coinSymbolMap[initial] || initial; // Use the letter as the symbol if it's not in the map

      try {
        const orgDocRef = doc(db, `custom-orgs/${props.orgUID}`);
        await updateDoc(orgDocRef, {
          "monetary_parameters.coin_name": newCoinName.value.trim(),
          "monetary_parameters.coin_symbol": newSymbol.toUpperCase(), // Update the coin symbol
        });
        // Update the local monetaryParameters object
        props.monetaryParameters.coin_name = newCoinName.value.trim();
        props.monetaryParameters.coin_symbol = newSymbol;

        // toast.success("Coin name and symbol updated successfully");
        isCoinNameOverlayVisible.value = false; // Close the overlay after updating
      } catch (error) {
        console.error("Error updating coin name and symbol:", error);
        toast.error("Failed to update coin name and symbol.");
      }
    };

    //OVERLAYS
    // Coin lifespan

    const handleCoinLifespanOverlay = () => {
      if (auth.currentUser) {
        // Immediately invoke the function returned by createOverlayHandler
        createOverlayHandler(
          `custom-orgs/${props.orgUID}`,
          "monetary_parameters.current_account.lifespan",
          isCoinLifespanOverlayVisible,
          coinLifespan,
          auth.currentUser.uid,
          props.orgUID,
          "monetary_parameters.current_account.lifespan",
          null,
          store
        )(); // Notice the () at the end, which invokes the function
      } else {
        console.error("No authenticated user found.");
      }
    };
    const handleMaxAccCurrentOverlay = () => {
      if (auth.currentUser) {
        createOverlayHandler(
          `custom-orgs/${props.orgUID}`,
          "monetary_parameters.current_account.cap",
          isMaxAccCurrentOverlayVisible,
          maxAccumulationCurrent,
          auth.currentUser.uid,
          props.orgUID,
          "monetary_parameters.current_account.cap"
        )();
      } else {
        console.error("No authenticated user found.");
      }
    };

    const handleMaxAccSavingsOverlay = () => {
      if (auth.currentUser) {
        createOverlayHandler(
          `custom-orgs/${props.orgUID}`,
          "monetary_parameters.savings_account.cap",
          isMaxAccSavingsOverlayVisible,
          maxAccumulationSavings,
          auth.currentUser.uid,
          props.orgUID,
          "monetary_parameters.savings_account.cap"
        )();
      } else {
        console.error("No authenticated user found.");
      }
    };

    const handleBasicIncomeOverlay = () => {
      if (auth.currentUser) {
        createOverlayHandler(
          `custom-orgs/${props.orgUID}`,
          "monetary_parameters.basic_income",
          isBasicIncomeOverlayVisible,
          basicIncome,
          auth.currentUser.uid,
          props.orgUID,
          "monetary_parameters.basic_income",
          {
            subCollection: "BasicIncome", // Using the same sub-collection
            docId: () => Date.now().toString(),
            extraData: () => ({
              value: basicIncome.value,
              regularity: regularity.value, // Include the current regularity value
            }),
          }
        )();
      } else {
        console.error("No authenticated user found.");
      }
    };

    const handleInitialCoinsOverlay = async () => {
      try {
        const orgDocRef = doc(db, `custom-orgs/${props.orgUID}`);
        await updateDoc(orgDocRef, {
          "monetary_parameters.initialCoins": initialCoins.value,
        });
        // Update the local monetaryParameters object
        props.monetaryParameters.initialCoins = initialCoins.value;

        toast.success("Initial coins updated successfully.");
        isInitialCoinsOverlayVisible.value = false; // Close the overlay after updating
      } catch (error) {
        console.error("Error updating initial coins:", error);
        toast.error("Failed to update initial coins.");
      }
    };

    const handleRegularityOverlay = () => {
      if (auth.currentUser) {
        createOverlayHandler(
          `custom-orgs/${props.orgUID}`,
          "monetary_parameters.regularity",
          isRegularityOverlayVisible,
          regularity,
          auth.currentUser.uid,
          props.orgUID,
          "monetary_parameters.regularity",
          {
            subCollection: "BasicIncome", // Using the same sub-collection
            docId: () => Date.now().toString(),
            extraData: () => ({
              regularity: regularity.value, // Explicitly updating regularity
              value: basicIncome.value,
            }),
          }
        )();
      } else {
        console.error("No authenticated user found.");
      }
    };

    // defining which fields are editabable
    const showEditButton = (row) => {
      if (!toggleSwitchState.value) return false;

      const editableFields = [
        "Basic income",
        "Max accumulation in current accounts",
        "Max accumulation in savings accounts",
        "Coin lifespan in current accounts",
        "Regularity of Basic Income",
        "Initial coins per user",
      ];

      return (
        editableFields.includes(row.col1) &&
        userRolePrivileges.value.monetaryParameters
      );
    };

    // Fetch user's role privieges when viewing this role
    let userRolePrivileges = computed(() => {
      return store.getters.userRolePrivileges
        ? store.getters.userRolePrivileges
        : {};
    });

    let toggleSwitchState = ref(false);
    // Replace the computed property with a ref and a watcher.
    const orderedParameters = computed(() => {
      const parameterOrder = [
        "basic_income",
        "regularity",
        "current_account",
        "savings_account",
        "max_shared_pool",
      ];
      const orderedParams = Object.fromEntries(
        Object.entries(props.monetaryParameters).sort(
          ([a], [b]) => parameterOrder.indexOf(a) - parameterOrder.indexOf(b)
        )
      );
      return orderedParams;
    });

    // Fetch role data from roles subcollection of custom-orgs collection
    const role = computed(() => {
      return store.getters.role;
    });

    let toggleSwitchVisible = computed(() => {
      // Compute ToggleSwitch visibility
      return userRolePrivileges.value.monetaryParameters;
    });
    onMounted(async () => {
      const auth = getAuth();
      currentUser.value = auth.currentUser;

      if (auth.currentUser) {
        // Check if there's a current user
        let orgUID = props.orgUID;
        await store.dispatch("getUserRolePrivileges", {
          orgUID,
        });
        await store.dispatch("fetchBasicOrgDetails", orgUID);
      } else {
        console.error("No authenticated user found.");
      }
    });

    // Return anything that should be accessible in the template
    return {
      orderedParameters,
      toggleSwitchVisible,
      toggleSwitchState,
      userRolePrivileges,
      showEditButton,
      editField,
      tableRows,
      basicOrgDetails,

      newCoinName,
      updateCoinName,

      coinLifespan,
      maxAccumulationCurrent,
      maxAccumulationSavings,
      basicIncome,
      regularity,
      initialCoins,

      formattedCoinLifespan,
      formattedMaxAccCurrent,
      formattedMaxAccSavings,
      formattedBasicIncome,
      formattedRegularity,

      isCoinNameOverlayVisible,
      isCoinLifespanOverlayVisible,
      isMaxAccCurrentOverlayVisible,
      isMaxAccSavingsOverlayVisible,
      isBasicIncomeOverlayVisible,
      isRegularityOverlayVisible,
      isInitialCoinsOverlayVisible,

      handleCoinLifespanOverlay,
      handleMaxAccCurrentOverlay,
      handleMaxAccSavingsOverlay,
      handleBasicIncomeOverlay,
      handleRegularityOverlay,
      handleInitialCoinsOverlay,
    };
  },
};
</script>

<style scoped></style>
