<template>
  <div class="fullWidth">
    <ProductCarousel v-if="product.images.length > 0" :product="product" />
    <div v-else class="placeholderImageNotAbsolute">
      <p>No image available</p>
    </div>

    <div class="productHeader row alignCentreRow headerRow">
      <div class="headercontainer column fullWidth">
        <div class="row alignCentreRow justifyToStartEnd">
          <h3 class="smallmargintopbottom">
            {{ productData.itemName || productData.serviceName }}
          </h3>
          <div class="leftGroup" v-if="productData.price">
            <div
              v-if="productData.existingStock !== undefined"
              class="product-stock"
            >
              <small
                class="soldout gray"
                v-if="productData.existingStock === 0"
              >
                Sold
              </small>
              <small
                v-else
                :class="{ stockWarningColor: productData.existingStock < 2 }"
              >
                {{ productData.existingStock }} in stock
              </small>
            </div>
            <div class="price innerSticker smallClickableHeight">
              <div class="row alignCentreRow justifyToCentre noWrap">
                <span class="priceNumberSymbol">
                  {{ parseFloat(productData.price).toFixed(2) }}
                  {{ coinSymbol }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- <p>
          {{ productData.itemDescription || productData.serviceDescription }}
        </p> -->
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed } from "vue";
import { auth } from "@/firebase";
import { useStore } from "vuex";
import ProductCarousel from "@/components/ProductComponents/ProductCarousel.vue";
import UserProfileImage from "@/components/ProductComponents/UserProfileImage.vue";

export default {
  name: "PreviewProduct",
  components: {
    ProductCarousel,
    UserProfileImage,
  },
  props: {
    productData: {
      type: Object,
      required: true,
    },
    userUnitsOrg: {
      type: Object,
      required: true,
    },
    coinSymbol: {
      type: String,
      required: true,
    },
    thumbnailIndex: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const currentUser = ref(auth.currentUser);
    const userCredentials = computed(() => store.getters.userCredentials);

    auth.onAuthStateChanged((userCredentials) => {
      store.dispatch("fetchUserCredentials", userCredentials);
    });

    const imageDataUrls = ref([]);

    const product = computed(() => ({
      ...props.productData,
      images: imageDataUrls.value,
    }));

    const convertFilesToDataUrls = () => {
      imageDataUrls.value = [];
      if (props.productData && props.productData.imagePreviews) {
        const images = [...props.productData.imagePreviews];
        const thumbnailIndex = props.thumbnailIndex;

        if (thumbnailIndex >= 0 && thumbnailIndex < images.length) {
          const thumbnailImage = images.splice(thumbnailIndex, 1)[0];
          images.unshift(thumbnailImage); // Move the thumbnail to the first position
        }

        images.forEach((file) => {
          if (typeof file === "string") {
            imageDataUrls.value.push(file);
          } else {
            const reader = new FileReader();
            reader.onload = (e) => {
              imageDataUrls.value.push(e.target.result);
            };
            reader.readAsDataURL(file);
          }
        });
      }
    };

    const fieldConfig = {
      itemName: "Product name",
      itemDescription: "Description",
      existingStock: "Existing stock",
      price: "Price per Item",
      selectedCategory: "Category",
      selectedCondition: "Condition",
    };

    const tableRows = computed(() => {
      if (props.productData) {
        const entries = Object.entries(props.productData);
        return entries
          .filter(([key]) => key !== "imagePreviews")
          .map(([key, value]) => {
            let displayValue = value;
            if (key === "price") {
              // Fixed template literal usage
              displayValue = `${value} ${props.coinSymbol}`;
            }
            return {
              col1: fieldConfig[key] || key,
              col2: displayValue,
            };
          });
      }
    });

    watch(
      () => props.productData.imagePreviews,
      () => {
        convertFilesToDataUrls();
      },
      { immediate: true, deep: true }
    );

    const confirmAndUpload = () => {
      emit("confirmUpload");
    };

    return {
      confirmAndUpload,
      imageDataUrls,
      tableRows,
      product,
      currentUser,
    };
  },
};
</script>

<style scoped>
.productCardComplete {
  display: flex;
  flex-direction: column; /* Make it a column */
  align-items: center; /* Align items vertically, not horizontally */
  padding: var(--margin);
  border-radius: var();
}

.allImgPreviewCont {
  column-count: 3; /* Adjust the number of columns */
  column-gap: var(--margin); /* Gap between columns */
}

.previewImageContainer {
  display: inline-block;
  margin: 0 0 var(--smallMargin);
  width: 100%;
}

.previewImage {
  width: 100%;
  border: 0px solid var(--solidMidLightgrey);
  border-radius: var(--borderRadius);
}
.smallText {
  text-align: left;
}
.headercontainer {
  margin-top: var(--margin);
}
.leftGroup {
  display: flex;
  flex-direction: row;
  gap: var(--margin);
  align-items: center;
}
.price {
  width: fit-content;
  border-radius: var(--borderRadiusButton);
  background-color: var(--white);
}

@media (max-width: 590px) {
  .allImgPreviewCont {
    column-count: 3;
  }
}
</style>
