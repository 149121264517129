<template>
  <transition name="coin-flip" mode="out-in">
    <div :class="['circle', rotateClass]" :key="coinKey">
      <span :title="coinName" :class="coinSizeClass">{{ coinSymbol }}</span>
    </div>
  </transition>
</template>

<script>
import { ref, watch, computed, onMounted, defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    size: {
      type: String,
      default: "regular",
      validator: (value) =>
        ["bigger", "big", "regular", "small"].includes(value),
    },
    customCoinSymbol: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const store = useStore();
    const rotateClass = ref("");
    const coinKey = ref(0);
    const monetaryParameters = computed(
      () => store.getters.monetary_parameters
    );
    const defaultCoinSymbol = computed(
      () => store.getters.getCoinSymbol || "Ʉ"
    );
    const coinName = computed(() => store.getters["getCoinName"]);

    const coinSymbol = computed(() => {
      return (
        props.customCoinSymbol ||
        monetaryParameters.value.coinSymbol ||
        defaultCoinSymbol.value
      );
    });

    function rotateCoin() {
      rotateClass.value = "rotate-to-90";
      setTimeout(() => {
        rotateClass.value = "rotate-to-0";
        coinKey.value += 1;
      }, 0);
    }

    watch(coinSymbol, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        rotateCoin();
      }
    });

    const coinSizeClass = computed(() => {
      switch (props.size) {
        case "bigger":
          return "bigger-coin";
        case "big":
          return "big-coin";
        case "small":
          return "small-coin";
        default:
          return "regular-coin";
      }
    });

    return { rotateClass, coinKey, coinSymbol, coinName, coinSizeClass };
  },
});
</script>

<style scoped>
.circle {
  transition: transform 0.15s ease-in-out;
}

.coin-flip-enter-active,
.coin-flip-leave-active {
  animation: spin 0.15s linear forwards;
}

@keyframes rotateTo90 {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(90deg);
  }
}

@keyframes rotateTo0 {
  0% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

.rotate-to-90 {
  animation: rotateTo90 0.15s linear forwards;
}

.rotate-to-0 {
  animation: rotateTo0 0.15s linear forwards;
}

.big-coin {
  font-weight: var(--fontweightlightH1);
}

.bigger-coin {
  font-size: var(--midBigFontsize);
  font-weight: var(--fontweightlightH1);
}

.regular-coin {
  font-size: var(--midFontsize);
  font-weight: var(--fontweightlightH1);
}

.small-coin {
  font-size: 12px; /* Adjust as needed */
}
</style>
