<template>
  <div class="card login">
    <form @submit.prevent="logInEmailPassword">
      <button type="button" @click="loginWithGoogle" v-haptic>
        <div class="row alignCentreRow">
          <div class="view compactImg">
            <img src="../../assets/google_large.png" :alt="buttonText" />
          </div>
          {{ $t("buttons.continueWithGoogle") }}
        </div>
      </button>
      <!--  <button type="button" @click="" v-haptic>
        <div class="row alignCentreRow">
          <div class="view compactImg">
            <img src="" :alt="appleButtonText" />
          </div>
          Continuar com a apple
        </div>
      </button> -->

      <p class="or">or</p>
      <div class="form-group email">
        <label for="email"> {{ $t("labels.email") }}</label>
        <input type="email" autocomplete="email" id="email" v-model="email" />
      </div>

      <div class="form-group password">
        <label for="password">{{ $t("labels.password") }}</label>
        <div class="row">
          <div class="row-child">
            <input
              id="passwordLogin"
              v-model="password"
              autocomplete="current-password"
              :type="showPassword ? 'text' : 'password'"
            />

            <button
              v-haptic
              title="Show/Hide password"
              type="button"
              class="show-password-button inputIcon"
            >
              <svg
                v-if="!showPassword"
                class="verySmall"
                @click="showPassword = !showPassword"
                v-haptic
              >
                <use href="../../assets/icons/iconset.svg#hide"></use>
              </svg>
              <svg
                v-else
                class="verySmall"
                @click="showPassword = !showPassword"
                v-haptic
              >
                <use href="../../assets/icons/iconset.svg#view"></use>
              </svg>
            </button>
          </div>
          <button type="submit" v-haptic>{{ $t("buttons.signIn") }}</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, computed, inject, watch } from "vue";
import {
  signInWithEmailAndPassword,
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
} from "firebase/auth";
import { auth, db } from "@/firebase";
import {
  collection,
  getDocs,
  where,
  query,
  doc,
  setDoc,
} from "firebase/firestore";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { toast } from "vue3-toastify";
import { useI18n } from "vue-i18n";
import { WalletSchema, CoinSchema } from "@/schemas/WalletSchema";

export default {
  name: "SignIn",
  props: {
    redirect: {
      type: Boolean,
      default: true,
    },
  },
  components: {},

  setup(props, { emit }) {
    const { t } = useI18n();

    const assetPath = computed(() => {
      return `@/assets`;
    });
    const email = ref("");
    const password = ref("");
    const error = ref(null);
    const success = ref(null);
    const showPassword = ref(false);
    const buttonText = "Click";
    const store = useStore();
    const router = useRouter();

    // let showLoginModal = computed(() => store.state.showLoginModal);

    let showLoginModal = computed(() => {
      console.log("Recomputed: ", store.state.showLoginModal);
      return store.state.showLoginModal;
    });

    let showRegisterModal = computed(() => store.state.showRegisterModal);
    const showLoginModalNoRedirect = computed(
      () => store.getters.showLoginModalNoRedirect
    );
    const showRegisterModalNoRedirect = computed(
      () => store.state.showRegisterModalNoRedirect
    );
    const logInEmailPassword = async (redirect) => {
      error.value = null;
      success.value = null;

      try {
        const userCredential = await store.dispatch("logInEmailPassword", {
          email: email.value,
          password: password.value,
        });
        await handleAuthSuccess(userCredential, redirect);
      } catch (err) {
        handleAuthError(err);
      }
    };

    const loginWithGoogle = async () => {
      error.value = null;
      success.value = null;
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      try {
        const userCredential = await signInWithPopup(auth, provider);

        await handleAuthSuccess(userCredential, props.redirect);
        await saveUserDataToFirestore(userCredential.user);
      } catch (err) {
        handleAuthError(err);
      }
    };

    // On login Registering and Saving the user anyway, since firebase auth allows to do this on a login operation
    const saveUserDataToFirestore = async (user) => {
      try {
        const usersCollection = collection(db, "users");
        const userQuerySnapshot = await getDocs(
          query(usersCollection, where("email", "==", user.email))
        );
        if (userQuerySnapshot.empty) {
          // User doesn't exist in Firestore, save user data
          const userDoc = doc(usersCollection, user.uid);
          const userData = {
            email: user.email,
            displayName: user.displayName,
            uid: user.uid,
            profile_picture: user.photoURL,
            emailVerified: user.emailVerified,
            isAnonymous: user.isAnonymous,
            created_at: user.metadata.createdAt,
            creationTime: user.metadata.creationTime,
            phoneNumber: user.phoneNumber,
            providerId: user.providerId,
          };

          // Save the user data to Firestore
          await setDoc(userDoc, userData);

          // // Create initial wallet for the user under a specific organization
          // const initialWalletData = { ...WalletSchema }; // <-- Use the schema

          // const walletDoc = doc(userDoc, "Wallets", orgId);
          // await setDoc(walletDoc, initialWalletData);
        } else {
          // User already exists in Firestore
          return;
        }
      } catch (error) {
        console.error("Error recording user data to Firestore:", error);
      }
    };

    const handleAuthSuccess = async (userCredential, redirect) => {
      if (!userCredential || !userCredential.user) {
        console.log(
          "Invalid userCredential or userCredential.user is undefined."
        );
        return;
      }
      await store.commit("SET_MENU_OPEN", false);
      await store.dispatch("fetchAndCommitUserUnits", userCredential.user.uid);
      await store.dispatch(
        "fetchAndCommitUserUnitsCustomOrgs",
        userCredential.user.uid
      );
      let successMessage = "You're logged in!";
      // if (
      //   router.currentRoute.value.path.indexOf("/invite/") !== 0 &&
      //   redirect
      // ) {
      // router.push("/wallet");
      // store.dispatch("changeActiveTab", "wallet");
      store.dispatch("toggleLoginModalNoRedirect");

      // }

      // emit("after-login", userCredential.user);

      setTimeout(toastNote, 800);
      function toastNote() {
        toast.success(successMessage, {
          autoClose: 2000,
        });
      }
    };

    const handleAuthError = (err) => {
      console.log("handleAuthError on Login");
      console.log(err);
      console.log(err.code);

      switch (err.code) {
        case "auth/invalid-email":
          error.value = t("AuthMessages.invalidEmail");
          break;

        case "auth/user-not-found":
          error.value = t("AuthMessages.noAccount");

          // Emit the email value to the parent so it can be passed to the Register modal
          emit("show-register", email.value); // Emit the email to the parent component

          if (props.redirect === true) {
            store.dispatch("toggleLoginModal", !showLoginModal.value);
            store.dispatch("toggleRegisterModal", !showRegisterModal.value);
          } else {
            store.dispatch(
              "toggleLoginModalNoRedirect",
              !showLoginModalNoRedirect.value
            );
            store.dispatch(
              "toggleRegisterModalNoRedirect",
              !showRegisterModalNoRedirect.value
            );
          }
          break;

        case "auth/invalid-password":
          error.value = t("AuthMessages.weakPassword");
          break;

        case "auth/missing-password":
          error.value = t("AuthMessages.missingPassword");
          break;

        case "auth/wrong-password":
          error.value = t("AuthMessages.wrongPassword");
          break;

        case "auth/network-request-failed":
          error.value = t("AuthMessages.networkError");
          break;

        case "auth/unauthorized-domain":
          error.value = t("AuthMessages.unauthorizedDomain");
          break;

        default:
          error.value = t("AuthMessages.unknownError");
          break;
      }
      toast.error(error.value);
    };

    // BUTTONS
    const continueWithGoogle = t("buttons.continueWithGoogle");
    const buttonIcon = "../../assets/icons/iconset.svg#hide"; // Replace with your variable for the icon

    const handleButtonClick = () => {
      // Handle the button click event
    };

    return {
      email,
      password,
      error,
      success,
      assetPath,
      showPassword,
      logInEmailPassword,
      loginWithGoogle,
      // BUTTONs' Props and emits
      buttonIcon,
      handleButtonClick,
      buttonText,
      continueWithGoogle,
    };
  },
};
</script>

<style scoped>
/* Show/Hide button */
.form-group button.show-password-button {
  position: absolute;
  width: 0px;
  padding: 0px;
  border: none;
  background: transparent;
  opacity: 0.3;
  transition: all 0.15s;
}

.form-group button.show-password-button:hover {
  opacity: 0.7;
  transform: translateY(var(--transY));
}

.form-group svg {
  position: absolute;
  top: 50%;
  right: var(--smallMargin);
  transform: translateY(calc(var(--smallMargin) / 2));
  cursor: pointer;
}
</style>
