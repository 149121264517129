<template>
  <div class="IntroBankCard fullWidth">
    <div class="cardCont">
      <div class="account-card">
        <form @submit.prevent="save" id="customEditCoinNameIBC">
          <div class="row alignCentreRow fullWidth">
            <div class="form-group coinAmount">
              <input
                type="number"
                v-model.number="coinAmount"
                min="1"
                :placeholder="'Coins'"
                required
                class="alignCentre inputInSticker coinAmount"
              />
            </div>
            <div class="currencySymbol">
              <CoinSymbol class="coin-symbol" />
            </div>
            <div class="form-group coinName">
              <input
                type="text"
                v-model="coinName"
                :placeholder="'Coin name: i.e. Unit'"
                required
                class="alignCentre inputInSticker coinName"
              />
            </div>
          </div>
        </form>
        <div class="balance">
          <span class="noWrap">
            <span class="balance-units">{{ balanceUnits }}</span
            ><strong
              >.<span class="balance-decimals">{{ balanceDecimals }}</span>
              {{ coinSymbol }}
            </strong></span
          >
        </div>
        <div class="separator_sticker"></div>
        <div class="createCoinButton">
          <CreateOrgSimplified
            :coinName="coinName"
            :coinSymbol="coinSymbol"
            :user="user"
            :coinAmount="coinAmount"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, watch, computed, reactive, ref } from "vue";
import { useStore } from "vuex";
import { auth } from "@/firebase";
import CreateOrgSimplified from "@/components/LandingComponents/CreateOrgSimplified.vue";
import CoinSymbol from "@/components/GenericComponents/CoinSymbol.vue";

export default {
  components: {
    CreateOrgSimplified,
    CoinSymbol,
  },
  props: {
    user: Object,
  },
  setup(props, { emit }) {
    const store = useStore();
    const coinAmount = ref(1);

    // New action to set Monetary rules
    const setMonetaryParameters = () => {
      // for coin symbol component
      store.dispatch("updateCoinSymbol", {
        symbol: updatedValues.coinSymbol || "Ʉ",
        name: updatedValues.coinName || "Unit",
      });
      // for Monetary rules
      const payload = {
        coinName: updatedValues.coinName || "Unit",
        coinSymbol: updatedValues.coinSymbol || "Ʉ",
      };
      store.commit("SET_MONETARY_PARAMS", payload);
    };

    const updatedValues = reactive({
      coinName: "Unit",
      coinSymbol: "Ʉ",
    });

    const handleCoinDetailsUpdate = (updatedDetails) => {
      updatedValues.coinName = updatedDetails.coinName;
      updatedValues.coinSymbol = updatedDetails.coinSymbol;
    };

    onMounted(async () => {
      auth.onAuthStateChanged((user) => {
        store.dispatch("fetchUser", user);
        store.dispatch("fetchUserCredentials", user); // Assuming user object has the credentials
      });
    });

    const user = computed(() => {
      return store.getters.user;
    });

    watch(updatedValues, (newVal) => {
      emit("update:allValues", newVal);
    });

    const coinName = ref("");
    const coinSymbolMap = {
      A: "₳",
      B: "฿",
      C: "Ċ",
      D: "Đ",
      E: "Ē",
      F: "₣",
      G: "₲",
      H: "Ħ",
      I: "Ī",
      J: "Ĵ",
      K: "₭",
      L: "Ł",
      M: "₼",
      N: "₦",
      O: "Ɵ",
      P: "₱",
      Q: "Ƣ",
      R: "Ɍ",
      S: "Ŝ",
      T: "₸",
      U: "Ʉ",
      V: "∀",
      W: "₩",
      X: "Ẋ",
      Y: "¥",
      Z: "Ƶ",
    };

    const coinSymbol = ref("Ʉ"); // default value

    const balance = computed(() => {
      const amount = parseFloat(coinAmount.value);
      return isNaN(amount) ? "0.00" : amount.toFixed(2);
    });

    const balanceUnits = computed(() => balance.value.split(".")[0]);
    const balanceDecimals = computed(() => balance.value.split(".")[1] || "00");

    watch(coinName, (newVal) => {
      const effectiveCoinName = newVal || "Unit";
      updatedValues.coinName = effectiveCoinName;

      const firstLetter = effectiveCoinName[0].toUpperCase();
      coinSymbol.value = coinSymbolMap[firstLetter] || "Ʉ"; // default to 'Ʉ' if no match

      updatedValues.coinSymbol = coinSymbol.value;
      emit("update:coin-name", {
        coinName: effectiveCoinName,
        coinSymbol: coinSymbol.value,
      });
      setMonetaryParameters();
    });

    // Coin flip transition logic
    const coinKey = ref(0); // to force re-rendering
    const isRotating = ref(false);
    const rotateClass = ref("");

    watch(coinSymbol, (newVal, oldVal) => {
      if (newVal !== oldVal && !isRotating.value) {
        isRotating.value = true;

        setTimeout(() => {
          rotateClass.value = "rotate-to-90";
        }, 0);

        setTimeout(() => {
          rotateClass.value = "rotate-to-0";
          coinSymbol.value = newVal;
        }, 150);

        setTimeout(() => {
          isRotating.value = false;
          rotateClass.value = ""; // reset to original state
        }, 300); // end of your animation duration
      }
    });

    return {
      user,
      coinName,
      coinSymbol,
      coinAmount,
      balanceUnits,
      balanceDecimals,
      rotateClass,
      handleCoinDetailsUpdate,
    };
  },
};
</script>

<style scoped>
.IntroBankCard {
  width: 50%;
  padding-top: 31.41%;
  border-radius: var(--borderRadiusBigger);
  position: relative; /* Needed for absolute positioning of content inside */
}

.account-card {
  /* Positions the card content using the top padding of IntroBankCard to maintain aspect ratio */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--purplePastelWashed) !important;
  border-radius: var(--borderRadiusBigger);
  box-shadow: var(--bankCardWithSticker);
  overflow: hidden;
  padding: var(--smallMargin);
  backdrop-filter: blur(8px);
  display: block; /* Enable flex container */
}

/* Position your content absolutely within the `.account-card` */
.account-card > * {
  position: absolute;
  left: 0;
  width: 100%;

  /* Additional styles to position and style your content */
}

.createCoinButton {
  position: absolute;
  bottom: var(--smallMargin);
  left: 0;
  width: 100%;
  height: var(--clickableHeight);
  padding: var(--smallMargin);
  display: flex;
  justify-content: center;
  align-items: center;
}
.separator_sticker {
  position: absolute;
  bottom: calc(var(--clickableHeight) + calc(var(--smallMargin)));
  left: var(--smallMargin);
  width: calc(100% - var(--smallMargin) * 2);
  height: 1px;
  background-color: var(--solidLighterGrey);
  transform: translateY(-50%);
}
#customEditCoinNameIBC {
  padding: 0 var(--smallMargin);
}
.form-group {
  min-width: initial;
}
.form-groupHalf {
  min-width: auto;
  width: 100%;
}

.form-group.coinAmount {
  min-width: 30%;
}
.form-group.coinName {
  min-width: 60%;
}
.coinAmount {
  width: 100%;
}

.coinName {
  min-width: 40%;
}

.formblock {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.avg.margin4px {
  margin-bottom: 4px;
}

/* flipping the coin: same styles as in your Navbar component */
.circle {
  transition: transform 0.15s ease-in-out;

  background-color: var(--solidLighterGrey);
}

.coin-flip-enter-active,
.coin-flip-leave-active {
  animation: spin 0.15s linear forwards;
}

.rotate-to-90 {
  animation: rotateTo90 0.15s linear forwards;
}

.rotate-to-0 {
  animation: rotateTo0 0.15s linear forwards;
}

.currencySymbol {
  position: absolute;
  transform: translateX(6px);
}

.balance {
  padding: var(--smallMargin);
  gap: var(--smallMargin);

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the element */
  display: flex;
  align-items: center;
  justify-content: center;
}

.balance-units {
  font-size: 40px;
  font-weight: lighter;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
}

.balance-decimals {
  font-size: 30px;
  font-weight: normal;

  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
}

@keyframes rotateTo90 {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(90deg);
  }
}

@keyframes rotateTo0 {
  0% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

@media only screen and (max-width: 450px) {
  .form-group {
    min-width: auto;
  }
  .input {
    width: 100%;
  }
  .IntroBankCard {
    width: 100%;
    padding-top: 62.83%;
  }
}

@media only screen and (max-width: 650px) {
  .IntroBankCard {
    width: 100%;
    padding-top: 62.83%;
  }
}

@media only screen and (max-width: 350px) {
  .IntroBankCard {
    width: 100%;
    padding-top: 62.83%;
  }
}
</style>
