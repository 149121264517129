<template>
  <div class="alertCard innerSticker" :class="alertClass" v-if="showAlert">
    <div class="column" :class="{ gap: gap }">
      <!-- Close button -->
      <svg
        class="smallClose compactImg"
        v-if="canClose"
        @click="closeAlert"
        v-haptic
      >
        <use href="@/assets/icons/iconset.svg#compactCross"></use>
      </svg>
      <div
        class="row iconTextRow alignTop"
        :class="{ iconTextRowFullWidth: !canClose }"
      >
        <!-- // use the following iconID props IN PARENT COMPONENT: error_warning, info, or any other custom icon in iconset.svg -->
        <svg class="compactImg" v-if="iconId">
          <use :href="iconHref"></use>
        </svg>

        <p>
          <slot name="text"></slot>
        </p>
      </div>
      <div
        class="row fullWidth100pct"
        :class="{
          justifyToEnd: justifyToEnd,
          justifyToStart: justifyToStart,
          justifyToCentre: justifyToCentre,
        }"
      >
        <div class="column fullWidth100pct">
          <div
            v-show="type === 'info' && canBeDismissed"
            class="separator_sticker"
          ></div>
          <ToggleSwitch
            v-show="type === 'info' && canBeDismissed"
            label="Don't show again"
            v-model="dismissInfo"
            :hideStatusText="true"
            :useLabelTag="true"
            :justifyToEnd="true"
          />
          <slot name="button"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// INSTRUCTIONS
// On the parent compoent use a Unique ID to control localstorage visibility
// if prop type==info then it wil show a toggle "don't show again"
// use justifyToStart/End to change aligment on the fullWidth100pct (last row with button and toggle "don't show again")
//
// ALERT CARD EXAMPLES
//          <AlertCard type="info" id="dev_contact" iconId="info">
//           <template #text>
//             Unit is still under development. We will start working with a selected
//             group of organisations soon. Please get in touch if you are
//             interested.
//           </template>
//           <template #button>
//             <button>Contact Us</button>
//           </template> </AlertCard
//         >
//         <AlertCard type="warning" id="test" iconId="error_warning">
//           <template #text>
//             Warning message goes here. Warning d d d d d d d d d d d d goes here
//           </template>
//         </AlertCard>
//         <AlertCard type="error" id="testa" iconId="error_warning">
//           <template #text> Error message goes here </template>
//         </AlertCard>

import { ref, computed, watchEffect, toRefs } from "vue";
import ToggleSwitch from "@/components/GenericComponents/ToggleSwitch.vue";
import iconset from "@/assets/icons/iconset.svg";

export default {
  name: "AlertCard",
  components: { ToggleSwitch },
  props: {
    type: {
      type: String,
      default: "null", // info, warning, error
    },
    iconId: String, // ID for SVG
    id: {
      type: String,
      required: true,
    }, // ID for this alert to control localStorage show/hide
    canBeDismissed: {
      type: Boolean,
      default: true,
    },
    justifyToStart: {
      type: Boolean,
      default: false,
    }, // for toggle and button aligmnent
    justifyToEnd: {
      type: Boolean,
      default: false,
    }, // for toggle and button aligmnent
    justifyToCentre: {
      type: Boolean,
      default: false,
    }, // for toggle and button aligmnent
    gap: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { slots }) {
    const iconHref = computed(() => `${iconset}#${props.iconId}`);
    // Whether to show the alert
    const showAlert = ref(true);
    // Whether the user can close the alert
    const canClose = computed(
      () => props.canBeDismissed && props.type !== "error"
    );

    const hasButtonSlot = computed(() => !!slots.button);

    // Close the alert
    const closeAlert = () => {
      showAlert.value = false;
      const storageKey = `${props.type}_${props.id}`;

      if (props.type === "warning") {
        localStorage.setItem(
          `${storageKey}_hideUntil`,
          Date.now() + 12 * 3600 * 1000
        );
      }
      if (props.type === "info" && dismissInfo.value) {
        localStorage.setItem(`${storageKey}_dismissed`, "true");
      }
    };
    // Whether the user opts to dismiss info alerts
    const dismissInfo = ref(false);

    // Check local storage to determine if alert should be shown
    watchEffect(() => {
      const storageKey = `${props.type}_${props.id}`;

      if (props.type === "warning") {
        const hideUntil = localStorage.getItem(`${storageKey}_hideUntil`);
        if (hideUntil && Date.now() < hideUntil) {
          showAlert.value = false;
        }
      }

      if (props.type === "info") {
        const infoDismissed = localStorage.getItem(`${storageKey}_dismissed`);
        if (infoDismissed === "true") {
          showAlert.value = false;
        }
      }
    });

    const alertClass = computed(() => {
      let baseClass = "alert_";
      switch (props.type) {
        case "info":
          return `${baseClass}info`;
        case "warning":
          return `${baseClass}warning`;
        case "error":
          return `${baseClass}error`;
        case "success": // Add this case
          return `${baseClass}success`;
        default:
          return `${baseClass}info`;
      }
    });

    return {
      iconHref,
      alertClass,
      showAlert,
      canClose,
      closeAlert,
      dismissInfo,
      hasButtonSlot,
    };
  },
};
</script>

<style scoped>
.alertCard {
  width: 100%;
  position: relative;
  margin-top: var(--smallMargin);
  margin-bottom: var(--smallMargin);
  border: 1px solid var(--solidMidLightgrey);
  padding: var(--margin);
  border-radius: var(--borderRadius);
}
.alertCard .column {
  display: flex;
  align-items: flex-start;
  gap: 0px;
}

.alertCard .column.gap {
  gap: var(--smallMargin);
}

.alertCard .column > :last-child {
  margin-bottom: 0;
}

svg.compactImg {
  width: calc(var(--smallClickableHeight) * 0.6) !important;
  height: calc(var(--smallClickableHeight) * 0.6) !important;
  min-width: calc(var(--smallClickableHeight) * 0.6) !important;
  min-height: calc(var(--smallClickableHeight) * 0.6) !important;
}

.alert_info {
  background-color: var(--lightBlueWashed);
}

.alert_warning {
  background-color: var(--lightYellowWashed);
}

.alert_success {
  background-color: var(--lightGreen);
}

.alert_error {
  background-color: var(--lightestred);
}

.iconTextRow {
  display: flex;
  align-items: flex-start;
  width: calc(100% - calc(var(--smallClickableHeight) * 0.4));
}

.iconTextRowFullWidth {
  width: 100%;
}

.alert_info p,
.alert_warning p,
.alert_error p {
  margin: 0;
  flex-grow: 1;
}

.innerSticker {
  height: auto;
  margin-bottom: 0;
}
</style>
