import { db } from "@/firebase";
import { getAuth } from "firebase/auth";
import { ref } from "vue";
import {
  addDoc,
  setDoc,
  updateDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  serverTimestamp,
  writeBatch,
} from "firebase/firestore";
import { toast } from "vue3-toastify";
const auth = getAuth();
import { WalletSchema } from "@/schemas/WalletSchema";
import { OrgSchema } from "@/schemas/OrgSchema";

export const createCustomOrganisation = async (store, orgDetails) => {
  // De-structure fields from orgDetails
  console.log("ORG details");
  console.log(JSON.stringify(orgDetails));
  const {
    organisationName,
    description,
    category,
    basicIncome,
    coinName,
    coinSymbol,
    baseFounderCompensation,
    currentAccountCap,
    allowSavings,
    infiniteSavings,
    currentAccountLifespan,
    averageSavingsAccountCap,
    loans,
    nextTriggerTime,
    initialCoins,
  } = orgDetails;

  console.log(
    `${organisationName} | ${description} | ${category} | ${basicIncome} | ${coinName} | ${coinSymbol} | ${baseFounderCompensation} | ${currentAccountCap} | ${currentAccountLifespan} | ${allowSavings} |  ${infiniteSavings} |savings account cap ${averageSavingsAccountCap} | ${loans} `
  );

  let currentUserUid = auth.currentUser ? auth.currentUser.uid : null;
  const organisations = ref(null);

  // Creates a new document in the "custom-orgs" collection and returns its reference
  const customOrgsDocRef = await addDoc(collection(db, "custom-orgs"), {});
  const customOrgsUid = customOrgsDocRef.id;

  const nextTriggerTimestamp = nextTriggerTime; // Directly use the Date object

  // Defines the organisationName
  try {
    // Create a new document under "custom-orgs" collection
    await setDoc(doc(db, "custom-orgs", customOrgsUid), {
      name: organisationName, // Use the organisationName parameter
      description: description, // Use the description parameter
      category: category, // Use the category parameter
      natural_persons: false,
      createdAt: serverTimestamp(), // Adding the timestamp here
      allowTrades: orgDetails.allowTrades,
      monetary_parameters: {
        coin_name: coinName,
        coin_symbol: coinSymbol,
        basic_income: basicIncome,
        regularity: orgDetails.regularity,
        nextTriggerTime: nextTriggerTimestamp,
        initialCoins: initialCoins,
        current_account: {
          cap: currentAccountCap,
          lifespan: currentAccountLifespan, // days
        },
        savings_account: {
          cap: averageSavingsAccountCap,
          allowSavings: allowSavings,
          infiniteSavings: infiniteSavings,
        },
        max_shared_pool: {
          cap: 1200000,
        },
        loans: {
          dti_effortRate: loans.dti_effortRate,
          maxLoanTerm: loans.maxLoanTerm,
          annualInterest: loans.annualInterest,
          allowIndividual: loans.allowIndividual,
          allowJoint: loans.allowJoint,
        },
      },
    });

    // Create a sub-collection "Roles" under the newly created [customOrgsUid] document
    const rolesCollectionRef = collection(
      db,
      `custom-orgs/${customOrgsUid}/Roles`
    );
    /// Roles Subcollection ///
    // Add documents to the "Roles" sub-collection
    const founderTitle = "Founder";
    const founderRoleDesc =
      "The founder's role may include leading the organisation's creation and development, inspire a community, or simply start a project.";
    let roleSlots = 1; // Default slots for initial founder role

    const roleDocRef = await addDoc(rolesCollectionRef, {
      roleTitle: founderTitle,
      public: true,
      roleDescription: founderRoleDesc,
      slots: roleSlots,
      slotsFilled: 1,
      baseCompensation: orgDetails.baseFounderCompensation,
      editablePrivileges: false,
      privileges: {
        sub_unitCreation: true,
        monetaryParameters: true,
        naturalPersonVerification: true,
        userModeration: true,
        userRegistration: true,
        roleAdministration: true,
        roleCreation: true,
      },
    });

    // Assign the created organisation document ID to the 'organisation' ref
    const roleUID = roleDocRef.id;
    await updateDoc(
      doc(rolesCollectionRef, roleDocRef.id),
      { roleUID },
      { merge: true }
    );
    // Create the 'RoleIDs' sub-collection under the user document
    const userRolesCollectionRef = collection(
      db,
      `users/${currentUserUid}/RoleIDs`
    );

    const joinTimestamp = serverTimestamp();

    // Add the roleUID document with joined-at and joiningTime fields
    await setDoc(doc(userRolesCollectionRef, roleUID), {
      joined: serverTimestamp(),
      organisationName: organisationName,
      organisationUID: customOrgsUid,
      roleTitle: founderTitle,
    });

    // Add documents to the "Roles" sub-collection again, now with the filledBy field
    await setDoc(
      doc(rolesCollectionRef, roleDocRef.id),
      {
        filledBy: {
          [currentUserUid]: {
            natural_person: true,
            isAnonymous: auth.currentUser.isAnonymous,
            // Conditionally add user_details based on the user's anonymous status
            ...(auth.currentUser.isAnonymous
              ? {} // If anonymous, no need to include user_details
              : {
                  user_details: {
                    displayName: auth.currentUser.displayName,
                    photoURL: auth.currentUser.photoURL,
                    email: auth.currentUser.email,
                  },
                }),
          },
        },
      },
      { merge: true }
    );

    /// Basic Income Subcollection ///
    // Create a sub-collection "BasicIncome" under the newly created document
    const basicIncomeCollectionRef = collection(
      db,
      `custom-orgs/${customOrgsUid}/BasicIncome`
    );

    // Generate a timestamp to use as the document ID
    const timestamp = Date.now();
    // Add documents to the "BasicIncome" sub-collection with the timestamp as the document ID
    await setDoc(doc(basicIncomeCollectionRef, timestamp.toString()), {
      value: basicIncome,
    });

    /// Members Subcollection ///
    // Create a separate collection "Members" for the organization
    const membersCollectionRef = collection(
      db,
      `custom-orgs/${customOrgsUid}/Members`
    );

    // Add documents to the "Members" collection
    await setDoc(doc(membersCollectionRef, currentUserUid), {
      roleID: null,
      userUID: currentUserUid,
      roleTitle: founderTitle,
      isAnonymous: auth.currentUser.isAnonymous,
      joinedUnix: serverTimestamp(),
      // Include additional fields from the user object if not anonymous
      ...(auth.currentUser.isAnonymous
        ? {} // If anonymous, no need to include sub-sub-collection
        : {
            user_details: {
              displayName: auth.currentUser.displayName,
              photoURL: auth.currentUser.photoURL,
              email: auth.currentUser.email,
            },
          }),
    });

    /// Update User node  ///
    // Gets a reference to the Units subcollection under the user document
    const userUnitsCollectionRef = collection(
      db,
      `users/${currentUserUid}/Units`
    );

    // Checks if the Units subcollection exists and if customOrgsUid document exists in it
    const unitDocSnapshot = await getDoc(
      doc(userUnitsCollectionRef, customOrgsUid)
    );

    if (unitDocSnapshot.exists()) {
      // If the customOrgsUid document exists, shows a warning toast
      toast.warning("You already belong to this unit", {
        autoClose: 2000,
      });
    } else {
      // If the customOrgsUid document doesn't exist, creates it with joined and currentRole fields
      const joinTimestamp = serverTimestamp();

      await setDoc(doc(userUnitsCollectionRef, customOrgsUid), {
        joined: joinTimestamp,
        currentRole: roleUID,
        organisationName: organisationName,
        organisationUID: customOrgsUid,
      });

      // Manually add the data to the Vuex state
      store.commit("SET_USER_UNITS", {
        joined: joinTimestamp,
        currentRole: roleUID,
        organisationName: organisationName,
        organisationUID: customOrgsUid,
      });
    }
    const initialWalletData = {
      ...WalletSchema,
      allowTrades: orgDetails.allowTrades,
      isActiveMember: true,
      monetary_parameters: {
        coin_name: coinName,
        coin_symbol: coinSymbol,
        basic_income: basicIncome,
        regularity: orgDetails.regularity,
        nextTriggerTime: nextTriggerTimestamp,
        current_account: {
          cap: currentAccountCap,
          lifespan: currentAccountLifespan,
        },
        savings_account: {
          cap: averageSavingsAccountCap,
          allowSavings: allowSavings,
          infiniteSavings: infiniteSavings,
        },
        max_shared_pool: {
          cap: 1200000,
        },
        loans: loans,
      },
    };

    // Get a reference to the new wallet document in the 'Wallets' sub-collection under the user document
    const walletDocRef = doc(
      db,
      "users",
      currentUserUid,
      "Wallets",
      customOrgsUid
    ); // Adjust this path as needed

    try {
      // Set the initial wallet data
      await setDoc(walletDocRef, initialWalletData);
      console.log(
        `Wallet data set for user ${currentUserUid} in org ${customOrgsUid}`
      );
    } catch (error) {
      console.error("Error setting wallet data:", error);
    }
    // Updates organisation reference value
    organisations.value = customOrgsDocRef.id;

    // Update products and services owned by the user
    const productsQuerySnapshot = await getDocs(
      query(collection(db, "products"), where("owner", "==", currentUserUid))
    );

    const batch = writeBatch(db);

    productsQuerySnapshot.forEach((productDoc) => {
      batch.update(productDoc.ref, {
        organisation: customOrgsUid,
        coinName: orgDetails.coinName.trim(),
        coinSymbol: orgDetails.coinSymbol,
        price: 0,
      });
    });

    const servicesQuerySnapshot = await getDocs(
      query(collection(db, "services"), where("owner", "==", currentUserUid))
    );

    servicesQuerySnapshot.forEach((serviceDoc) => {
      batch.update(serviceDoc.ref, {
        organisation: customOrgsUid,
        coinName: orgDetails.coinName.trim(),
        coinSymbol: orgDetails.coinSymbol,
        price: 0,
      });
    });

    await batch.commit();
    console.log("Batch commit successful");

    let successMessage = "🎉 Done! Unit created.";
    await store.dispatch("fetchAndCommitUserUnitsCustomOrgs", currentUserUid);
    await store.dispatch("fetchBasicOrgDetails", customOrgsDocRef.id);

    toast.success(successMessage, {
      autoClose: 2000,
    });
    return customOrgsUid;
  } catch (error) {
    // Handles any error that occurs during the process
    toast.error(
      "Error. There was an error. Please wait while we deal with issue.",
      error,
      {
        autoClose: 5000,
      }
    );
    console.error("Error creating Custom organisation:", error);
  }
  document.body.classList.remove("no-scroll");
};
