<template>
  <div
    @click-outside="closeMenu"
    :class="{ open: data.showMenu === true, shadow: true }"
    class="menuOpen"
    id="nav"
    v-haptic
  >
    <div
      @click="toggle()"
      :class="{ open: data.showMenu === true }"
      id="nav-icon1"
    >
      <span></span>
      <span></span>
      <span></span>
    </div>

    <div v-if="data.showMenu" id="menulist" v-click-outside="closeMenu">
      <span v-if="data.showMenu" class="menulistspan">
        <div class="menu-item menu-link" @click="copyToClipboard">
          <div class="row alignCentreRow">
            <svg class="icon compactImg">
              <use href="@/assets/icons/iconset.svg#link-compact"></use>
            </svg>
            Copy URL
          </div>
        </div>
        <!-- display this when user is logged in -->
        <span v-if="user.loggedIn" class="menuItems" id="loggedInMenuItems">
          <!--   <div v-haptic class="menu-item menu-link" @click="toggleMessages">
            <div class="row alignCentreRow">
              <svg class="icon compactImg">
                <use href="@/assets/icons/iconset.svg#message_compact"></use>
              </svg>
              Messages
            </div>
          </div>
          <router-link
            v-haptic
            class="menu-item menu-link"
            to="/ecosystem"
            @click.native="resetActiveState('ecosystem'), closeMenu()"
          >
            <div
              class="row alignCentreRow"
              :class="{ active: activeTab === 'ecosystem' }"
              @click="changeTab('ecosystem')"
            >
              <svg class="icon compactImg">
                <use href="@/assets/icons/iconset.svg#shop-compact"></use>
              </svg>
              Marketplace
            </div>
          </router-link>

          <router-link
            v-haptic
            class="menu-item menu-link"
            to="/wallet"
            @click.native="resetActiveState('wallet'), closeMenu()"
          >
            <div
              class="row alignCentreRow"
              :class="{ active: activeTab === 'wallet' }"
              @click="changeTab('wallet')"
            >
              <svg class="icon compactImg">
                <use href="@/assets/icons/iconset.svg#wallet-compact"></use>
              </svg>
              Wallet
            </div>
          </router-link> -->

          <!-- <router-link
            v-haptic
            class="menu-item menu-link"
            to="/invites"
            @click.native="resetActiveState('invites'), closeMenu()"
          >
            <div class="row alignCentreRow">
              <svg class="icon compactImg">
                <use href="@/assets/icons/iconset.svg#invite-compact"></use>
              </svg>
              Invites
            </div>
          </router-link> -->

          <router-link
            v-haptic
            class="menu-item menu-link"
            to="/dashboard"
            @click.native="resetActiveState('dashboard'), closeMenu()"
          >
            <div class="row alignCentreRow">
              <svg class="icon compactImg">
                <use href="@/assets/icons/iconset.svg#dashboard-compact"></use>
              </svg>
              Admin dashboard
            </div>
          </router-link>

          <router-link
            v-haptic
            class="menu-item menu-link"
            to="/account"
            @click.native="resetActiveState('account'), closeMenu()"
          >
            <div class="row alignCentreRow">
              <svg class="icon compactImg">
                <use href="@/assets/icons/iconset.svg#user_compact"></use>
              </svg>
              Your settings
            </div>
          </router-link>

          <div
            class="menu-item menu-link"
            @click="openAlertModal"
            :class="isSignOutActive"
          >
            <div class="row alignCentreRow">
              <svg class="icon compactImg">
                <use href="@/assets/icons/iconset.svg#signOut-compact"></use>
              </svg>
              Sign out
            </div>
          </div>
        </span>
        <span v-if="!user.loggedIn" class="menuItems" id="loggedOutMenuItems">
          <div
            class="menu-item menu-link"
            @click="toggleLoginModal"
            :class="isSignInActive"
          >
            <div class="row alignCentreRow">
              <svg class="icon compactImg">
                <use href="@/assets/icons/iconset.svg#signIn-compact"></use>
              </svg>
              Sign in
            </div>
          </div>
          <router-link
            v-haptic
            class="menu-item menu-link"
            to="/About"
            @click.native="resetActiveState, closeMenu()"
          >
            <div class="row alignCentreRow">
              <svg class="icon compactImg">
                <use href="@/assets/icons/iconset.svg#owl-compact"></use>
              </svg>
              {{ $t("menu.about") }}
            </div>
          </router-link>
        </span>
      </span>
    </div>
    <AlertModalLocal
      :visible="showAlertModal"
      :title="$t('menu.signOut')"
      :messageLineOne="$t('AlertModalLocal.messageLineOne')"
      @confirm="confirmSignOut"
      @close="closeAlertModal"
      @click-outside="closeAlertModal"
    >
      <!-- Custom buttons -->
      <button class="secondaryButton" v-haptic @click="closeAlertModal">
        {{ $t("buttons.cancel") }}
      </button>
      <button v-haptic @click="confirmSignOut">
        {{ $t("buttons.yesLogMeOut") }}
      </button>
    </AlertModalLocal>
  </div>
</template>

<script>
import { inject, reactive, computed, ref, watch } from "vue";
import { useStore, mapMutations } from "vuex";
import { getAuth, signOut } from "firebase/auth";
import { useRouter } from "vue-router";
import AlertModalLocal from "@/components/GenericComponents/AlertModalLocal.vue"; // Import the AlertModal component
import LanguageSelection from "@/components/GenericComponents/LanguageSelection.vue";
import { toast } from "vue3-toastify";

export default {
  name: "Menu",
  components: {
    AlertModalLocal,
    LanguageSelection,
  },
  methods: {
    ...mapMutations(["toggleLoginModal", "changeActiveTab"]), // Ensure changeActiveTab is mapped here
    toggleModal() {
      this.toggleLoginModal(!this.$store.state.showLoginModal);
    },
    changeTab(tabName) {
      this.changeActiveTab(tabName);
    },
    resetActiveState(tabName) {
      this.changeActiveTab(tabName);
    },
  },
  setup() {
    const router = useRouter();
    const store = useStore(); // Use the store
    const showAlertModal = ref(false); // Reactive variable for the alert modal

    // Use a reactive object to track whether each div is currently active.
    const divIsActive = reactive({
      signIn: false,
      signOut: false,
    });
    // Toggle the active state of the sign in div.
    const toggleSignInActive = () => {
      divIsActive.signIn = !divIsActive.signIn;
    };
    const toggleSignOutActive = () => {
      divIsActive.signOut = !divIsActive.signOut;
    };

    // Reset the active state of the sign in/out divs.
    const resetActiveState = (tabName) => {
      store.dispatch("changeActiveTab", tabName);

      divIsActive.signIn = false;
      divIsActive.signOut = false;
    };

    const copyToClipboard = () => {
      const url = window.location.href;

      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard
          .writeText(url)
          .then(() => {
            toast.success("URL copied to clipboard", {
              autoClose: 2000,
            });
          })
          .catch((err) => {
            console.error("Failed to copy: ", err);
            toast.error("Cannot copy to clipboard", {
              autoClose: 2000,
            });
          });
      } else {
        // Fallback for unsupported browsers
        const textArea = document.createElement("textarea");
        textArea.value = url;
        textArea.style.position = "fixed"; // Fixed positioning
        textArea.style.top = "0"; // Position it at the top
        textArea.style.left = "-9999px"; // Move it off-screen
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand("copy");
          toast.success("URL copied to clipboard", {
            autoClose: 2000,
          });
        } catch (err) {
          console.error("Fallback: Oops, unable to copy", err);
          toast.error("Cannot copy to clipboard", {
            autoClose: 2000,
          });
        }
        document.body.removeChild(textArea);
      }
      closeMenu();
    };

    // Change the classes assigned based on the active state.
    const isSignInActive = computed(() => {
      return {
        "mimic-active-link": divIsActive.signIn && !showLoginModal.value,
      };
    });
    const isSignOutActive = computed(() => {
      return {
        "mimic-active-link": divIsActive.signOut && !showAlertModal.value,
      };
    });

    let data = reactive({
      publicPath: process.env.BASE_URL,
      get showMenu() {
        return store.state.menuOpen;
      },
      set showMenu(value) {
        store.commit("SET_MENU_OPEN", value);
      },
    });

    const areMessagesOpen = computed(
      () => store.getters["UIState/areMessagesOpen"]
    );
    const toggleMessages = () => {
      store.dispatch("UIState/setMessagesMinimised", false);
      store.dispatch("UIState/setMessagesOpen", !areMessagesOpen.value);
      closeMenu();
    };

    const closeMenu = () => {
      data.showMenu = false;
    };

    const toggle = () => {
      event.stopPropagation();
      data.showMenu = !data.showMenu;
      store.commit("SET_MENU_OPEN", data.showMenu); // Update the Vuex state
      store.dispatch("UIState/setMessagesOpen", false);
    };

    const user = computed(() => store.getters.user);
    const activeTab = computed(() => store.getters.activeTab); // Add this line
    const showLoginModal = computed(() => store.state.showLoginModal);

    const toggleLoginModal = async () => {
      data.showMenu = false; // Close the menu

      store.dispatch("toggleLoginModal", !showLoginModal.value);
    };

    const openAlertModal = () => {
      document.body.classList.add("no-scroll");
      showAlertModal.value = true;
    };

    const closeAlertModal = () => {
      document.body.classList.remove("no-scroll");
      showAlertModal.value = false;
    };
    const confirmSignOut = async () => {
      closeAlertModal(); // Close the alert modal
      await store.commit("SET_MENU_OPEN", false);
      setMonetaryParameters();

      await store.dispatch("logOutStateCommit"); // Log out
      await store.dispatch("clearAllState");
      router.push("/"); // Redirect to home route
    };

    const setMonetaryParameters = () => {
      const payload = {
        coinName: "Unit",
        coinSymbol: "Ʉ",
      };
      // for coin symbol component
      store.dispatch("updateCoinSymbol", {
        symbol: "Ʉ",
        name: "Unit",
      });
      // for Monetary rules
      store.commit("SET_MONETARY_PARAMS", payload);
    };

    watch(user, (newValue, oldValue) => {
      // if user was logged in but not anymore, so close the menu if menu was open
      if (oldValue.loggedIn && !newValue.loggedIn) {
        closeMenu();
      }
    });

    return {
      data,
      toggle,
      toggleLoginModal,
      user,
      closeMenu,
      showLoginModal,
      showAlertModal,
      openAlertModal,
      closeAlertModal,
      confirmSignOut,
      isSignInActive,
      isSignOutActive,
      toggleSignInActive,
      toggleSignOutActive,
      resetActiveState,
      toggleMessages,
      areMessagesOpen,
      activeTab,
      copyToClipboard,
    };
  },
};
</script>

<style scoped>
html {
  scroll-behavior: smooth;
}
.no-scroll {
  overflow: hidden;
}

#nav a.button.router-link-active {
  /*  background-color: var(--solidlightgrey) !important; */
}

#nav {
  position: -webkit-sticky;
  position: fixed;
  top: 0;
  right: 0;
  padding: var(--smallMargin) var(--margin) 0 0;
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-end;
  box-sizing: border-box;
  z-index: 1;
  transition: all 0.15s ease;
  box-shadow: none;
  width: 0%;
}
#nav.open {
  background: var(--backgroundColourOrg);
  backdrop-filter: blur(6px);
  box-shadow: 0 2px 6px var(--darkbluetransplight);
  border-bottom: 1px solid var(--solidlightgrey);
  border-bottom-left-radius: var(--borderRadius);
  height: initial;
  width: fit-content;
  max-width: 64vw;
  z-index: 3;
}

.menuOpen {
  background-color: var(--backgroundColourOrg);
  box-shadow: 0 2px 6px var(--darkbluetransplight);
}

#nav a.button,
.aButton {
  margin-left: auto;
  align-self: center;
  background: rgba(255, 255, 255, 0.4);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  color: var(--black);
  border: 1px solid var(--black);
  text-decoration: none;
  z-index: 3;
  box-sizing: border-box;
  font-family: var(--fontlight);
  font-weight: var(--fontweightlight);
}

#menulist {
  padding-top: calc(var(--clickableHeight) + var(--smallMargin));
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  transform: translateX(var(--smallMargin));
}
.menulistspan {
  width: 100%;
  margin-bottom: var(--smallMargin);
}

.menuItems {
  width: 100%;
}

#loggedInMenuItems,
#loggedOutMenuItems {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  align-content: flex-end;
}

.menu-item {
  padding: 0 var(--smallMargin);
  width: 100%;
  height: var(--clickableHeight);
  text-decoration: none;
  color: var(--black);
  transition: all 0.15s;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: center;

  border-radius: var(--borderRadius);
}
.menu-item:hover {
  opacity: 0.8;
  transform: translateY(var(--transY));
  text-decoration: none;
  color: var(--black);
}

.menu-link {
  width: 100%;
  text-align: right;
  margin-top: 5px;
  position: relative;
  cursor: pointer;
}

.menu-link a {
  color: var(--black);
  text-decoration: none; /* Remove underline */
}

.menu-link.router-link-active {
  box-shadow: var(--neuMorphBoxInnerSmall);
  transform: translateY(1px);
}
.menu-link.router-link-active:before {
  box-shadow: var (--neuMorphBoxInnerSmall);
  transform: translateY(1px);
  box-shadow: none;
}

.menu-link.router-link-active + *::before {
  /* To hide the ::before pseudo-element from the immediate sibling after the .router-link-active */
  display: none;
}

.no-active-style.router-link-active {
  box-shadow: none;
  transform: none;
}

.no-active-style.router-link-active::before {
  box-shadow: var(--neuMorphBoxInnerSmall);
}

.menu-link::before {
  content: "";
  position: absolute;
  top: -5px;
  left: 0;
  width: 100%;
  height: 5px;
  box-shadow: var(--neuMorphBoxInnerRidge);
  filter: blur(1px);
}

.menu-item-enter-from,
.menu-item-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

/* mimic buttons for divs that don't route in the menu */
.mimic-active-link {
  box-shadow: var(--neuMorphBoxInnerSmall);
  transform: translateY(1px);
}

/* Icon 1 */
#nav-icon1 {
  position: absolute;
  width: 40px;
  height: 30px;
  z-index: 2;
  transform: rotate(0deg) translateY(calc((var(--clickableHeight) - 32px) / 2));
  transition: transform 0.5s ease-in-out;
  cursor: pointer;
}

#nav-icon1 span {
  display: block;
  position: absolute;
  height: 1px;
  width: 100%;
  background: var(--black);
  border-radius: var(--borderRadius);
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: var(--slideInSpeed) ease-in-out;
  -moz-transition: var(--slideInSpeed) ease-in-out;
  -o-transition: var(--slideInSpeed) ease-in-out;
  transition: var(--slideInSpeed) ease-in-out;
}

#nav-icon1 span:nth-child(1) {
  top: 0px;
}

#nav-icon1 span:nth-child(2) {
  top: 15px;
  height: 1.2px;
}

#nav-icon1 span:nth-child(3) {
  top: 30px;
}

#nav-icon1.open span:nth-child(1) {
  top: 15px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#nav-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -40px;
}
#nav-icon1 span:nth-child(3) {
  margin-bottom: 2px;
}

#nav-icon1.open span:nth-child(3) {
  top: 15px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

/* SMALL SCREENS */

/* SMALL SCREENS */
@media only screen and (max-width: 450px) {
}

@media only screen and (max-width: 900px) {
  #nav a.button {
  }
  #nav a.logo {
    display: inline-flex;
    width: calc(100vw - 60px);
    height: 60px;
    justify-content: center;
    padding: 0 0 1.5rem 0 !important;
  }
}
</style>
