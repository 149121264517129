<template>
  <div class="UVPIntroCoreMessage column alignItemsCentre">
    <h2 class="shortwidth alignCentre smallmarginbottom">
      Create your own monetary system
    </h2>
    <img
      v-if="windowWidth > 450"
      class="svgFullWidth noMultiply"
      :src="largeSvgSrc"
      alt="Hierarchical SVG"
    />
    <img
      v-else
      class="svgFullWidth noMultiply"
      :src="smallSvgSrc"
      alt="Hierarchical SVG"
    />
    <p class="shortwidth smallmargintopnomarginbottom">
      We're in the era of automation, yet we still use old ways made for a time
      when steam engines were cutting edge technology, and labour and capital
      were arguably the sole drivers of value creation.
    </p>
    <p class="shortwidth nomargintop">
      In a world that's accelerating faster than ever, the existing monetary
      framework is outdated, and sluggish. It perpetuates an economic growth
      model that is increasingly harmful to society.
    </p>
    <div class="separator"></div>

    <h2 class="shortwidth alignCentre">The 3 basic rules of Unit <br /></h2>
    <div class="rowOn regularColumns columnOnMobile">
      <div class="column alignCentre">
        <div class="circle">1</div>
        <div class="column sticker gold alignCentre">
          <h3 class="tinymargintopnomarginbottom">
            You can create as many coins as you want
          </h3>
          <p class="nomargintoptinymarginbottom">
            In Unit, everyone can create their own coin. People can create a
            coin just for themselves or create a currency that several people
            use. Regardless of the number of people using a currency, there is
            no limit to the number of coins you can create.
          </p>
        </div>
      </div>
      <div class="column alignCentre">
        <div class="circle">2</div>
        <div class="column sticker gold alignCentre">
          <h3 class="tinymargintopnomarginbottom">
            You can't price anything higher than the amount of coins you create
          </h3>
          <p class="nomargintoptinymarginbottom">
            If you want to sell a product or service, you decide how much things
            cost in your own currency. The only rule here is that you can't
            price anything higher than the total amount of coins existing in
            your organisation.
          </p>
        </div>
      </div>
      <div class="column alignCentre">
        <div class="circle">3</div>
        <div class="column sticker gold alignCentre">
          <h3 class="tinymargintopnomarginbottom">
            Your coins are as valuable as anyone else's coin
          </h3>
          <p class="nomargintoptinymarginbottom">
            Unit exchange rate is anchored to the value of human life rather
            than market forces. It uses a simple smart contract to calculate the
            average amount of currency per person within each organization,
            adjusting transaction prices based on this social metric.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "UVPIntroCoreMessage",
  setup() {
    // Vuex store
    const store = useStore();

    // Reactive data
    const windowWidth = ref(window.innerWidth);

    // Computed property to check if dark mode is enabled
    const isDarkMode = computed(() => store.getters["UIState/isDarkMode"]);

    // Computed properties for SVG sources
    const largeSvgSrc = computed(() =>
      isDarkMode.value
        ? require("@/assets/icons/eraofautomation_fullwidth_white.svg")
        : require("@/assets/icons/eraofautomation_fullwidth.svg")
    );
    const smallSvgSrc = computed(() =>
      isDarkMode.value
        ? require("@/assets/icons/eraofautomation_fullwidth_mobile_white.svg")
        : require("@/assets/icons/eraofautomation_fullwidth_mobile.svg")
    );

    // Function to update windowWidth
    const updateWindowWidth = () => {
      windowWidth.value = window.innerWidth;
    };

    // Listen to window resize event
    onMounted(() => {
      window.addEventListener("resize", updateWindowWidth);
    });

    // Return the reactive data and computed properties to the template
    return {
      windowWidth,
      largeSvgSrc,
      smallSvgSrc,
    };
  },
};
</script>

<style scoped>
.column {
  width: 100%;
}
</style>
