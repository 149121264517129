<template>
  <div class="dashboard main_container">
    <div v-if="user.loggedIn" class="userLoggedIn">
      <YourUnitverse />
    </div>
    <div v-else class="userNotLoggedIn">
      <Landing />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import { auth } from "@/firebase";
import YourUnitverse from "@/components/DashboardComponents/YourUnitverse.vue";
import Landing from "@/components/LandingComponents/Landing.vue";

export default {
  name: "DashboardView",
  components: {
    YourUnitverse,
    Landing,
  },
  setup() {
    const store = useStore();

    // Watch for authentication state changes
    auth.onAuthStateChanged((user) => {
      store.dispatch("fetchUser", user);
      if (user) {
        store.dispatch("fetchUserCredentials", user);
      }
    });

    const user = computed(() => store.getters.user);

    return { user };
  },
};
</script>

<style scoped>
.userLoggedIn,
.userNotLoggedIn {
  width: 100%;
}
</style>
