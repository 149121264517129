<template>
  <div class="table-panel">
    <div
      v-for="(row, rowIndex) in rows"
      :key="`row-${rowIndex}`"
      :class="[
        'row',
        { 'first-row': rowIndex === 0 && !hideFirstRowTopBorder },
        { gray: toggleSwitchState && row.conditional },
      ]"
    >
      <div
        v-for="(col, colIndex) in [row.col1, row.col2]"
        :key="`col-${colIndex}`"
        :class="
          colIndex === 0
            ? 'first-column'
            : colIndex === 1
            ? isEditButtonFunction && showEditButton(row) === 'Auto'
              ? 'second-column wide-content'
              : 'second-column'
            : ''
        "
      >
        <span v-if="typeof col === 'string' && String(col).endsWith('U')">
          {{ col }} {{ monetaryParameters }}
        </span>
        <span v-else-if="typeof col === 'string'">{{ col }}</span>

        <span v-else-if="typeof col === 'object' && col.text">
          {{ col.text }}
          <span
            v-if="showReadMoreButton"
            class="readMore divTinyMarginTop clickableAnim smallText"
            @click="emitToggleDescription"
          >
            {{ col.button }}
            <span v-if="col.button == 'Read less'" class="dropdown-arrow">
              <svg class="tinyImg">
                <use
                  href="@/assets/icons/iconset.svg#arrowup_carousel-tiny"
                ></use>
              </svg>
            </span>
            <span v-if="col.button == 'Read more'" class="dropdown-arrow">
              <svg class="tinyImg">
                <use
                  href="@/assets/icons/iconset.svg#arrowdown_carousel-tiny"
                ></use>
              </svg>
            </span>
          </span>
        </span>

        <span
          v-else-if="typeof col === 'object' && col.html"
          v-html="col.html"
        ></span>

        <component
          v-else-if="typeof col === 'object' && col.component"
          :is="col.component"
          v-bind="col.props"
        />
      </div>

      <button
        v-if="
          isEditButtonFunction &&
          showEditButton(row) === true &&
          row.field !== 'allowTrades'
        "
        @click="editRow(rowIndex)"
        class="microButton purpleText"
        v-haptic
      >
        {{ $t("buttons.edit") }}
      </button>
      <span
        v-else-if="isEditButtonFunction && showEditButton(row) === 'Auto'"
        >{{ showEditButton(row) }}</span
      >
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import Tooltip from "@/components/GenericComponents/Tooltip.vue";

export default {
  name: "TablePanel",
  components: {
    Tooltip,
  },
  props: {
    rows: {
      type: Array,
      required: true,
    },
    toggleSwitchState: Boolean,
    editButton: {
      type: Boolean,
      default: false,
    },
    showEditButton: {
      type: [Function, String, Boolean],
      default: () => false,
    },
    monetaryParameters: {
      type: Object,
    },
    hideFirstRowTopBorder: {
      type: Boolean,
      default: false,
    },
    showReadMoreButton: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isEditButtonFunction = computed(
      () => typeof props.showEditButton === "function"
    );

    const strikethroughUs = (text) => {
      return String(text).replace(/U$/, '<span class="strikethrough">U</span>');
    };

    const editRow = (rowIndex) => {
      console.log("Edit row", rowIndex);
      emit("edit-row", { index: rowIndex, field: props.rows[rowIndex].col1 });
    };

    const emitToggleDescription = () => {
      emit("toggle-description");
    };

    return {
      strikethroughUs,
      editRow,
      isEditButtonFunction,
      emitToggleDescription,
    };
  },
};
</script>

<style scoped>
.table-panel {
  margin: var(--margin) 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.table-panel .row {
  align-items: flex-start;
}
.row {
  min-height: calc(var(--clickableHeight) + 2px);
  justify-content: space-between;
  border-bottom: 1px solid var(--solidMidLightgrey);
  padding: var(--margin) 0;
}
.first-row {
  border-top: 1px solid var(--solidMidLightgrey);
}
.first-column {
  min-width: 60%;
  display: flex;
  align-items: center;
}
.second-column {
  display: flex;
  justify-content: space-between;
  width: 50%;
  align-items: center;
}
.strikethrough {
  text-decoration: line-through;
}
.edit-button {
  margin-left: 10px;
}

.no-border-bottom {
  border-bottom: none !important;
  padding: 0 !important;
  min-height: 0;
  gap: 0;
}

.wide-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.logo-image {
  height: var(--clickableHeight);
}

span.readMore.divTinyMarginTop {
  display: inline-flex;
  align-items: center;
}

span.readMore {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  cursor: pointer;
}

span.dropdown-arrow {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
/* Small Screens */
@media (max-width: 530px) {
  .first-column {
    min-width: 40%;
    width: 45%;
    display: flex;
    align-items: center;
  }
}
</style>
