<template>
  <h3 class="small-margin-top tiny-margin-bottom">
    {{ translatedPrice }} {{ currencySymbol }}
  </h3>
</template>

<script>
import { ref, watch, computed } from "vue";
import { ethers } from "ethers";
import exchangeRateCalculatorABI from "@/../artifacts/contracts/ExchangeRateCalculator.sol/ExchangeRateCalculator.json";
import currencySymbols from "@/../currency-symbols.json";

export default {
  name: "TranslatedPrice",
  props: {
    product: {
      type: Object,
      required: true,
    },
    userCurrency: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const provider = new ethers.providers.JsonRpcProvider();

    // Replace this with your actual deployed contract address
    const contractAddress = "your-deployed-contract-address";

    const jsonRpcProvider = new ethers.providers.JsonRpcProvider(
      "http://localhost:8545"
    );

    const exchangeRateCalculator = new ethers.Contract(
      contractAddress,
      exchangeRateCalculatorABI,
      provider
    );

    const translatedPrice = ref("");

    const calculatePrice = async () => {
      const rate = await exchangeRateCalculator.getExchangeRate(
        props.product.currency,
        props.userCurrency
      ); // Make sure your contract has a function like this
      translatedPrice.value = (props.product.price * rate).toFixed(2);
    };

    watch(() => [props.product, props.userCurrency], calculatePrice, {
      immediate: true,
    });

    const getCurrencySymbol = (currencyCode) => {
      return currencySymbols[currencyCode] || "$"; // Default to $ if the symbol is not found
    };

    const currencySymbol = computed(() =>
      getCurrencySymbol(props.userCurrency)
    );

    return {
      translatedPrice,
      currencySymbol,
    };
  },
};
</script>
