<template>
  <div class="yourUnitverse">
    <Loading v-if="isLoading" />
    <div v-else class="fullWidth">
      <div
        v-if="
          !userUnits_customOrgs ||
          Object.keys(userUnits_customOrgs).length === 0
        "
        class="fullWidth"
      >
        <CreateOrJoin />
      </div>

      <div v-else>
        <div
          v-for="(unitData, unitUID) in userUnits_customOrgs"
          :key="unitUID"
          class="userUnits"
        >
          <!-- <div class="row alignCentreRow unitHeader mainHeader">
            <div class="row headerRow">
              <div class="column">
                <h1 class="nomargintopbottom">
                  {{ unitData.name }}
                </h1>
              </div>
              <div class="column">
                <router-link
                  :to="{
                    name: 'OrganisationView',
                    params: { orgUID: unitUID },
                  }"
                  @click.native="resetActiveSpace"
                >
                  <button class="smallButton secondaryButton" v-haptic>
                    <svg class="compactImg">
                      <use
                        href="../../assets/icons/iconset.svg#view_compact"
                      ></use>
                    </svg>
                    View public page
                  </button>
                </router-link>
              </div>
            </div>
          </div> -->

          <!-- CARDS -->
          <div class="cardButtonContainer">
            <div
              class="cardButton"
              :class="{ pressed: pressedCard === 'overview' }"
              :style="{ backgroundColor: getColorForCard(0) }"
              @click="setPressedCard('overview', 0)"
            >
              <svg class="smaller">
                <use
                  href="../../assets/icons/iconset.svg#organisation_hierachical"
                ></use>
              </svg>
              <div class="cardButton_text">
                <p class="nomargintopbottom">Overview</p>
              </div>
            </div>
            <div
              class="cardButton"
              :class="{ pressed: pressedCard === 'members' }"
              :style="{ backgroundColor: getColorForCard(1) }"
              @click="setPressedCard('members', 1)"
            >
              <svg class="smaller">
                <use href="../../assets/icons/iconset.svg#members"></use>
              </svg>
              <div class="cardButton_text">
                <p class="nomargintopbottom">Members</p>
              </div>
            </div>
            <div
              v-if="
                userRolePrivileges?.roleAdministration ||
                userRolePrivileges?.roleCreation
              "
              class="cardButton"
              :class="{ pressed: pressedCard === 'roles' }"
              :style="{ backgroundColor: getColorForCard(2) }"
              @click="setPressedCard('roles', 2)"
            >
              <svg class="smaller">
                <use href="@/assets/icons/iconset.svg#roles"></use>
              </svg>
              <div class="cardButton_text">
                <p class="nomargintopbottom">Roles</p>
              </div>
            </div>

            <div
              v-if="userRolePrivileges?.monetaryParameters"
              class="cardButton"
              :class="{ pressed: pressedCard === 'monetary' }"
              :style="{ backgroundColor: getColorForCard(3) }"
              @click="setPressedCard('monetary', 3)"
            >
              <svg class="smaller">
                <use
                  href="../../assets/icons/iconset.svg#monetary_params2"
                ></use>
              </svg>
              <div class="cardButton_text">
                <p class="nomargintopbottom">Monetary rules</p>
              </div>
            </div>
          </div>

          <!-- <div class="cardButtonContainer">
            <CardButton
              v-for="(section, index) in sections"
              :canViewMonetaryParameters="canViewMonetaryParameters"
              :key="section.key"
              :userRolePrivileges="userRolePrivileges"
              :illustration="section.illustration"
              :iconId="section.iconId"
              :title="section.title"
              :description="'Enabled'"
              :backgroundColor="getColorForCard(index)"
              @click="
                toggleSectionVisibility(section.key);
                setPressedCard(section.key, index);
              "
              :class="{
                pressed: pressedCard == section.key,
              }"
              :unitData="unitData"
              :buttonClass="{
                pressed: pressedCard == section.key,
              }"
            />
          </div> -->
          <!-- OVERVIEW -->
          <div>
            <div
              v-show="isSectionVisible('overview')"
              class="card-cont-wrapper fade-in-out"
            >
              <div class="dashboardSection">
                <OrgOverview
                  v-if="unitData"
                  :unitData="unitData"
                  :orgUID="unitUID"
                  :userRolePrivileges="userRolePrivileges"
                  :backgroundColor="getColorForCard(selectedIndex)"
                />
              </div>
            </div>
          </div>

          <!-- MEMBERS -->
          <div>
            <div
              v-show="isSectionVisible('members')"
              class="card-cont-wrapper fade-in-out"
            >
              <div class="dashboardSection">
                <OrgMembers
                  :members="unitData.members"
                  :orgUID="unitUID"
                  :userRolePrivileges="userRolePrivileges"
                  :backgroundColor="getColorForCard(selectedIndex)"
                />
              </div>
            </div>
          </div>
          <!-- ROLES -->
          <div>
            <div
              v-show="isSectionVisible('roles')"
              class="card-cont-wrapper fade-in-out"
            >
              <div class="dashboardSection">
                <OrgRoles
                  :orgUID="unitUID"
                  :roles="unitData.roles"
                  :userRolePrivileges="userRolePrivileges"
                  :userCredentials="userCredentials"
                  :backgroundColor="getColorForCard(selectedIndex)"
                  @role-created="addRoleToUnitData($event, unitUID)"
                />
              </div>
            </div>
          </div>
          <!-- MONETARY PARAMS -->
          <div
            v-show="isSectionVisible('monetary')"
            class="card-cont-wrapper fade-in-out"
          >
            <div class="dashboardSection">
              <MonetaryParametersPanel
                v-if="unitData && unitData.monetary_parameters"
                :unitData="unitData"
                :orgUID="unitUID"
                :monetaryParameters="unitData.monetary_parameters"
                :backgroundColor="getColorForCard(selectedIndex)"
                :userRolePrivileges="userRolePrivileges"
                :getParameterDisplayString="getParameterDisplayString"
              />
            </div>
          </div>
          <div class="column">
            <router-link
              :to="{
                name: 'OrganisationView',
                params: { orgUID: unitUID },
              }"
              @click.native="resetActiveSpace"
            >
              <button class="smallButton secondaryButton" v-haptic>
                <svg class="compactImg">
                  <use href="../../assets/icons/iconset.svg#view_compact"></use>
                </svg>
                View <strong>{{ unitData.name }}</strong> public page
              </button>
            </router-link>
          </div>
        </div>
        <!-- end of loop through user units -->
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter, onBeforeRouteLeave } from "vue-router";

import {
  computed,
  onMounted,
  watch,
  reactive,
  ref,
  onBeforeUnmount,
} from "vue";
import { auth, db } from "@/firebase";
import { toast } from "vue3-toastify";

// import {  where, getDocs } from "firebase/firestore";
// import OrgTemplate from "@/components/OrgComponents/OrgTemplate/OrgTemplate.vue";
import CreateOrgCustom from "@/components/OrgComponents/OrgCustom/CreateOrgCustom.vue";

// import CreateOrgTemplate from "@/components/OrgComponents/OrgTemplate/CreateOrgTemplate.vue";
import OrgMembers from "@/components/DashboardComponents/OrgMembers.vue";
import OrgRoles from "@/components/DashboardComponents/OrgRoles.vue";
import OrgOverview from "@/components/DashboardComponents/OrgOverview.vue";
import Loading from "@/components/GenericComponents/LoadingState.vue";
import MonetaryParametersPanel from "@/components/OrgComponents/OrgCustom/MonetaryParametersPanel.vue";
// import CardButton from "@/components/GenericComponents/CardButton.vue";

import CreateOrJoin from "@/components/LandingComponents/CreateOrJoin.vue";

export default {
  name: "YourUnitverse",
  components: {
    // OrgTemplate,
    CreateOrgCustom,
    // CreateOrgTemplate,
    OrgMembers,
    Loading,
    MonetaryParametersPanel,
    OrgRoles,
    OrgOverview,
    // CardButton,
    CreateOrJoin,
  },

  setup() {
    const colorList = [
      "var(--lightPinkWashed)",
      "var(--lightBlueWashed)",
      "var(--lightGreenWashed)",
      "var(--lightYellowWashed)",
      "var(--greenWashed)",
      "var(--pinkWashed)",
      "var(--lightRedWashed)",
      "var(--gradblueWashed)",
      "var(--redWashed)",
    ];
    const authUser = auth.currentUser;

    // monitoring changes to user org to display a diffreten button if user has selected fundation
    const selectedOrg = ref(null); // or reactive({}); based on your needs
    const handleSelectedOrg = (org) => {
      selectedOrg.value = org;
      // other logic can go here
    };

    let userRolePrivileges = computed(() => {
      return store.getters.userRolePrivileges
        ? store.getters.userRolePrivileges
        : {};
    });

    const selectedIndex = ref(0);
    const storedCardIndex = localStorage.getItem("lastSelectedIndex"); // Get the index from local storage
    selectedIndex.value = storedCardIndex ? parseInt(storedCardIndex) : 0; // Set the selectedIndex to the value from local storage or default to 0

    const userUnits_customOrgs = computed(() => {
      return store.getters.userUnits_customOrgs;
    });
    const canViewMonetaryParameters = computed(() => {
      return userRolePrivileges.value.monetaryParameters;
    });

    const sections = reactive([
      {
        key: "overview",
        title: "Overview",
        illustration: "your_default_illustration_path",
        iconId: "organisation_hierachical",
        description: Object.keys(userUnits_customOrgs)[0],
        visible: true,
      },
      {
        key: "members",
        title: "Members",
        illustration: "your_default_illustration_path",
        iconId: "members",
        visible: false,
      },
      {
        key: "roles",
        title: "Roles",
        illustration: "your_default_illustration_path",
        iconId: "roles",
        visible: false,
      },
      {
        key: "monetary",
        title: "Monetary parameters",
        illustration: "your_default_illustration_path",
        iconId: "monetary_params2",
        visible: false,
        canView: canViewMonetaryParameters,
      },
    ]);

    const resetActiveSpace = () => {
      store.dispatch("changeActiveTab", null);
    };
    const store = useStore();
    const currentOrgUID = ref(null);
    const coinSymbol = computed(() => store.getters.coinSymbol);

    const fetchCoinSymbol = async (userUID) => {
      await store.dispatch("fetchUserOrgCoinSymbol", userUID);
    };

    const isLoading = computed(() => store.getters.isLoading);
    const storedCard = localStorage.getItem("lastPressedCard");
    const pressedCard = ref(storedCard ? storedCard : sections[0].key);

    const user = computed(() => {
      return store.getters.user;
    });
    const userCredentials = computed(() => {
      return store.getters.userCredentials;
    });

    const isOrgUIDLoading = ref(true); // Dedicated loading state for organization UID

    const fetchAndSetCurrentOrgUID = async () => {
      isOrgUIDLoading.value = true; // Set the loading state to true before fetching
      await store.dispatch("fetchAndCommitUserUnits", auth.currentUser?.uid);
      currentOrgUID.value = store.getters.currentOrgUID;
      isOrgUIDLoading.value = false; // Reset the loading state once the fetch is complete
    };

    watch(isOrgUIDLoading, (newVal) => {
      if (!newVal) {
        // Proceed only if the loading state is false (i.e., loading is complete)
        if (currentOrgUID.value) {
          // toast.success(
          //   `Your Unitverse watch isOrgUIDLoading Current organization UID: ${currentOrgUID.value}`
          // );
        } else {
          toast.error("You aren't in any Unit at the moment.");
        }
      }
    });

    const setPressedCard = (key, index) => {
      pressedCard.value = key;
      selectedIndex.value = index;
      localStorage.setItem("lastPressedCard", key);
      localStorage.setItem("lastSelectedIndex", index.toString());
    };
    const getColorForCard = (index) => {
      const sectionKeys = ["overview", "members", "roles", "monetary"];
      const key = sectionKeys[index];
      if (pressedCard.value === key) {
        const colorList = [
          "var(--lightPinkWashed)",
          "var(--lightBlueWashed)",
          "var(--lightGreenWashed)",
          "var(--lightYellowWashed)",
          "var(--greenWashed)",
          "var(--pinkWashed)",
          "var(--lightRedWashed)",
          "var(--gradblueWashed)",
          "var(--redWashed)",
        ];
        return colorList[index % colorList.length];
      }
      return "transparent"; // Default background color when not pressed
    };
    // define the data for the card buttons and a variable to keep track of which section is visible
    const toggleSectionVisibility = (key) => {
      // If the pressed card is the same as the last one, reset it
      if (pressedCard.value === key) {
        pressedCard.value = null;
      } else {
        pressedCard.value = key; // Only this card should be visible
      }
    };
    const isSectionVisible = (key) => {
      return sections.some((s) => s.key === key && s.key === pressedCard.value);
    };
    // Formating monetary parameters
    const parameterMap = reactive({
      basic_income: "Basic income",
      savings_account: { cap: "Savings acct. cap" },
      current_account: {
        cap: "Current acct. cap",
        lifespan: "Current acct. currency lifespan",
      },
      max_shared_pool: { cap: "Shared pool cap" },
    });
    const getParameterDisplayString = (parameter, subParameter) => {
      if (subParameter) {
        // Handle sub-parameters
        return parameterMap[parameter][subParameter];
      } else {
        // Handle main parameters
        return parameterMap[parameter];
      }
    };
    const addRoleToUnitData = (newRole, unitUID) => {
      if (!userUnits_customOrgs.value[unitUID]) {
        // Handle case where userUnits_customOrgs[unitUID] is undefined.
        // console.error("Tried to add role to non-existent unit:", unitUID);
        return;
      }

      // If roles property doesn't exist for the unit, initialize it as an empty array.
      if (!userUnits_customOrgs.value[unitUID].roles) {
        userUnits_customOrgs.value[unitUID].roles = [];
      }

      // Add the new role to the correct unit.
      store.dispatch("fetchAndCommitUserUnitsCustomOrgs", auth.currentUser.uid);
    };

    onMounted(async () => {
      store.commit("SET_LOADING_STATE", true); // Begin loading state

      try {
        // Wait for the current authenticated user
        const currentUser = await new Promise((resolve, reject) => {
          const unsubscribe = auth.onAuthStateChanged((user) => {
            unsubscribe(); // Clean up the observer to prevent memory leaks
            if (user) {
              resolve(user); // User is signed in, resolve the promise with the user
            } else {
              store.dispatch("updateCoinSymbol", {
                symbol: "Ʉ",
              });

              reject(new Error("No authenticated user found.")); // No user is signed in
            }
          });
        });

        // Successfully identified the authenticated user, log and show a toast
        console.log("Authenticated user:", currentUser);
        // toast.success(`Authenticated user: ${currentUser.uid}`);

        // Fetch and commit user units based on the authenticated user's UID
        await store.dispatch("fetchAndCommitUserUnits", currentUser.uid);
        // Fetch and commit custom organizations for the user
        await store.dispatch(
          "fetchAndCommitUserUnitsCustomOrgs",
          currentUser.uid
        );

        // Now, fetch and set the current organization UID based on the authenticated user
        await fetchAndSetCurrentOrgUID();
        fetchCoinSymbol(currentUser.uid);
        // store.dispatch("fetchUserOrgCoinSymbol", {currentUser.uid}
        store.dispatch("fetchUserOrgCoinSymbol", currentUser.uid);
        // Now, set the coinSymbol on coinSymbol states
        if (coinSymbol.value) {
          store.dispatch("updateCoinSymbol", {
            symbol: coinSymbol.value,
          });
        }

        // Fetch user role privileges
        if (currentOrgUID.value) {
          await store.dispatch("getUserRolePrivileges", {
            orgUID: currentOrgUID.value,
          });
        }

        // Check if an organization UID is successfully retrieved and show a toast accordingly
        if (currentOrgUID.value) {
          // console.log(
          //   "Your Unitverse OnMounted Current organization UID:",
          //   currentOrgUID.value
          // );
        } else {
          // console.error("No organisation found for the current user.");
        }
      } catch (error) {
        // Log and show any errors that occurred during the process
        // console.error("An error occurred:", error);
      } finally {
        store.commit("SET_LOADING_STATE", false); // End loading state
      }
    });

    onBeforeRouteLeave((to, from, next) => {
      localStorage.setItem("lastPressedCard", pressedCard.value);
      localStorage.setItem("lastSelectedIndex", selectedIndex.value.toString());
      next();
    });

    return {
      user,
      userCredentials,
      userUnits_customOrgs,
      isLoading,
      getParameterDisplayString,
      addRoleToUnitData,
      sections,
      toggleSectionVisibility,
      pressedCard,
      setPressedCard,
      getColorForCard,
      selectedIndex,
      isSectionVisible,
      selectedOrg,
      handleSelectedOrg,
      // shouldDisplayCreateOrgCustom,
      currentOrgUID,
      fetchAndSetCurrentOrgUID,
      coinSymbol,
      userRolePrivileges,
      canViewMonetaryParameters,
      authUser,
      resetActiveSpace, // Add this line to expose the function
    };
  },
};
</script>

<style scoped>
.yourUnitverse {
  width: 100%;
  height: fit-content;
}

.noHeight {
  height: 0;
  border: 1px solid red;
}

.unitHeader {
  justify-content: space-between;
}

.list {
  width: 100%;
  margin-top: 0px;
  position: relative;
}
.list::before {
  content: "";
  position: absolute;
  top: calc(-1 * var(--smallMargin));
  left: 0;
  width: 100%;
  height: 3px;
  box-shadow: var(--neuMorphBoxOuterRidge);
  filter: blur(1px);
  transition: all 0.15s ease-in;
}

/* CARDS */
.cardButtonContainer {
  display: flex;
  flex-wrap: wrap;
  gap: var(--smallMargin);
  margin-bottom: var(--margin);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-in-out {
  animation: fadeIn 0.5s forwards;
  /* for fade out you'd swap to using the fadeOut keyframes */
}

/* CARD BUTTONS */
/* .inactive {
  padding: var(--smallMargin);
} */

.info-tile__image {
  width: var(--smallClickableHeight);
  height: var(--smallClickableHeight);
  object-fit: cover;
}

.cardButton_text {
  display: flex;
  flex-direction: column;
  gap: var(--smallMargin);
}
/* Default to 4 columns */
.cardButton {
  display: flex;
  flex: 1 0 calc(24% - var(--smallMargin));
  align-items: center;
  width: auto;
  height: calc(var(--clickableHeight) * 1);
  gap: var(--smallMargin);
  padding: 0 var(--smallMargin) 0 var(--smallMargin);
  border-radius: var(--borderRadius);
  cursor: pointer;
  box-shadow: var(--neuMorphBoxOuter);
  filter: (90%);
}

.innerStickerCardButton {
  display: flex;
  gap: var(--smallMargin);
  height: 100%;
  width: 100%;
  border-radius: 2px;
  align-items: center;
  padding: var(--smallMargin);
}

/* 2 columns */
@media (max-width: 800px) {
  .cardButton {
    flex: 1 0 calc(50% - var(--smallMargin));
    height: calc(var(--clickableHeight) * 1);
  }
}
</style>
