<template>
  <div class="user-avatarMicro">
    <div v-if="photoURL" class="avatarCircleMicro">
      <img :src="photoURL" :alt="displayName" />
    </div>
    <div v-else :class="{ avatarCircleMicro: true, anonymous: isAnonymous }">
      <h1 class="nomargintopbottom">{{ fallbackInitial }}</h1>
    </div>
  </div>
</template>

<script>
import { computed, ref, watchEffect, onMounted } from "vue";
import { db } from "@/firebase"; // Adjust the import path as necessary
import { doc, getDoc } from "firebase/firestore";

export default {
  props: {
    user: Object, // Optional user object
    userUid: {
      type: String,
      required: true, // userUid is required if user is undefined
    },
    microMargin: {
      type: String,
      default: "8px", // Default value for microMargin
    },
  },
  setup(props) {
    const photoURL = ref(null);
    const displayName = ref("");
    const isAnonymous = ref(true);

    // Fallback initial is based on displayName or email
    const fallbackInitial = computed(() => {
      if (isAnonymous.value) {
        return "A";
      }
      if (email.value) {
        return email.value.charAt(0).toUpperCase();
      }
      return "U";
    });

    // If user prop is provided, use it
    if (props.user) {
      photoURL.value =
        props.user.photoURL || props.user.profile_picture || null;
      displayName.value = props.user.displayName || "";
      isAnonymous.value = props.user.isAnonymous ?? true;
    } else {
      // If user prop is not provided, fetch the user data from Firestore
      const fetchUserData = async () => {
        try {
          const userDoc = await getDoc(doc(db, "users", props.userUid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            photoURL.value = userData.profile_picture || null;
            displayName.value = userData.displayName || "";
            isAnonymous.value = userData.isAnonymous ?? true;
          } else {
            console.warn(`User with UID ${props.userUid} not found`);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

      // Fetch data on component mount
      onMounted(() => {
        fetchUserData();
      });
    }

    return {
      isAnonymous,
      photoURL,
      displayName,
      fallbackInitial,
    };
  },
};
</script>

<style scoped>
.user-avatarMicro {
  display: flex;
  align-items: center;
}

.avatarCircleMicro,
.avatarCircleMicro img {
  width: var(--margin);
  height: var(--margin);
  border-radius: 50%;
}

.anonymous {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--solidMidLightgrey);
  color: var(--solidMidDarkgrey);
}

.nomargintopbottom {
  margin-top: 0;
  margin-bottom: 0;
}

/* Root variable for microMargin */
</style>
