<template>
  <div :class="{ HowToGuide: showOnFAQ }">
    <div :class="{ column_container: showOnFAQ }">
      <div :class="['singleColumn', showOnFAQ ? '' : 'column']">
        <h2 class="shortwidth alignCentre nomargintopbottom" v-if="showOnFAQ">
          How to start using Unit?
        </h2>

        <p
          :class="[
            'shortwidth',
            showOnFAQ ? 'smallmargintopnomarginbottom' : 'nomargintopbottom',
          ]"
        >
          The quickest way for someone to start using Unit is to register and
          create a unit.
        </p>
        <p class="shortwidth smallmargintop">
          If you want you can also invite memebers. Remember, establishing a
          functional monetary system is about crafting a thoughtful social
          contract<Tooltip
            message="A set framework defining the rules within a community, crucial for establishing its economic and social systems. It's similar to designing a game together where members understand and agree on the rules to achieve collective goals."
          />
          for your community. If you're strugglng with these aspects, we're here
          to help.
          <a
            href="mailto:example@example.com?subject=Initial%20Talk&body=If%20you%27re%20struggling%20with%20these%20aspects%2C%20we%27re%20here%20to%20help.%20Get%20in%20touch%21%20And%20if%20you%20are%20interested%20and%20it%27s%20necessary%2C%20we%20can%20arrange%20a%20workshop%20with%20your%20team%20so%20you%20can%20leverage%20Unit%20to%20meet%20your%20unique%20challenges."
            class="email-button"
          >
            Get in touch!
          </a>
          If necessary we can arrange a meeting so you can leverage Unit to meet
          your unique challenges.
        </p>

        <div class="column_container">
          <div class="circle">1</div>
          <h3 class="smallmargintopnomarginbottom">
            Register Your Organisation
          </h3>
          <p class="shortwidth smallmargintopbottom">
            Register and fill out your organisation's details. Your role will be
            automatically set as "Founder". When you register you'l be able to
            name your currency and define your initial monetary policies.
          </p>

          <div class="circle">2</div>
          <h3 class="smallmargintopnomarginbottom">
            Create Roles and Invite Users
          </h3>
          <p class="shortwidth smallmargintopbottom">
            Create roles like 'Manager', 'Creative', 'Maintenance', 'Support
            Staff', or any other roles essential to your organisation. You can
            then extend invitations to users to join your organisation<Tooltip
              message="Suppose an organisation supports vulnerable people and receives in-kind donations. They can pay Caregivers a salary and establish a basic income, so service users can reward Caregivers."
            />
          </p>

          <div class="circle">3</div>
          <h3 class="smallmargintopnomarginbottom">
            Edit your monetary policies
          </h3>
          <p class="shortwidth smallmargintop">
            Unit allows allows you to modify your monetary policies as you
            please, enabling you to adapt seamlessly as your organisation
            evolves. Navigate to the 'Monetary rules' section to edit rules
            related to basic income, accumulation limits, and so on.
          </p>
          <div class="circle">4</div>

          <h3 class="smallmargintopnomarginbottom">Start trading!</h3>
          <p class="shortwidth smallmargintop">
            Consider your unique contributions and add your products or
            services.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

import { computed, onMounted, ref } from "vue";
import { toast } from "vue3-toastify";
import PreEngagement from "@/components/GenericComponents/PreEngagement.vue";
import Tooltip from "@/components/GenericComponents/Tooltip.vue";
import ToggleButtonContent from "@/components/GenericComponents/ToggleButtonContent.vue";

export default {
  name: "HowToGuide",
  props: {
    showOnFAQ: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Tooltip,
    ToggleButtonContent,
    PreEngagement,
  },
  setup() {
    return {};
  },
};
</script>

<style scoped>
.HowToGuide {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--biggerMargin);
}

.email-button {
  border: none;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: var(--borderRadius);
}
</style>
