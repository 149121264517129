<template>
  <div class="marketplace">
    <div class="productMarketplace">
      <div class="product-lists">
        <!-- Loading state to show while fetching data -->
        <div v-if="isLoadingData" class="divSmallMarginBottom">Loading...</div>

        <!-- Existing OrganisationCoinStatus component -->
        <OrganisationCoinStatus
          v-if="!isLoadingData && !currentUserOrgUID && !buyerOrgHasCoins"
          :currentUserUid="currentUserUid"
          :orgUID="currentUserOrgUID"
          :buyerOrgHasCoins.sync="buyerOrgHasCoins"
        >
          <!-- Custom content for 'transactions-exist' slot -->
          <template v-slot:transactions-exist> </template>
          <!-- Custom content for 'no-transactions' slot -->
          <template v-slot:no-transactions>
            <p class="shortwidth">
              You need to belong to a unit to see the product prices of other
              units.
              <Tooltip
                :message="'To calculate the exchange rate, we need to know the total number of coins in two units. Since you do not belong to a unit we cannot calculate the exchange rate.'"
                icon="Why?"
              />
              <Tooltip
                :message="`When you create your coin with Unit you automatically create an organisation. Since coins and organisations come hand-in-hand, the term 'unit' can apply to both the coin and its founding organisation.`"
                icon="What's a unit?"
              />
            </p>
            <button @click="redirectToWallet">🤑 Create your unit</button>
          </template>
          <!-- Custom content for 'no-money' slot -->
          <template v-slot:no-money>
            <p class="shortwidth">It seems like you're running out of money.</p>
            <CreateOneCoinInUserWallet
              :orgUID="currentUserOrgUID"
              @coinCreated="handleCoinCreated"
            />
          </template>
          <!-- Custom content for 'no-coins' slot -->
          <template v-slot:no-coins>
            <p class="shortwidth">
              Your organisation has no coins. We need at least one coin to show
              you the item prices in your currency.
              <Tooltip
                :message="'To calculate the exchange rate, we need to know the total number of coins in your organisation, and it cannot be zero.'"
                icon="Why?"
              />
            </p>
            <CreateOneCoinInUserWallet
              :orgUID="currentUserOrgUID"
              @coinCreated="handleCoinCreated"
            />
          </template>
        </OrganisationCoinStatus>

        <!-- Display the product/service list or a message if empty -->
        <div
          v-if="
            !isLoadingData &&
            (!sortedByCreationDateProductsAndServices ||
              !sortedByCreationDateProductsAndServices.length)
          "
        >
          👀 No items around at the moment
        </div>
        <div v-else>
          <transition name="slide-fade">
            <p class="nomargintopsmallmarginbottom" v-if="showWishlist">
              Your wishlist
            </p>
          </transition>
          <transition name="slide-fade">
            <ScrollableMarketplaceList
              class="wishlist"
              v-show="showWishlist"
              :sortedProductsAndServices="userWishlistProducts"
              :wishlist="true"
              @wishlistUpdated="repopulateUserWishlist"
            />
          </transition>
          <transition name="slide-fade">
            <p v-show="showRecentlyAdded" class="nomargintopsmallmarginbottom">
              {{ title }}
            </p>
          </transition>
          <transition name="slide-fade">
            <ScrollableMarketplaceList
              v-show="showRecentlyAdded"
              :sortedProducts="sortedByCreationDateProducts"
              :sortedProductsAndServices="
                sortedByCreationDateProductsAndServices
              "
              @wishlistUpdated="repopulateUserWishlist"
              @noCoinsInBuyerOrg="handleNoCoinsInGrandparent"
              @hasCoinsInBuyerOrg="handleHasCoinsInGrandparent"
              @userHasNoOrganisations="handleUserHasNoOrganisations"
              @userHasOrganisations="handleUserHasOrganisations"
              @itemEdited="$emit('itemEdited')"
            />
          </transition>
          <div v-if="showRecentlyAdded" class="separator"></div>
          <MasonryMarketplace
            :products="filteredProducts || []"
            :services="filteredServices || []"
            :showItems="'allUsers'"
            :organisationUid="currentUserOrgUID"
            @wishlistUpdated="repopulateUserWishlist"
            @itemEdited="$emit('itemEdited')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watchEffect, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { auth, db } from "@/firebase";
import {
  getDocs,
  collection,
  query,
  where,
  doc,
  getDoc,
} from "firebase/firestore";
import ScrollableMarketplaceList from "@/components/DashboardComponents/EcosystemComponents/ProductListComponents/ScrollableMarketplaceList.vue";
import Tooltip from "@/components/GenericComponents/Tooltip.vue";
import CreateOneCoinInUserWallet from "@/components/GenericComponents/CreateOneCoinInUserWallet.vue";
import OrganisationCoinStatus from "@/components/DashboardComponents/EcosystemComponents/OrganisationCoinStatus.vue";
import MasonryMarketplace from "@/components/DashboardComponents/EcosystemComponents/MasonryMarketplaceComponents/MasonryMarketplace.vue";

export default {
  name: "MainMarketplace",
  components: {
    ScrollableMarketplaceList,
    Tooltip,
    CreateOneCoinInUserWallet,
    OrganisationCoinStatus,
    MasonryMarketplace,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    services: {
      type: Array,
      default: () => [],
    },
    title: String,
    buyerOrgHasCoins: Boolean,
    showRecentlyAdded: Boolean,
    organisationUid: String,
  },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();

    const buyerOrgHasCoins = ref(props.buyerOrgHasCoins);
    const showWishlist = computed(() => store.getters["UIState/showWishlist"]);
    const isLoadingData = ref(true);

    const handleCoinCreated = () => {
      buyerOrgHasCoins.value = true;
      emit("update:buyerOrgHasCoins", true);
    };

    const redirectToWallet = () => {
      router.push("/wallet");
      store.dispatch("changeActiveTab", "wallet");
    };

    const userWishlistProducts = ref([]);
    const currentUserUid = ref(auth.currentUser ? auth.currentUser.uid : null);

    const currentUserOrgUID = ref(null);
    const buyerHasOrganisations = ref(true);

    const repopulateUserWishlist = async () => {
      try {
        const q = query(
          collection(db, "users", currentUserUid.value, "Wishlist")
        );
        const querySnapshot = await getDocs(q);

        const wishlistItems = querySnapshot.docs.map((doc) => doc.data());

        // Filter existing items
        const validWishlistItems = await Promise.all(
          wishlistItems.map(async (item) => {
            const productRef = doc(db, "products", item.id);
            const serviceRef = doc(db, "services", item.id);
            const productSnap = await getDoc(productRef);
            const serviceSnap = await getDoc(serviceRef);
            if (productSnap.exists()) {
              return { ...productSnap.data(), id: productSnap.id };
            }
            if (serviceSnap.exists()) {
              return { ...serviceSnap.data(), id: serviceSnap.id };
            }
            return null;
          })
        );

        // Remove null values
        userWishlistProducts.value = validWishlistItems.filter(
          (item) => item !== null
        );

        // Update userHasWishlistItems
        const hasWishlistItems = userWishlistProducts.value.length > 0;
        emit("update:userHasWishlistItems", hasWishlistItems);
      } catch (error) {
        console.error("Error fetching wishlist: ", error);
      }
    };

    const sortedByCreationDateProducts = computed(() => {
      return [...props.products]
        .sort((a, b) => {
          const timeA = a?.createdAt?.seconds
            ? a.createdAt.seconds * 1e9 + a.createdAt.nanoseconds
            : 0;
          const timeB = b?.createdAt?.seconds
            ? b.createdAt.seconds * 1e9 + b.createdAt.nanoseconds
            : 0;
          return timeB - timeA;
        })
        .slice(0, 10);
    });

    const sortedByCreationDateProductsAndServices = computed(() => {
      const allItems = [...props.products, ...props.services];
      return allItems
        .sort((a, b) => {
          const timeA = a?.createdAt?.seconds
            ? a.createdAt.seconds * 1e9 + a.createdAt.nanoseconds
            : 0;
          const timeB = b?.createdAt?.seconds
            ? b.createdAt.seconds * 1e9 + b.createdAt.nanoseconds
            : 0;
          return timeB - timeA;
        })
        .slice(0, 10);
    });

    const handleNoCoinsInGrandparent = () => {
      buyerOrgHasCoins.value = false;
      emit("noCoinsInBuyerOrg");
    };

    const handleUserHasNoOrganisations = () => {
      buyerHasOrganisations.value = false;
      emit("userHasNoOrganisations");
    };

    const handleUserHasOrganisations = () => {
      buyerHasOrganisations.value = true;
      emit("userHasOrganisations");
    };

    const handleHasCoinsInGrandparent = () => {
      buyerOrgHasCoins.value = true;
      emit("hasCoinsInBuyerOrg");
    };

    watchEffect(() => {
      const orgDetails = store.getters.getBuyerOrgDetails(
        currentUserOrgUID.value
      );
      // console.log("MainMarketplace - orgDetails:", orgDetails);
      // console.log(
      //   "MainMarketplace - buyerOrgHasCoins before:",
      //   buyerOrgHasCoins.value
      // );

      buyerOrgHasCoins.value = !!orgDetails && orgDetails.totalOrgCoins > 0;
      // console.log(
      //   "MainMarketplace - buyerOrgHasCoins after:",
      //   buyerOrgHasCoins.value
      // );
    });

    const filteredProducts = ref([]);
    const filteredServices = ref([]);
    const updateFilteredItems = () => {
      if (props.organisationUid) {
        filteredProducts.value = props.products.filter((product) => {
          return product.organisation === props.organisationUid;
        });

        filteredServices.value = props.services.filter((service) => {
          return service.organisation === props.organisationUid;
        });
      } else {
        filteredProducts.value = props.products;
        filteredServices.value = props.services;
      }
    };

    watch(
      () => props.organisationUid,
      (newVal) => {
        if (newVal) {
          updateFilteredItems(); // Ensure this is called to update the filtered arrays
        } else {
          updateFilteredItems(); // Reset to show all products/services
        }
      },
      { immediate: true }
    );

    watchEffect(() => {
      const orgDetails = store.getters.getBuyerOrgDetails(
        currentUserOrgUID.value
      );
      buyerOrgHasCoins.value = !!orgDetails && orgDetails.totalOrgCoins > 0;
      if (!buyerOrgHasCoins.value || orgDetails.totalOrgCoins <= 0) {
        handleNoCoinsInGrandparent();
      } else if (buyerOrgHasCoins.value) {
        handleHasCoinsInGrandparent();
      }
      const customOrgs = store.getters.userUnits_customOrgs || {};
      buyerHasOrganisations.value = Object.keys(customOrgs).length > 0;
      if (!buyerHasOrganisations.value) {
        handleUserHasNoOrganisations();
      } else if (buyerHasOrganisations.value) {
        handleUserHasOrganisations();
      }
    });
    watchEffect(() => {
      updateFilteredItems(); // Ensure this is called to update the filtered arrays
    });

    const fetchUserOrgUID = async () => {
      const user = auth.currentUser;

      if (user) {
        const userUID = user.uid;
        const walletsRef = collection(db, "users", userUID, "Wallets");
        const q = query(walletsRef, where("isActiveMember", "==", true));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          currentUserOrgUID.value = querySnapshot.docs[0].id;
          await store.dispatch("fetchAndCommitOrgMembersCount", {
            orgUID: currentUserOrgUID.value,
            context: "buyer",
          });
          await store.dispatch("fetchAndCommitOrgTotalCoins", {
            orgUID: currentUserOrgUID.value,
            context: "buyer",
          });

          checkMonetaryParameterPrivileges(currentUserOrgUID.value);
        }
      }
    };

    const checkMonetaryParameterPrivileges = async (orgUID) => {
      const user = auth.currentUser;

      if (!user || !orgUID) return;

      const userUID = user.uid;
      const rolesRef = collection(db, "custom-orgs", orgUID, "Roles");
      const q = query(
        rolesRef,
        where("filledBy." + userUID + ".public", "==", true)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          const roleData = doc.data();
          if (roleData.privileges && roleData.privileges.monetaryParameters) {
          } else {
            buyerOrgDetailsMissing.value = true;
          }
        });
      }
    };

    onMounted(async () => {
      await fetchUserOrgUID();
      if (!currentUserUid.value) {
        isLoadingData.value = false;
        return;
      }
      await repopulateUserWishlist();

      isLoadingData.value = false;
    });

    return {
      userWishlistProducts,
      sortedByCreationDateProducts,
      sortedByCreationDateProductsAndServices,
      repopulateUserWishlist,
      handleNoCoinsInGrandparent,
      handleHasCoinsInGrandparent,
      buyerOrgHasCoins,
      currentUserUid,
      currentUserOrgUID,
      isLoadingData,
      handleUserHasNoOrganisations,
      handleUserHasOrganisations,
      buyerHasOrganisations,
      redirectToWallet,
      buyerOrgHasCoins,
      handleCoinCreated,
      showWishlist,
      updateFilteredItems,
      filteredProducts,
      filteredServices,
    };
  },
};
</script>

<style scoped>
.product-lists {
  width: 100%;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
