<template>
  <div class="UserActions column">
    <!-- User Actions Header -->
    <div class="UserActionsHeader fullWidth">
      <div class="row alignCentreRow" v-if="user?.loggedIn">
        <div class="postAdd" v-if="userIsInAnOrg">
          <div
            class="moreOptionsContainer"
            v-click-outside="closeMenu"
            :class="{ open: showMenu }"
          >
            <!-- More Options Button -->
            <button
              @click="toggleMenu"
              :class="[
                'moreOptionsButton',
                'secondaryButton',
                { 'box-shadow-active': showMenu },
              ]"
            >
              <svg v-if="!showMenu" class="verySmall" alt="more options icon">
                <use href="@/assets/icons/iconset.svg#addCross_tiny"></use>
              </svg>
              <svg v-else class="verySmall" alt="more options icon">
                <use href="@/assets/icons/iconset.svg#subtract_tiny"></use>
              </svg>
            </button>

            <!-- Dropdown Menu -->
            <transition name="slide-fade">
              <div v-if="showMenu" class="dropdownMenu">
                <!-- Wishlist Menu Item -->
                <div
                  class="menu-item menu-link"
                  @click="toggleWishlist"
                  :class="{ pressed: isWishlistPressed }"
                >
                  <div class="row alignCentreRow">
                    <svg class="compactImg" alt="view wishlist icon">
                      <use
                        href="@/assets/icons/iconset.svg#addedtowishlist_compact"
                      ></use>
                    </svg>
                    Wishlist
                  </div>
                </div>

                <!-- Recently Added Menu Item -->
                <div
                  class="menu-item menu-link"
                  @click="toggleRecentlyAdded"
                  :class="{ pressed: showRecentlyAdded }"
                >
                  <div class="row alignCentreRow">
                    <svg class="compactImg" alt="recently added icon">
                      <use href="@/assets/icons/iconset.svg#new_compact"></use>
                    </svg>
                    Recently added
                  </div>
                </div>

                <!-- Organisation Only Menu Item (Conditionally Rendered) -->
                <!-- <div
                  class="menu-item menu-link"
                  v-if="userOrgAllowsTrades"
                  @click="toggleShowOwnOrganisationOnly"
                  :class="{ pressed: showOwnOrganisationOnly }"
                >
                  <div class="row alignCentreRow">
                    <svg class="compactImg" alt="organisation only icon">
                      <use href="@/assets/icons/iconset.svg#view_compact"></use>
                    </svg>
                    Organisation only
                  </div>
                </div> -->
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>

    <!-- Selling Action Overlays -->
    <transition name="slide-fade">
      <SellingAction
        v-if="showSellingAction && userIsInAnOrg"
        :userCredentials="userCredentials"
        :user="user"
        :userUnitsOrg="userUnitsOrg"
        :userIsInAnOrg="userIsInAnOrg"
        @itemEdited="handleItemEdited"
      />
    </transition>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref, onMounted, watch } from "vue";
import { auth, db } from "@/firebase";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import SellingAction from "@/components/DashboardComponents/EcosystemComponents/SellingAction.vue";

export default {
  name: "UserActions",
  components: { SellingAction },
  props: {
    userCredentials: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    buyerOrgHasCoins: {
      type: Boolean,
      required: true,
    },
    buyerHasOrganisations: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const showSellingAction = ref(false);

    const showRecentlyAdded = ref(false); // Controls Recently Added visibility
    const userHasWishlistItems = ref(false);

    const showMenu = ref(false); // Controls the dropdown menu visibility
    const isWishlistPressed = computed(() => store.state.UIState.showWishlist);
    const isSellPressed = ref(false);
    const showOwnOrganisationOnly = computed(
      () => store.state.UIState.showOwnOrganisationOnly
    );
    const userOrgAllowsTrades = ref(true); // Default to true, updated based on organisation data

    // References to form components
    const addProductFormRef = ref(null);
    const addServiceFormRef = ref(null);

    // Fetch the organisation's trade status on mount
    const fetchOrganisationTradeStatus = async (orgUID) => {
      try {
        const orgRef = doc(db, "custom-orgs", orgUID);
        const orgDoc = await getDoc(orgRef);
        if (orgDoc.exists()) {
          userOrgAllowsTrades.value = orgDoc.data().allowTrades || false;
          // If the org does not allow trades, enforce showOwnOrganisationOnly to true
          if (!userOrgAllowsTrades.value) {
            store.dispatch("UIState/setShowOwnOrganisationOnly", true);
          }
        }
      } catch (error) {
        console.error("Error fetching organization trade status:", error);
      }
    };

    // Fetch user units and organisation status on mount
    const userUID = ref(
      props.userCredentials
        ? props.userCredentials.data.uid
        : auth.currentUser.uid
    );

    const userUnitsOrg = ref(null);
    const userUnitsFromStore = computed(
      () => store.getters.userUnits_customOrgs
    );
    const userIsInAnOrg = computed(() => {
      return userUnitsOrg.value && Object.keys(userUnitsOrg.value).length > 0;
    });

    onMounted(async () => {
      if (userUnitsFromStore.value === null) {
        await store.dispatch(
          "fetchAndCommitUserUnitsCustomOrgs",
          userUID.value
        );
        userUnitsOrg.value = store.getters.userUnits_customOrgs;
      } else {
        userUnitsOrg.value = userUnitsFromStore.value;
      }
      await fetchOrganisationTradeStatus(Object.keys(userUnitsOrg.value)[0]);
      checkWishlist();
    });

    watch(userUnitsFromStore, (newValue) => {
      userUnitsOrg.value = newValue;
    });

    // Close the dropdown menu when clicking outside
    const closeMenu = () => {
      showMenu.value = false;
    };

    // Toggle the dropdown menu
    const toggleMenu = () => {
      showMenu.value = !showMenu.value;
    };

    // Handle Sell button click
    const toggleSellingAction = () => {
      showSellingAction.value = !showSellingAction.value;
      emit("updateShowSellingAction", showSellingAction.value); // Emit the updated value if needed
      // **Close the UserActions menu when "Post" is clicked**
      closeMenu();
    };

    // Handle toggleWishlist visibility toggle
    const toggleWishlist = () => {
      store.dispatch("UIState/toggleWishlist");
      closeMenu();
    };

    // Handle Recently Added visibility toggle
    const toggleRecentlyAdded = () => {
      showRecentlyAdded.value = !showRecentlyAdded.value;
      closeMenu();

      emit("toggleRecentlyAdded");
    };

    // Handle Selling Action item edited
    const handleItemEdited = () => {
      emit("itemEdited");
    };

    // Check if user has wishlist items
    const checkWishlist = async () => {
      const wishlistRef = collection(db, "users", userUID.value, "Wishlist");
      const wishlistSnapshot = await getDocs(wishlistRef);
      userHasWishlistItems.value = !wishlistSnapshot.empty;
    };

    // Toggle Organisation Only visibility
    const toggleShowOwnOrganisationOnly = () => {
      closeMenu();
      store.dispatch("UIState/toggleShowOwnOrganisationOnly");
    };

    return {
      // State
      showSellingAction,
      showRecentlyAdded,
      userHasWishlistItems,
      showMenu,
      isWishlistPressed,
      isSellPressed,
      showOwnOrganisationOnly,
      userOrgAllowsTrades,

      // Methods
      toggleMenu,
      closeMenu,
      toggleSellingAction,
      toggleWishlist,
      toggleRecentlyAdded,
      handleItemEdited,
      toggleShowOwnOrganisationOnly,

      // Refs
      userUnitsOrg,
      userIsInAnOrg,
    };
  },
};
</script>

<style scoped>
/* User Actions Header Styles */
.UserActionsHeader {
  /* Ensure full width */
  width: 100%;
}

.row {
  display: flex;
  align-items: center;
}

.alignCentreRow {
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
}

.fullWidth {
  width: 100%;
}

.postAdd {
  display: flex;
  gap: var(--smallMargin);
  position: relative;
  max-height: var(--clickableHeight);
  margin-left: auto; /* Pushes the moreOptionsContainer to the right */
}

.moreOptionsContainer {
  position: relative;
}

.moreOptionsButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--smallClickableHeight) !important;
  height: var(--smallClickableHeight) !important;
  border-radius: 50%;
  background-color: var(--backgroundColourOrg);
  border: none;
  cursor: pointer;
}

.moreOptionsButton.box-shadow-active {
  box-shadow: var(
    --neuMorphBoxInnerSmall
  ); /* Change box-shadow when showMenu is true */
}

.moreOptionsButton svg {
  pointer-events: none; /* Ensure the click event is passed to the button, not the SVG */
}

.moreOptionsContainer.open .moreOptionsButton {
  background: var(--backgroundColourOrgHover);
}

/* Dropdown Menu Styles */
.dropdownMenu {
  position: absolute;
  top: calc(100% + var(--smallMargin)); /* Add margin between button and menu */
  right: var(--margin);
  width: 210px;
  background: var(--glass-bg);
  box-shadow: var(--glass-box-shadow);
  border-radius: var(--borderRadius);
  overflow: hidden;
  z-index: 3;
  padding: var(--smallerMargin);
  transition: all 0.15s ease;
  backdrop-filter: var(--glass-backdrop-filter);
  -webkit-backdrop-filter: var(--glass-backdrop-filter);
  border: var(--glass-border);
}

.menu-item {
  border-radius: var(--borderRadius);
  padding: var(--smallMargin);
  width: 100%;
  height: var(--clickableHeight);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: all 0.15s;
  cursor: pointer;
  border-bottom: 1px solid var(--borderColour); /* Bottom border for all items */
  margin-bottom: var(
    --tinyMargin
  ); /* Margin between items when both are pressed */
}

.menu-item .row {
  gap: 0;
}

.menu-item:first-child {
  border-top: 1px solid var(--borderColour); /* Top border for the first item */
}

.menu-item:last-child {
  margin-bottom: 0;
  border-bottom: none; /* No bottom border for the last item */
}

.menu-item:hover {
  opacity: 0.8;
  transform: translateY(var(--transY));
}

.menu-link {
  width: 100%;
  text-align: right;
  position: relative;
}

.menu-link.pressed {
  border-bottom: none; /* Remove bottom border when pressed */
  box-shadow: var(--neuMorphBoxInnerSmall);
  transform: translateY(1px);
}

/* Remove top border from first item if any item is pressed */
.menu-link.pressed ~ .menu-item:first-child {
  border-top: none;
}

.compactImg {
  margin-right: var(--smallerMargin);
  width: 20px; /* Adjust size as needed */
  height: 20px; /* Adjust size as needed */
}

/* SellingAction Component Styles (if any) */
/* You can add additional styles here for the SellingAction component if necessary */

/* Responsive Styles */
@media (max-width: 900px) {
  .cardButtonSmall {
    /*flex: 1 0 calc(50% - var(--smallMargin)); */
  }
}

/* Animation for dropdown transitions */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>
