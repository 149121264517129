<template>
  <div class="interactive-message">
    <!-- Hide the entire image container if there's no productImageUrl -->
    <!-- <div class="column">
      <small><strong>Product Price:</strong> {{ productPrice }}</small>
      <small><strong>Converted Price:</strong> {{ convertedPrice }}</small>
      <small><strong>Coin Symbol:</strong> {{ coinSymbol }}</small>
      <small><strong>Seller Coin Symbol:</strong> {{ sellerCoinSymbol }}</small>
      <small><strong>Buyer Coin Symbol:</strong> {{ buyerCoinSymbol }}</small>
    </div> -->
    <div
      v-if="productImageUrl"
      class="product-image-container"
      :class="{ 'circle-image': type === 'send' || type === 'request' }"
    >
      <img
        v-if="productImageUrl"
        :src="productImageUrl"
        alt="Product"
        class="product-image"
      />
    </div>
    <!-- Ensure product details are displayed regardless of the image -->
    <div class="product-details">
      <div class="product-name">
        <strong>{{ truncatedItemName || "Transfer" }}</strong>
      </div>

      <div class="product-price">
        <!-- Display both buyer's price and seller's price with their respective coin symbols -->
        <template v-if="type === 'service' && bookingDate">
          <!-- Buyer's Price -->
          <strong>{{ formattedDisplayPrice }}</strong> {{ buyerCoinSymbol }}

          <!-- Seller's Price -->
          <span class="gray"> ≈ {{ productPrice }} {{ sellerCoinSymbol }}</span>
          <br />
        </template>

        <template v-else-if="type === 'product' && itemName">
          <!-- Buyer's Price -->
          <strong>{{ formattedDisplayPrice }}</strong> {{ buyerCoinSymbol }}

          <!-- Seller's Price -->
          <span class="gray"> ≈ {{ productPrice }} {{ sellerCoinSymbol }}</span>
          <br />
        </template>
        <template v-if="type === 'send' || type === 'request'">
          <strong>{{ transferAmount }} {{ buyerCoinSymbol }}</strong>
        </template>
      </div>

      <div class="divnomargintopbottom">
        <small v-if="type === 'service' && bookingDate" class="gray">
          {{ formattedBookingDate }}, {{ formattedBookingTime }} ({{
            slotDuration
          }}
          min) <br />
        </small>
        <small v-if="!itemName && !productImageUrl" class="purple500">
          Transfer details
        </small>
      </div>
    </div>

    <!-- Action Buttons -->
    <div class="actions">
      <div class="column alignRightColumn notSoSmallGap">
        <div
          v-if="!isBalanceInsufficient"
          @click="handleMakeOffer"
          class="row alignCentreRow button smallButton smallerClickableHeight noWrap"
        >
          {{ actionLabel }}
          <svg
            v-if="type === 'service'"
            class="smaller compactImg"
            alt="book a slot icon"
          >
            <use href="@/assets/icons/iconset.svg#calendar_compact"></use>
          </svg>
          <svg v-else class="smaller compactImg" alt="view org icon">
            <use href="@/assets/icons/iconset.svg#message_money_compact"></use>
          </svg>
        </div>
        <small v-if="isBalanceInsufficient" class="balance-warning">
          Insufficient balance to make this offer
        </small>
        <!-- Cancel Button -->
        <small class="cancelText gray" @click="handleClearProduct"
          >Cancel</small
        >
      </div>
    </div>
  </div>
</template>
<script>
import { computed, ref, onMounted, watch } from "vue";
import { formatNumberTo4Digits } from "@/utils/numberFormattingTo4digits";
import { format } from "date-fns";
import { useStore } from "vuex";
import {
  ProductInfoSchema,
  InteractiveResponseSchema,
} from "@/schemas/MessageSchema";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "@/firebase";
import { calculateReversePrice } from "@/utils/exchangeRateCalculator";

export default {
  props: {
    ...ProductInfoSchema,
    ...InteractiveResponseSchema,
    otherUserData: Object,
    buyerOrgUID: String,
    currentUserWallet: Object,
    isBalanceInsufficient: Boolean,
    transferAmount: Number,
    senderOrgDetails: Object,
    receiverOrgDetails: Object,
    sellerCoinSymbol: String,
    buyerCoinSymbol: String,
  },
  emits: ["makeOffer"],
  setup(props, { emit }) {
    const store = useStore();
    const walletBalance = ref(0);
    console.log(props);
    const sellerCoinSymbol = computed(
      () => props.sellerCoinSymbol || "SellerCoinSymbol"
    );

    const truncatedItemName = computed(() => {
      if (!props.itemName || props.itemName.length <= 20) {
        return props.itemName;
      }
      const firstPart = props.itemName.substring(0, 15);
      const lastPart = props.itemName.substring(props.itemName.length - 3);
      return `${firstPart}(…)${lastPart}`;
    });
    watch(
      () => props,
      (newProps, oldProps) => {
        console.log("Props changed:", newProps);
      },
      { deep: true, immediate: true }
    );
    const fetchUserWalletBalance = async () => {
      if (auth.currentUser && props.buyerOrgUID) {
        try {
          const walletRef = doc(
            db,
            "users",
            auth.currentUser.uid,
            "Wallets",
            props.buyerOrgUID
          );
          const walletSnapshot = await getDoc(walletRef);

          if (walletSnapshot.exists()) {
            const walletData = walletSnapshot.data();
            walletBalance.value = walletData?.currentAccount?.balance || 0;
          } else {
            walletBalance.value = 0;
          }
        } catch (error) {
          walletBalance.value = 0;
        }
      } else {
        walletBalance.value = 0;
      }
    };

    // Determine the buyer's price to display
    const formattedDisplayPrice = computed(() => {
      if (
        (props.type === "send" || props.type === "request") &&
        props.transferAmount
      ) {
        const isSender = auth.currentUser.uid !== props.otherUserData?.uid;

        if (isSender && props.senderOrgDetails && props.receiverOrgDetails) {
          const { amountInReceiverCoins } = calculateReversePrice(
            props.senderOrgDetails.membersCount,
            props.receiverOrgDetails.membersCount,
            props.transferAmount,
            props.senderOrgDetails.totalCoins,
            props.receiverOrgDetails.totalCoins
          );
          return formatNumberTo4Digits(amountInReceiverCoins);
        } else {
          return formatNumberTo4Digits(props.transferAmount);
        }
      } else {
        return formatNumberTo4Digits(
          props.convertedPrice || props.productPrice || 0
        );
      }
    });

    // Buyer and seller coin symbols
    const buyerCoinSymbol = computed(() => {
      return props.buyerCoinSymbol || "BuyerCoin";
    });

    const isBalanceInsufficient = computed(() => {
      // console.log(
      //   "Checking balance:",
      //   walletBalance.value,
      //   formattedDisplayPrice.value
      // );
      return walletBalance.value < (formattedDisplayPrice.value || 0);
    });

    const actionLabel = computed(() => {
      switch (props.type?.toLowerCase()) {
        case "send":
          return "Send";
        case "request":
          return "Request";
        case "product":
          return "Make offer";
        case "service":
          return "Request";
        default:
          return "Action";
      }
    });

    const formattedBookingDate = computed(() => {
      if (props.bookingDate) {
        try {
          // Create an object to map full day names to their abbreviations
          const dayAbbreviations = {
            Monday: "Mon",
            Tuesday: "Tue",
            Wednesday: "Wed",
            Thursday: "Thu",
            Friday: "Fri",
            Saturday: "Sat",
            Sunday: "Sun",
          };

          // If the booking date is a full day name like "Monday", convert it to the abbreviation
          if (dayAbbreviations[props.bookingDate]) {
            return dayAbbreviations[props.bookingDate];
          }

          // If props.bookingDate is a valid date string, format it as needed
          const date = new Date(props.bookingDate);
          if (!isNaN(date.getTime())) {
            // Format the date to abbreviated day, month, and day of the month
            return format(date, "eee, MMM do"); // "Mon, Sep 4"
          }
        } catch (error) {
          console.error("Invalid booking date:", props.bookingDate);
        }
      }
      return "";
    });

    const formattedBookingTime = computed(() => {
      if (props.bookingTime) {
        try {
          const time = new Date(`1970-01-01T${props.bookingTime}`);
          if (!isNaN(time.getTime())) {
            return format(time, "p");
          }
        } catch (error) {
          console.error("Invalid booking time:", props.bookingTime);
        }
      }
      return "";
    });

    const handleMakeOffer = () => {
      console.log("Making offer with:", {
        ...props,
        transferAmount: props.transferAmount || props.productPrice,
      });
      emit("makeOffer", {
        ...props,
        transferAmount: props.transferAmount || props.productPrice,
        sellerCoinSymbol: props.sellerCoinSymbol,
      });
    };

    const handleClearProduct = () => {
      store.dispatch("UIState/clearProductInfo");
    };

    onMounted(() => {
      setTimeout(() => {
        fetchUserWalletBalance();
      }, 500);
    });

    watch(
      () => [props.currentUserWallet, props.buyerOrgUID],
      () => {
        fetchUserWalletBalance();
        console.log("Wallet balance updated");
      },
      { immediate: true }
    );

    return {
      actionLabel,
      truncatedItemName,
      formattedDisplayPrice,
      buyerCoinSymbol,
      sellerCoinSymbol,
      formattedBookingDate,
      formattedBookingTime,
      handleMakeOffer,
      handleClearProduct,
      isBalanceInsufficient,
      walletBalance,
    };
  },
};
</script>

<style scoped>
.interactive-message {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--smallMargin);
  width: 100%;
  justify-content: space-between;
}

.interactive-message .row.smallButton {
  gap: var(--smallerMargin) !important;
}

.product-image-container {
  width: calc(var(--clickableHeight) * 1.3);
  height: calc(var(--clickableHeight) * 1.3);
  margin-right: var(--smallerMargin);
  border-radius: var(--borderRadius);
  display: flex; /* Ensure the image is centred */
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Ensure the image doesn't overflow */
  background-color: var(
    --backgroundLighterGraySuperTransp
  ); /* Optional: to add a background color */
}

.circle-image {
  border-radius: calc(var(--clickableHeight) * 1.3 / 2);
  border: 1px solid var(--black);
  min-width: fit-content;
  padding: 0 var(--tinyMargin);
}

.product-image {
  width: 100%;
  object-fit: cover; /* Ensures the image covers the container without distortion */
  border-radius: var(--borderRadius);
}
.product-details {
  flex-grow: 1;
}

.strong {
  font-weight: var(--fontweightheavy);
}

.purple500 {
  color: var(--purple500);
}

/* Styles for the action buttons */
.actions {
  display: flex;
  gap: var(--smallerMargin);
  align-items: flex-start;
}

.actions .alignRightColumn {
  align-items: flex-end;
  justify-content: space-between;
}

.biggerText {
  font-size: var(--pfontsizeBig);
}
</style>
