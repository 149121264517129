<template>
  <div class="fullWidth divMarginBottom">
    <form @submit.prevent="save" id="customOrgMonetaryForm">
      <div class="form-group">
        <label>Coin name</label>
        <div class="row alignCentreRow">
          <div class="circle currencySymbol">
            {{ coinSymbol }}
          </div>

          <input
            type="text"
            v-model="updatedValues.coinName"
            :placeholder="updatedValues.coinName"
            required
            class="alignCentre"
          />
        </div>
      </div>

      <div class="formblockCont">
        <h4 class="shortwidth alignCentre smallmarginbottom">
          <strong>Basic Income</strong>
        </h4>
        <div class="row fullWidth">
          <div class="singleColumn form-groupHalf">
            <div class="formblock">
              <label> Amount </label>
              <small class="avg margin4px">
                <em class="avg">
                  &nbsp Avg.
                  {{ displayBasicIncome }} Ʉ</em
                ><Tooltip
                  :message="'Basic income is an amount of money given out regularly to all members. If you choose 0, it means you are opting for no basic income.'"
              /></small>
              <StepCounter
                :value="updatedValues.averageBasicIncome"
                :required="true"
                :min="1"
                @update:value="
                  hasFetched.value
                    ? null
                    : ((averageBasicIncome = $event),
                      (updatedValues.averageBasicIncome = $event))
                "
              />
            </div>
          </div>
          <div class="singleColumn form-groupHalf">
            <div class="formblock">
              <label>Regularity </label>
              <small class="avg margin4px">
                <em class="avg">&nbsp Avg. {{ displayRegularity }} days</em>
                <Tooltip
                  :message="'Regularity defines how often the basic income is distributed. For example, a regularity of 30 means the basic income is distributed every 30 days.'"
                />
              </small>

              <StepCounter
                :value="updatedValues.averageRegularity"
                :required="true"
                :min="1"
                @update:value="
                  hasFetched.value
                    ? null
                    : (updatedValues.averageRegularity = $event)
                "
              />
            </div>
          </div>
        </div>
      </div>
      <div class="formblockCont">
        <h4 class="shortwidth alignCentre smallmarginbottom">
          <strong>Current accounts</strong>
        </h4>
        <div class="row fullWidth">
          <div class="singleColumn form-groupHalf">
            <div class="formblock">
              <label>Accumulation limit</label
              ><small class="avg margin4px"
                ><em>
                  &nbsp Avg.
                  {{ displayCurrentAccountCap }} Ʉ</em
                ><Tooltip
                  :message="'To control inflation you can set up accumulation limits to everyday spending accounts.'"
              /></small>
              <StepCounter
                :value="updatedValues.averageCurrentAccountCap"
                :required="true"
                :min="1"
                @update:value="
                  hasFetched.value
                    ? null
                    : ((averageCurrentAccountCap = $event),
                      (updatedValues.averageCurrentAccountCap = $event))
                "
              />
            </div>
          </div>
          <div class="singleColumn form-groupHalf">
            <div class="formblock">
              <label>Coin lifespan</label
              ><small class="avg margin4px"
                ><em>
                  &nbsp Avg.
                  {{ displayCurrentAccountLifespan }} days</em
                ><Tooltip
                  :message="'You can set a lifespan for coins, automatically eliminating unspent ones. Set it too short, and spending becomes rushed; too long, and inflation risks rise.'"
              /></small>

              <StepCounter
                :value="updatedValues.averageCurrentAccountLifespan"
                :required="true"
                :min="1"
                @update:value="
                  hasFetched.value
                    ? null
                    : ((averageCurrentAccountLifespan = $event),
                      (updatedValues.averageCurrentAccountLifespan = $event))
                "
              />
            </div>
          </div>
        </div>
      </div>
      <div class="formblockCont divSmallMarginTopNoMarginBottom">
        <ToggleSwitch
          v-model="updatedValues.allowSavings"
          label="Savings account?"
        />
        <div v-if="updatedValues.allowSavings">
          <h4 class="shortwidth alignCentre nomargintopsmallmarginbottom">
            <strong>Savings accounts</strong>
          </h4>

          <div v-if="!updatedValues.infiniteSavings">
            <div class="form-group">
              <div class="alignCentreRow">
                <label
                  >Accumulation limit
                  <em class="avg">
                    &nbsp Avg.
                    {{ displaySavingsAccountCap }}Ʉ</em
                  ><Tooltip
                    :message="'Savings accounts preserve the lifespan of coins, but you can set a limit to them. A way to control inflation and inequality at the same time'"
                /></label>
              </div>
              <StepCounter
                :value="updatedValues.averageSavingsAccountCap"
                :required="true"
                @update:value="
                  hasFetched.value
                    ? null
                    : ((averageSavingsAccountCap = $event),
                      (updatedValues.averageSavingsAccountCap = $event))
                "
              />
            </div>
          </div>
          <div v-else class="fullWidth">
            <label
              >No limit
              <em class="avg">
                &nbsp Avg.
                {{ displaySavingsAccountCap }}Ʉ</em
              ><Tooltip
                :message="'Savings accounts preserve the lifespan of coins, but you can set a limit to them. A way to control inflation and inequality at the same time'"
            /></label>
            <input
              class="fullWidth alignCentre"
              type="text"
              value="Infinite"
              disabled
            />
          </div>
          <div class="divSmallMarginTop">
            <ToggleSwitch
              v-model="updatedValues.infiniteSavings"
              label="Allow infinite accumulation?"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed, reactive, nextTick } from "vue";
import { useStore } from "vuex";
import { db } from "@/firebase";
import { collection, getDocs, query, limit, doc } from "firebase/firestore";
import Tooltip from "@/components/GenericComponents/Tooltip.vue";
import StepCounter from "@/components/GenericComponents/StepCounter.vue";
import ToggleSwitch from "@/components/GenericComponents/ToggleSwitch.vue";
import { formatNumberTo4Digits } from "@/utils/numberFormattingTo4digits";

export default {
  props: ["orgDetails"],
  components: {
    Tooltip,
    StepCounter,
    ToggleSwitch,
  },
  setup(props, { emit }) {
    let hasFetched = ref(false);
    const store = useStore();
    const monetaryParameters = computed(
      () => store.getters["monetary_parameters"]
    );
    const toggleUseAverages = ref(false);
    const coinNameFromOrgDetails = props.orgDetails?.coinName;

    const coinSymbol = ref(
      store.state.customOrgs.monetary_parameters.coinSymbol
    );

    const originalValues = ref(null);
    const updatedValues = reactive({
      averageSavingsAccountCap: 0,
      allowSavings: false,
      infiniteSavings: false,
      coinName: coinNameFromOrgDetails,
      averageBasicIncome: 0,
      averageRegularity: 30,
      averageCurrentAccountCap: 1,
      averageCurrentAccountLifespan: 24,
    });

    const coinSymbolMap = {
      A: "₳",
      B: "฿",
      C: "Ċ",
      D: "Đ",
      E: "Ē",
      F: "₣",
      G: "₲",
      H: "Ħ",
      I: "Ī",
      J: "Ĵ",
      K: "₭",
      L: "Ł",
      M: "₼",
      N: "₦",
      O: "Ɵ",
      P: "₱",
      Q: "Ƣ",
      R: "Ɍ",
      S: "Ŝ",
      T: "₸",
      U: "Ʉ",
      V: "∀",
      W: "₩",
      X: "Ẋ",
      Y: "¥",
      Z: "Ƶ",
    };

    watch(
      updatedValues,
      (newVal) => {
        console.log("updatedValues changed:", newVal); // Added log

        const firstLetter = newVal.coinName
          ? newVal.coinName[0].toUpperCase()
          : "";
        coinSymbol.value = coinSymbolMap[firstLetter] || "Ʉ";
        console.log(newVal);
        emit("update:allMonetaryDeets", newVal);
        emit("update:coin-name", {
          coinSymbol: coinSymbol.value,
          coinName: newVal.coinName,
        });
        emit("update:allMonetaryDeets", newVal);
      },
      { deep: true }
    );

    const basicIncome = ref(null);
    const coinName = ref(null);
    const currAccountsMax = ref(0);
    let averageBasicIncome = ref(0);
    let displayBasicIncome = ref(0);
    let averageRegularity = ref(0);
    let displayRegularity = ref(0);

    const allowSavings = ref(false);
    const infiniteSavings = ref(false);
    let averageCurrentAccountCap = ref(0);
    let displayCurrentAccountCap = ref(0);

    let averageCurrentAccountLifespan = ref(0);
    let displayCurrentAccountLifespan = ref(0);

    let averageSavingsAccountCap = ref(0);
    let displaySavingsAccountCap = ref(0);

    watch(basicIncome, (newVal, oldVal) => {
      emit("update:basic-income", newVal);
    });
    watch(
      () => updatedValues.averageRegularity,
      async (newRegularity) => {
        if (!newRegularity) return;

        // Calculate the new nextTriggerTime based on the updated regularity
        const newNextTriggerTime = calculateNextTriggerTime(newRegularity);

        if (props.orgDetails.id) {
          try {
            // Update the organization's nextTriggerTime
            await updateOrganizationNextTriggerTime(
              props.orgDetails.id,
              newNextTriggerTime
            );

            // Update nextTriggerTime for all user wallets associated with this organization
            await updateUserWalletsNextTriggerTime(
              props.orgDetails.id,
              newNextTriggerTime
            );

            console.log(
              "Successfully updated nextTriggerTime for organization and user wallets."
            );
          } catch (error) {
            console.error("Error updating nextTriggerTime:", error);
          }
        } else {
          console.log(
            "Organization ID is undefined because this is a new organisation"
          );
        }
      }
    );

    // Function to calculate next trigger time based on regularity
    function calculateNextTriggerTime(regularity) {
      const now = new Date();
      return new Date(now.getTime() + regularity * 24 * 60 * 60 * 1000);
    }

    // Function to update organization's nextTriggerTime
    async function updateOrganizationNextTriggerTime(orgId, nextTriggerTime) {
      const orgDocRef = doc(db, "custom-orgs", orgId);
      await updateDoc(orgDocRef, { nextTriggerTime: nextTriggerTime });
    }

    // Function to update all user wallets associated with the organization
    async function updateUserWalletsNextTriggerTime(orgId, nextTriggerTime) {
      const walletsSnapshot = await getDocs(
        collection(db, `users/${orgId}/Wallets`)
      );
      walletsSnapshot.forEach(async (walletDoc) => {
        const walletDocRef = doc(db, `users/${orgId}/Wallets`, walletDoc.id);
        await updateDoc(walletDocRef, { nextTriggerTime: nextTriggerTime });
      });
    }

    onMounted(async () => {
      // Check if averages are fetched for the first time
      // hasFetched.value = false;

      let totalBasicIncome = 0;
      let totalRegularity = 0;
      let basicIncEarnersCount = 0;
      let orgsWithRegularityCount = 0;
      let totalFounderCompensation = 0;
      let founderRolesCount = 0;
      let totalCurrentAccountCap = 0;
      let currentAccountCapCount = 0;
      let totalSavingsAccountCap = 0;
      let savingsAccountCapCount = 0;
      let totalCurrentAccountLifespan = 0;
      let currentAccountLifespanCount = 1;
      let monetaryParameters = null;

      // If coinName and coinSymbol are in vuex
      coinNameFromOrgDetails;
      updatedValues.coinName =
        coinNameFromOrgDetails ||
        store.state.customOrgs.monetary_parameters.coinName;

      const customOrgsSnapshot = await getDocs(collection(db, "custom-orgs"));

      for (const doc of customOrgsSnapshot.docs) {
        // For Founder Roles
        const rolesQuery = query(
          collection(db, `custom-orgs/${doc.id}/Roles`),
          limit(25)
        );
        const rolesSnapshot = await getDocs(rolesQuery);
        rolesSnapshot.docs.forEach((roleDoc) => {
          if (
            roleDoc.data().roleTitle === "Founder" &&
            roleDoc.data().baseCompensation !== undefined
          ) {
            totalFounderCompensation += roleDoc.data().baseCompensation;
            founderRolesCount++;
          }
        });
        /// Fetch Monetary rules for each org
        monetaryParameters = doc.data().monetary_parameters;

        if (monetaryParameters) {
          if (monetaryParameters.basic_income) {
            totalBasicIncome += Number(monetaryParameters.basic_income);
            basicIncEarnersCount++;
          }
          if (
            monetaryParameters &&
            monetaryParameters.basic_income_regularity
          ) {
            totalRegularity += monetaryParameters.basic_income_regularity;
            orgsWithRegularityCount++;
          }

          if (monetaryParameters.current_account?.cap) {
            totalCurrentAccountCap += Number(
              monetaryParameters.current_account.cap
            );
            currentAccountCapCount++;
          }

          if (monetaryParameters.current_account?.lifespan) {
            totalCurrentAccountLifespan += Number(
              monetaryParameters.current_account.lifespan
            );
            currentAccountLifespanCount++;
          }

          if (monetaryParameters.savings_account?.cap) {
            totalSavingsAccountCap += monetaryParameters.savings_account.cap;
            savingsAccountCapCount++;
          }
        }
        //  add any similar loops here
      }

      // For Basic Income Cap
      averageBasicIncome.value = basicIncEarnersCount
        ? totalBasicIncome / basicIncEarnersCount
        : 0;
      displayBasicIncome.value = formatNumberTo4Digits(
        averageBasicIncome.value
      );
      // console.log("Total Regularity:", totalRegularity);
      // console.log("Orgs with Regularity Count:", orgsWithRegularityCount);

      averageRegularity.value = orgsWithRegularityCount
        ? totalRegularity / orgsWithRegularityCount
        : 0;

      nextTick(() => {
        displayRegularity.value = formatNumberTo4Digits(
          averageRegularity.value
        );
      });
      // For Current Account Cap
      averageCurrentAccountCap.value = currentAccountCapCount
        ? totalCurrentAccountCap / currentAccountCapCount
        : 0;
      displayCurrentAccountCap.value = formatNumberTo4Digits(
        averageCurrentAccountCap.value
      );

      // For Savings Account Cap
      averageSavingsAccountCap.value = savingsAccountCapCount
        ? totalSavingsAccountCap / savingsAccountCapCount
        : 0;
      displaySavingsAccountCap.value = formatNumberTo4Digits(
        averageSavingsAccountCap.value
      );

      // For Current Account Lifespan
      averageCurrentAccountLifespan.value = currentAccountLifespanCount
        ? totalCurrentAccountLifespan / currentAccountLifespanCount
        : 0;
      displayCurrentAccountLifespan.value = formatNumberTo4Digits(
        averageCurrentAccountLifespan.value
      );
    });

    watch(
      () => props.orgDetails.coinName, // the property you want to watch
      (newCoinName, oldCoinName) => {
        // Update updatedValues.coinName whenever props.orgDetails.coinName changes
        updatedValues.coinName = newCoinName;
      },
      { immediate: true } // this option will run the watcher immediately upon setup
    );

    return {
      hasFetched,
      monetaryParameters,
      toggleUseAverages,
      coinNameFromOrgDetails,
      coinSymbol,
      originalValues,
      updatedValues,
      displaySavingsAccountCap,
      displayCurrentAccountCap,
      displayCurrentAccountLifespan,
      displayBasicIncome,
      displayRegularity,
      allowSavings,
      infiniteSavings,
    };
  },
};
</script>

<style scoped>
.form-group {
  width: 100%;
}
.form-groupHalf {
  min-width: auto;
  width: 100%;
}

.formblockCont {
  width: 100%;
}

.formblock {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.avg.margin4px {
  margin-bottom: 4px;
}
.currencySymbol {
  position: absolute;
  transform: translateX(6px);
}

@media only screen and (max-width: 450px) {
  .form-group {
    min-width: auto;
  }
  .input {
    width: 100%;
  }
}
</style>
