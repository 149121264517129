export const OrgSchema = {
  name: '',
  description: '',
  category: '',
  natural_persons: false,
  createdAt: null,  
  allowTrades: false,
  monetary_parameters: {
    coin_name: 'Unit',
    coin_symbol: 'Ʉ',
    basic_income: 0,
    current_account: {
      cap: 0,
      lifespan: 0,
    },
    savings_account: {
      cap: 0,
      allowSavings: null,
      infiniteSavings: null,
    },
    max_shared_pool: {
      cap: 1200000,
    },
    loans: {
      dti_effortRate: 0,
      maxLoanTerm: 480,
      annualInterest: 0,
      allowIndividual: null,
      allowJoint: null,
    },
  },
};