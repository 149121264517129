<template>
  <div class="">
    <router-link
      v-if="isLink"
      :to="{ name: 'UserProfile', params: { userUID: member.userUID } }"
      v-haptic
    >
      <AvatarContentInOrgMembers :member="member" />
    </router-link>

    <AvatarContentInOrgMembers v-else :member="member" />
  </div>
</template>

<script>
import AvatarContentInOrgMembers from "@/components/UserComponents/AvatarContentInOrgMembers.vue";

export default {
  components: {
    AvatarContentInOrgMembers,
  },
  props: {
    member: Object,
    isLink: {
      type: Boolean,
      default: true,
    },
  },
  computed: {},
};
</script>

<style scoped>
.member-info a {
  text-decoration: none;
  color: var(--black);
}
</style>
