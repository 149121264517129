<template>
  <div ref="d3Parent" class="D3_parent scrollContainer">
    <div ref="coinLifeCycleSankey"></div>
  </div>
</template>

<script>
import { ref, watch, onMounted, computed } from "vue";
import * as d3 from "d3";
import { sankey as d3Sankey, sankeyLinkHorizontal } from "d3-sankey";
import { toast } from "vue3-toastify";

export default {
  name: "OrgCoinLifecycleSankeyChart",
  props: {
    basicOrgDetails: {
      type: Object,
      required: true,
    },
    orgRoles: {
      type: Array,
      required: true,
    },
    orgRoleAvgSalaries: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const coinLifeCycleSankey = ref(null);
    const width = 1200;
    const height = 235;

    function appendTransparency(colors, alphaHex) {
      return colors.map((color) => {
        if (color.length === 9) {
          // If the original color already has transparency
          return color.substring(0, 7) + alphaHex;
        }
        return color + alphaHex;
      });
    }

    function adjustColorBrightness(hex, percent) {
      let [r, g, b] = [0, 2, 4].map((offset) =>
        parseInt(hex.slice(offset + 1, offset + 3), 16)
      );
      r = Math.min(255, Math.max(0, Math.floor(r * percent)));
      g = Math.min(255, Math.max(0, Math.floor(g * percent)));
      b = Math.min(255, Math.max(0, Math.floor(b * percent)));
      return (
        "#" + [r, g, b].map((n) => n.toString(16).padStart(2, "0")).join("")
      );
    }

    const myColorsBase = [
      "#a2f59c", // green
      "#95f5c1", // greenPastel
      "#d9e8ff", // blue-gray300
      "#baf4ff", // gradblue
      "#66e5ff", // darkBlue
      "#f6e985", // yellowPastel
      "#f5de75", // lightYellow
      "#f3cdca", // terracotaPastel
      "#f1b8f1", // pinkPastel
      "#ff9d9d", // lightRed
      "#FFC8C8", // lightestred
      "#ff7681", // red
      "#b09cf4", // purplePastel
      "#050506", // purple600
      "#513FBF", // purple500
      "#745FF2", // purple400
      "#b5aaff", // purple300
      "#F2F5FA", // blue-gray200
      "#bdff7a", // lightGreen
      "#cece92", // gold
      "#ffbdff", // lightPink
      "#ffa4f9", // pink
    ];

    const myColorsBrightnessAdjusted = myColorsBase.map((color) =>
      adjustColorBrightness(color, 0.8)
    );

    const myColors = appendTransparency(myColorsBrightnessAdjusted, "40"); // '80' is for 50% transparency

    const color = d3.scaleOrdinal(myColors);

    const drawChart = (width, height) => {
      let svg = d3.select(coinLifeCycleSankey.value).select("svg");
      svg.remove();
      // Remove any previous SVG to avoid duplication
      // d3.select(coinLifeCycleSankey.value).select("svg").remove();

      svg = d3
        .select(coinLifeCycleSankey.value)
        .append("svg")
        .attr("width", width)
        .attr("height", height);

      let basicIncomeName = `Basic Income: ${props.basicOrgDetails.basicIncome}${props.basicOrgDetails.coinSymbol}/mo`;
      let currentAccounts = `Current accounts: ${props.basicOrgDetails.currentAccountCap}${props.basicOrgDetails.coinSymbol}`;
      let savingsAccounts = `Savings accounts: ${props.basicOrgDetails.savingsAccountCap}${props.basicOrgDetails.coinSymbol}`;
      let individualLoans = `Individual loans: ${props.basicOrgDetails.loans.annualInterest}%IR`;
      let jointLoans = `Joint loans: ${props.basicOrgDetails.loans.annualInterest}%IR`;
      let deadMoney = `Dead money: ${props.basicOrgDetails.currentAccountLifespan}days`;
      let salaries = `Salaries Avg.${props.orgRoleAvgSalaries}${props.basicOrgDetails.coinSymbol}/mo`;

      // Move your nodes and links logic here
      let nodes = [
        {
          name: basicIncomeName,
          value: 1,
          bgColor: myColors[0],
        },
        {
          name: salaries,
          value: 1,
          bgColor: myColors[1],
        },
        {
          name: currentAccounts,
          value: 1,
          bgColor: myColors[7],
        },
        {
          name: savingsAccounts,
          value: 1,
          bgColor: myColors[8],
        },
        { name: "Investment programs", value: 1, bgColor: myColors[9] },
      ];

      if (props.basicOrgDetails.loans.allowIndividual) {
        nodes.push({
          name: individualLoans,
          value: 1,
          bgColor: myColors[3],
        });
        nodes.push({
          name: "Individual loan accounts",
          value: 1,
          bgColor: myColors[5],
        });
      }

      if (props.basicOrgDetails.loans.allowJoint) {
        nodes.push({
          name: jointLoans,
          value: 1,
          bgColor: myColors[4],
        });
        nodes.push({
          name: "Joint loan accounts",
          value: 1,
          bgColor: myColors[6],
        });
      }

      if (
        props.basicOrgDetails.loans.allowIndividual &&
        props.basicOrgDetails.loans.allowJoint
      ) {
        nodes.push({ name: "Loans", value: 1, bgColor: myColors[2] });
      }

      if (
        props.basicOrgDetails.loans.allowIndividual ||
        props.basicOrgDetails.loans.allowJoint
      ) {
        nodes.push({
          name: "Loan repayments",
          value: 1,
          bgColor: myColors[10],
        });
      }

      // Adding just one deadMoney node
      nodes.push({ name: deadMoney, value: 1, bgColor: myColors[10] });

      const sankey = d3Sankey()
        .nodeWidth(1)
        .nodePadding(30)
        .size([width, height]);

      let linkIndex = {}; // To quickly find node index by node name
      nodes.forEach((d, i) => {
        linkIndex[d.name] = i;
      });

      let links = [];

      // Direct links from salaries and basic income to current accounts
      links.push({
        source: linkIndex[salaries],
        target: linkIndex[currentAccounts],
        value: 1,
      });
      links.push({
        source: linkIndex[basicIncomeName],
        target: linkIndex[currentAccounts],
        value: 0.5,
      });

      // From current accounts to other nodes
      links.push({
        source: linkIndex[currentAccounts],
        target: linkIndex[savingsAccounts],
        value: 1,
      });
      links.push({
        source: linkIndex[currentAccounts],
        target: linkIndex[deadMoney],
        value: 1,
      });

      // If loans are allowed
      if (
        props.basicOrgDetails.loans.allowIndividual ||
        props.basicOrgDetails.loans.allowJoint
      ) {
        links.push({
          source: linkIndex[currentAccounts],
          target: linkIndex["Loan repayments"],
          value: 0.5,
        });
      }
      // From savings accounts to other nodes
      links.push({
        source: linkIndex[savingsAccounts],
        target: linkIndex[deadMoney],
        value: 0.5,
      });

      if (
        props.basicOrgDetails.loans.allowIndividual &&
        props.basicOrgDetails.loans.allowJoint
      ) {
        links.push({
          source: linkIndex[savingsAccounts],
          target: linkIndex["Loan repayments"],
          value: 0.7,
        });
      }

      // 7
      links.push({
        source: linkIndex[savingsAccounts],
        target: linkIndex["Investment programs"],
        value: 0.5,
      });

      // From Loans to individual and collective loans if both are allowed

      if (
        props.basicOrgDetails.loans.allowIndividual &&
        props.basicOrgDetails.loans.allowJoint
      ) {
        links.push({
          source: linkIndex["Loans"],
          target: linkIndex[individualLoans],
          value: 1,
        });
        links.push({
          source: linkIndex["Loans"],
          target: linkIndex[jointLoans],
          value: 1,
        });
      }

      // From individual loans to individual loan accounts
      if (props.basicOrgDetails.loans.allowIndividual) {
        links.push({
          source: linkIndex[individualLoans],
          target: linkIndex["Individual loan accounts"],
          value: 1,
        });
        links.push({
          source: linkIndex["Individual loan accounts"],
          target: linkIndex[currentAccounts],
          value: 1,
        });
      }

      // From collective loans to Joint loan accounts
      if (props.basicOrgDetails.loans.allowJoint) {
        links.push({
          source: linkIndex[jointLoans],
          target: linkIndex["Joint loan accounts"],
          value: 1,
        });
        links.push({
          source: linkIndex["Joint loan accounts"],
          target: linkIndex["Investment programs"],
          value: 1,
        });
      }

      // From investment programs to other nodes
      links.push({
        source: linkIndex["Investment programs"],
        target: linkIndex[deadMoney],
        value: 0.5,
      }).then;
      if (
        props.basicOrgDetails.loans.allowIndividual ||
        props.basicOrgDetails.loans.allowJoint
      ) {
        links.push({
          source: linkIndex["Investment programs"],
          target: linkIndex["Loan repayments"],
          value: 0.5,
        });
      }

      console.log("Nodes:", nodes);
      console.log("Links:", links);

      // Create the sankey layout with nodes and links
      try {
        const { nodes: sankeyNodes, links: sankeyLinks } = sankey({
          nodes: nodes.map((d) => Object.assign({}, d)),
          links: links.map((d) => Object.assign({}, d)),
        });
        console.log("sankeyNodes and links");

        console.log(sankeyNodes);
        console.log(sankeyLinks);
        // Add the nodes (the "rects")

        svg
          .append("g")
          .selectAll("rect")
          .data(sankeyNodes)
          .enter()
          .append("rect")
          .attr("x", function (d) {
            return d.x0;
          })
          .attr("y", function (d) {
            return d.y0;
          })
          .attr("height", function (d) {
            return d.y1 - d.y0;
          })
          .attr("width", function (d) {
            return d.x1 - d.x0;
          })
          .attr("stroke", "#2d2d2d")
          .attr("stroke-width", "0.3px");

        // Add the links (the "paths")
        svg
          .append("g")
          .selectAll("path")
          .data(sankeyLinks)
          .enter()
          .append("path")
          .attr("d", sankeyLinkHorizontal())
          .attr("stroke", (d) => {
            return color(d.source.index); // Fallback to original color scale
          })
          .attr("stroke-width", function (d) {
            return Math.max(1, d.width);
          })
          .attr("fill", "none");

        // Your logic for drawing sankey nodes and links would go here
        svg
          .append("g")
          .attr(
            "font-family",
            "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"
          )
          .attr("font-size", 14)
          .selectAll("text")
          .data(sankeyNodes)
          .join("g")
          .attr("transform", (d) => {
            let yOffset = 0; // Initialize an offset variable

            if (d.name === currentAccounts) {
              yOffset = -14; // Move up by 10 units
            } else if (d.name === savingsAccounts) {
              yOffset = 14; // Move down by 10 units
            }

            return `translate(${d.x0 < width / 2 ? d.x1 + 10 : d.x0 - 10}, ${
              (d.y1 + d.y0) / 2 + yOffset
            })`;
          })
          .each(function (d) {
            const self = d3.select(this);

            const text = self
              .append("text")
              .attr("dy", "0.35em")
              .attr("text-anchor", d.x0 < width / 2 ? "start" : "end")
              .text(d.name)
              .style("user-select", "none");

            const bbox = text.node().getBBox();

            self
              .insert("rect", "text")
              .attr("x", bbox.x - 5)
              .attr("y", bbox.y - 3)
              .attr("width", bbox.width + 10)
              .attr("height", bbox.height + 6)
              .attr("fill", d.bgColor || "transparent") // background color set here
              .attr("stroke", "var(--black)")
              .attr("rx", 11) // rounded corners
              .attr("ry", 14);
          });
      } catch (e) {
        console.error("Sankey error:", e);
      }
    };

    watch(
      () => props.basicOrgDetails,
      () => {
        drawChart(width, height);
      },
      { immediate: true }
    );

    onMounted(() => {
      let scrolling = false;
      let startX = 0;
      let scrollLeft = 0;
      const container = document.querySelector(".scrollContainer");
      // fixin scrol for iOS
      container.addEventListener("touchstart", (e) => {
        startX = e.touches[0].pageX - container.offsetLeft;
        scrollLeft = container.scrollLeft;
        scrolling = true;
      });

      container.addEventListener("touchmove", (e) => {
        if (!scrolling) return;
        const x = e.touches[0].pageX - container.offsetLeft;
        const walk = x - startX;
        container.scrollLeft = scrollLeft - walk;
      });

      container.addEventListener("touchend", () => {
        scrolling = false;
      });
      // setting d3 graph measures

      drawChart(width, height);
    });

    return { coinLifeCycleSankey };
  },
};
</script>
<style scoped>
.D3_parent,
.scrollContainer {
  overflow-x: scroll;
  position: relative;
  width: 100%;
  box-shadow: var(--neuMorphBoxInner);
  border-radius: var(--borderRadius);
  padding: var(--margin) 0;
  margin-top: var(--margin);
}

.clickable {
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
</style>
