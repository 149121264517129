<template>
  <transition name="slide-up-down">
    <div v-if="isVisible" class="emoji-media-picker">
      <div class="picker-header">
        <!-- Close Icon aligned to the left -->

        <!-- Category Icons aligned to the right -->
        <div class="category-icons" v-if="pickerMode === 'emoji'">
          <svg
            class="category-icon clickableAnim"
            @click="scrollToCategory('Smileys & Emotion')"
          >
            <use href="../../assets/icons/iconset.svg#smile"></use>
          </svg>
          <svg
            class="category-icon clickableAnim"
            @click="scrollToCategory('People & Body')"
          >
            <use href="../../assets/icons/iconset.svg#members"></use>
          </svg>
          <svg
            class="category-icon clickableAnim"
            @click="scrollToCategory('Animals & Nature')"
          >
            <use href="../../assets/icons/iconset.svg#chicken"></use>
          </svg>
          <svg
            class="category-icon clickableAnim"
            @click="scrollToCategory('Food & Drink')"
          >
            <use href="../../assets/icons/iconset.svg#food"></use>
          </svg>
          <svg
            class="category-icon clickableAnim"
            @click="scrollToCategory('Activities')"
          >
            <use href="../../assets/icons/iconset.svg#football"></use>
          </svg>
          <svg
            class="category-icon clickableAnim"
            @click="scrollToCategory('Travel & Places')"
          >
            <use href="../../assets/icons/iconset.svg#fast_train"></use>
          </svg>
          <svg
            class="category-icon clickableAnim"
            @click="scrollToCategory('Objects')"
          >
            <use href="../../assets/icons/iconset.svg#unit-coin"></use>
          </svg>
          <svg
            class="category-icon clickableAnim"
            @click="scrollToCategory('Symbols')"
          >
            <use href="../../assets/icons/iconset.svg#peace"></use>
          </svg>
          <svg
            class="category-icon clickableAnim"
            @click="scrollToCategory('Flags')"
          >
            <use href="../../assets/icons/iconset.svg#world"></use>
          </svg>
        </div>
        <svg class="close-icon clickableAnim" @click="closePicker">
          <use href="../../assets/icons/iconset.svg#cross"></use>
        </svg>
      </div>
      <!-- <div class="separator_sticker divNoMarginTopBottom"></div> -->

      <div class="picker-content" v-if="pickerMode === 'emoji'">
        <!-- Emoji Picker Content -->
        <!-- Recent emojis section -->
        <div v-if="recentEmojis.length" class="emoji-section">
          <div
            class="section-title smallText gray divNoMarginTopSmallMarginBottom"
          >
            Recent
          </div>
          <div class="emoji-list">
            <span
              class="emoji-item"
              v-for="emoji in recentEmojis"
              :key="'recent-' + emoji"
              @click="selectEmoji(emoji)"
            >
              {{ emoji }}
            </span>
          </div>
        </div>
        <!-- Emoji Categories -->
        <div
          v-for="category in emojiCategories"
          :key="category.name"
          :id="getCategoryId(category.name)"
          class="emoji-section"
        >
          <div
            class="section-title smallText gray divNoMarginTopSmallMarginBottom"
          >
            {{ category.name }}
          </div>
          <div class="emoji-list">
            <span
              class="emoji-item"
              v-for="emoji in category.emojis"
              :key="emoji"
              @click="selectEmoji(emoji)"
            >
              {{ emoji }}
            </span>
          </div>
        </div>
      </div>
      <div class="picker-content" v-else>
        <!-- Image Upload Content -->
        <ImageUploadSingleImg
          :existingImages="existingImages"
          @updateImages="handleImageSelected"
          @logoRemoved="removeImage"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import { ref, nextTick } from "vue";
import ImageUploadSingleImg from "@/components/GenericComponents/ImageUploadSingleImg.vue"; // Adjust the path accordingly

export default {
  name: "EmojiAndMediaPicker",
  components: {
    ImageUploadSingleImg,
  },
  props: {
    recentEmojis: {
      type: Array,
      default: () => [],
    },
    isVisible: Boolean,
    existingImages: {
      type: Array,
      default: () => [],
    },
    pickerMode: {
      type: String,
      default: "emoji", // Default to 'emoji' if not provided
    },
  },
  emits: ["emojiSelected", "closePicker", "imageSelected", "removeImage"],
  setup(props, { emit }) {
    // Define the emoji categories with the maximum amount of emojis
    const emojiCategories = ref([
      {
        name: "Smileys & Emotion",
        iconId: "care", // Using 'care' icon from the SVG symbols
        emojis: [
          "😀",
          "😃",
          "😄",
          "😁",
          "😆",
          "😅",
          "😂",
          "🤣",
          "😊",
          "😇",
          "🙂",
          "🙃",
          "😉",
          "😌",
          "😍",
          "🥰",
          "😘",
          "😗",
          "😙",
          "😚",
          "😋",
          "😛",
          "😜",
          "🤪",
          "😝",
          "🤑",
          "🤗",
          "🤭",
          "🤫",
          "🤔",
          "🤐",
          "🤨",
          "😐",
          "😑",
          "😶",
          "😏",
          "😒",
          "🙄",
          "😬",
          "🤥",
          "😌",
          "😔",
          "😪",
          "🤤",
          "😴",
          "😷",
          "🤒",
          "🤕",
          "🤢",
          "🤮",
          "🤧",
          "😵",
          "🤯",
          "🤠",
          "🥳",
          "🥴",
          "🥺",
          "😲",
          "😳",
          "🥵",
          "🥶",
          "😱",
          "😨",
          "😰",
          "😥",
          "😓",
          "🤗",
          "🤔",
          "🤭",
          "🤫",
          "🤥",
          "😤",
          "😠",
          "😡",
          "🤬",
          "😈",
          "👿",
          "💀",
          "☠️",
          "🤡",
          "👹",
          "👺",
          "👻",
          "👽",
          "👾",
          "🤖",
          "😺",
          "😸",
          "😹",
          "😻",
          "😼",
          "😽",
          "🙀",
          "😿",
          "😾",
          "💋",
          "👋",
          "🤚",
          "🖐",
          "✋",
          "🖖",
          "👌",
          "🤏",
          "✌️",
          "🤞",
          "🤟",
          "🤘",
          "🤙",
          "👈",
          "👉",
          "👆",
          "🖕",
          "👇",
          "☝️",
          "👍",
          "👎",
          "✊",
          "👊",
          "🤛",
          "🤜",
          "👏",
          "🙌",
          "👐",
          "🤲",
          "🤝",
          "🙏",
          "✍️",
          "💅",
          "🤳",
          "💪",
          "🦾",
          "🦿",
          "🦵",
          "🦶",
          "👂",
          "🦻",
          "👃",
          "🧠",
          "🦷",
          "🦴",
          "👀",
          "👁",
          "👅",
          "👄",
          "💋",
          "💘",
          "💝",
          "💖",
          "💗",
          "💓",
          "💞",
          "💕",
          "💟",
          "❣️",
          "💔",
          "❤️",
          "🧡",
          "💛",
          "💚",
          "💙",
          "💜",
          "🤎",
          "🖤",
          "🤍",
          "💯",
          "💢",
          "💥",
          "💫",
          "💦",
          "💨",
          "🕳",
          "💣",
          "💬",
          "👁‍🗨",
          "🗨",
          "🗯",
          "💭",
          "💤",
        ],
      },
      {
        name: "People & Body",
        iconId: "members",
        emojis: [
          "👶",
          "👧",
          "🧒",
          "👦",
          "👩",
          "🧑",
          "👨",
          "👩‍🦱",
          "👨‍🦱",
          "👩‍🦰",
          "👨‍🦰",
          "👱‍♀️",
          "👱‍♂️",
          "👩‍🦳",
          "👨‍🦳",
          "👩‍🦲",
          "👨‍🦲",
          "🧔",
          "👵",
          "🧓",
          "👴",
          "👲",
          "👳‍♀️",
          "👳‍♂️",
          "🧕",
          "👮‍♀️",
          "👮‍♂️",
          "👷‍♀️",
          "👷‍♂️",
          "💂‍♀️",
          "💂‍♂️",
          "🕵️‍♀️",
          "🕵️‍♂️",
          "👩‍⚕️",
          "👨‍⚕️",
          "👩‍🌾",
          "👨‍🌾",
          "👩‍🍳",
          "👨‍🍳",
          "👩‍🎓",
          "👨‍🎓",
          "👩‍🎤",
          "👨‍🎤",
          "👩‍🏫",
          "👨‍🏫",
          "👩‍🏭",
          "👨‍🏭",
          "👩‍💻",
          "👨‍💻",
          "👩‍💼",
          "👨‍💼",
          "👩‍🔧",
          "👨‍🔧",
          "👩‍🔬",
          "👨‍🔬",
          "👩‍🎨",
          "👨‍🎨",
          "👩‍🚒",
          "👨‍🚒",
          "👩‍✈️",
          "👨‍✈️",
          "👩‍🚀",
          "👨‍🚀",
          "👩‍⚖️",
          "👨‍⚖️",
          "👰",
          "🤵",
          "👸",
          "🤴",
          "🥷",
          "🦸‍♀️",
          "🦸‍♂️",
          "🦹‍♀️",
          "🦹‍♂️",
          "🤶",
          "🎅",
          "🧙‍♀️",
          "🧙‍♂️",
          "🧝‍♀️",
          "🧝‍♂️",
          "🧛‍♀️",
          "🧛‍♂️",
          "🧟‍♀️",
          "🧟‍♂️",
          "🧞‍♀️",
          "🧞‍♂️",
          "🧜‍♀️",
          "🧜‍♂️",
          "🧚‍♀️",
          "🧚‍♂️",
          "👼",
          "🤰",
          "🤱",
          "👩‍🍼",
          "👨‍🍼",
          "🙇‍♀️",
          "🙇‍♂️",
          "💁‍♀️",
          "💁‍♂️",
          "🙅‍♀️",
          "🙅‍♂️",
          "🙆‍♀️",
          "🙆‍♂️",
          "🙋‍♀️",
          "🙋‍♂️",
          "🧏‍♀️",
          "🧏‍♂️",
          "🤦‍♀️",
          "🤦‍♂️",
          "🤷‍♀️",
          "🤷‍♂️",
          "🙎‍♀️",
          "🙎‍♂️",
          "🙍‍♀️",
          "🙍‍♂️",
          "💇‍♀️",
          "💇‍♂️",
          "💆‍♀️",
          "💆‍♂️",
          "🧖‍♀️",
          "🧖‍♂️",
          "💅",
          "🤳",
          "💃",
          "🕺",
          "👯‍♀️",
          "👯‍♂️",
          "🕴",
          "👩‍🦽",
          "👨‍🦽",
          "👩‍🦼",
          "👨‍🦼",
          "🚶‍♀️",
          "🚶‍♂️",
          "👩‍🦯",
          "👨‍🦯",
          "🏃‍♀️",
          "🏃‍♂️",
          "🧍‍♀️",
          "🧍‍♂️",
          "🧎‍♀️",
          "🧎‍♂️",
          "🧗‍♀️",
          "🧗‍♂️",
          "🏇",
          "🏂",
          "🏌️‍♀️",
          "🏌️‍♂️",
          "🏄‍♀️",
          "🏄‍♂️",
          "🚣‍♀️",
          "🚣‍♂️",
          "🏊‍♀️",
          "🏊‍♂️",
          "⛹️‍♀️",
          "⛹️‍♂️",
          "🏋️‍♀️",
          "🏋️‍♂️",
          "🚴‍♀️",
          "🚴‍♂️",
          "🚵‍♀️",
          "🚵‍♂️",
          "🤸‍♀️",
          "🤸‍♂️",
          "🤼‍♀️",
          "🤼‍♂️",
          "🤽‍♀️",
          "🤽‍♂️",
          "🤾‍♀️",
          "🤾‍♂️",
          "🤹‍♀️",
          "🤹‍♂️",
          "🧘‍♀️",
          "🧘‍♂️",
          "👫",
          "👭",
          "👬",
          "💏",
          "💑",
          "👪",
          "👨‍👩‍👧",
          "👨‍👩‍👧‍👦",
          "👨‍👩‍👦‍👦",
          "👨‍👩‍👧‍👧",
          "👩‍👩‍👦",
          "👩‍👩‍👧",
          "👩‍👩‍👧‍👦",
          "👩‍👩‍👦‍👦",
          "👩‍👩‍👧‍👧",
          "👨‍👨‍👦",
          "👨‍👨‍👧",
          "👨‍👨‍👧‍👦",
          "👨‍👨‍👦‍👦",
          "👨‍👨‍👧‍👧",
          "👩‍👦",
          "👩‍👧",
          "👩‍👧‍👦",
          "👩‍👦‍👦",
          "👩‍👧‍👧",
          "👨‍👦",
          "👨‍👧",
          "👨‍👧‍👦",
          "👨‍👦‍👦",
          "👨‍👧‍👧",
          "🤲",
          "👐",
          "🙌",
          "👏",
          "🤝",
          "👍",
          "👎",
          "👊",
          "✊",
          "🤛",
          "🤜",
          "🤞",
          "✌️",
          "🤟",
          "🤘",
          "👌",
          "🤏",
          "👈",
          "👉",
          "👆",
          "👇",
          "☝️",
          "✋",
          "🤚",
          "🖐",
          "🖖",
          "👋",
          "🤙",
          "💪",
          "🦾",
          "🖕",
          "✍️",
          "🙏",
          "🦿",
          "🦵",
          "🦶",
          "👂",
          "🦻",
          "👃",
          "👣",
          "👀",
          "👁",
          "🧠",
          "🦷",
          "🦴",
          "👅",
          "👄",
        ],
      },
      {
        name: "Animals & Nature",
        iconId: "world",
        emojis: [
          "🐵",
          "🐒",
          "🦍",
          "🦧",
          "🐶",
          "🐕",
          "🦮",
          "🐕‍🦺",
          "🐩",
          "🐺",
          "🦊",
          "🦝",
          "🐱",
          "🐈",
          "🐈‍⬛",
          "🦁",
          "🐯",
          "🐅",
          "🐆",
          "🐴",
          "🐎",
          "🦄",
          "🦓",
          "🦌",
          "🦬",
          "🐮",
          "🐂",
          "🐃",
          "🐄",
          "🐷",
          "🐖",
          "🐗",
          "🐽",
          "🐏",
          "🐑",
          "🐐",
          "🐪",
          "🐫",
          "🦙",
          "🦒",
          "🐘",
          "🦣",
          "🦏",
          "🦛",
          "🐭",
          "🐁",
          "🐀",
          "🐹",
          "🐰",
          "🐇",
          "🐿️",
          "🦔",
          "🦇",
          "🐻",
          "🐻‍❄️",
          "🐨",
          "🐼",
          "🦥",
          "🦦",
          "🦨",
          "🦘",
          "🦡",
          "🐾",
          "🦃",
          "🐔",
          "🐓",
          "🐣",
          "🐤",
          "🐥",
          "🐦",
          "🐧",
          "🕊️",
          "🦅",
          "🦆",
          "🦢",
          "🦉",
          "🦤",
          "🪶",
          "🦩",
          "🦚",
          "🦜",
          "🐸",
          "🐊",
          "🐢",
          "🦎",
          "🐍",
          "🐲",
          "🐉",
          "🦕",
          "🦖",
          "🐳",
          "🐋",
          "🐬",
          "🦭",
          "🐟",
          "🐠",
          "🐡",
          "🦈",
          "🐙",
          "🐚",
          "🪸",
          "🪼",
          "🐌",
          "🦋",
          "🐛",
          "🐜",
          "🐝",
          "🪲",
          "🐞",
          "🦗",
          "🪳",
          "🕷️",
          "🕸️",
          "🦂",
          "🦟",
          "🪰",
          "🪱",
          "🦠",
          "💐",
          "🌸",
          "💮",
          "🪷",
          "🏵️",
          "🌹",
          "🥀",
          "🌺",
          "🌻",
          "🌼",
          "🌷",
          "🌱",
          "🪴",
          "🌲",
          "🌳",
          "🌴",
          "🌵",
          "🌾",
          "🌿",
          "☘️",
          "🍀",
          "🍁",
          "🍂",
          "🍃",
          "🪹",
          "🪺",
          "🌍",
          "🌎",
          "🌏",
          "🌑",
          "🌒",
          "🌓",
          "🌔",
          "🌕",
          "🌖",
          "🌗",
          "🌘",
          "🌙",
          "🌚",
          "🌛",
          "🌜",
          "☀️",
          "🌝",
          "🌞",
          "⭐",
          "🌟",
          "🌠",
          "☁️",
          "⛅",
          "⛈️",
          "🌤️",
          "🌥️",
          "🌦️",
          "🌧️",
          "🌨️",
          "🌩️",
          "🌪️",
          "🌫️",
          "🌬️",
          "🌀",
          "🌈",
          "🌂",
          "☂️",
          "☔",
          "⛱️",
          "⚡",
          "❄️",
          "☃️",
          "⛄",
          "☄️",
          "🔥",
          "💧",
          "🌊",
          "🎄",
          "✨",
          "🎋",
          "🎍",
          "🧚",
          "🧜",
          "🧚‍♂️",
        ],
      },
      {
        name: "Food & Drink",
        iconId: "voucher_systems",
        emojis: [
          "🍇",
          "🍈",
          "🍉",
          "🍊",
          "🍋",
          "🍌",
          "🍍",
          "🥭",
          "🍎",
          "🍏",
          "🍐",
          "🍑",
          "🍒",
          "🍓",
          "🫐",
          "🥝",
          "🍅",
          "🫒",
          "🥥",
          "🥑",
          "🍆",
          "🥔",
          "🥕",
          "🌽",
          "🌶️",
          "🫑",
          "🥒",
          "🥬",
          "🥦",
          "🧄",
          "🧅",
          "🍄",
          "🥜",
          "🌰",
          "🍞",
          "🥐",
          "🥖",
          "🫓",
          "🥨",
          "🥯",
          "🥞",
          "🧇",
          "🧀",
          "🍖",
          "🍗",
          "🥩",
          "🥓",
          "🍔",
          "🍟",
          "🍕",
          "🌭",
          "🥪",
          "🌮",
          "🌯",
          "🫔",
          "🥙",
          "🧆",
          "🥚",
          "🍳",
          "🥘",
          "🍲",
          "🫕",
          "🥣",
          "🥗",
          "🍿",
          "🧈",
          "🧂",
          "🥫",
          "🍱",
          "🍘",
          "🍙",
          "🍚",
          "🍛",
          "🍜",
          "🍝",
          "🍠",
          "🍢",
          "🍣",
          "🍤",
          "🍥",
          "🥮",
          "🍡",
          "🥟",
          "🥠",
          "🥡",
          "🦪",
          "🍦",
          "🍧",
          "🍨",
          "🍩",
          "🍪",
          "🎂",
          "🍰",
          "🧁",
          "🥧",
          "🍫",
          "🍬",
          "🍭",
          "🍮",
          "🍯",
          "🍼",
          "🥛",
          "☕",
          "🫖",
          "🍵",
          "🍶",
          "🍾",
          "🍷",
          "🍸",
          "🍹",
          "🍺",
          "🍻",
          "🥂",
          "🥃",
          "🫗",
          "🥤",
          "🧋",
          "🧃",
          "🧉",
          "🧊",
          "🥢",
          "🍽️",
          "🍴",
          "🥄",
          "🔪",
          "🫙",
          "🏺",
        ],
      },
      {
        name: "Activities",
        iconId: "football",
        emojis: [
          "⚽",
          "🏀",
          "🏈",
          "⚾",
          "🥎",
          "🎾",
          "🏐",
          "🏉",
          "🥏",
          "🎱",
          "🪀",
          "🏓",
          "🏸",
          "🏒",
          "🏑",
          "🥍",
          "🏏",
          "🪃",
          "🥅",
          "⛳",
          "🪁",
          "🏹",
          "🎣",
          "🤿",
          "🥊",
          "🥋",
          "🎽",
          "🛹",
          "🛼",
          "🛷",
          "⛸️",
          "🥌",
          "🎿",
          "⛷️",
          "🏂",
          "🪂",
          "🏋️‍♀️",
          "🏋️‍♂️",
          "🤺",
          "🤼‍♀️",
          "🤼‍♂️",
          "🤸‍♀️",
          "🤸‍♂️",
          "⛹️‍♀️",
          "⛹️‍♂️",
          "🤾‍♀️",
          "🤾‍♂️",
          "🏌️‍♀️",
          "🏌️‍♂️",
          "🏇",
          "🧘‍♀️",
          "🧘‍♂️",
          "🏄‍♀️",
          "🏄‍♂️",
          "🏊‍♀️",
          "🏊‍♂️",
          "🤽‍♀️",
          "🤽‍♂️",
          "🚣‍♀️",
          "🚣‍♂️",
          "🧗‍♀️",
          "🧗‍♂️",
          "🚵‍♀️",
          "🚵‍♂️",
          "🚴‍♀️",
          "🚴‍♂️",
          "🏆",
          "🥇",
          "🥈",
          "🥉",
          "🏅",
          "🎖️",
          "🏵️",
          "🎗️",
          "🎫",
          "🎟️",
          "🎪",
          "🤹‍♀️",
          "🤹‍♂️",
          "🎭",
          "🩰",
          "🎨",
          "🎬",
          "🎤",
          "🎧",
          "🎼",
          "🎹",
          "🥁",
          "🪘",
          "🎷",
          "🎺",
          "🪗",
          "🎸",
          "🪕",
          "🎻",
          "🎲",
          "♟️",
          "🎯",
          "🎳",
          "🎮",
          "🕹️",
          "🎰",
        ],
      },
      {
        name: "Travel & Places",
        iconId: "overview",
        emojis: [
          "🚗",
          "🚕",
          "🚙",
          "🚌",
          "🚎",
          "🏎️",
          "🚓",
          "🚑",
          "🚒",
          "🚐",
          "🛻",
          "🚚",
          "🚛",
          "🚜",
          "🏍️",
          "🛵",
          "🦽",
          "🦼",
          "🛺",
          "🚲",
          "🛴",
          "🛹",
          "🛼",
          "🚨",
          "🚔",
          "🚍",
          "🚘",
          "🚖",
          "🚡",
          "🚠",
          "🚟",
          "🚃",
          "🚋",
          "🚞",
          "🚝",
          "🚄",
          "🚅",
          "🚈",
          "🚂",
          "🚆",
          "🚇",
          "🚊",
          "🚉",
          "✈️",
          "🛫",
          "🛬",
          "🛩️",
          "💺",
          "🛰️",
          "🚀",
          "🛸",
          "🛳️",
          "⛴️",
          "🛥️",
          "🚤",
          "⛵",
          "🛶",
          "🚁",
          "🚟",
          "🗺️",
          "🗾",
          "🧭",
          "🏔️",
          "⛰️",
          "🌋",
          "🗻",
          "🏕️",
          "🏖️",
          "🏜️",
          "🏝️",
          "🏞️",
          "🏟️",
          "🏛️",
          "🏗️",
          "🧱",
          "🪨",
          "🪵",
          "🛖",
          "🏘️",
          "🏚️",
          "🏠",
          "🏡",
          "🏢",
          "🏣",
          "🏤",
          "🏥",
          "🏦",
          "🏨",
          "🏩",
          "🏪",
          "🏫",
          "🏬",
          "🏭",
          "🏯",
          "🏰",
          "💒",
          "🗼",
          "🗽",
          "⛪",
          "🕌",
          "🛕",
          "🕍",
          "⛩️",
          "🕋",
          "⛲",
          "⛺",
          "🌁",
          "🌃",
          "🏙️",
          "🌄",
          "🌅",
          "🌆",
          "🌇",
          "🌉",
          "♨️",
          "🎠",
          "🎡",
          "🎢",
          "💈",
          "🎪",
          "🚂",
          "🚃",
          "🚄",
          "🚅",
          "🚆",
          "🚇",
          "🚈",
          "🚉",
          "🚊",
          "🚝",
          "🚞",
          "🚋",
          "🚌",
          "🚍",
          "🚎",
          "🚐",
          "🚑",
          "🚒",
          "🚓",
          "🚔",
          "🚕",
          "🚖",
          "🚗",
          "🚘",
          "🚙",
          "🚚",
          "🚛",
          "🚜",
          "🏎️",
          "🏍️",
          "🛵",
          "🛺",
          "🚲",
          "🛴",
          "🛹",
          "🛼",
          "🚏",
          "🛣️",
          "🛤️",
          "🛢️",
          "⛽",
          "🚨",
          "🚥",
          "🚦",
          "🛑",
          "🚧",
          "⚓",
          "🛟",
          "⛵",
          "🛶",
          "🚤",
          "🛳️",
          "⛴️",
          "🛥️",
          "🚢",
          "✈️",
          "🛩️",
          "🛫",
          "🛬",
          "🪂",
          "💺",
          "🚁",
          "🚟",
          "🚠",
          "🚡",
          "🛰️",
          "🚀",
          "🛸",
          "🛎️",
          "🧳",
          "⌛",
          "⏳",
          "⌚",
          "⏰",
          "⏱️",
          "⏲️",
          "🕰️",
          "🕛",
          "🕧",
          "🕐",
          "🕜",
          "🕑",
          "🕝",
          "🕒",
          "🕞",
          "🕓",
          "🕟",
          "🕔",
          "🕠",
          "🕕",
          "🕡",
          "🕖",
          "🕢",
          "🕗",
          "🕣",
          "🕘",
          "🕤",
          "🕙",
          "🕥",
          "🕚",
          "🕦",
          "🌑",
          "🌒",
          "🌓",
          "🌔",
          "🌕",
          "🌖",
          "🌗",
          "🌘",
          "🌙",
          "🌚",
          "🌛",
          "🌜",
          "☀️",
          "🌝",
          "🌞",
          "⭐",
          "🌟",
          "🌠",
          "☁️",
          "⛅",
          "⛈️",
          "🌤️",
          "🌥️",
          "🌦️",
          "🌧️",
          "🌨️",
          "🌩️",
          "🌪️",
          "🌫️",
          "🌬️",
          "🌀",
          "🌈",
          "🌂",
          "☂️",
          "☔",
          "⛱️",
        ],
      },
      {
        name: "Objects",
        iconId: "unit-coin",
        emojis: [
          "⌚",
          "📱",
          "📲",
          "💻",
          "⌨️",
          "🖥️",
          "🖨️",
          "🖱️",
          "🖲️",
          "🕹️",
          "🗜️",
          "💽",
          "💾",
          "💿",
          "📀",
          "📼",
          "📷",
          "📸",
          "📹",
          "🎥",
          "📽️",
          "🎞️",
          "📞",
          "☎️",
          "📟",
          "📠",
          "📺",
          "📻",
          "🎙️",
          "🎚️",
          "🎛️",
          "🧭",
          "⏱️",
          "⏲️",
          "⏰",
          "🕰️",
          "⌛",
          "⏳",
          "📡",
          "🔋",
          "🔌",
          "💡",
          "🔦",
          "🕯️",
          "🪔",
          "🧯",
          "🛢️",
          "💸",
          "💵",
          "💴",
          "💶",
          "💷",
          "💰",
          "💳",
          "🧾",
          "💎",
          "⚖️",
          "🪙",
          "💰",
          "🔧",
          "🔨",
          "⚒️",
          "🛠️",
          "⛏️",
          "🔩",
          "⚙️",
          "🗜️",
          "⚖️",
          "🔗",
          "⛓️",
          "🧰",
          "🧲",
          "⚗️",
          "🧪",
          "🧫",
          "🧬",
          "🔬",
          "🔭",
          "📡",
          "💉",
          "🩸",
          "💊",
          "🩹",
          "🩺",
          "🚪",
          "🛏️",
          "🛋️",
          "🪑",
          "🚽",
          "🚿",
          "🛁",
          "🪠",
          "🧴",
          "🧷",
          "🧹",
          "🧺",
          "🧻",
          "🧼",
          "🪥",
          "🧽",
          "🧯",
          "🛒",
          "🚬",
          "⚰️",
          "⚱️",
          "🗿",
          "🪦",
          "🛍️",
          "🎁",
          "🎈",
          "🎏",
          "🎀",
          "🎊",
          "🎉",
          "🎎",
          "🏮",
          "🎐",
          "🧧",
          "✉️",
          "📩",
          "📨",
          "📧",
          "💌",
          "📥",
          "📤",
          "📦",
          "🏷️",
          "📪",
          "📫",
          "📬",
          "📭",
          "📮",
          "📯",
          "📜",
          "📃",
          "📄",
          "📑",
          "📊",
          "📈",
          "📉",
          "🗒️",
          "🗓️",
          "📆",
          "📅",
          "🗑️",
          "📇",
          "📁",
          "📂",
          "🗂️",
          "🗃️",
          "🗄️",
          "🗞️",
          "📋",
          "📌",
          "📍",
          "📎",
          "🖇️",
          "📏",
          "📐",
          "✂️",
          "🗣️",
          "👤",
          "👥",
          "👣",
          "💬",
          "👁️‍🗨️",
          "🗨️",
          "🗯️",
          "💭",
          "💤",
          "🗯",
          "💬",
        ],
      },
      {
        name: "Symbols",
        iconId: "peace",
        emojis: [
          "❤️",
          "🧡",
          "💛",
          "💚",
          "💙",
          "💜",
          "🤎",
          "🖤",
          "🤍",
          "💔",
          "❣️",
          "💕",
          "💞",
          "💓",
          "💗",
          "💖",
          "💘",
          "💝",
          "💟",
          "☮️",
          "✝️",
          "☪️",
          "🕉️",
          "☸️",
          "✡️",
          "🔯",
          "🕎",
          "☯️",
          "☦️",
          "🛐",
          "⛎",
          "♈",
          "♉",
          "♊",
          "♋",
          "♌",
          "♍",
          "♎",
          "♏",
          "♐",
          "♑",
          "♒",
          "♓",
          "🆔",
          "⚛️",
          "🉑",
          "☢️",
          "☣️",
          "📴",
          "📳",
          "🈶",
          "🈚",
          "🈸",
          "🈺",
          "🈷️",
          "✴️",
          "🆚",
          "💮",
          "🉐",
          "㊙️",
          "㊗️",
          "🈴",
          "🈵",
          "🈹",
          "🈲",
          "🅰️",
          "🅱️",
          "🆎",
          "🆑",
          "🅾️",
          "🆘",
          "❌",
          "⭕",
          "🛑",
          "⛔",
          "📛",
          "🚫",
          "💯",
          "💢",
          "♨️",
          "💬",
          "🗨️",
          "🗯️",
          "💭",
          "💤",
          "🔞",
          "🚯",
          "🚱",
          "🚳",
          "🚷",
          "🚸",
          "🛂",
          "🛃",
          "🛄",
          "🛅",
          "♿",
          "🚭",
          "❗",
          "❕",
          "❓",
          "❔",
          "‼️",
          "⁉️",
          "🔅",
          "🔆",
          "〽️",
          "⚠️",
          "🚸",
          "🔱",
          "⚜️",
          "🔰",
          "♻️",
          "✅",
          "🈯",
          "💹",
          "❎",
          "📳",
          "📴",
          "🆗",
          "💠",
          "♠️",
          "♣️",
          "♥️",
          "♦️",
          "🃏",
          "🎴",
          "🀄",
          "🕐",
          "🕑",
          "🕒",
          "🕓",
          "🕔",
          "🕕",
          "🕖",
          "🕗",
          "🕘",
          "🕙",
          "🕚",
          "🕛",
          "🌀",
          "♾️",
          "🅿️",
          "🧿",
          "🔗",
          "➰",
          "〰️",
          "✔️",
          "✖️",
          "➕",
          "➖",
          "➗",
          "💲",
          "£",
          "¥",
          "€",
          "🔟",
          "#️⃣",
          "*️⃣",
          "0️⃣",
          "1️⃣",
          "2️⃣",
          "3️⃣",
          "4️⃣",
          "5️⃣",
          "6️⃣",
          "7️⃣",
          "8️⃣",
          "9️⃣",
          "🔠",
          "🔡",
          "🔢",
          "🔣",
          "🔤",
          "🅰️",
          "🆎",
          "🅱️",
          "🆑",
          "🆒",
          "🆓",
          "ℹ️",
          "🆔",
          "Ⓜ️",
          "🆕",
          "🆖",
          "🅾️",
          "🆗",
          "🅿️",
          "🆘",
          "🆙",
          "🆚",
          "🈁",
          "🈂️",
          "🈷️",
          "🈶",
          "🈯",
          "🈚",
          "🈸",
          "🈺",
          "🈴",
          "🈳",
          "㊗️",
          "㊙️",
          "🈲",
          "🈹",
          "🈺",
          "🈵",
          "🈴",
          "🈳",
          "🈲",
          "🈹",
          "🈺",
          "🈵",
          "🈴",
          "🈳",
        ],
      },
      {
        name: "Flags",
        iconId: "world",
        emojis: [
          "🏁",
          "🚩",
          "🎌",
          "🏴",
          "🏳️",
          "🏳️‍🌈",
          "🏳️‍⚧️",
          "🏴‍☠️",
          "🇦🇫",
          "🇦🇽",
          "🇦🇱",
          "🇩🇿",
          "🇦🇸",
          "🇦🇩",
          "🇦🇴",
          "🇦🇮",
          "🇦🇶",
          "🇦🇬",
          "🇦🇷",
          "🇦🇲",
          "🇦🇼",
          "🇦🇺",
          "🇦🇹",
          "🇦🇿",
          "🇧🇸",
          "🇧🇭",
          "🇧🇩",
          "🇧🇧",
          "🇧🇾",
          "🇧🇪",
          "🇧🇿",
          "🇧🇯",
          "🇧🇲",
          "🇧🇹",
          "🇧🇴",
          "🇧🇦",
          "🇧🇼",
          "🇧🇷",
          "🇻🇬",
          "🇧🇳",
          "🇧🇬",
          "🇧🇫",
          "🇧🇮",
          "🇰🇭",
          "🇨🇲",
          "🇨🇦",
          "🇮🇨",
          "🇨🇻",
          "🇧🇶",
          "🇰🇾",
          "🇨🇫",
          "🇹🇩",
          "🇨🇱",
          "🇨🇳",
          "🇨🇽",
          "🇨🇨",
          "🇨🇴",
          "🇰🇲",
          "🇨🇬",
          "🇨🇩",
          // (Include all country flags)
        ],
      },
    ]);

    // Function to generate a valid ID for each category
    const getCategoryId = (categoryName) =>
      `category-${categoryName.replace(/\s+/g, "-")}`;

    // Method to scroll to the selected category
    const scrollToCategory = (categoryName) => {
      nextTick(() => {
        const categoryId = getCategoryId(categoryName);
        const categoryElement = document.getElementById(categoryId);
        if (categoryElement) {
          categoryElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      });
    };

    const selectEmoji = (emoji) => {
      emit("emojiSelected", emoji);
    };

    const closePicker = () => {
      console.log("closePicker");
      emit("closePicker");
    };

    const handleImageSelected = (images) => {
      // Since it's a single image upload, we take the first image
      if (images && images.length > 0) {
        emit("imageSelected", images[0]);
      }
    };

    const removeImage = () => {
      emit("removeImage");
      closePicker();
    };

    return {
      emojiCategories,
      getCategoryId,
      scrollToCategory,
      selectEmoji,
      closePicker,
      removeImage,
      handleImageSelected,
    };
  },
};
</script>

<style scoped>
.emoji-media-picker {
  position: absolute;
  bottom: calc(var(--clickableHeight) + var(--smallMargin) * 2);
  left: 0;
  width: 100%;
  max-height: 50vh;
  background-color: var(--backgroundColourOrg);
  box-shadow: 0 -2px 6px var(--darkbluetransplight);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 2;
  margin-top: 0 !important;
  border-top-left-radius: var(--borderRadius);
  border-top-right-radius: var(--borderRadius);
}

.picker-header {
  right: var(--smallerMargin);
  gap: var(--smallerMargin);

  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: var(--smallMargin);
}

.category-icons {
  display: flex;
  gap: var(--smallerMargin);
  height: var(--smallClickableHeight);
  justify-content: space-between;
  width: 100%;
}
.category-icons svg {
  width: var(--smallerClickableHeight);
  height: var(--smallerClickableHeight);
  cursor: pointer;
}
.picker-title {
  font-weight: bold;
}

.close-icon {
  width: var(--smallerClickableHeight);
  height: var(--smallerClickableHeight);
  margin-left: auto; /* Pushes the icon to the right */
}

.picker-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: var(--smallMargin);
  margin-top: 0 !important;
  margin: var(--smallMargin);
  box-shadow: var(--neuMorphBoxInner);
  border-radius: var(--borderRadius);
  z-index: 0; /* Ensure it's below picker-header */
}

.emoji-section {
  margin-bottom: var(--smallMargin);
}

.section-title {
  margin-bottom: var(--tinyMargin);
}

.emoji-list {
  display: flex;
  flex-wrap: wrap;
  gap: var(--smallerMargin);
}

.emoji-item {
  font-size: var(--smallClickableHeight);
  cursor: pointer;
}

.picker-footer {
  display: flex;
  align-items: center;
  padding: var(--smallMargin);
}

.photo-icon {
  width: var(--clickableHeight);
  height: var(--clickableHeight);
  cursor: pointer;
}

.image-thumbnail {
  position: relative;
  margin-left: var(--smallMargin);
}

.image-thumbnail img {
  width: var(--clickableHeight);
  height: var(--clickableHeight);
  object-fit: cover;
  border-radius: var(--borderRadius);
}

.remove-image-icon {
  position: absolute;
  top: -var(--microMargin);
  right: -var(--microMargin);
  width: var(--smallerClickableHeight);
  height: var(--smallerClickableHeight);
  cursor: pointer;
}
</style>
