<template>
  <div class="UseInviteLink">
    <div class="sticker yellow divNoMarginBottom">
      <div class="column fullWidth alignCentre">
        <div class="fullWidth">
          <div>
            <input
              class="alignCentre fullWidth inputInSticker"
              type="url"
              id="inviteLink"
              v-model="inviteLink"
              autocomplete="inviteLink"
              :placeholder="$t('Invites.placeholderText')"
              @keyup.enter="goToInviteLink"
            />
          </div>
          <div class="separator_sticker"></div>
          <div
            class="row justifyToCentre alignCentreRow divNoMarginTopSmallMarginBottom"
          >
            <button type="submit" @click="goToInviteLink" v-haptic>
              {{ $t("buttons.checkInvite") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore, mapGetters } from "vuex";
import { useRoute, useRouter } from "vue-router";

import { ref, onMounted } from "vue";
import { auth, db } from "@/firebase";
import { collection } from "firebase/firestore";
import { toast } from "vue3-toastify";

export default {
  name: "UseInviteLink",
  components: {},

  setup() {
    const inviteLink = ref(""); // Remove the initial value assignment
    const route = useRoute();
    const router = useRouter();

    const goToInviteLink = () => {
      let orgUID;
      let inviteUID;

      try {
        const inviteUrl = new URL(inviteLink.value);
        const pathSegments = inviteUrl.pathname.split("/");

        // pathSegments[0] is an empty string because pathname starts with '/'
        if (pathSegments[1] === "invite") {
          orgUID = pathSegments[2];
          inviteUID = pathSegments[3]; // undefined if it doesn't exist, which is okay
        }
      } catch (error) {
        // If it's not a valid URL, treat it as a relative path
        let normalizedInput = inviteLink.value.startsWith("/")
          ? inviteLink.value.slice(1)
          : inviteLink.value;

        if (normalizedInput.startsWith("invite/")) {
          normalizedInput = normalizedInput.slice("invite/".length);
        }

        const parts = normalizedInput.split("/");
        if (parts.length >= 2) {
          orgUID = parts[0];
          inviteUID = parts[1];
        } else if (parts.length === 1) {
          orgUID = parts[0];
        }
      }

      if (!orgUID) {
        // Invalid invite link, display an error message or handle accordingly
        toast.error("Invalid invite link");
        return;
      }

      router.push({
        name: "accept-invite",
        params: {
          orgUID: orgUID,
          inviteUID: inviteUID, // undefined if it doesn't exist, which is okay
        },
      });
    };

    onMounted(async () => {});

    return {
      inviteLink,
      goToInviteLink,
    };
  },
};
</script>

<style scoped>
.UseInviteLink {
  width: 100%;
}
</style>
