<template>
  <div class="innerSticker dropdownPanel">
    <div
      v-for="(item, index) in faqItemsRef"
      :key="item.question"
      class="dropdown-item"
    >
      <div @click="toggle(item.question)" class="question">
        <div class="row alignCentreRow fullWidth justifyToStartEnd">
          <div class="questionTextBox fullWidth">
            <h3>{{ item.question }}</h3>
          </div>
          <svg class="compactImg" v-if="!item.open" v-haptic>
            <use href="@/assets/icons/iconset.svg#arrowdown_carousel"></use>
          </svg>
          <svg class="compactImg" v-else v-haptic>
            <use href="@/assets/icons/iconset.svg#arrowup_carousel"></use>
          </svg>
        </div>
      </div>
      <transition name="slide-fade">
        <div v-if="item.open" class="answer">
          <p
            v-if="item.answer"
            class="nomargintopsmallmarginbottom shortwidth"
            v-for="(line, i) in item.answer.split('\n')"
            :key="i"
          >
            {{ line }}
          </p>
          <slot :name="sanitizeQuestion(item.question)"></slot>
        </div>
      </transition>
      <div
        v-if="index !== faqItemsRef.length - 1"
        class="separator_sticker"
      ></div>
    </div>
  </div>
</template>

<script>
import { ref, toRefs } from "vue";

export default {
  name: "DropdownPanel",
  props: {
    faqItems: Array,
  },
  setup(props) {
    const { faqItems } = toRefs(props);
    const faqItemsRef = ref(faqItems.value);

    const toggle = (question) => {
      const item = faqItemsRef.value.find((i) => i.question === question);
      if (item) {
        item.open = !item.open;
      }
    };

    const sanitizeQuestion = (question) => {
      if (typeof question === "string") {
        return question
          .replace(/ /g, "_")
          .replace(/[^\w\s]/gi, "")
          .toLowerCase();
      }
      return "";
    };

    return {
      faqItemsRef,
      toggle,
      sanitizeQuestion,
    };
  },
};
</script>
<style scoped>
h3 {
  font-weight: var(--fontweightlight);
}

.dropdownPanel {
  background-color: var(--gray300);
  border-radius: var(--borderRadiusEvenBigger) !important;
  transition: all var(--slideInSpeed);
}

.compactImg {
  min-width: calc(var(--smallClickableHeight) * 0.6);
}

.dropdown-item {
  min-height: var(--clickableHeight);
  border-radius: var(--borderRadiusEvenBigger);
  transition: all var(--slideInSpeed);
}

.dropdown-item .separator_sticker {
  margin: var(--tinyMargin) 0;
  transition: all var(--slideInSpeed);
}

.question {
  display: flex;
  transition: all var(--slideInSpeed);
}

.questionTextBox {
  display: flex;
}

.questionTextBox h3 {
  text-align: left;
}
</style>
