<template>
  <div :class="['lang_select', compactClass]">
    <select
      v-model="selectedLocale"
      @change="changeLocale"
      @focus="isInteracting = true"
      @blur="isInteracting = false"
      v-haptic
    >
      <option
        v-for="(lang, index) in languages"
        :key="index"
        :value="lang.code"
        v-haptic
      >
        {{ displayLabel(lang) }}
      </option>
    </select>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { i18n } from "../../main.js";

export default {
  name: "LanguageSelection",
  props: {
    compact: Boolean,
  },
  setup(props) {
    const selectedLocale = ref(i18n.global.locale);
    const isInteracting = ref(false);
    const languages = [
      { code: "en", display: "EN", emoji: "🇬🇧" },
      { code: "pt", display: "PT", emoji: "🇵🇹" },
      // Add more language options as needed
    ];

    const compactClass = computed(() => {
      return props.compact ? "lang_select_compact" : "lang_select_row";
    });

    const displayLabel = (lang) => {
      if (isInteracting.value || compactClass.value === "lang_select_row") {
        return `${lang.emoji} ${lang.display}`;
      } else {
        return lang.emoji;
      }
    };

    const changeLocale = () => {
      localStorage.setItem("selectedLanguage", selectedLocale.value);

      i18n.global.locale = selectedLocale.value;
    };

    return {
      selectedLocale,
      languages,
      displayLabel,
      changeLocale,
      compactClass,
      isInteracting,
    };
  },
};
</script>

<style scoped>
.lang_select_row.lang_select {
  position: initial;
  z-index: 5;
  margin-right: 50px;
}

.lang_select_compact.lang_select {
  position: initial !important;
  z-index: 5 !important;
  margin-right: 50px;
}
select {
  border: 0px;
  border-radius: none;
  background-color: transparent;
  padding: var(--smallMargin) !important;
  appearance: none;
}
</style>
