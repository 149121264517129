<template>
  <div class="image-slider">
    <div
      class="arrow leftArrow"
      v-if="product.images.length > 1"
      @click="changeImage(-1)"
    >
      <svg class="compactImg differentColour">
        <use href="@/assets/icons/iconset.svg#arrowleft_carousel_smaller"></use>
      </svg>
    </div>
    <div class="image-container">
      <img
        v-if="product.images.length > 0"
        class="productImage"
        v-for="(image, index) in product.images"
        :key="index"
        :src="image"
        alt="Product Image"
        v-show="currentImageIndex === index"
      />
      <div v-else class="placeholderImage">
        <p>No image available</p>
      </div>
    </div>
    <div
      class="arrow rightArrow"
      v-if="product.images.length > 1"
      @click="changeImage(1)"
    >
      <svg class="compactImg differentColour">
        <use
          href="@/assets/icons/iconset.svg#arrowright_carousel_smaller"
        ></use>
      </svg>
    </div>

    <!-- Image Indicators -->
    <div class="image-indicators" v-if="product.images.length > 1">
      <div
        v-for="(image, index) in product.images"
        :key="index"
        :class="['circleInd', { active: currentImageIndex === index }]"
      ></div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "ProductCarousel",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const currentImageIndex = ref(0);

    const changeImage = (direction) => {
      console.log("Changing image, direction: ", direction); // Debugging line
      currentImageIndex.value =
        (currentImageIndex.value + direction + props.product.images.length) %
        props.product.images.length;
    };

    return {
      currentImageIndex,
      changeImage,
    };
  },
};
</script>

<style scoped>
.image-slider {
  position: relative;
  width: 100% !important;
  border-radius: var(--borderRadius);
  /* max-height: 70vh; */
  border: 1px solid var(--solidLighterGrey);
  box-shadow: var(--stickerRidge);
}
.image-container {
  z-index: 1;
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  overflow: hidden; /* If you want text inside of the container to resize on font change, set to 'auto' */
}

.productImage {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.arrow {
  z-index: 2;
  position: absolute;
  opacity: 0.8;
  transition: all 0.15s;
  height: 100%;
  display: flex;
  align-items: center;
  bottom: 4px;
}

.arrow svg {
  filter: drop-shadow(0px 0px 0.7px rgb(255 255 255)) !important;
}

.arrow:hover {
  transform: translateY(var(--transY));
}

.arrow {
  mix-blend-mode: difference;
}

.arrow.rightArrow {
  cursor: e-resize;
  right: 0;
}
.arrow.leftArrow {
  cursor: w-resize;
  left: 0;
}

.image-indicators {
  z-index: 2;
  position: absolute;
  bottom: 9px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: var(--microMargin);
}

.circleInd {
  width: 7px;
  height: 7px;
  background-color: var(--backgroundLighterGrayTransp);
  border-radius: 50%;
  opacity: 1;
  border: 1px solid var(--black);
  transition: all var(--slideInSpeed) ease-in;
}

.circleInd.active {
  width: 9px;
  height: 9px;
  opacity: 1;
}
@media only screen and (max-width: 450px) {
  .image-container {
    padding-bottom: calc(100% + var(--margin));
  }
}
</style>
