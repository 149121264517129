<template>
  <div class="slider-container">
    <small class="percentageLabel">{{ label }}</small>
    <div class="dynamicSlider">
      <div
        :class="['dynamicFill', fillColor]"
        :style="{ width: percentage + '%' }"
      ></div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "DynamicSlider",
  props: {
    percentage: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  setup() {
    const fillColor = ref("orange"); // You can set conditions here to change colors

    return {
      fillColor,
    };
  },
};
</script>

<style scoped>
.slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.percentageLabel {
  font-size: var(--smallerFontsize);
  font-weight: bold;
  margin-bottom: var(--tinyMargin);
  text-align: center;
}

.dynamicSlider {
  position: relative;
  height: 10px;
  width: 100%;
  border-radius: var(--borderRadius);
  overflow: hidden;
  background-color: var(--solidMidLightgrey);
}

.dynamicFill {
  height: 10px;
  border-top-left-radius: var(--borderRadius);
  border-bottom-left-radius: var(--borderRadius);
  position: absolute;
  top: 0;
  left: 0;
}

.dynamicFill.orange {
  background-color: var(--gray500);
}
</style>
