<template>
  <div class="Ecosystem">
    <Loading v-if="isLoading" />

    <div v-else class="fullWidth">
      <div
        v-if="
          !userUnits_customOrgs ||
          Object.keys(userUnits_customOrgs).length === 0
        "
        class="fullWidth"
      >
        <CreateOrJoin />
      </div>
      <div v-else>
        <div v-if="user.loggedIn" class="column">
          <div class="row relativePosition justifyToStartEnd">
            <div
              class="row marketSelector alignCentreRow tinyGap"
              @click="toggleShowOwnOrganisationOnly"
              style="cursor: pointer"
            >
              <svg
                v-if="!showOwnOrganisationOnly"
                class="smaller verySmall"
                alt="World icon"
              >
                <use href="@/assets/icons/iconset.svg#world"></use>
              </svg>
              <svg v-else class="smaller verySmall" alt="view org icon">
                <use href="@/assets/icons/iconset.svg#organisation_flat"></use>
              </svg>
              <h1 class="nomargintopbottom">
                {{
                  showOwnOrganisationOnly
                    ? "Your organisation market"
                    : "Global market"
                }}
              </h1>
            </div>
            <div class="row justifyToEnd">
              <button
                class="smallClickableHeight noGap secondaryButton"
                @click.stop="toggleSellingAction"
                :class="{ pressed: showSellingAction }"
                v-haptic
              >
                <svg class="compactImg" alt="sell icon">
                  <use
                    href="@/assets/icons/iconset.svg#megaphone_compact"
                  ></use>
                </svg>
                Post
              </button>

              <UserActions
                ref="userActionsRef"
                :userCredentials="userCredentials"
                :user="user"
                :buyerOrgHasCoins="buyerOrgHasCoins"
                :buyerHasOrganisations="buyerHasOrganisations"
                @toggleRecentlyAdded="toggleRecentlyAdded"
                @updateShowSellingAction="handleShowSellingActionUpdate"
              />
            </div>
            <transition name="slide-fade">
              <SellingAction
                v-if="showSellingAction && buyerHasOrganisations"
                :userCredentials="userCredentials"
                :user="user"
                :userUnitsOrg="userUnits_customOrgs"
                :userIsInAnOrg="buyerHasOrganisations"
                @itemEdited="handleItemEdited"
                @closeSellingAction="handleCloseSellingAction"
                parentContext="Ecosystem"
              />
            </transition>
          </div>

          <div>
            <div class="separator"></div>
            <MainMarketplace
              v-if="productStatus === 'loaded'"
              :products="products"
              :services="services"
              :buyerOrgHasCoins="buyerOrgHasCoins"
              :buyerHasOrganisations="buyerHasOrganisations"
              :organisationUid="
                showOwnOrganisationOnly ? currentUserOrgUID : null
              "
              :title="'Recently added'"
              :showRecentlyAdded="showRecentlyAdded"
              @noCoinsInBuyerOrg="handleNoCoinsInGrandparent"
              @hasCoinsInBuyerOrg="handleHasCoinsInGrandparent"
              @userHasNoOrganisations="handleUserHasNoOrganisations"
              @userHasOrganisations="handleUserHasOrganisations"
              @itemEdited="initializeData"
            />
            <div v-else-if="productStatus === 'loading'">
              Loading products...
            </div>
            <div v-else-if="serviceStatus === 'loading'">
              Loading services...
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref, onMounted } from "vue";
import { auth, db } from "@/firebase";
import { collection, getDocs } from "firebase/firestore";

import UserActions from "@/components/DashboardComponents/EcosystemComponents/UserActions.vue";
import MainMarketplace from "@/components/DashboardComponents/EcosystemComponents/MainMarketplace.vue";
import Loading from "@/components/GenericComponents/LoadingState.vue";
import CreateOrJoin from "@/components/LandingComponents/CreateOrJoin.vue";
import ToggleSwitch from "@/components/GenericComponents/ToggleSwitch.vue";
import SellingAction from "@/components/DashboardComponents/EcosystemComponents/SellingAction.vue";

export default {
  name: "Ecosystem",
  components: {
    UserActions,
    MainMarketplace,
    Loading,
    CreateOrJoin,
    ToggleSwitch,
    SellingAction,
  },

  setup() {
    const store = useStore();
    const orgs = ref([]);
    const products = computed(() => store.getters["products"]);
    const services = computed(() => store.getters["services"]);
    const globalProducts = ref([]);
    const globalServices = ref([]);
    const hasFetchedGlobalData = ref(false);

    const productStatus = ref("loading");
    const serviceStatus = ref("loading");
    const buyerOrgHasCoins = ref(false);
    const buyerHasOrganisations = ref(false);
    const isLoading = ref(true);
    const showRecentlyAdded = ref(false);
    const showOwnOrganisationOnly = computed(
      () => store.getters["UIState/showOwnOrganisationOnly"]
    );
    const toggleShowOwnOrganisationOnly = async () => {
      store.dispatch("UIState/toggleShowOwnOrganisationOnly");

      if (!showOwnOrganisationOnly.value && !hasFetchedGlobalData.value) {
        // Fetch global products and services once
        try {
          productStatus.value = "loading"; // Start loading state

          // Fetch global products
          const productsSnapshot = await getDocs(collection(db, "products"));
          globalProducts.value = productsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          // Fetch global services
          const servicesSnapshot = await getDocs(collection(db, "services"));
          globalServices.value = servicesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          hasFetchedGlobalData.value = true; // Set the flag to indicate the data has been fetched
          productStatus.value = "loaded"; // Stop loading state
        } catch (error) {
          console.error("Failed to fetch global products and services:", error);
          productStatus.value = "error"; // Error state
        }
      }
    };

    const userActionsRef = ref(null);
    const showSellingAction = ref(false);

    const toggleSellingAction = () => {
      showSellingAction.value = !showSellingAction.value;
      console.log(
        "Ecosystem: Toggled showSellingAction to",
        showSellingAction.value
      );

      // **Manually close the UserActions menu using the ref**
      if (userActionsRef.value) {
        console.log("Ecosystem: Calling UserActions.closeMenu()");
        userActionsRef.value.closeMenu();
      }
    };
    const handleCloseSellingAction = () => {
      showSellingAction.value = false;
    };

    const handleShowSellingActionUpdate = (newValue) => {
      console.log("showSellingAction updated to:", newValue);
      showSellingAction.value = newValue;
    };

    const currentUserOrgUID = computed(() => {
      const customOrgs = store.getters.userUnits_customOrgs;
      return customOrgs ? Object.keys(customOrgs)[0] : null;
    });

    const orgHasProducts = computed(() => {
      if (!currentUserOrgUID.value) return false;
      return products.value.some(
        (product) => product.organisation === currentUserOrgUID.value
      );
    });

    const orgHasServices = computed(() => {
      if (!currentUserOrgUID.value) return false;
      return services.value.some(
        (service) => service.organisation === currentUserOrgUID.value
      );
    });

    const truncatedOrgName = computed(() => {
      const orgName =
        userUnits_customOrgs.value?.[currentUserOrgUID.value]?.name ||
        "your organisation";
      if (orgName.length > 16) {
        return `${orgName.slice(0, 16)}...${orgName.slice(-3)}`;
      }
      return orgName;
    });

    const user = computed(() => store.getters.user);
    const userCredentials = computed(() => store.getters.userCredentials);
    const userUnits_customOrgs = computed(
      () => store.getters.userUnits_customOrgs
    );

    const fetchDashboardData = async (user) => {
      await store.dispatch("fetchAndCommitUserUnits", user.uid);
      await store.dispatch("fetchAndCommitUserUnitsCustomOrgs", user.uid);
    };

    const initializeData = async () => {
      try {
        const currentUser = await new Promise((resolve, reject) => {
          const unsubscribe = auth.onAuthStateChanged((user) => {
            unsubscribe();
            if (user) {
              resolve(user);
            } else {
              reject(new Error("No authenticated user found."));
            }
          });
        });

        await store.dispatch("fetchUser", currentUser);
        await store.dispatch("fetchUserCredentials", currentUser);
        await fetchDashboardData(currentUser);

        const orgUID = store.getters.currentUserOrgUID;
        buyerHasOrganisations.value = !!orgUID;

        if (orgUID) {
          await store.dispatch("fetchAndCommitOrgTotalCoins", {
            orgUID,
            context: "buyer",
          });

          const totalOrgCoins = store.getters.getBuyerOrgTotalCoins();
          buyerOrgHasCoins.value = totalOrgCoins > 0;
        }

        await store.dispatch("fetchProducts", {
          batchSize: 10,
          orgUID: orgUID,
        });
        productStatus.value = "loaded";

        await store.dispatch("fetchServices", {
          batchSize: 10,
          orgUID: orgUID,
        });
        serviceStatus.value = "loaded";
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      const querySnapshot = await getDocs(collection(db, "custom-orgs"));
      orgs.value = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      initializeData();
    });

    const handleNoCoinsInGrandparent = () => {
      buyerOrgHasCoins.value = false;
    };

    const handleUserHasNoOrganisations = () => {
      buyerHasOrganisations.value = false;
    };

    const handleUserHasOrganisations = () => {
      buyerHasOrganisations.value = true;
    };

    const handleHasCoinsInGrandparent = () => {
      buyerOrgHasCoins.value = true;
    };

    const toggleRecentlyAdded = () => {
      showRecentlyAdded.value = !showRecentlyAdded.value;
    };

    const handleItemEdited = () => {
      initializeData();
    };

    return {
      user,
      userCredentials,
      userUnits_customOrgs,
      orgs,
      products,
      services,
      productStatus,
      serviceStatus,
      buyerOrgHasCoins,
      buyerHasOrganisations,
      showRecentlyAdded,
      handleNoCoinsInGrandparent,
      handleHasCoinsInGrandparent,
      handleUserHasNoOrganisations,
      handleUserHasOrganisations,
      handleItemEdited,
      isLoading,
      initializeData,
      toggleRecentlyAdded,
      currentUserOrgUID,
      truncatedOrgName,
      showSellingAction,
      handleShowSellingActionUpdate,
      orgHasProducts,
      orgHasServices,
      showOwnOrganisationOnly,
      toggleShowOwnOrganisationOnly,
      handleCloseSellingAction,
      toggleSellingAction,
      userActionsRef,
    };
  },
};
</script>
<style scoped>
.relativePosition {
  position: relative !important;
}

button.pressed {
  box-shadow: var(--neuMorphBoxInnerSmall);
}
</style>
