<template>
  <div class="ExchangeRateSimulator divMarginBottom">
    <section class="fullWidth100pct">
      <h1
        :class="[
          {
            shortwidth: showOnFAQ,
            alignCentre: showOnFAQ,
            nomargintopsmallmarginbottom: !showOnFAQ,
            smallmargintopbottom: showOnFAQ,
          },
        ]"
        class="purpleText bigmargintop alignLeft"
      >
        Unit's exchange rate is a universal value: Human life
      </h1>

      <div class="sticker unit_money">
        <h3 class="smallmargintopnomarginbottom emphasisNumber">
          Unit currencies
        </h3>
        <label class="eraLabel smallerText">21st Century</label>
        <PercentageSlider
          :percentage="0"
          label="Commodity focus: 0%, Social contract focus: 100%"
        />
        <p class="smallerText nomargintopbottom">
          <span v-html="'\\[\\frac{ɄPerCapita_A}{ɄPerCapita_B}\\]'"></span>
        </p>
        <p class="shortwidth nomargintop less">
          Unit's exchange rate anchors the value of money to something
          universally important—<strong class="purpleText">human life</strong>.
          It uses a smart-contract to check the
          <strong class="purpleText">coins per capita</strong> in each
          organisation, translating prices proportionally. This distinctive
          feature promotes a transparent and equitable economic framework.
        </p>
      </div>
    </section>
    <section>
      <h1 class="shortwidth alignLeft bigmargintop purpleText">
        The Smart Contract
      </h1>
      <p class="shortwidth nomargintopsmallmarginbottom less">
        The contract functions by receiving: Unique IDs for the buyer and seller
        organisations, the count of members in each organization, the price in
        the seller's currency for a given item or service, and the total
        currency in circulation within each organisation.
      </p>
      <p class="shortwidth nomargintopsmallmarginbottom less">
        It then provides: The exchange rate, reflecting the relative economic
        standings of the organisations, and the computed price in the buyer's
        currency. This method aligns monetary transactions with a
        socially-conscious perspective, integrating human elements into the
        financial equation.
      </p>
      <pre class="solidity-code"><code>
// SPDX-License-Identifier: MIT
pragma solidity ^0.8.0;

contract ExchangeRateCalculator {
    // Function to calculate the conversion rate and price in buyer coins
    function calculatePrice(
        uint256 buyerOrgUsers,
        uint256 sellerOrgUsers,
        uint256 priceInSellerCoins,
        uint256 totalBuyerCoins,
        uint256 totalSellerCoins
    ) public pure returns (uint256 conversionRate, uint256 priceInBuyerCoins) {
        require(buyerOrgUsers > 0, "Buyer organization must have at least one user.");
        require(sellerOrgUsers > 0, "Seller organization must have at least one user.");
        require(totalBuyerCoins > 0, "Buyer organization must have a non-zero coin supply.");
        require(totalSellerCoins > 0, "Seller organization must have a non-zero coin supply.");

        uint256 buyerPerCapita = totalBuyerCoins / buyerOrgUsers;
        uint256 sellerPerCapita = totalSellerCoins / sellerOrgUsers;

        conversionRate = (sellerPerCapita * 1e18) / buyerPerCapita;
        priceInBuyerCoins = (priceInSellerCoins * conversionRate) / 1e18;

        return (conversionRate, priceInBuyerCoins);
    }
}
  </code></pre>
    </section>

    <section>
      <div class="noGap">
        <p class="shortwidth smallmargintopnomarginbottom">
          Play with the example below to see how Unit's unique exchange rate
          system adjusts pricing between two different organisations based on
          per capita coin distribution.
        </p>
        <p class="shortwidth smallmargintopnomarginbottom">
          In this example, a group of friends is buying a bottle of wine from a
          member of an organisation that makes wine.
        </p>
        <!-- Empty Center column -->
        <div class="noGap">
          <div class="row alignCentreRow justifyCenter">
            <img
              class="svgBig noMultiply"
              :src="tradingSvgSrc"
              alt="People exchanging with Unit"
            />
          </div>
          <div class="row">
            <div class="singleColumn calculator">
              <h2 class="nomargintopbottom">Buyer</h2>
            </div>
            <div class="singleColumn calculator">
              <h2 class="nomargintopbottom">Seller</h2>
            </div>
          </div>
          <div class="row alignCentreRow spaceAround">
            <div class="column alignCentre noGap iconColumn">
              <div class="row alignCentreRow justifyCenter">
                <svg class="small">
                  <use href="../../assets/icons/iconset.svg#friends"></use>
                </svg>
              </div>
            </div>
            <div class="column alignCentre iconColumn">
              <div class="row alignCentreRow justifyCenter">
                <svg class="small">
                  <use href="../../assets/icons/iconset.svg#shop"></use>
                </svg>
              </div>
            </div>
          </div>

          <div class="row alignCentreRow spaceAround">
            <div class="singleColumn labelAndNumber">
              <label>𝔹 per capita</label>
              <div class="smallText">{{ orgA_unitsPerCapitaAbbreviated }}</div>
            </div>
            <div class="singleColumn labelAndNumber">
              <label>Ѕ̶ per capita</label>
              <div class="smallText">{{ orgB_unitsPerCapitaAbbreviated }}</div>
            </div>
          </div>

          <!-- PRICES -->
          <div class="">
            <div class="row alignCentreRow spaceAround relative">
              <div class="singleColumn priceTagLabel">
                <label class="fixedLabel">Price to pay in 𝔹 coins</label>
              </div>
              <div class="singleColumn priceTagLabel">
                <label class="fixedLabel">Price asked in Ѕ̶ coins</label>
              </div>
            </div>

            <div class="row alignCentreRow spaceAround relative">
              <div class="singleColumn priceTagColumn">
                <div class="price-tag ptA">
                  <span> {{ priceTagAFormatted }}</span>
                  <div class="smallCircle currencySymbol">𝔹</div>
                </div>
              </div>
              <div class="singleColumn priceTagColumn">
                <div class="price-tag ptB">
                  <input
                    type="number"
                    min="1"
                    v-model.number="orgB_winePrice"
                  />

                  <div class="smallCircle currencySymbol">Ѕ̶</div>
                </div>
              </div>
              <div class="singleColumn FXrate absolute">
                <div class="formblock">
                  <label>
                    Ex. rate <br />
                    {{ formattedExchangeRate }}</label
                  >
                  <div class=""></div>
                </div>
              </div>
            </div>
          </div>

          <!-- INTERACTIVE INPUTS: number of people, number of coins -->

          <label>Number of people in each organisation </label>
          <div class="row alignCentreRow spaceAround">
            <div class="singleColumn calculator">
              <div class="formblock">
                <StepCounter
                  :value="orgA_people"
                  @update:value="orgA_people = $event"
                />
              </div>
            </div>
            <div class="singleColumn calculator">
              <div class="formblock">
                <StepCounter
                  :value="orgB_people"
                  @update:value="orgB_people = $event"
                />
              </div>
            </div>
          </div>
          <label> Number of coins in each organisation </label>
          <div class="row alignCentreRow spaceAround">
            <!-- Form for Organisation A -->
            <div class="singleColumn calculator">
              <div class="formblock">
                <StepCounter
                  :value="orgA_units"
                  @update:value="orgA_units = $event"
                />
              </div>
            </div>
            <div class="singleColumn calculator">
              <div class="formblock">
                <StepCounter
                  :value="orgB_units"
                  @update:value="orgB_units = $event"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row alignBottomRow conclusion">
          <!-- SVG in between -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { watch, ref, computed, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import { formatNumberTo5Digits } from "@/utils/numberFormattingTo5digits";

import Tooltip from "@/components/GenericComponents/Tooltip.vue";
import PercentageSlider from "@/components/GenericComponents/PercentageSlider.vue";
import StepCounter from "@/components/GenericComponents/StepCounter.vue";

export default {
  name: "ExchangeRateSimulator",
  props: {
    showOnFAQ: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Tooltip,
    PercentageSlider,
    StepCounter,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const tooltipMessagePeople =
      "The total number of people existing in this organisation at the time of trade";
    const tooltipMessageCoins =
      "The total number of coins existing in this organisation at the time of trade";
    const orgA_people = ref(3);
    const orgA_units = ref(6000);
    const orgB_people = ref(3);
    const orgB_units = ref(12000);
    const orgB_winePrice = ref(20);
    const orgBdisplayPrice = computed(() => `${orgB_winePrice.value}Ѕ̶`);

    const isDarkMode = computed(() => store.getters["UIState/isDarkMode"]);

    const tradingSvgSrc = computed(() =>
      isDarkMode.value
        ? require("@/assets/icons/trading_css_white.svg")
        : require("@/assets/icons/trading_css.svg")
    );

    function formatNumber(num) {
      let divisor = 1;
      let suffix = "";

      if (num >= 1e24) {
        divisor = 1e24;
        suffix = "Y";
      } else if (num >= 1e21) {
        divisor = 1e21;
        suffix = "Z";
      } else if (num >= 1e18) {
        divisor = 1e18;
        suffix = "E";
      } else if (num >= 1e15) {
        divisor = 1e15;
        suffix = "P";
      } else if (num >= 1e12) {
        divisor = 1e12;
        suffix = "T";
      } else if (num >= 1e9) {
        divisor = 1e9;
        suffix = "G";
      } else if (num >= 1e6) {
        divisor = 1e6;
        suffix = "M";
      } else if (num >= 1000) {
        divisor = 1000;
        suffix = "K";
      }

      let formatted = num / divisor;
      let integerPartLength = Math.floor(formatted).toString().length;

      // Calculate how many decimal places we can have so the total length remains 5
      let decimalPlaces = 5 - integerPartLength;

      // Fix the number to the appropriate number of decimal places
      formatted = formatted.toFixed(decimalPlaces);

      // Remove trailing zeros after the decimal point
      formatted = parseFloat(formatted).toString();

      return `${formatted}${suffix}`;
    }

    const orgA_unitsPerCapita = computed(() => {
      return orgA_units.value / orgA_people.value;
    });

    const orgB_unitsPerCapita = computed(() => {
      return orgB_units.value / orgB_people.value;
    });

    const orgA_unitsPerCapitaAbbreviated = computed(() => {
      return formatNumberTo5Digits(orgA_unitsPerCapita.value);
    });

    const orgB_unitsPerCapitaAbbreviated = computed(() => {
      return formatNumberTo5Digits(orgB_unitsPerCapita.value);
    });

    const formattedExchangeRate = computed(() => {
      const rate = orgB_unitsPerCapita.value / orgA_unitsPerCapita.value;
      return formatNumberTo5Digits(rate);
    });

    onMounted(() => {
      if (window.MathJax) {
        window.MathJax.typeset();
      } else {
      }
    });

    // formatting price tag A
    const priceTagA = computed(() => {
      if (
        orgB_winePrice.value === undefined ||
        orgA_unitsPerCapita.value === undefined ||
        orgB_unitsPerCapita.value === undefined
      ) {
        return null;
      }

      const price =
        (Number(orgB_winePrice.value) * Number(orgA_unitsPerCapita.value)) /
        Number(orgB_unitsPerCapita.value);

      return price;
    });

    const priceTagAFormatted = computed(() => {
      const price =
        (orgB_winePrice.value * orgA_unitsPerCapita.value) /
        orgB_unitsPerCapita.value;
      return formatNumberTo5Digits(price);
    });

    watch(orgB_winePrice, (newVal) => {
      if (newVal === null || newVal === 0 || newVal === "") {
        orgB_winePrice.value = 0; // Handle null or 0 case
      } else {
        orgB_winePrice.value = parseFloat(newVal.toFixed(2));
      }
    });

    return {
      tooltipMessagePeople,
      tooltipMessageCoins,
      orgA_people,
      orgA_units,
      orgB_people,
      orgB_units,
      orgB_winePrice,
      orgA_unitsPerCapita,
      orgA_unitsPerCapitaAbbreviated,
      orgB_unitsPerCapita,
      orgB_unitsPerCapitaAbbreviated,
      formattedExchangeRate,
      priceTagAFormatted,
      tradingSvgSrc,
    };
  },
};
</script>

<style scoped>
.ExchangeRateSimulator {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AnimationSVG {
  mix-blend-mode: darken;
}
.noGap {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.spaceAround {
  justify-content: space-around !important;
}
.iconRow {
  align-items: end;
}

.iconColumn {
  width: 100%;
}

.singleColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.row {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
}
.calculator {
  width: 50%;
  display: flex;
  align-items: center;
  gap: var(--smallMargin);
  flex-direction: column;
  margin-bottom: var(--margin);
}

.FXrate {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: var(--smallMargin);
  flex-direction: column;
  margin-bottom: var(--margin);
}
.labelAndNumber,
.priceTagColumn {
  width: 50%;
  display: flex;
  align-items: center;
  margin-top: var(--smallMargin);
  margin-bottom: var(--margin);
}

.priceTagColumn {
  width: 50%;
  display: flex;
  align-items: center;
  margin-top: 0px;
  margin-bottom: var(--margin);
}
.priceTagColumn input {
  display: inline;
}

/* For Chrome, Safari, and Opera */
.priceTagColumn input::-webkit-outer-spin-button,
.priceTagColumn input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
.priceTagColumn input[type="number"] {
  -moz-appearance: textfield;
}

.currencySymbol {
  position: absolute;
  right: 7px;
}

.alignCentre {
  display: flex;
  flex-direction: column;
  align-items: center;
}
input {
  width: 100%;
  text-align: center;
}

.formblock {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* sticker */
.smallText {
  line-height: var(--pfontsize) !important;
}

/*Sticker Colours*/
.sticker.unit_money {
  background-color: var(--backgroundColourOrgTransp);
  border: 1px solid var(--black);
}

/* Style for era label */
.eraLabel {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: var(--microMargin);
  font-style: italic;
}

/* Extra space between header and labels */
.emphasisNumber {
  margin-bottom: var(--tinyMargin);
}

p.shortwidth.nomargintop.less {
  margin-block-end: var(--smallMargin);
  max-width: calc(65ch - calc(var(--margin) * 2));
}

/* price tag */
.price-tag,
.ptA {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 75%;
  height: 50px;
  background-color: var(--green);
  text-align: center;
  line-height: 50px;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 10% 50%);
  border-radius: var(--borderRadius);
}
.price-tag::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.ptB {
  background-color: var(--solidLighterGrey);
}

/* input field */
.price-tag input {
  position: relative;
  z-index: 2;
  border: none;
  background-color: transparent;
  text-align: center;
  outline: none;
  width: 95%; /* Adjust width as needed */
}

.solidity-code {
  background-color: #2d2d2d; /* Darker background color */
  color: #f8f8f2; /* Light text color for better readability */
  padding: 1em;
  margin: 1em 0;
  overflow-x: auto; /* Allows horizontal scrolling for longer lines */
  white-space: pre-wrap; /* Wraps text */
  word-wrap: break-word;
  font-family: "Consolas", "Monaco", "Courier New", monospace;
  font-size: 0.9em;
  line-height: 1.4;
  border-radius: 4px;
}

/* Optional: Style for code inside the block */
.solidity-code code {
  display: block; /* Makes the code element a block element */
}
</style>
