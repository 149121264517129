<template>
  <div v-if="showAnimation" class="coin-falling-animation">
    <div class="coin" v-for="n in numberOfCoins" :key="n"></div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "CoinFallingAnimation",
  props: {
    numberOfCoins: {
      type: Number,
      default: 20, // Number of coins to fall
    },
    duration: {
      type: Number,
      default: 3000, // Duration of the animation in milliseconds
    },
  },
  setup(props) {
    const showAnimation = ref(false);

    const startAnimation = () => {
      showAnimation.value = true;
      setTimeout(() => {
        showAnimation.value = false;
      }, props.duration);
    };

    return {
      showAnimation,
      startAnimation,
    };
  },
};
</script>

<style scoped>
.coin-falling-animation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9999;
}

.coin {
  position: absolute;
  top: -50px;
  width: 20px;
  height: 20px;
  background-color: gold;
  border-radius: 50%;
  animation: fall 3s linear infinite;
}

@keyframes fall {
  to {
    transform: translateY(100vh);
  }
}
</style>
