<template>
  <div class="orgMembers">
    <div class="innerSticker" :style="{ 'background-color': backgroundColor }">
      <div class="row card-header alignCentreRow">
        <div class="column">
          <h1 class="nomargintopbottom">
            Members
            <!-- <span>{{ totalMembers }}</span
            > -->
          </h1>
        </div>
        <div class="editing row" v-if="userRolePrivileges?.userRegistration">
          <InviteMembers :orgUID="orgUID" :members="members" />
        </div>
      </div>
      <div class="separator_sticker noMarginTop"></div>

      <div class="members-container">
        <div
          v-for="member in sortedMembers"
          :key="member.user_details.email"
          class="member-item"
        >
          <div class="member-info">
            <UserAvatarInOrgMembers :member="member" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

import { db } from "@/firebase";
import {
  doc,
  setDoc,
  getDoc,
  serverTimestamp,
  addDoc,
  collection,
} from "firebase/firestore"; // Add the missing import statement

import { toast } from "vue3-toastify";

import UserAvatarInOrgMembers from "@/components/UserComponents/UserAvatarInOrgMembers.vue";
import InviteMembers from "@/components/DashboardComponents/InviteMembers.vue";

export default {
  name: "OrgMembers",
  props: {
    members: {
      type: Array,
      required: true,
    },
    orgUID: {
      type: String,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: "",
    },
    userRolePrivileges: {
      type: Object,
      required: false,
    },
  },
  components: {
    UserAvatarInOrgMembers,
    InviteMembers,
  },
  emits: ["confirm", "close", "generateLink"],
  setup(props) {
    const store = useStore();

    const userCredentials = computed(() => {
      return store.getters.userCredentials;
    });

    // get total memebr in organisation
    const totalMembers = computed(() => {
      return props.members.length;
    });

    // Sorted members
    const sortedMembers = computed(() => {
      return [...props.members].sort((a, b) => {
        // Check if members have role, if so, they should come first
        if (a.roleTitle && !b.roleTitle) {
          return -1;
        }
        if (b.roleTitle && !a.roleTitle) {
          return 1;
        }
        // If both have role or none has role, check for displayName
        if (a.user_details.displayName && !b.user_details.displayName) {
          return -1;
        }
        if (b.user_details.displayName && !a.user_details.displayName) {
          return 1;
        }
        // If both have displayName or none has displayName, check for isAnonymous
        if (a.isAnonymous && !b.isAnonymous) {
          return 1;
        }
        if (b.isAnonymous && !a.isAnonymous) {
          return -1;
        }
        // If both are anonymous or both are not anonymous, they are equal
        return 0;
      });
    });

    return {
      userCredentials,
      totalMembers,
      sortedMembers,
    };
  },
};
</script>

<style scoped>
.members-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.member-item {
  margin-right: var(--smallMargin);
  margin-bottom: calc(var(--smallMargin) - 1px);
}

.member-info {
  position: relative;
  display: flex;
  align-items: center;
}
</style>
