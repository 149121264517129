const hapticDirective = {
  mounted(el, binding) {
    let pressTimeout;
    const duration = 8; // Default duration of 10ms

    const startVibration = () => {
      if ("vibrate" in navigator) {
        navigator.vibrate(duration);
      } else {
        console.warn("Vibration API is not supported in this browser.");
      }
    };

    const handleButtonPress = () => {
      pressTimeout = setTimeout(startVibration, 8); // Delay before starting vibration
    };

    const handleButtonRelease = () => {
      clearTimeout(pressTimeout);
      startVibration(); // Trigger vibration on button release
    };

    el.addEventListener("mousedown", handleButtonPress);
    el.addEventListener("mouseup", handleButtonRelease);
    el.addEventListener("touchstart", handleButtonPress, { passive: false }); // Marked as passive
    el.addEventListener("touchend", handleButtonRelease);

    if (typeof binding.value === "function") {
      el.addEventListener("click", binding.value);
    }

    // Store the event listeners on the element itself for access in beforeUnmount
    el.hapticEventListeners = {
      handleButtonPress,
      handleButtonRelease,
    };
  },
  beforeUnmount(el, binding) {
    // Ensure the event listeners object exists before trying to access it
    if (!el.hapticEventListeners) {
      console.warn("Haptic event listeners never set on element:", el);
      return; // Exit the function if hapticEventListeners is not defined
    }

    const { handleButtonPress, handleButtonRelease } = el.hapticEventListeners;

    el.removeEventListener("mousedown", handleButtonPress);
    el.removeEventListener("mouseup", handleButtonRelease);
    el.removeEventListener("touchstart", handleButtonPress, { passive: true }); // Marked as passive
    el.removeEventListener("touchend", handleButtonRelease);

    if (typeof binding.value === "function") {
      el.removeEventListener("click", binding.value);
    }
  },
};

export default hapticDirective;
