<template>
  <div class="recent-messages" @scroll="onScroll">
    <!-- Messages List -->
    <div v-if="filteredUsers.length === 0" class="no-messages">
      <small><em>No users found</em></small>
    </div>

    <div v-else class="height-transition">
      <div v-if="displayedRecentMessages.length" class="section">
        <small>Recent</small>
        <ul>
          <li
            v-for="(message, index) in displayedRecentMessages"
            :key="message.user?.uid || index"
            @click="openMessage(message.user)"
            class="messageItem"
          >
            <div class="row alignCentreRow fullWidth">
              <!-- <span v-if="!message.read" class="unread-dot">•</span> -->
              <div class="row alignCentreRow">
                <AvatarLightSmall
                  :user="message.user"
                  :emphasis="!message.read"
                />
                <div v-if="!message.read" class="unread-dot"></div>
              </div>
              <div class="message-info smallText">
                <div class="row alignCentreRow tinyGap">
                  <svg v-if="message.from !== currentUserUid" class="tinyImg">
                    <use
                      href="../../assets/icons/iconset.svg#receivedArrow_tiny"
                    ></use>
                  </svg>
                  <p :class="{ 'emphasized-class': !message.read }">
                    {{ formatMessageTime(message.messageTime) }}
                  </p>
                  <svg v-if="message.from === currentUserUid" class="tinyImg">
                    <use
                      href="../../assets/icons/iconset.svg#sentArrow_tiny"
                    ></use>
                  </svg>
                </div>
              </div>
            </div>
            <div
              v-if="index < displayedRecentMessages.length - 1"
              class="separator_sticker"
            ></div>
          </li>
        </ul>
      </div>

      <!-- No more messages message after recent messages section -->

      <div
        v-if="displayedRecentMessages.length < 10 && displayedOrgUsers.length"
        class="section"
      >
        <div class="separator noMarginTop"></div>
        <small>Users in Your Organisation</small>
        <ul>
          <li
            v-for="(user, index) in displayedOrgUsers"
            :key="user.uid"
            @click="openMessage(user)"
            class="messageItem"
          >
            <div class="row alignCentreRow fullWidth">
              <AvatarLightSmall :user="user" />
            </div>
            <div
              v-if="index < displayedOrgUsers.length - 1"
              class="separator_sticker"
            ></div>
          </li>
        </ul>
      </div>

      <div
        v-if="displayedRecentMessages.length < 10 && displayedOtherUsers.length"
        class="section"
      >
        <small>Other</small>
        <ul>
          <li
            v-for="(user, index) in displayedOtherUsers"
            :key="user.uid"
            @click="openMessage(user)"
            class="messageItem"
          >
            <div class="row alignCentreRow fullWidth">
              <AvatarLightSmall :user="user" />
            </div>
            <div
              v-if="index < displayedOtherUsers.length - 1"
              class="separator_sticker"
            ></div>
          </li>
        </ul>
      </div>

      <!-- Loading indicator -->
      <div v-if="loading" class="loading"><small>Loading...</small></div>

      <!-- No more messages message for less than 10 messages -->
      <div
        v-if="hasMoreUsers"
        class="load-more-messages"
        @click="loadMoreUsers"
      >
        <small>Load more users</small>
      </div>
      <div v-else class="no-more-messages gray">
        <small>You have no more messages</small>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { auth, db } from "@/firebase";
import {
  collection,
  query,
  getDocs,
  getDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";
import AvatarLightSmall from "@/components/UserComponents/AvatarLightSmall.vue";
import { onAuthStateChanged } from "firebase/auth";

export default {
  name: "RecentMessages",
  props: ["searchQuery"],
  components: { AvatarLightSmall },
  setup(props) {
    const store = useStore();
    const messages = ref([]);
    const users = ref([]);
    const currentUserUid = ref(null);
    const currentUserOrgUID = computed(
      () => store.getters["currentUserOrgUID"]
    ); // Get current user's org UID
    const loading = ref(false);
    const limitCount = ref(10);
    const hasMoreMessages = ref(true);
    const hasMoreUsers = computed(() => {
      return (
        displayedOrgUsers.value.length +
          displayedOtherUsers.value.length +
          displayedRecentMessages.value.length <
        users.value.length
      );
    });
    const loadMoreUsers = () => {
      limitCount.value += 10;
    };
    const fetchRecentMessages = async () => {
      if (!currentUserUid.value) {
        console.error("Current user UID is null or undefined");
        return;
      }
      loading.value = true;
      try {
        const messagesRef = collection(
          db,
          "users",
          currentUserUid.value,
          "Messages"
        );
        const q = query(messagesRef);
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const tempMessages = [];
          const userIds = new Set();

          querySnapshot.forEach((doc) => {
            const messageData = doc.data().messages;
            if (Array.isArray(messageData) && messageData.length > 0) {
              const lastMessage = messageData[messageData.length - 1];
              const fullMessage = { id: doc.id, ...lastMessage };
              tempMessages.push(fullMessage);
              const otherUserId =
                fullMessage.from === currentUserUid.value
                  ? fullMessage.to
                  : fullMessage.from;
              if (otherUserId) {
                userIds.add(otherUserId);
              }
            }
          });

          if (userIds.size === 0) return;

          const userPromises = Array.from(userIds).map((userId) =>
            getDoc(doc(db, "users", userId))
          );
          Promise.all(userPromises).then((userDocs) => {
            const userMap = userDocs.reduce((acc, userDoc) => {
              if (userDoc.exists()) {
                acc[userDoc.id] = { uid: userDoc.id, ...userDoc.data() };
              }
              return acc;
            }, {});

            messages.value = tempMessages.map((message) => {
              const otherUserId =
                message.from === currentUserUid.value
                  ? message.to
                  : message.from;
              return {
                ...message,
                user: userMap[otherUserId] || {},
              };
            });

            hasMoreMessages.value = tempMessages.length > limitCount.value;
          });
        });

        return unsubscribe;
      } catch (error) {
        console.error("Error fetching recent messages:", error);
      } finally {
        loading.value = false;
      }
    };

    const fetchUsers = async () => {
      try {
        const usersRef = collection(db, "users");
        const querySnapshot = await getDocs(usersRef);
        const tempUsers = [];
        querySnapshot.forEach((doc) => {
          const userData = doc.data();
          if (userData.uid !== currentUserUid.value) {
            tempUsers.push({ uid: doc.id, ...userData });
          }
        });
        users.value = tempUsers;
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    const recentMessages = computed(() => {
      const latestMessages = {};
      messages.value.forEach((msg) => {
        const userId = msg.user?.uid;
        if (
          userId &&
          (!latestMessages[userId] ||
            msg.messageTime > latestMessages[userId].messageTime)
        ) {
          latestMessages[userId] = msg;
        }
      });
      return Object.values(latestMessages).sort(
        (a, b) => b.messageTime - a.messageTime
      );
    });

    // Users from the current user's organization
    const orgUsers = computed(() => {
      const messageUserIds = new Set(
        recentMessages.value.map((msg) => msg.user?.uid)
      );
      return users.value.filter(
        (user) =>
          user.orgUID === currentUserOrgUID.value &&
          !messageUserIds.has(user.uid)
      );
    });

    // Users from other organizations
    const otherUsers = computed(() => {
      const messageUserIds = new Set(
        recentMessages.value.map((msg) => msg.user?.uid)
      );
      return users.value.filter(
        (user) =>
          user.orgUID !== currentUserOrgUID.value &&
          !messageUserIds.has(user.uid)
      );
    });

    const filteredRecentMessages = computed(() => {
      if (props.searchQuery.trim() === "") {
        return recentMessages.value;
      }
      return recentMessages.value.filter((message) => {
        const nameOrEmail =
          message.user?.displayName || message.user?.email || "";
        return nameOrEmail
          .toLowerCase()
          .includes(props.searchQuery.toLowerCase());
      });
    });

    // Filter and combine org users and other users
    const filteredOrgUsers = computed(() => {
      if (props.searchQuery.trim() === "") {
        return orgUsers.value;
      }
      return orgUsers.value.filter((user) => {
        const nameOrEmail = user.displayName || user.email || "";
        return nameOrEmail
          .toLowerCase()
          .includes(props.searchQuery.toLowerCase());
      });
    });

    const filteredOtherUsers = computed(() => {
      if (props.searchQuery.trim() === "") {
        return otherUsers.value;
      }
      return otherUsers.value.filter((user) => {
        const nameOrEmail = user.displayName || user.email || "";
        return nameOrEmail
          .toLowerCase()
          .includes(props.searchQuery.toLowerCase());
      });
    });

    const filteredUsers = computed(() => {
      let combinedUsers = [];

      combinedUsers = combinedUsers.concat(filteredRecentMessages.value);
      if (combinedUsers.length < limitCount.value) {
        combinedUsers = combinedUsers.concat(
          filteredOrgUsers.value.slice(
            0,
            limitCount.value - combinedUsers.length
          )
        );
      }
      if (combinedUsers.length < limitCount.value) {
        combinedUsers = combinedUsers.concat(
          filteredOtherUsers.value.slice(
            0,
            limitCount.value - combinedUsers.length
          )
        );
      }
      return combinedUsers;
    });

    const displayedRecentMessages = computed(() =>
      filteredRecentMessages.value.slice(0, limitCount.value)
    );
    const displayedOrgUsers = computed(() => {
      const remainingCount =
        limitCount.value - displayedRecentMessages.value.length;
      return filteredOrgUsers.value.slice(0, remainingCount);
    });
    const displayedOtherUsers = computed(() => {
      const remainingCount =
        limitCount.value -
        displayedRecentMessages.value.length -
        displayedOrgUsers.value.length;
      return filteredOtherUsers.value.slice(0, remainingCount);
    });

    const onSearch = () => {
      limitCount.value = 10; // Reset limit when a new search is performed
    };

    const loadMoreMessages = () => {
      if (hasMoreMessages.value) {
        limitCount.value += 10;
      }
      hasMoreMessages.value = messages.value.length > limitCount.value;
    };

    const onScroll = (event) => {
      const bottomReached =
        event.target.scrollTop + event.target.clientHeight >=
        event.target.scrollHeight - 50; // Adjusting the threshold to ensure the bottom is reached
      if (bottomReached && hasMoreMessages.value) {
        loading.value = true;
        setTimeout(() => {
          loadMoreMessages();
          loading.value = false;
        }, 500);
      }
    };

    const openMessage = (user) => {
      if (!user || !user.uid) {
        console.error("Invalid user object in openMessage", user);
        return;
      }

      try {
        store.dispatch("UIState/setOtherUserData", user);
      } catch (error) {
        console.error("Error in openMessage dispatch", error);
      }
    };

    const formatMessageTime = (timestamp) => {
      const messageDate = new Date(timestamp);
      const currentDate = new Date();
      const oneWeekAgo = new Date(
        currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
      );

      const isSameDay =
        messageDate.toDateString() === currentDate.toDateString();
      const isWithinAWeek = messageDate > oneWeekAgo;
      const isSameMonth =
        messageDate.getMonth() === currentDate.getMonth() &&
        messageDate.getFullYear() === currentDate.getFullYear();
      const isSameYear =
        messageDate.getFullYear() === currentDate.getFullYear();

      let options = { hour: "2-digit", minute: "2-digit", hour12: false };

      if (!isSameDay) {
        options = { ...options, weekday: "short", day: "2-digit" };

        if (!isWithinAWeek) {
          options = { ...options, month: "2-digit" };

          if (!isSameMonth || !isSameYear) {
            options = { ...options, year: "numeric" };
          }
        }
      }

      return new Intl.DateTimeFormat("en-GB", options).format(messageDate);
    };

    onMounted(() => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          currentUserUid.value = user.uid;
          store.dispatch("fetchCurrentUserOrgUID", user.uid);
          fetchRecentMessages();
          fetchUsers();
        } else {
          console.error("User is not authenticated");
        }
      });
    });

    watch(
      () => props.searchQuery,
      () => {
        onSearch();
      }
    );

    return {
      searchQuery: props.searchQuery,
      filteredUsers,
      filteredRecentMessages,
      filteredOrgUsers,
      filteredOtherUsers,
      displayedRecentMessages,
      displayedOrgUsers,
      displayedOtherUsers,
      loadMoreMessages,
      openMessage,
      formatMessageTime,
      loading,
      hasMoreMessages,
      onSearch,
      onScroll,
      hasMoreUsers,
      loadMoreUsers,
      currentUserUid,
    };
  },
};
</script>

<style scoped>
.recent-messages {
  overflow-y: visible; /* Ensure overflow auto for scrolling */
  height: 100%; /* Ensure the component takes the full height */
}

.unread-dot {
  min-width: 10px;
  height: 10px;
  background-color: var(--purple400);
  border-radius: 50%;
  color: var(--backgroundColourOrg);
  text-align: center;
  padding: 0 4px;
  border: 1px solid var(--backgroundColourOrg);
}

.height-transition {
  transition: height 0.3s ease, opacity 0.3s ease;
}

.search-container {
  display: flex;
  align-items: center;
  margin-bottom: var(--smallMargin);
}

search-container input {
  flex: 1;
  padding: var(--smallMargin);
  border-radius: var(--borderRadius);
  border: 1px solid var(--gray300);
}

.search-icon {
  margin-left: var(--smallMargin);
  cursor: pointer;
}

ul {
  list-style-type: none;
  padding: 0;
}

li.messageItem {
  display: flex;
  flex-direction: column;
  gap: var(--tinyMargin);
  align-items: flex-start;
  padding: var(--tinyMargin) 0;
  cursor: pointer;
}
li.messageItem > .fullWidth {
  justify-content: space-between;
}
.separator {
  margin: var(--tinyMargin) 0 0 0;
  width: 100%;
}

.separator_sticker {
  margin: var(--tinyMargin) 0 0 0;
  width: 100%;
}

.loading {
  text-align: center;
  margin-top: var(--smallMargin);
}

.no-more-messages,
.load-more-messages {
  text-align: center;
  /* margin-top: var(--smallMargin); */
  padding-bottom: var(--smallMargin);
  color: var(--gray500);
}

.load-more-messages {
  cursor: pointer;
}
.load-more-messages:hover {
  cursor: pointer;
  color: var(--gray600);
}

.section {
  margin-bottom: var(--mediumMargin);
}

.message-info {
  opacity: 0.5;
}

.emphasized-class {
  color: var(--purple400);
  font-weight: var(--fontweightheavy);
}

.unread-dot-message {
  width: 16px;
  height: 16px;
  background-color: var(--red);
  border-radius: 50%;
  color: var(--backgroundColourOrg);
  text-align: center;
  font-size: 12px;
  padding: 0 4px;
  border: 1px solid var(--backgroundColourOrg);
}
</style>
