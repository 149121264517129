import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  orderBy,
  startAfter,
  limit,
} from "firebase/firestore";
import { db } from "@/firebase";

export default {
  state: {
    products: [],
    product: null,
    loading: false,
    lastVisibleProduct: null,
    allowedOrgs: [],
  },
  getters: {
    products: (state) => state.products,
    product: (state) => state.product,
    lastVisibleProduct: (state) => state.lastVisibleProduct,
  },
  actions: {
    // In your Vuex store file, add this action to the actions object

    async fetchAllowedOrganisations({ commit, state }) {
      try {
        const orgsRef = collection(db, "custom-orgs");
        const orgsSnapshot = await getDocs(orgsRef);

        const allowedOrgs = orgsSnapshot.docs
          .filter((doc) => {
            const orgData = doc.data();
            // Check if the org is public or allows trades
            return orgData.public || orgData.allowTrades;
          })
          .map((doc) => doc.id); // Store the org UID for filtering

        commit("SET_ALLOWED_ORGANISATIONS", allowedOrgs);
      } catch (error) {
        console.error("Error fetching organizations:", error);
      }
    },

    async fetchProducts(
      { commit, state, dispatch },
      { batchSize = 10, isInitial = false, refresh = false, orgUID = null } = {}
    ) {
      commit("SET_LOADING", true);

      // Wait for allowed organizations to be fetched
      await dispatch("fetchAllowedOrganisations");

      // Reset lastVisibleProduct if refresh is requested
      if (refresh) {
        commit("SET_LAST_VISIBLE_PRODUCT", null);
      }

      try {
        const productsRef = collection(db, "products");
        let q = query(
          productsRef,
          orderBy("createdAt", "desc"),
          limit(batchSize)
        );

        if (!isInitial && state.lastVisibleProduct) {
          q = query(
            productsRef,
            orderBy("createdAt", "desc"),
            startAfter(state.lastVisibleProduct),
            limit(batchSize)
          );
        }

        const productSnapshot = await getDocs(q);
        const products = productSnapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .filter((product) => {
            // Filter by organization
            return (
              state.allowedOrgs.includes(product.organisation) ||
              product.organisation === orgUID
            );
          });

        if (productSnapshot.docs.length > 0) {
          const lastVisible =
            productSnapshot.docs[productSnapshot.docs.length - 1];
          commit("SET_LAST_VISIBLE_PRODUCT", lastVisible);
        }

        if (isInitial || refresh) {
          commit("SET_PRODUCTS", products);
        } else {
          commit("ADD_PRODUCTS", products);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        commit("SET_LOADING", false);
      }
    },

    async fetchProduct({ commit }, productId) {
      commit("SET_LOADING", true);
      try {
        const productDoc = await getDoc(doc(db, "products", productId));
        if (productDoc.exists()) {
          const product = { id: productDoc.id, ...productDoc.data() };
          commit("SET_PRODUCT", product);
        } else {
          console.error("No such product!");
        }
      } catch (error) {
        console.error("Error fetching product:", error);
      } finally {
        commit("SET_LOADING", false);
      }
    },
    clearProduct({ commit }) {
      commit("CLEAR_PRODUCT");
    },
  },
  mutations: {
    SET_ALLOWED_ORGANISATIONS(state, allowedOrgs) {
      state.allowedOrgs = allowedOrgs;
    },
    SET_PRODUCTS(state, products) {
      state.products = products;
    },
    ADD_PRODUCTS(state, products) {
      state.products = [...state.products, ...products];
    },
    SET_PRODUCT(state, product) {
      state.product = product;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_LAST_VISIBLE_PRODUCT(state, product) {
      state.lastVisibleProduct = product;
    },
    CLEAR_PRODUCT(state) {
      state.product = null;
    },
  },
};
