<template>
  <div class="step-counter">
    <div
      class="minusIcon inputIcon"
      @click="decrease"
      :disabled="min !== undefined ? value <= min : value <= 1"
      v-haptic
    >
      <svg class="compactImg">
        <use xlink:href="../../assets/icons/iconset.svg#subtract_compact"></use>
      </svg>
    </div>
    <input
      v-if="!showAbbreviated"
      type="text"
      :value="inputValue"
      @blur="handleInput"
      :required="required"
      class="custom-input noPadding"
    />
    <input
      v-else
      type="text"
      @blur="handleInput"
      :value="abbreviatedValue"
      :required="required"
      class="custom-input noPadding"
    />

    <div
      class="addIcon inputIcon"
      @click="increase"
      :disabled="max !== undefined ? value >= max : false"
      v-haptic
    >
      <svg class="compactImg">
        <use xlink:href="../../assets/icons/iconset.svg#addCross_compact"></use>
      </svg>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import { formatNumberTo5Digits } from "@/utils/numberFormattingTo5digits";

export default {
  name: "StepCounter",
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    placeholder: String,
    required: Boolean,
    min: Number,
    max: Number,
  },
  emits: ["update:value"],
  setup(props, { emit }) {
    const value = ref(parseInitialValue(props.value));
    const inputValue = ref(value.value.toString());
    const showAbbreviated = ref(false);
    const abbreviatedValue = ref("");

    function parseInitialValue(value) {
      if (value === undefined || value === null) return 0;
      if (typeof value === "number") return value;
      return parseFloat(value.replace(/,/g, "").match(/-?\d+\.?\d*/)[0]) || 0;
    }

    watchEffect(() => {
      value.value = parseInitialValue(props.value);
      inputValue.value = value.value.toString();

      if (value.value >= 1e4) {
        showAbbreviated.value = true;
        abbreviatedValue.value = formatNumberTo5Digits(value.value);
      } else {
        showAbbreviated.value = false;
      }
    });

    function stepSize(val) {
      if (val < 1) return 0.1; // Allow smaller steps for values less than 1
      const numberOfDigits = Math.floor(Math.log10(Math.abs(val))) + 1;
      if (numberOfDigits <= 1) return 1;
      const baseStep = Math.pow(10, numberOfDigits - 2);
      if (val < 2 * baseStep) {
        return baseStep;
      } else if (val < 5 * baseStep) {
        return 2.5 * baseStep;
      } else {
        return 5 * baseStep;
      }
    }

    function increase() {
      const step = stepSize(value.value);
      const remainder = value.value % step;
      if (remainder !== 0) {
        value.value += step - remainder;
      } else {
        value.value += step;
      }
      if (props.max !== undefined) {
        value.value = Math.min(props.max, value.value);
      }
      inputValue.value = value.value.toString();
      emit("update:value", value.value);
    }

    function decrease() {
      const step = stepSize(value.value);
      const remainder = value.value % step;
      if (remainder !== 0) {
        value.value -= remainder;
      } else {
        value.value -= step;
      }
      if (props.min !== undefined) {
        value.value = Math.max(props.min, value.value);
      }
      inputValue.value = value.value.toString();
      emit("update:value", value.value);
    }

    function handleInput(event) {
      let val = event.target.value.trim();

      // Filter out non-digit characters except the decimal point and shorthand notations at the end
      val = val.replace(/[^0-9.kKmMgGtTpPeEzZyY]/g, "");

      let parsedVal = 0;

      if (/Y$/.test(val)) {
        parsedVal = parseFloat(val) * 1e24;
      } else if (/Z$/.test(val)) {
        parsedVal = parseFloat(val) * 1e21;
      } else if (/E$/.test(val)) {
        parsedVal = parseFloat(val) * 1e18;
      } else if (/P$/.test(val)) {
        parsedVal = parseFloat(val) * 1e15;
      } else if (/T$/.test(val)) {
        parsedVal = parseFloat(val) * 1e12;
      } else if (/G$/.test(val)) {
        parsedVal = parseFloat(val) * 1e9;
      } else if (/M$/.test(val)) {
        parsedVal = parseFloat(val) * 1e6;
      } else if (/K$/.test(val)) {
        parsedVal = parseFloat(val) * 1e3;
      } else {
        parsedVal = parseFloat(val);
      }

      // If the input is not a valid number, default to the minimum value
      if (isNaN(parsedVal) || parsedVal < 0) {
        parsedVal = props.min !== undefined ? props.min : 0;
      }

      if (props.min !== undefined) {
        parsedVal = Math.max(props.min, parsedVal);
      }
      if (props.max !== undefined) {
        parsedVal = Math.min(props.max, parsedVal);
      }

      value.value = parsedVal;
      inputValue.value = value.value.toString();
      emit("update:value", value.value);
    }

    return {
      value,
      inputValue,
      increase,
      decrease,
      handleInput,
      showAbbreviated,
      abbreviatedValue,
    };
  },
};
</script>

<style scoped>
.custom-input {
  width: 100%;
  text-align: center;
  font-size: var(--pfontsize);
  padding: var(--smallMargin);
  border-radius: var(--borderRadiusButton);
  background-color: var(--solidLighterGrey);
  height: var(--clickableHeight);
  border: 1px solid var(--solidMidLightgrey);
  align-items: stretch;
  transition: all 0.15s;
  background-color: var(--solidLighterGrey);
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

/* For Chrome, Safari, and Opera */
.custom-input::-webkit-outer-spin-button,
.custom-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
.custom-input[type="number"] {
  -moz-appearance: textfield;
}

.step-counter {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.inputIcon {
  position: absolute;
  background: transparent;
  opacity: 0.7;
  display: flex;
  height: var(--clickableHeight);
  width: calc(var(--clickableHeight) - var(--tinyMargin));
  align-items: center;
  justify-content: center;
  transition: all 0.15s;
  background-color: var(--solidLighterGrey);
  border: 1px solid var(--solidMidLightgrey);
}

.inputIcon:hover {
  box-shadow: none;
  transform: none;
  cursor: pointer;
  transform: translateY(1px);
}
.minusIcon {
  left: 0;
  border-top-left-radius: var(--borderRadiusButton);
  border-bottom-left-radius: var(--borderRadiusButton);
  border-right: 1px solid var(--solidMidLightgrey);
}
.addIcon {
  right: 0;
  border-top-right-radius: var(--borderRadiusButton);
  border-bottom-right-radius: var(--borderRadiusButton);
  border-left: 1px solid var(--solidMidLightgrey);
}

.minusIcon svg {
  transform: translateX(2px);
}
.addIcon svg {
  transform: translateX(-2px);
}
</style>
