<template>
  <div class="overview">
    <div class="innerSticker" :style="{ 'background-color': backgroundColor }">
      <div class="row card-header alignCentreRow">
        <div class="row alignCentreRow logo-edit-row">
          <!-- Display the Logo or a Placeholder -->
          <div class="logo-display">
            <img
              v-if="newLogoUrl"
              :src="newLogoUrl"
              alt="Organization Logo"
              class="logo-image"
            />
            <span v-else>No logo available</span>
          </div>

          <!-- Edit Button -->
          <button
            v-if="userRolePrivileges.monetaryParameters && toggleSwitchState"
            @click="isLogoOverlayVisible = true"
            class="microButton purpleText"
          >
            Edit
          </button>
        </div>
        <ToggleSwitch
          v-if="userRolePrivileges.monetaryParameters"
          label="Edit"
          v-model="toggleSwitchState"
          :fullWidth="false"
          :hideStatusText="true"
        />
      </div>
      <div class="separator_sticker noMarginTop"></div>

      <TablePanel
        :rows="tableRows"
        :hideFirstRowTopBorder="true"
        :showEditButton="showEditButton"
        :showEditButtonState="toggleSwitchState"
        :showReadMoreButton="shouldShowReadMoreButton"
        @edit-row="editField($event.field)"
        @toggle-description="toggleDescription"
      />

      <!-- Overlay for Name -->
      <Overlay
        :visible="isNameOverlayVisible"
        :title="`Editing name`"
        @close="isNameOverlayVisible = false"
      >
        <div class="column alignCentre">
          <input
            class="alignCentre"
            v-model="newName"
            placeholder="Enter new name"
          />
          <button
            @click="updateName"
            class="overlay-save-btn"
            :disabled="isSaving"
          >
            {{ isSaving ? "Processing..." : "Save" }}
          </button>
        </div>
      </Overlay>

      <!-- Overlay for Description -->
      <Overlay
        :visible="isDescriptionOverlayVisible"
        :title="`Edit description`"
        @close="isDescriptionOverlayVisible = false"
      >
        <template #before-buttons>
          <div class="form-group">
            <textarea
              class="fullWidth"
              v-model="newDescription"
              placeholder="Enter new description"
            ></textarea>
            <AlertCard
              v-if="isTextTooLong"
              type="warning"
              id="char-limit-warning"
              iconId="error_warning"
              :canBeDismissed="false"
            >
              <template #text>
                Description cannot exceed 1500 characters.
                <p class="smallText nomargintopbottom">
                  Characters: {{ characterCount }} / 1500
                </p>
              </template>
            </AlertCard>
          </div>
          <br />
        </template>

        <button @click="isDescriptionOverlayVisible = false" v-haptic>
          Cancel
        </button>
        <button
          @click="updateDescription"
          :disabled="isSaving || isTextTooLong"
          v-haptic
        >
          {{ isSaving ? "Processing..." : "Save" }}
        </button>
      </Overlay>

      <!-- Overlay for Category -->
      <Overlay
        :visible="isCategoryOverlayVisible"
        @close="isCategoryOverlayVisible = false"
        title="Editing category"
      >
        <div class="column alignCentre">
          <select v-model="newCategory">
            <option disabled value="">
              {{ $t("CreateCustomOrg.selectCategory") }}
            </option>
            <option value="Public Governance">
              {{ $t("CreateCustomOrg.publicGovernance") }}
            </option>
            <option value="Business Association">
              {{ $t("CreateCustomOrg.businesAssociation") }}
            </option>
            <option value="Technology">
              {{ $t("CreateCustomOrg.technology") }}
            </option>
            <option value="Education/Research">
              {{ $t("CreateCustomOrg.educationResearch") }}
            </option>
            <option value="Healthcare/Sports">
              {{ $t("CreateCustomOrg.healthcareSports") }}
            </option>
            <option value="Retail and E-commerce">
              {{ $t("CreateCustomOrg.retailEcommerce") }}
            </option>
            <option value="Finance">
              {{ $t("CreateCustomOrg.finance") }}
            </option>
            <option value="Hospitality">
              {{ $t("CreateCustomOrg.hospitality") }}
            </option>
            <option value="Arts and Culture">
              {{ $t("CreateCustomOrg.artsAndCulture") }}
            </option>
            <option value="Environmental">
              {{ $t("CreateCustomOrg.environmental") }}
            </option>
            <option value="Industry">
              {{ $t("CreateCustomOrg.industry") }}
            </option>
            <option value="Other/Specialised">
              {{ $t("CreateCustomOrg.otherSpecialised") }}
            </option>
          </select>
          <button
            @click="updateCategory"
            class="overlay-save-btn"
            :disabled="isSaving"
          >
            {{ isSaving ? "Processing..." : "Save" }}
          </button>
        </div>
      </Overlay>
    </div>

    <!-- Overlay for Logo -->
    <Overlay
      :visible="isLogoOverlayVisible"
      title="Edit logo"
      @close="isLogoOverlayVisible = false"
    >
      <div class="column alignCentre">
        <AlertCard
          type="info"
          id="pro-tip-logo"
          iconId="info"
          :canBeDismissed="false"
        >
          <template #text>You can use transparent background</template>
        </AlertCard>
        <ImageUploadSingleImg
          @updateImages="handleLogoUpdates"
          @logoRemoved="removeLogo"
          :existingImages="existingLogoUrls"
        />
        <button @click="saveLogo" class="overlay-save-btn" :disabled="isSaving">
          {{ isLogoRemoved ? "Close" : isSaving ? "Processing..." : "Save" }}
        </button>
      </div>
    </Overlay>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { db } from "@/firebase";
import {
  doc,
  updateDoc,
  writeBatch,
  query,
  where,
  collection,
  getDocs,
  getDoc,
} from "firebase/firestore";
import { toast } from "vue3-toastify";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import TablePanel from "@/components/GenericComponents/TablePanel.vue";
import Overlay from "@/components/GenericComponents/Overlay.vue";
import ToggleSwitch from "@/components/GenericComponents/ToggleSwitch.vue";
import AlertCard from "@/components/GenericComponents/AlertCard.vue";
import ImageUploadSingleImg from "@/components/GenericComponents/ImageUploadSingleImg.vue";
import { createOverlayHandler } from "@/utils/overlayFactory.js";

export default {
  name: "OrgOverview",
  components: {
    TablePanel,
    Overlay,
    ToggleSwitch,
    AlertCard,
    ImageUploadSingleImg,
  },
  props: {
    unitData: {
      type: Object,
      required: true,
    },
    orgUID: {
      type: String,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: "",
    },
    userRolePrivileges: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    const store = useStore();
    const isEditing = ref(false);
    const editFieldKey = ref("");
    const editFieldValue = ref("");
    const toggleSwitchState = ref(false);
    const isDescriptionExpanded = ref(false);
    const userRolePrivileges = computed(() => {
      return store.getters.userRolePrivileges || {};
    });
    const isLogoOverlayVisible = ref(false);
    const isLogoRemoved = ref(false);
    const newLogo = ref(null);
    const newLogoUrl = ref("");

    const existingLogoUrls = computed(() =>
      props.unitData.logo ? [props.unitData.logo] : []
    );

    onMounted(() => {
      if (props.unitData.logo) {
        newLogoUrl.value = props.unitData.logo;
      }
    });

    const tableRows = computed(() => [
      // {
      //   col1: "Logo",
      //   col2: newLogoUrl.value
      //     ? {
      //         html: `<img src="${newLogoUrl.value}" class="logo-image"/>`,
      //       }
      //     : "No logo available",
      //   field: "logo",
      // },
      { col1: "Name", col2: props.unitData.name, field: "name" },
      {
        col1: "Description",
        col2: {
          text: descriptionDisplay.value,
          button: toggleDescriptionButton.value,
        },
        field: "description",
      },
      { col1: "Category", col2: props.unitData.category, field: "category" },
      {
        col1: "Open economy",
        col2: toggleSwitchState.value
          ? {
              component: ToggleSwitch,
              props: {
                modelValue: props.unitData.allowTrades,
                "onUpdate:modelValue": updateAllowTrades,
                label: "",
                fullWidth: false,
                class: "no-border-bottom",
              },
            }
          : props.unitData.allowTrades
          ? "Yes"
          : "No",
        field: "allowTrades",
      },
    ]);

    const handleLogoUpdates = (updatedImages) => {
      if (updatedImages.length > 0) {
        newLogo.value = updatedImages[0].file;
        newLogoUrl.value = updatedImages[0].url;
        isLogoRemoved.value = false;
      }
    };

    const removeLogo = async (logoUrl) => {
      try {
        const decodedUrl = decodeURIComponent(logoUrl);
        const filePath = decodedUrl.split("/").pop().split("?")[0];

        console.log("Decoded URL:", decodedUrl);
        console.log("File path:", filePath);

        const storage = getStorage();
        const storageReference = storageRef(storage, `org_logos/${filePath}`);

        await deleteObject(storageReference);
        console.log("Logo deleted from storage.");

        // Update Firestore document
        const orgDocRef = doc(db, "custom-orgs", props.orgUID);
        await updateDoc(orgDocRef, { logo: "" });
        console.log("Logo field updated in Firestore.");

        // Update local state
        newLogoUrl.value = "";
        store.commit("setLogoUrl", ""); // Use the Vuex mutation to update the logo URL
        console.log("Store updated with removed logo URL.");

        toast.success("Logo removed successfully!", {
          autoClose: 4000,
        });
        isLogoRemoved.value = true; // Update the state to reflect that the logo has been removed
        isLogoOverlayVisible.value = false;
      } catch (error) {
        console.error("Error removing logo:", error);
        toast.error(`Error removing logo: ${error.message}`, {
          autoClose: 5000,
        });
      }
    };

    const saveLogo = async () => {
      isSaving.value = true;
      try {
        let logoURL = newLogoUrl.value;
        if (newLogo.value) {
          logoURL = await uploadLogo(newLogo.value);
        }

        const orgDocRef = doc(db, "custom-orgs", props.orgUID);
        await updateDoc(orgDocRef, { logo: logoURL || "" });

        props.unitData.logo = logoURL;
        newLogoUrl.value = logoURL;
        store.commit("setLogoUrl", logoURL); // Update the Vuex store state for logo URL
        toast.success("Logo updated successfully!");
        isLogoOverlayVisible.value = false;
      } catch (error) {
        console.error("Failed to update logo:", error);
        toast.error("Failed to update logo.");
      } finally {
        isSaving.value = false;
      }
    };

    const uploadLogo = async (file) => {
      const storage = getStorage();
      const storageReference = storageRef(storage, `org_logos/${file.name}`);
      await uploadBytes(storageReference, file);
      const downloadURL = await getDownloadURL(storageReference);
      console.log("Uploaded logo URL:", downloadURL);
      return downloadURL;
    };

    const descriptionDisplay = computed(() => {
      if (isDescriptionExpanded.value) {
        return props.unitData.description;
      }
      if (props.unitData.description.length > 60) {
        return props.unitData.description.substring(0, 60) + "...";
      }
      return props.unitData.description;
    });

    const toggleDescriptionButton = computed(() => {
      return isDescriptionExpanded.value ? "Read less" : "Read more";
    });
    const shouldShowReadMoreButton = computed(() => {
      return props.unitData.description.length > 60;
    });

    const toggleDescription = () => {
      isDescriptionExpanded.value = !isDescriptionExpanded.value;
    };

    const showEditButton = (row) => {
      if (!toggleSwitchState.value) return false;
      if (userRolePrivileges.value.monetaryParameters) {
        if (
          ["Name", "Description", "Category", "Logo", "Allow Trades"].includes(
            row.col1
          )
        ) {
          return true;
        }
      }
      return false;
    };

    const isNameOverlayVisible = ref(false);
    const newName = ref(props.unitData.name || "");

    const isDescriptionOverlayVisible = ref(false);
    const newDescription = ref(props.unitData.description || "");

    const isCategoryOverlayVisible = ref(false);
    const newCategory = ref(props.unitData.category || "");

    const isTextTooLong = computed(() => {
      return newDescription.value.length > 1500;
    });

    const characterCount = computed(() => newDescription.value.length);

    const isSaving = ref(false);

    const editField = (field) => {
      isEditing.value = true;
      console.log("editField called with field:", field); // Log field
      if (field === "Name") {
        console.log("Setting isNameOverlayVisible to true");
        isNameOverlayVisible.value = true;
      } else if (field === "Description") {
        console.log("Setting isDescriptionOverlayVisible to true");
        isDescriptionOverlayVisible.value = true;
      } else if (field === "Category") {
        console.log("Setting isCategoryOverlayVisible to true");
        isCategoryOverlayVisible.value = true;
      } else if (field === "Logo") {
        console.log("Setting isLogoOverlayVisible to true");
        isLogoOverlayVisible.value = true;
      }
    };

    const closeEdit = () => {
      isEditing.value = false;
    };

    const saveEdit = async () => {
      console.log(
        "saveEdit called with editFieldKey:",
        editFieldKey.value,
        "editFieldValue:",
        editFieldValue.value
      );
      isSaving.value = true;
      try {
        const unitDocRef = doc(db, "custom-orgs", props.orgUID);
        await updateDoc(unitDocRef, {
          [editFieldKey.value]: editFieldValue.value,
        });

        toast.success("Changes saved successfully!");
        props.unitData[editFieldKey.value] = editFieldValue.value;
        closeEdit();
        isEditing.value = false; // Close the overlay
        console.log("Toggling navbar visibility back on");
        store.dispatch("toggleNavbarVisibility", true); // Toggle navbar back on
      } catch (error) {
        console.error("Error saving changes:", error);
        toast.error("Failed to save changes.");
      } finally {
        isSaving.value = false;
      }
    };

    onMounted(async () => {
      console.log("onMounted called, dispatching getUserRolePrivileges");
      await store.dispatch("getUserRolePrivileges", { orgUID: props.orgUID });
    });

    const updateName = async () => {
      isSaving.value = true;
      const batch = writeBatch(db);

      try {
        // Update the name in the custom-orgs collection
        const orgDocRef = doc(db, `custom-orgs/${props.orgUID}`);
        const updateData = { name: newName.value };
        console.log(`Updating Firestore: ${orgDocRef.path} with`, updateData);
        batch.update(orgDocRef, updateData);
        console.log(
          `Successfully added update for organisation name to "${newName.value}" in custom-orgs collection.`
        );

        // Fetch all users to loop through and update sub-collections
        const usersSnapshot = await getDocs(collection(db, "users"));
        console.log(`Found ${usersSnapshot.size} users for update.`);

        for (const userDoc of usersSnapshot.docs) {
          const userRef = doc(db, "users", userDoc.id);
          console.log(
            `Processing user ID: ${userDoc.id}, Data:`,
            userDoc.data()
          );

          // Updating Units sub-collection
          const unitsDocRef = doc(
            db,
            `users/${userDoc.id}/Units/${props.orgUID}`
          );
          const unitsDocSnap = await getDoc(unitsDocRef);
          if (unitsDocSnap.exists()) {
            console.log(`Adding Units update to batch: ${unitsDocRef.path}`);
            batch.update(unitsDocRef, { organisationName: newName.value });
          } else {
            console.log(`No Units entry found at path: ${unitsDocRef.path}`);
          }

          // Updating RoleIDs sub-collection
          const roleIDsCollectionRef = collection(
            db,
            `users/${userDoc.id}/RoleIDs`
          );
          const roleIDsSnapshot = await getDocs(roleIDsCollectionRef);
          let foundRole = false;
          roleIDsSnapshot.forEach((roleDoc) => {
            const roleData = roleDoc.data();
            if (roleData.organisationUID === props.orgUID) {
              foundRole = true;
              console.log(
                `Adding RoleIDs update to batch: ${roleDoc.ref.path}`
              );
              batch.update(roleDoc.ref, { organisationName: newName.value });
            }
          });
          if (!foundRole) {
            console.log(
              `No matching RoleIDs entry found for user ID: ${userDoc.id} and orgUID: ${props.orgUID}.`
            );
          }

          // Updating PastUnits sub-collection
          const pastUnitsDocRef = doc(
            db,
            `users/${userDoc.id}/PastUnits/${props.orgUID}`
          );
          const pastUnitsDocSnap = await getDoc(pastUnitsDocRef);
          if (pastUnitsDocSnap.exists()) {
            console.log(
              `Adding PastUnits update to batch: ${pastUnitsDocRef.path}`
            );
            batch.update(pastUnitsDocRef, { organisationName: newName.value });
          } else {
            console.log(
              `No PastUnits entry found at path: ${pastUnitsDocRef.path}`
            );
          }

          // Updating Wallets sub-collection
          const walletsDocRef = doc(
            db,
            `users/${userDoc.id}/Wallets/${props.orgUID}`
          );
          const walletsDocSnap = await getDoc(walletsDocRef);
          if (walletsDocSnap.exists()) {
            console.log(
              `Adding Wallets update to batch: ${walletsDocRef.path}`
            );
            batch.update(walletsDocRef, { orgName: newName.value });
          } else {
            console.log(
              `No Wallets entry found at path: ${walletsDocRef.path}`
            );
          }
        }

        // Commit the batch update
        console.log("Committing batch update...");
        await batch.commit();
        console.log("Batch commit successful.");

        // Update the local state
        props.unitData.name = newName.value;
        console.log(`Local state updated with new name: "${newName.value}".`);
        toast.success("Name updated successfully!");
        isNameOverlayVisible.value = false;
      } catch (error) {
        console.error("Failed to update name:", error);
        toast.error("Failed to update name.");
      } finally {
        isSaving.value = false;
      }
    };

    const updateDescription = async () => {
      console.log("updateDescription called");
      if (isTextTooLong.value) {
        toast.warn("Description cannot exceed 1500 characters", {
          autoClose: 3000,
        });
        return;
      }
      isSaving.value = true;

      const fullPath = `custom-orgs/${props.orgUID}`;
      const propPath = "description";
      const overlayRef = isDescriptionOverlayVisible;
      const valueRef = newDescription;

      const handleOverlayUpdate = createOverlayHandler(
        fullPath,
        propPath,
        overlayRef,
        valueRef
      );

      try {
        await handleOverlayUpdate();
        props.unitData.description = newDescription.value;
        toast.success("Description updated successfully.");
        isDescriptionOverlayVisible.value = false;
      } catch (error) {
        console.error("Failed to update description:", error);
        toast.error("Failed to update description.");
      } finally {
        isSaving.value = false;
      }
    };

    const updateCategory = async () => {
      console.log("updateCategory called");
      isSaving.value = true;
      const orgDocRef = doc(db, "custom-orgs", props.orgUID);
      const newCategoryValue = newCategory.value;

      try {
        await updateDoc(orgDocRef, { category: newCategoryValue });
        props.unitData.category = newCategoryValue;
        isCategoryOverlayVisible.value = false;

        toast.success("Category updated successfully!");
      } catch (error) {
        console.error("Failed to update category:", error);
        toast.error("Failed to update category.");
      } finally {
        isSaving.value = false;
      }
    };

    const updateAllowTrades = async (newValue) => {
      console.log("updateAllowTrades called with newValue:", newValue);
      isSaving.value = true;
      try {
        const unitDocRef = doc(db, "custom-orgs", props.orgUID);
        await updateDoc(unitDocRef, { allowTrades: newValue });

        toast.success("Economy mode updated successfully!");
        props.unitData.allowTrades = newValue; // Update the local state
      } catch (error) {
        console.error("Error updating Allow Trades:", error);
        toast.error("Failed to update Economy mode.");
      } finally {
        isSaving.value = false;
      }
    };

    return {
      tableRows,
      isEditing,
      editFieldKey,
      editFieldValue,
      closeEdit,
      saveEdit,
      showEditButton,

      toggleSwitchState,
      userRolePrivileges,

      isNameOverlayVisible,
      newName,
      isDescriptionOverlayVisible,
      newDescription,
      isCategoryOverlayVisible,
      newCategory,
      isTextTooLong,
      characterCount,
      editField,
      updateName,
      updateDescription,
      updateCategory,
      updateAllowTrades,
      isDescriptionExpanded,
      shouldShowReadMoreButton,
      toggleDescription,

      existingLogoUrls,
      isLogoOverlayVisible,
      isLogoRemoved,
      newLogoUrl,
      isSaving,

      handleLogoUpdates,
      removeLogo,
      saveLogo,
    };
  },
};
</script>

<style scoped>
.strikethrough {
  margin-left: 5px;
  text-decoration: line-through;
}
.month-abbreviation {
  margin-left: 5px;
}

textarea {
  min-height: calc(var(--clickableHeight) * 4);
  height: calc(var(--clickableHeight) * 4);
  resize: none;
}

.logo-img {
  height: var(--clickableHeight);
}

.no-border-bottom {
  border-bottom: none !important;
}
</style>
