<template>
  <div
    class="modal"
    :class="{ 'slide-in': visible, 'slide-out': !visible }"
    @click.self="$emit('close')"
  >
    <div class="modal-content" @click.stop>
      <div class="modalHeader">
        <svg class="close verySmall" @click="$emit('close')" v-haptic>
          <use href="../../assets/icons/iconset.svg#cross_smaller"></use>
        </svg>
      </div>
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import { watch, ref, computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "Modal",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();

    const toggleLoginModal = () => {
      store.commit("SET_MENU_OPEN", false); // Set the menuOpen state to false
      props.visible = !props.visible;
      store.dispatch("toggleLoginModal", props.visible);
    };

    watch(
      () => props.visible,
      (newVal) => {
        if (newVal) {
          document.body.classList.add("no-scroll");
        } else {
          document.body.classList.remove("no-scroll");
        }
      }
    );

    return {};
  },
};
</script>

<style scoped>
.slide-in {
  opacity: 1;
  pointer-events: auto;
}

.slide-out {
  opacity: 0;
  pointer-events: none;
}

.modal-content {
  z-index: 5;
  background-color: var(--backgroundColourOrg);
  padding: var(--smallMargin);
  max-width: 100%;
  max-height: 90%;
  overflow-y: auto;
  border-radius: var(--borderRadius);
  position: relative;
  transform: translateY(100%);
  transition: all 0var (--slideInSpeed);
  animation: slide-in var(--slideInSpeed) forwards;
  box-shadow: var(--dropShadowRegular);
}

.modal-content .modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: var(--tinyMargin);
  justify-content: flex-end;
}

.slide-out .modal-content {
  animation: slide-out var(--slideInSpeed) forwards;
}

/* @keyframes slide-in {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
} */

@media (max-width: 590px) {
  .modal-content {
    width: 100%;
  }
}
</style>
