<template>
  <div class="EditCoinName fullWidth">
    <form @submit.prevent="save" id="customEditCoinName">
      <div class="row alignCentreRow fullWidth">
        <div class="currencySymbol">
          <CoinSymbol class="coin-symbol" size="big" />
          <!-- 
          <transition name="coin-flip" mode="out-in">
            <div :class="['circle', 'coinSymbol', rotateClass]" :key="coinKey">
              {{ coinSymbol }}
            </div>
          </transition> -->
        </div>
        <div class="form-group">
          <!--          <label>{{ coinName ? coinName : "Unit" }}</label> -->
          <input
            type="text"
            v-model="coinName"
            :placeholder="'Type your coin name: i.e. Unit'"
            required
            class="alignCentre inputInSticker"
          />
        </div>
      </div>
    </form>
    <div class="separator_sticker"></div>

    <CreateOrgCustom
      :coinName="updatedValues.coinName"
      :coinSymbol="updatedValues.coinSymbol"
      @update:coin-details="handleCoinDetailsUpdate"
    />
    <!--     <div class="row justifyToCentre divSmallMarginBottom">
      <button type="submit" @click="toggleRegisterModal" v-haptic>
        Register your coin
      </button>
    </div> -->
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  watch,
  defineProps,
  defineEmits,
  computed,
  reactive,
} from "vue";
import { useStore } from "vuex";
import { db } from "@/firebase";
import { collection, getDocs, query, limit, doc } from "firebase/firestore";
import Tooltip from "@/components/GenericComponents/Tooltip.vue";
import CreateOrgCustom from "@/components/OrgComponents/OrgCustom/CreateOrgCustom.vue";
import CoinSymbol from "@/components/GenericComponents/CoinSymbol.vue";

const store = useStore();
const emit = defineEmits();

const toggleRegisterModal = () => store.dispatch("toggleRegisterModal");

// New action to set Monetary rules
const setMonetaryParameters = () => {
  const payload = {
    coinName: updatedValues.coinName || "Unit",
    coinSymbol: updatedValues.coinSymbol || "Ʉ",
  };
  //  for coin Symbol compoent
  store.dispatch("updateCoinSymbol", {
    symbol: updatedValues.coinSymbol || "Ʉ",
    name: updatedValues.coinName || "Unit",
  });
  // for Monetary rules
  store.commit("SET_MONETARY_PARAMS", payload);
};

let hasFetched = ref(false);

const updatedValues = reactive({
  coinName: "Unit",
  coinSymbol: "Ʉ",
});
const handleCoinDetailsUpdate = (updatedDetails) => {
  updatedValues.coinName = updatedDetails.coinName;
  updatedValues.coinSymbol = updatedDetails.coinSymbol;
};

watch(updatedValues, (newVal) => {
  emit("update:allValues", newVal);
});

const coinName = ref("Unit");

const coinSymbolMap = {
  A: "₳",
  B: "฿",
  C: "Ċ",
  D: "Đ",
  E: "Ē",
  F: "₣",
  G: "₲",
  H: "Ħ",
  I: "Ī",
  J: "Ĵ",
  K: "₭",
  L: "Ł",
  M: "₼",
  N: "₦",
  O: "Ɵ",
  P: "₱",
  Q: "Ƣ",
  R: "Ɍ",
  S: "Ŝ",
  T: "₸",
  U: "Ʉ",
  V: "∀",
  W: "₩",
  X: "Ẋ",
  Y: "¥",
  Z: "Ƶ",
};

const coinSymbol = ref("Ʉ"); // default value

watch(coinName, (newVal) => {
  const effectiveCoinName = newVal || "Unit";
  updatedValues.coinName = effectiveCoinName;

  const firstLetter = effectiveCoinName[0].toUpperCase();
  coinSymbol.value = coinSymbolMap[firstLetter] || "Ʉ"; // default to 'Ʉ' if no match

  updatedValues.coinSymbol = coinSymbol.value;
  emit("update:coin-name", {
    coinName: effectiveCoinName,
    coinSymbol: coinSymbol.value,
  });
  setMonetaryParameters();
});

// Coin flip transition logic
const coinKey = ref(0); // to force re-rendering
const isRotating = ref(false);
const rotateClass = ref("");
watch(coinSymbol, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    isRotating.value = true;

    setTimeout(() => {
      rotateClass.value = "rotate-to-90";
    }, 0);

    setTimeout(() => {
      rotateClass.value = "rotate-to-0";
      coinSymbol.value = newVal;
    }, 150);

    setTimeout(() => {
      isRotating.value = false;
      rotateClass.value = ""; // reset to original state
    }, 300); // end of your animation duration
  }
});

onMounted(async () => {
  // Check if averages are fetched for the first time
  hasFetched.value = false;

  if (!hasFetched.value) {
    hasFetched.value = true;
  }
});
</script>

<style scoped>
.coinDetails {
  background-color: var(--backgroundColourOrgTransp);
  border: 1.5px solid var(--solidLighterGrey);
  align-items: initial;
}
.form-group {
  width: 100%;
}
.form-groupHalf {
  min-width: auto;
  width: 100%;
}

.formblock {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.avg.margin4px {
  margin-bottom: 4px;
}

/* flipping the coin: same styles as in your Navbar component */
.circle {
  transition: transform 0.15s ease-in-out;
}

.coin-flip-enter-active,
.coin-flip-leave-active {
  animation: spin 0.15s linear forwards;
}

.rotate-to-90 {
  animation: rotateTo90 0.15s linear forwards;
}

.rotate-to-0 {
  animation: rotateTo0 0.15s linear forwards;
}

.currencySymbol {
  position: absolute;
  transform: translateX(6px);
}

@keyframes rotateTo90 {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(90deg);
  }
}

@keyframes rotateTo0 {
  0% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

@media only screen and (max-width: 450px) {
  .form-group {
    min-width: auto;
  }
  .input {
    width: 100%;
  }
}
</style>
