<template>
  <teleport to="body">
    <div
      class="overlay"
      :class="{ 'slide-in': visible, 'slide-out': !visible }"
      @click.self="$emit('close')"
    >
      <div
        class="overlay-content"
        :class="{ red: currentStep === 'delete' }"
        @click.stop
      >
        <div class="overlayHeader">
          <svg
            v-if="showBackArrow"
            class="backArrow verySmall"
            @click="previousStep"
            v-haptic
          >
            <use href="../../assets/icons/iconset.svg#arrowleft"></use>
          </svg>
          <svg v-else class="backArrow verySmall">
            <use href="../../assets/icons/iconset.svg#arrowight"></use>
          </svg>
          <h2
            class="alignCentre"
            :class="smallTextAfterTitle ? 'nomargintopbottom' : ''"
          >
            {{ title }}
          </h2>
          <svg class="close verySmall" @click="$emit('close')" v-haptic>
            <use href="../../assets/icons/iconset.svg#cross_smaller"></use>
          </svg>
        </div>
        <p class="shortwidth nomargintopsmallmarginbottom alignCentre">
          <small v-if="smallTextAfterTitle"
            ><em>{{ smallTextAfterTitle }}</em></small
          >
        </p>
        <p v-if="message" class="alignCentre wrap">{{ message }}</p>
        <slot name="before-buttons" :product="product"></slot>

        <div class="overlay-buttons row">
          <slot @close-overlay="close"></slot>
        </div>
        <slot name="after-buttons"></slot>
      </div>
    </div>
  </teleport>
</template>

<script>
import { ref, computed, onMounted, watchEffect, watch, nextTick } from "vue";

import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { toast } from "vue3-toastify";
import ToggleSwitch from "@/components/GenericComponents/ToggleSwitch.vue";

export default {
  name: "Overlay",
  components: {
    ToggleSwitch,
  },
  props: {
    product: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    currentProduct: {
      type: Object,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    smallTextAfterTitle: {
      type: String,
    },
    message: {
      type: String,
    },
    showBackArrow: {
      type: Boolean,
      default: false,
    },
    currentStep: {
      type: String,
      required: false,
    },
  },
  emits: ["close", "previousStep"],
  setup(props, { emit }) {
    const router = useRouter();

    const close = () => {
      emit("close");
    };
    const previousStep = () => {
      console.log("Emitting previousStep from Overlay");
      emit("previousStep");
    };

    watchEffect(() => {
      // console.log("Product changed on Overlay!");
      // console.log(props.product); // Log it to see changes
    });

    const overlayContentRef = ref(null);

    watch(
      () => props.visible,
      (newVal) => {
        if (newVal) {
          nextTick(() => {
            if (overlayContentRef.value) {
              const { width, height } =
                overlayContentRef.value.getBoundingClientRect();
              overlayContentRef.value.style.width = width + "px";
              overlayContentRef.value.style.height = height + "px";
            }
          });
        }
      },
      { immediate: true }
    );
    onMounted(() => {
      // console.log("Product in overlay");
      // console.log(props.product);
    });

    return {
      close,
      previousStep,
      overlayContentRef,
    };
  },
};
</script>

<style scoped>
.overlay {
  backdrop-filter: blur(10px);
  position: fixed;
  z-index: 3;
  left: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  background-color: transparent;
  color: var(--black);
  background-color: var(--backgroundColourOrgTransp);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: var(--margin);
  opacity: 0;
  pointer-events: none;
  transition: all var(--slideInSpeed);
  padding-bottom: 0;
  margin-bottom: 0;
}

.slide-in {
  opacity: 1;
  pointer-events: auto;
}

.slide-out {
  opacity: 0;
  pointer-events: none;
}

.overlay-content {
  max-width: 100%;
  min-width: 340px;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 5;
  background-color: var(--backgroundColourOrg);
  padding: var(--smallMargin);
  padding-bottom: var(--biggerMargin);

  max-height: 100%;
  overflow-y: auto;
  border-top-left-radius: var(--borderRadius);
  border-top-right-radius: var(--borderRadius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;
  transform: translateY(100%);
  transition: all 0.15s;
  animation: slide-in var(--slideInSpeed) forwards;
  /* box-shadow: var(--neuMorphBoxOuter); */
  box-shadow: 0 2px 6px var(--darkbluetransplight);

  overflow-x: hidden;
  transition: all var(--slideInSpeed);
}

.overlay-content.red {
  background-color: var(
    --lightestred
  ) !important; /* Adjust this to your desired red style */
}

.overlay-content .overlayHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: var(--tinyMargin);
}

.overlay-content h2 {
  font-size: var(--smallFontsize);
  font-weight: var(--fontweightlightH2);
}

.overlay-buttons {
  display: flex;
  justify-content: center;
  width: 100%;
}

.slide-out .overlay-content {
  animation: slide-out var(--slideInSpeed) forwards;
}

@keyframes slide-in {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}
/* SMALL SCREENS */
@media only screen and (max-width: 450px) {
  .overlay-content {
    width: 100%;
  }
}
</style>
