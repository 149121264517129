<template>
  <div class="user-profile-image-container" v-if="profileImageUrl">
    <img :src="profileImageUrl" alt="User Profile" class="user-profile-image" />
    <p class="positionCentreAbsolute">No image available</p>
  </div>
  <div v-else class="placeholderImage">
    <p>No image available</p>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { auth } from "@/firebase";

export default {
  name: "UserProfileImage",
  setup() {
    const profileImageUrl = ref("");

    onMounted(() => {
      const user = auth.currentUser;
      if (user && user.photoURL) {
        profileImageUrl.value = user.photoURL;
      }
    });

    return {
      profileImageUrl,
    };
  },
};
</script>

<style scoped>
.user-profile-image-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio to match the carousel */
  overflow: hidden;
  position: relative;
  border: 1px solid var(--solidLighterGrey);

  border-radius: var(--borderRadius);
}

.user-profile-image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  filter: blur(107px);
}

.placeholderImage {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--backgroundLighterGrayTransp);
  border: 1px solid var(--solidLighterGrey);
  border-radius: var(--borderRadius);
}

.positionCentreAbsolute {
  position: absolute;
  top: 50%; /* Position halfway down the container */
  left: 50%; /* Position halfway across the container */
  transform: translate(
    -50%,
    -50%
  ); /* Offset the element to center it precisely */
  text-align: center; /* Ensure the text within is centered if it wraps */
  width: 100%; /* Make it as wide as the container */
  color: white; /* Adjust color to make text visible against possible image backgrounds */
  background: rgba(
    0,
    0,
    0,
    0.5
  ); /* Optional: background to enhance text readability */
  padding: 10px 0; /* Optional: padding to increase the clickable area */
  box-sizing: border-box; /* Ensure padding doesn't affect the final dimensions */
}
</style>
