<template>
  <div class="marketplace main_container">
    <div v-if="user.loggedIn" class="userLoggedIn">
      <Ecosystem v-show="activeTab == 'ecosystem'" />
    </div>
    <div v-else class="userNotLoggedIn">
      <Landing />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed, ref, watch } from "vue";
import { auth } from "@/firebase";
import Ecosystem from "@/components/DashboardComponents/Ecosystem.vue";
import YourUnitverse from "@/components/DashboardComponents/YourUnitverse.vue";
import PreEngagement from "@/components/GenericComponents/PreEngagement.vue";
import Landing from "@/components/LandingComponents/Landing.vue";

export default {
  name: "Dashboard",
  components: {
    Ecosystem,
    Landing,
  },

  setup() {
    const store = useStore();
    const activeTab = computed(() => store.getters.activeTab);
    // watch(activeTab, (newValue) => {
    //   console.log("activeTab:", newValue);
    //   console.log("Show Ecosystem:", newValue === "ecosystem");
    // });

    auth.onAuthStateChanged((user) => {
      store.dispatch("fetchUser", user);
    });
    auth.onAuthStateChanged((userCredentials) => {
      store.dispatch("fetchUserCredentials", userCredentials);
    });

    const user = computed(() => {
      return store.getters.user;
    });

    const tabs = ref([{ name: "ecosystem", component: Ecosystem }]);

    const changeTab = (tabName) => {
      store.dispatch("changeActiveTab", tabName);
    };
    watch(
      () => store.getters.userUnits_customOrgs,
      (newVal, oldVal) => {
        if (newVal) {
          const orgUID = Object.keys(newVal)[0]; // Get the first key which is dynamic
          // console.log("ORGUID");
          // console.log(newVal[orgUID]);
          if (orgUID) {
            const monetaryParams = newVal[orgUID].monetary_parameters;

            if (monetaryParams) {
              const coinName = monetaryParams.coin_name || "Unit";
              const coinSymbol = monetaryParams.coin_symbol || "Ʉ";

              const payload = {
                coinName,
                coinSymbol,
              };
              // console.log("Landing: Setting monetary params:", payload);

              // for coin symbol component
              store.dispatch("updateCoinSymbol", {
                symbol: monetaryParams.coin_symbol || "Ʉ",
                name: monetaryParams.coin_name || "Unit",
              });
              // for Monetary rules
              store.commit("SET_MONETARY_PARAMS", payload);
            }
          }
        }
      },
      { immediate: true }
    );

    return { user, tabs, changeTab, activeTab };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.content-container {
  padding-top: 40px; /* Adjust this value to provide space below the fixed tab bar */
}

.content {
  position: absolute;
  width: calc(100% - var(--margin));
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: all var(--slideInSpeed) ease;
}

.slide-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.slide-left-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}

.slide-left-enter-to,
.slide-left-leave {
  opacity: 1;
  transform: translateX(0);
}

.slide-right-enter-active,
.slide-right-leave-active {
  transition: all var(--slideInSpeed) ease;
}

.slide-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.slide-right-leave-to {
  opacity: 0;
  transform: translateX(100%);
}

.slide-right-enter-to,
.slide-right-leave {
  opacity: 1;
  transform: translateX(0);
}
</style>
