<template>
  <div :class="['tooltip-wrapper', sizeClass, { 'flexible-width': icon }]">
    <div class="icon" :title="message" @click="showToast" v-haptic>
      {{ icon || "i" }}
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { toast } from "vue3-toastify";

export default {
  name: "Tooltip",
  props: {
    message: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: "i",
    },
    size: {
      type: String,
      required: false,
      default: "normal",
    },
  },
  setup(props) {
    const timeToRead = (props.message.split(" ").length / 4) * 1400;

    const showToast = () => {
      toast.success(props.message, { autoClose: timeToRead });
    };

    const sizeClass = computed(() => {
      return `tooltip-wrapper--${props.size}`;
    });

    return {
      showToast,
      sizeClass,
    };
  },
};
</script>

<style scoped>
.tooltip-wrapper {
  display: inline-block;
  position: relative;
  border-radius: var(--borderRadiusButton);
  width: var(--borderRadiusButton);
  height: var(--microClickableHeight);
  margin-left: var(--tinyMargin);
  box-shadow: var(--neuMorphBoxOuterSmall);
  transition: all 0.15s;
}

.tooltip-wrapper--small {
}

.tooltip-wrapper--normal {
}

.tooltip-wrapper--large {
  height: calc(var(--clickableHeight));
  border-radius: var(--borderRadiusButton) !important;
  padding: 0 var(--margin) !important;
}

.tooltip-wrapper:hover {
  transform: translateY(var(--transY));
  cursor: help;
}

.tooltip-wrapper--large * {
  font-size: var(--pfontsize) !important;
}

.icon {
  user-select: none;
  font-size: var(--smallFontsize);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  cursor: help;
}

.flexible-width {
  width: fit-content;
  padding: 5px;
  border-radius: var(--borderRadiusBigger);
}
</style>
