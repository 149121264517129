<template>
  <div class="user-avatar">
    <div v-if="member.user_details.photoURL" class="avatarCircle">
      <img :src="member.user_details.photoURL" :alt="displayName" />
    </div>
    <div v-else :class="{ avatarCircle: true }">
      <h1
        class="nomargintopbottom"
        v-if="!member.isAnonymous && member.user_details.email"
      >
        {{ member.user_details.email.charAt(0).toUpperCase() }}
      </h1>

      <h1 v-else>A</h1>
    </div>
    <div class="row alignCentreRow">
      <span class="display-name"
        ><h4 class="nomargintopbottom">{{ truncatedDisplayName }}</h4></span
      >
      <Badge v-if="member.roleTitle" :message="member.roleTitle"></Badge>
    </div>
  </div>
</template>

<script>
import Badge from "@/components/GenericComponents/Badge.vue";

export default {
  props: {
    member: Object,
  },
  components: {
    Badge,
  },
  created() {
    // console.log(this.member.roleTitle); // Add this
  },
  computed: {
    displayName() {
      if (this.member.isAnonymous) {
        return "Anonymous user";
      } else if (
        this.member.user_details &&
        this.member.user_details.displayName
      ) {
        return this.member.user_details.displayName;
      } else {
        return this.member && this.member.userUID
          ? `${this.member.userUID.slice(0, 4)}...${this.member.userUID.slice(
              -4
            )}`
          : "Anonymous user";
      }
    },
    truncatedDisplayName() {
      if (this.displayName) {
        const names = this.displayName.split(" ");
        if (names.length > 1) {
          return `${names[0]} ${names[names.length - 1]}`;
        }
      }
      return this.displayName;
    },
  },
};
</script>

<style scoped>
.avatarCircle:hover {
  box-shadow: var(--neuMorphBoxOuterSmall);
}
</style>
