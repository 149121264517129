<template>
  <div class="user-avatar">
    <div v-if="!isAnonymous && photoURL" class="avatarCircle">
      <img :src="photoURL" :alt="displayName" />
    </div>
    <div v-else :class="{ avatarCircle: true, anonymous: isAnonymous }">
      <h1 class="nomargintopbottom" v-if="!isAnonymous && email">
        {{ email.charAt(0).toUpperCase() }}
      </h1>
      <h1 v-else>A</h1>
    </div>
    <div v-if="!hideName" class="row alignCentreRow">
      <div class="column tinyGap">
        <span class="display-name">
          <h4 class="nomargintopbottom">{{ truncatedDisplayName }}</h4>
        </span>
        <small class="nomargintopbottom sellerInfo"><slot></slot></small>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  props: {
    user: Object,
    hideName: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isAnonymous = computed(() => props.user?.isAnonymous ?? true);
    const photoURL = computed(() => {
      return props.user?.photoURL || props.user?.profile_picture || null;
    });
    const email = computed(() => props.user?.email ?? "");
    const displayName = computed(() => {
      if (props.user?.isAnonymous) {
        return "Anonymous user";
      } else if (props.user?.displayName) {
        return props.user.displayName;
      } else {
        return props.user?.uid
          ? `${props.user.uid.slice(0, 4)}...${props.user.uid.slice(-4)}`
          : "Anonymous user";
      }
    });

    const truncatedDisplayName = computed(() => {
      if (displayName.value) {
        const names = displayName.value.split(" ");
        if (names.length > 1) {
          return `${names[0]} ${names[names.length - 1]}`;
        }
      }
      return displayName.value;
    });

    return {
      isAnonymous,
      photoURL,
      email,
      displayName,
      truncatedDisplayName,
    };
  },
};
</script>
