<template>
  <div class="divSmallMarginBottom">
    <!-- Search Row with Filter Icon at the End -->
    <div class="row search-container">
      <input v-model="localSearchQuery" placeholder="Search..." />
      <svg class="search-icon compactImg">
        <use href="@/assets/icons/iconset.svg#lens-compact"></use>
      </svg>
      <!-- New Filter Icon -->
      <svg class="filter-icon compactImg" @click="toggleFilters">
        <use href="@/assets/icons/iconset.svg#filter"></use>
      </svg>
    </div>

    <!-- Filters Row - Initially Hidden -->
    <transition name="slide-fade">
      <div v-if="filtersVisible" class="filters columnOnMobile">
        <div class="row smallerGap">
          <select v-model="localFilterType">
            <option value="">All</option>
            <option value="product">Product</option>
            <option value="service">Service</option>
          </select>
          <select v-model="localSortOrder">
            <option value="asc">Low to High</option>
            <option value="desc">High to Low</option>
          </select>
          <select
            id="category"
            v-model="selectedCategory"
            @change="onCategoryChange"
          >
            <option value="">All categories</option>
            <option
              v-for="category in categories"
              :key="category.name"
              :value="category.name"
            >
              {{ category.name }}
            </option>
          </select>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, watch } from "vue";

export default {
  name: "FiltersComponent",
  props: {
    categories: {
      type: Array,
      required: true,
    },
  },
  emits: [
    "update:searchQuery",
    "update:filterType",
    "update:sortOrder",
    "update:selectedCategory", // Added this line
  ],

  setup(props, { emit }) {
    const localSearchQuery = ref("");
    const localFilterType = ref("");
    const localSortOrder = ref("desc");
    const selectedCategory = ref("");

    const filtersVisible = ref(false); // Controls filter visibility

    const toggleFilters = () => {
      filtersVisible.value = !filtersVisible.value;
    };

    const onCategoryChange = () => {
      emit("update:selectedCategory", selectedCategory.value);
    };

    watch(localSearchQuery, (newValue) => {
      emit("update:searchQuery", newValue);
    });

    watch(localFilterType, (newValue) => {
      emit("update:filterType", newValue);
    });

    watch(localSortOrder, (newValue) => {
      emit("update:sortOrder", newValue);
    });

    return {
      localSearchQuery,
      localFilterType,
      localSortOrder,
      filtersVisible, // Bind visibility state
      toggleFilters, // Method to toggle filter visibility
      selectedCategory,
      onCategoryChange,
    };
  },
};
</script>

<style scoped>
.filters {
  display: flex;
  margin-top: var(--smallerMargin);
  gap: var(--smallerMargin);
  max-width: 900px;
}

input,
select {
  height: var(--smallClickableHeight);
  padding: var(--smallerMargin);
}
select#category {
  width: 33%;
  flex-grow: 1;
}

.search-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.search-container input {
  width: 100%;
  padding: var(--smallerMargin);
  padding-left: calc(var(--smallClickableHeight) * 0.9);
  padding-right: calc(
    var(--smallClickableHeight) * 1.5
  ); /* Space for filter icon */
  border-radius: var(--borderRadius);
}

.search-container .search-icon {
  position: absolute;
  left: var(--smallerMargin);
  pointer-events: none;
  display: flex;
  align-items: center;
  opacity: 0.6;
}

.search-container .filter-icon {
  position: absolute;
  right: var(--smallerMargin);
  cursor: pointer;
  display: flex;
  align-items: center;
  opacity: 0.6;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
</style>
