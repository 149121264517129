<template>
  <div :class="{ UseCases: showOnFAQ }">
    <div :class="{ column_container: showOnFAQ }">
      <div :class="['singleColumn', showOnFAQ ? '' : 'column']">
        <section>
          <h2 class="alignCentre smallmargintopnomarginbottom" v-if="showOnFAQ">
            Use cases for organisations
          </h2>
          <h1
            :class="[
              'nomarginbottom',
              {
                nomargintopbottom: !showOnFAQ,
                nomarginbottom: showOnFAQ,
                alignCentre: showOnFAQ,
              },
            ]"
            class="purpleText"
          >
            Friends and households
          </h1>
          <p class="shortwidth tinymargintop">
            Flatmates can earn and trade their coins for chores, or favours. It
            can make mundane interactions more organised.
          </p>

          <h1
            :class="[
              'nomarginbottom',
              {
                alignCentre: showOnFAQ,
              },
            ]"
            class="purpleText"
          >
            Indepent communities
          </h1>
          <p class="shortwidth tinymargintop">
            It enables communities to incentivise specific tasks among members,
            promoting local trade and self-sufficiency. It facilitate
            transactions between different organisations using its own
            human-indexed exchange rate.
          </p>
          <h1
            :class="['nomarginbottom', { alignCentre: showOnFAQ }]"
            class="purpleText"
          >
            Academia
          </h1>

          <p class="shortwidth tinymargintop">
            Unit is a valuable tool for academic research, allowing educators
            and researchers to explore new economic models, study the effects of
            incentives, and analyse behaviour in controlled environments. For
            example, a school can allow internal trades between students, and
            allow students to exchange those coins for predefined things.
          </p>

          <h1
            :class="['nomarginbottom', { alignCentre: showOnFAQ }]"
            class="purpleText"
          >
            Public and private organisations
          </h1>
          <p class="shortwidth tinymargintop">
            Unit enables organisations to use a currency between colleagues and
            teams, or a network of organisations. By leveraging Unit,
            organisations can create an custom economic environment, without
            compromising liquidity in traditional money.
          </p>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UseCases",
  props: {
    showOnFAQ: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.HowToGuide {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--biggerMargin);
}
.separator {
  /* your styles for separator */
}
/* other styles */
</style>
