<template>
  <div class="appCont">
    <PasswordPage
      v-if="showPasswordPage"
      @passwordEntered="handlePasswordEntered"
    />
    <!-- <DraggableBackButton /> -->

    <Navbar v-if="user" />
    <router-view v-if="user" />
    <Modal :visible.sync="showLoginModal" @close="toggleLoginModal">
      <template v-slot:content>
        <Login :redirect="true" />
      </template>
    </Modal>
    <Modal :visible.sync="showRegisterModal" @close="toggleRegisterModal">
      <template v-slot:content>
        <Register :redirect="true" />
      </template>
    </Modal>
    <Messages
      v-if="user.loggedIn"
      :isOpen="areMessagesOpen"
      :currentUser="userCredentials"
      otherUserUid="123"
    />
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { onMounted, computed, ref } from "vue";
import { auth } from "@/firebase";
import { toast } from "vue3-toastify";
import { useI18n } from "vue-i18n";

import PasswordPage from "@/components/GenericComponents/PasswordPage.vue";
import Navbar from "@/components/NavbarComponents/Navbar.vue";
import Login from "@/components/AuthComponents/Login.vue";
import Register from "@/components/AuthComponents/Register.vue";
import Modal from "@/components/AuthComponents/Modal.vue";
import Messages from "@/components/MessagingComponents/Messages.vue";
// import DraggableBackButton from "@/components/NavbarComponents/DraggableBackButton.vue";

export default {
  name: "App",
  components: {
    PasswordPage,
    Navbar,
    Register,
    Login,
    Modal,
    Messages,
    // DraggableBackButton,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const assetPath = ref(`${window.location.origin}/assets`);

    auth.onAuthStateChanged((user) => {
      if (user) {
        store.dispatch("logInStateCommit", { user });
      } else {
        store.dispatch("logOutStateCommit");
      }
    });

    const user = computed(() => store.getters.user);
    const userCredentials = computed(() => store.getters.userCredentials);
    const areMessagesOpen = computed(
      () => store.getters["UIState/areMessagesOpen"]
    );

    const showPasswordPage = ref(false);

    const currentRoute = router.currentRoute;
    const isInviteRoute = computed(() => currentRoute.value.name === "invite");

    const handlePasswordEntered = (password) => {
      if (password === "UnitJam") {
        showPasswordPage.value = false;
        router.push("/");
        const successMessage = t("app.welcome");
        toast.success(successMessage, { autoClose: 2000 });
      } else {
        const errorMessage = t("app.incorrectPassword");
        toast.error(errorMessage, { autoClose: 2000 });
      }
    };

    const showLoginModal = computed(() => store.state.showLoginModal);
    const showRegisterModal = computed(() => store.state.showRegisterModal);

    const toggleLoginModal = () => store.dispatch("toggleLoginModal");
    const toggleRegisterModal = () => store.dispatch("toggleRegisterModal");

    // onMounted(() => {
    //   store.dispatch("UIState/initDarkMode");
    // });

    return {
      assetPath,
      user,
      userCredentials,
      areMessagesOpen,
      showPasswordPage,
      handlePasswordEntered,
      isInviteRoute,
      showLoginModal,
      toggleLoginModal,
      showRegisterModal,
      toggleRegisterModal,
    };
  },
};
</script>

<style src="@/assets/main.css" lang="css"></style>
