<template>
  <div class="landing">
    <UnitLogoReactive v-if="!loadingUserLoginStatus && !user.loggedIn" />

    <!-- <CreateOrJoin v-if="!userUnits_customOrgs" :user="user" />
    <div
      class="separator"
      v-if="!loadingUserLoginStatus && !user.loggedIn"
    ></div> -->

    <div class="singleColumn fullWidth">
      <YourUnitverse />
    </div>
    <!-- <div
      class="separator"
      v-if="!loadingUserLoginStatus && !user.loggedIn"
    ></div> -->

    <div class="singleColumn" v-if="!loadingUserLoginStatus && !user.loggedIn">
      <UVPIntroCoreMessage />
      <button @click="goToAbout" class="learn-more-button">Learn more</button>
      <div class="separator divBigMarginTop"></div>

      <FAQPanel />
      <!--    <HowToGuide /> -->
    </div>
    <!-- <div
      class="separator"
      v-if="!loadingUserLoginStatus && !user.loggedIn"
    ></div> -->

    <!-- <PreEngagement v-if="!loadingUserLoginStatus && !user.loggedIn" /> -->
  </div>
</template>

<script>
import { computed, onMounted, watch, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { auth } from "@/firebase";
import UVPIntro from "@/components/LandingComponents/UVPIntro.vue";
import UVPIntroCoreMessage from "@/components/LandingComponents/UVPIntroCoreMessage.vue";
import FAQPanel from "@/components/AboutComponents/FAQPanel.vue";
import UnitLogoReactive from "@/components/LandingComponents/UnitLogoReactive.vue";
import YourUnitverse from "@/components/DashboardComponents/YourUnitverse.vue";
import PreEngagement from "@/components/GenericComponents/PreEngagement.vue";
// import CreateOrJoin from "@/components/LandingComponents/CreateOrJoin.vue";
// import HowToGuide from "@/components/AboutComponents/HowToGuide.vue";

export default {
  name: "Landing",
  components: {
    UVPIntro,
    UVPIntroCoreMessage,
    UnitLogoReactive,
    PreEngagement,
    // CreateOrJoin,
    // HowToGuide,

    YourUnitverse,
    FAQPanel,
  },
  setup() {
    const loadingUserLoginStatus = ref(true);

    const store = useStore();
    const router = useRouter();
    const activeTab = computed(() => store.getters.activeTab);
    // watch(activeTab, (newValue) => {
    //   console.log("activeTab:", newValue);
    //   console.log("Show YourUnitverse:", newValue === "yourUnitverse");
    //   console.log("Show Ecosystem:", newValue === "ecosystem");
    // });

    const userUnits_customOrgs = computed(() => {
      return store.getters.userUnits_customOrgs;
    });

    const loginModalVisible = computed(() => store.state.showLoginModal);
    const registerModalVisible = computed(() => store.state.showRegisterModal);
    const goToAbout = () => {
      router.push("/about");
      store.dispatch("changeActiveTab", null);
    };
    const toggleLoginModal = () => store.dispatch("toggleLoginModal");
    const toggleRegisterModal = () => store.dispatch("toggleRegisterModal");

    onMounted(async () => {
      auth.onAuthStateChanged((user, userCredentials) => {
        store.dispatch("fetchUser", user);
        store.dispatch("fetchUserCredentials", user); // Assuming user object has the credentials
        loadingUserLoginStatus.value = false;
      });
    });

    const changeTab = (tabName) => {
      console.log(tabName);
      store.dispatch("changeActiveTab", tabName);
    };

    const user = computed(() => {
      return store.getters.user;
    });

    const userCredentials = computed(() => {
      return store.getters.userCredentials;
    });

    watch(
      () => store.getters.userUnits_customOrgs,
      (newVal, oldVal) => {
        if (newVal) {
          const orgUID = Object.keys(newVal)[0]; // Get the first key which is dynamic
          // console.log("ORGUID");
          // console.log(newVal[orgUID]);
          if (orgUID) {
            const monetaryParams = newVal[orgUID].monetary_parameters;

            if (monetaryParams) {
              const coinName = monetaryParams.coin_name || "Unit";
              const coinSymbol = monetaryParams.coin_symbol || "Ʉ";

              const payload = {
                coinName,
                coinSymbol,
              };
              // console.log("Landing: Setting monetary params:", payload);

              // for coin symbol component
              store.dispatch("updateCoinSymbol", {
                symbol: monetaryParams.coin_symbol || "Ʉ",
                name: monetaryParams.coin_name || "Unit",
              });
              // for Monetary rules
              store.commit("SET_MONETARY_PARAMS", payload);
            }
          }
        }
      },
      { immediate: true }
    );

    return {
      user,
      goToAbout,
      activeTab,
      loginModalVisible,
      toggleLoginModal,
      registerModalVisible,
      toggleRegisterModal,
      loadingUserLoginStatus,
      userUnits_customOrgs,
      userCredentials,
    };
  },
};
</script>

<style scoped>
.landing {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  gap: var(--biggerMargin);
  width: 100%;
}

.logo_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.rotated_logo {
  max-height: 100%;
}

/* SMALL SCREENS */
@media only screen and (max-width: 530px) {
  .parent_container {
    width: 100%;
    height: 85vh; /* Set the height of the parent container */
  }

  .rotated_logo {
    transform: rotate(90deg); /* Rotate the image by 90 degrees */
    max-height: 100%; /* Adjust the height of the rotated image */
  }
}
</style>
