<template>
  <form @submit.prevent="$emit('save')" id="customOrgForm">
    <div class="form-group">
      <label>{{ $t("CreateCustomOrg.name") }}</label>
      <input
        v-model="localOrgDetails.organisationName"
        :placeholder="placeholderText"
        required
        class="alignCentre"
      />
    </div>

    <div class="form-group">
      <label>{{ $t("CreateCustomOrg.descriptionOptional") }}</label>
      <textarea v-model="localOrgDetails.description"></textarea>
      <AlertCard
        v-if="isDescriptionTooLong"
        type="warning"
        id="char-limit-warning"
        iconId="error_warning"
        :canBeDismissed="false"
      >
        <template #text>
          Text cannot exceed 1500 characters.
          <p class="smallText nomargintopbottom">
            Characters: {{ descriptionCharacterCount }} / 1500
          </p>
        </template>
      </AlertCard>
    </div>
  </form>
</template>

<script>
import { ref, reactive, watch, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import AlertCard from "@/components/GenericComponents/AlertCard.vue";

export default {
  name: "CreateCustomOrgForm",
  components: {
    AlertCard,
  },
  props: {
    orgDetails: {
      type: Object,
      required: true,
    },
  },
  emits: ["save", "description-too-long", "update:basicOrgDeets"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const localOrgDetails = reactive({
      organisationName: props.orgDetails?.organisationName || "",
      description: props.orgDetails?.description || "",
    });

    const placeholderText = ref("Type your organization name");
    const isDescriptionTooLong = ref(false);
    const descriptionCharacterCount = ref(0);

    watchEffect(() => {
      descriptionCharacterCount.value = localOrgDetails.description.length;
      isDescriptionTooLong.value = descriptionCharacterCount.value > 1500;
      emit("description-too-long", isDescriptionTooLong.value);
    });

    watch(
      localOrgDetails,
      (newVal) => {
        emit("update:basicOrgDeets", newVal);
      },
      { deep: true }
    );

    watch(
      () => props.orgDetails,
      (newVal) => {
        localOrgDetails.organisationName = newVal.organisationName || "";
        localOrgDetails.description = newVal.description || "";
      },
      { immediate: true, deep: true }
    );

    return {
      localOrgDetails,
      placeholderText,
      isDescriptionTooLong,
      descriptionCharacterCount,
      t,
    };
  },
};
</script>

<style scoped>
.form-group {
  display: flex;
  flex-direction: column;
}
textarea {
  min-height: calc(var(--clickableHeight) * 4);
  height: calc(var(--clickableHeight) * 4);
  resize: none;
}
</style>
