<template>
  <div class="setLocationContainer">
    <div v-if="showMap" ref="mapContainer" class="mapContainer"></div>
  </div>
</template>

<script>
import "mapbox-gl/dist/mapbox-gl.css";
import { ref, onMounted, watch, nextTick } from "vue";
import mapboxgl from "mapbox-gl";
import { toast } from "vue3-toastify";

export default {
  name: "SetLocation",
  emits: ["update-location"],
  props: {
    showMap: {
      type: Boolean,
      required: true,
    },
    lastPickedLocation: {
      type: Object,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const mapContainer = ref(null);
    const map = ref(null);
    const marker = ref(null);

    mapboxgl.accessToken =
      "pk.eyJ1IjoiamFtYXJydWNobyIsImEiOiJjbG5sejgxa3owMTVnMmpueTYzc2UxeXp4In0.mMWOGw2VuYEVv6WM6DFDrg";

    const initializeMap = (lat, lng) => {
      // Destroy the previous map instance if any
      if (map.value) map.value.remove();

      map.value = new mapboxgl.Map({
        container: mapContainer.value,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [lng, lat],
        zoom: 1,
      });

      map.value.on("load", () => {
        marker.value = new mapboxgl.Marker()
          .setLngLat([lng, lat])
          .addTo(map.value);
      });

      map.value.on("click", (event) => {
        const location = { lat: event.lngLat.lat, lng: event.lngLat.lng }; // Changed to an object
        marker.value.setLngLat([location.lng, location.lat]);
        emit("update-location", location);
      });
    };

    const openMap = () => {
      if (
        props.lastPickedLocation &&
        props.lastPickedLocation.lat &&
        props.lastPickedLocation.lng
      ) {
        initializeMap(
          props.lastPickedLocation.lat,
          props.lastPickedLocation.lng
        ); // Use lat/lng from object
      } else if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            initializeMap(latitude, longitude);
          },
          () => initializeMap(51.5074, -0.1278) // Fallback to London
        );
      } else {
        initializeMap(51.5074, -0.1278); // Fallback to London
      }
    };

    watch(
      () => props.showMap,
      (newVal) => {
        if (newVal) {
          nextTick(openMap); // Ensure the map container is rendered before initializing the map
        } else if (map.value) {
          map.value.remove(); // Clean up the map instance when the map is toggled off
          map.value = null;
          marker.value = null;
        }
      }
    );

    watch(
      () => props.showMap,
      (newVal) => {
        if (!newVal) {
          emit("update-location", null); // Set location to null when map is toggled off
        }
      }
    );

    onMounted(() => {
      if (props.showMap) {
        nextTick(openMap); // Ensure the map container is rendered before initializing the map
      }
    });

    return {
      openMap,
      mapContainer,
    };
  },
};
</script>

<style scoped>
.setLocationContainer {
  width: 85%;
  margin-top: var(--smallMargin);
}
.mapContainer {
  /* border: 1px solid var(--black); */
  border: var(--glass-border);

  border-radius: var(--borderRadius);
  width: 100%;
  height: 400px;
}

@media (max-width: 590px) {
  .mapContainer {
    height: 310px;
  }
}
</style>
