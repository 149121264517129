// We will load the messages dynamically here
let messages = {};

// A function to import all the json files from the locales directory
function importAll(r) {
  r.keys().forEach((key) => (messages[key.replace(/(\.\/|\.json)/g, "")] = r(key)));
}

// Use webpack's require.context to import all json files in the locales directory
importAll(require.context('../locales', false, /\.json$/));

export default messages;