<template>
  <div class="role-list" @scroll="handleScroll" ref="scroller">
    <div
      class="role-container divMarginBottom"
      v-if="rolesWithFilledBy.length >= 1"
      v-for="role in rolesWithFilledBy"
      :key="role.roleUID"
    >
      <div v-if="rolesWithFilledBy.length > 1" class="role-header">
        <div class="row justifyToStart">
          <h4 class="role-title nomargintopbottom" :title="role.roleTitle">
            {{ truncatedRoleTitle(role.roleTitle) }}
          </h4>
          <p class="nomargintopbottom gray">
            Salary: {{ role.baseCompensation }}{{ coinSymbol }}
          </p>
        </div>

        <small
          class="actionText gray"
          @click="redirectToRoleView(role.roleUID, orgUID)"
        >
          About the role
        </small>
      </div>
      <div class="user-cards">
        <div
          v-for="(user, uid) in role.filledBy"
          :key="uid"
          class="user-card innerSticker"
          :style="getUserCardStyle(Object.keys(role.filledBy).length)"
          @click="redirectToUserProfile(uid)"
        >
          <template v-if="userPictures[uid]">
            <div class="user-photo-container">
              <img
                :src="userPictures[uid]"
                :alt="`Avatar of ${
                  user.user_details.displayName || 'Anonymous'
                }`"
                class="user-photo"
              />
            </div>
          </template>
          <template v-else>
            <svg
              class="user-icon"
              :title="`Avatar of ${
                user.user_details.displayName || 'Anonymous'
              }`"
            >
              <use href="@/assets/icons/iconset.svg#user-big"></use>
            </svg>
          </template>
          <div class="user-info">
            <strong :title="user.user_details.displayName || 'Anonymous'">
              <!-- {{ user.user_details }} -->
              {{
                truncatedUserName(user.user_details.displayName || "Anonymous")
              }}
            </strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { getDoc, doc } from "firebase/firestore";
import { db } from "@/firebase";
import CoinSymbol from "../GenericComponents/CoinSymbol.vue";

export default {
  name: "RoleScroll",
  props: {
    roles: {
      type: [Array, Object],
      required: true,
      default: () => [],
    },
    orgUID: {
      type: String,
      required: true,
    },
    coinSymbol: {
      type: String,
      required: false, // Mark it as required to ensure it's always passed
    },
  },
  setup(props) {
    const router = useRouter();
    const numberOfRolesToShow = ref(10);
    const scroller = ref(null);
    const userPictures = ref({});

    // Process roles prop to ensure it's always an array
    const processedRoles = computed(() => {
      return Array.isArray(props.roles) ? props.roles : [props.roles];
    });
    // Filter roles to include only those with at least one user in filledBy
    const rolesWithFilledBy = computed(() => {
      return processedRoles.value.filter((role) => {
        return role.filledBy && Object.keys(role.filledBy).length > 0;
      });
    });
    const visibleRoles = computed(() => {
      return rolesWithFilledBy.value.slice(0, numberOfRolesToShow.value);
    });
    const handleScroll = () => {
      const { scrollLeft, scrollWidth, clientWidth } = scroller.value;
      if (scrollLeft + clientWidth >= scrollWidth - 100) {
        numberOfRolesToShow.value += 10;
      }
    };

    const fetchUserProfilePicture = async (uid, user) => {
      const userDocRef = doc(db, "users", uid);
      const userDoc = await getDoc(userDocRef);
      let pictureUrl = null;

      if (userDoc.exists()) {
        const userData = userDoc.data();
        pictureUrl = userData.profile_picture;
      }

      if (!pictureUrl && user.user_details.photoURL) {
        pictureUrl = user.user_details.photoURL;
      }

      userPictures.value[uid] = pictureUrl || null;
    };

    const truncatedUserName = (name) => {
      if (name && name.length > 16) {
        const firstPart = name.substring(0, 10).trim();
        const lastPart = name.substring(name.length - 3).trim();
        return `${firstPart}(…)${lastPart}`;
      }
      return name;
    };

    const truncatedRoleTitle = (title) => {
      if (title && title.length > 20) {
        const firstPart = title.substring(0, 17).trim();
        return `${firstPart}(…)`;
      }
      return title;
    };
    const isSmallScreen = ref(window.innerWidth <= 590);

    const getUserCardStyle = () => {
      let width = isSmallScreen.value ? "50%" : "25%";
      return {
        width,
      };
    };

    const updateUserCardStyleOnResize = () => {
      window.addEventListener("resize", () => {
        // This function ensures that style updates happen on window resize
      });
    };

    onMounted(() => {
      const updateIsSmallScreen = () => {
        isSmallScreen.value = window.innerWidth <= 590;
      };

      window.addEventListener("resize", updateIsSmallScreen);

      // Clean up on unmount
      onUnmounted(() => {
        window.removeEventListener("resize", updateIsSmallScreen);
      });

      // Existing code to fetch user pictures
      rolesWithFilledBy.value.forEach((role) => {
        Object.keys(role.filledBy).forEach((uid) => {
          if (!userPictures.value[uid]) {
            fetchUserProfilePicture(uid, role.filledBy[uid]);
          }
        });
      });
    });

    const redirectToRoleView = (roleUID, orgUID) => {
      router.push({
        name: "RoleView",
        params: { roleUID, orgUID },
      });
    };

    const redirectToUserProfile = (uid) => {
      router.push({
        name: "UserProfile",
        params: { userUID: uid },
      });
    };

    return {
      rolesWithFilledBy,
      processedRoles, // Make sure this is returned here
      visibleRoles,
      handleScroll,
      scroller,
      redirectToRoleView,
      redirectToUserProfile,
      getUserCardStyle,
      truncatedUserName,
      truncatedRoleTitle,
      userPictures,
    };
  },
};
</script>

<style scoped>
.role-list {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  white-space: nowrap;
  scroll-snap-type: x mandatory;
}

.role-container {
  display: inline-block;
  width: 100%;
  margin-bottom: var(--mediumMargin);
}

.role-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--smallerMargin);
  margin-top: var(--smallMargin);
}

.role-title {
  margin: 0;
  font-weight: bold;
}

.user-cards {
  box-shadow: var(--neuMorphBoxInner);
  border-radius: var(--borderRadiusBigger);
  height: auto;
  width: 100%;
  display: flex !important;
  overflow-x: auto;
  position: relative;
  display: inline-block;
  scroll-snap-align: start;
  padding: var(--smallerMargin);
  border-radius: var(--borderRadius);
  vertical-align: top;
  gap: var(--smallMargin);
}

.user-card {
  transform: scale(0.99);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  background-color: var(--lightGrey);
  border-radius: var(--borderRadius);
  height: calc(var(--clickableHeight) * 4);
  cursor: pointer;
  transition: all var(--slideInSpeed) ease-in-out;
  overflow: hidden;
  gap: 0 !important;
}

.user-card:hover {
  transform: scale(1);
}

.user-photo-container {
  width: 100%;
  height: 80%;
  border-radius: var(--borderRadius) var(--borderRadius) 0 0;
  overflow: hidden;
}

.user-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-icon {
  width: auto;
  height: 80%;
  background-color: var(--solidLightGrey);
  fill: var(--solidLightGrey);
}

.user-info {
  width: 100%;
  text-align: left;
  padding: var(--smallerMargin);
  display: flex;
  flex-direction: column;
}

.actionText {
  cursor: pointer;
  color: var(--primaryColor);
}

@media (max-width: 590px) {
  .modal-content {
    width: 100%;
  }
}
</style>
