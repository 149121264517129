<template>
  <div class="info-tile">
    <svg v-if="active" class="compactImg">
      <use href="@/assets/icons/iconset.svg#checkmark_compact"></use>
    </svg>
    <img
      v-if="illustration == !'your_default_illustration_path'"
      :src="illustration"
      alt="Illustration"
      class="info-tile__image"
    />
    <div class="info-tile__text">
      <p class="info-tile__title nomargintopbottom">{{ title }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoTile",
  props: {
    illustration: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.info-tile {
  display: flex;
  align-items: center;
  width: auto;
  height: calc(var(--smallClickableHeight) * 0.8);
  gap: var(--microMargin);
  padding: var(--microMargin);
  padding-right: var(--smallMargin);
  border-radius: var(--borderRadiusButton);
  border: 1px solid var(--solidMidgrey);
}

.inactive {
  padding: var(--smallMargin);
}

.info-tile__image {
  width: var(--smallClickableHeight);
  height: var(--smallClickableHeight);
  object-fit: cover;
}

.info-tile__text {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
</style>
