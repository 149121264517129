<template>
  <teleport to="body">
    <div
      class="modal local"
      :class="{ 'slide-in': visible, 'slide-out': !visible }"
      @click.self="$emit('close')"
    >
      <div
        class="modal-content"
        :class="{ red: bgColor === 'red' }"
        @click.stop
      >
        <div class="modalHeader">
          <svg
            v-if="showBackArrow"
            class="backArrow verySmall"
            @click="previousStep"
            v-haptic
          >
            <use href="../../assets/icons/iconset.svg#arrowleft"></use>
          </svg>
          <svg v-else class="backArrow verySmall hidden">
            <use href="../../assets/icons/iconset.svg#arrowight"></use>
          </svg>
          <h2 class="alignCentre smallTitle">{{ title }}</h2>
          <svg class="close verySmall" @click="$emit('close')" v-haptic>
            <use href="../../assets/icons/iconset.svg#cross_smaller"></use>
          </svg>
        </div>
        <p class="alignCentre tinymargintopbottom">
          {{ messageLineOne }}
        </p>
        <p
          v-if="messageLineTwo"
          class="alignCentre tinymargintopbottom redText"
        >
          {{ messageLineTwo }}
        </p>
        <p
          v-if="messageLineThree"
          class="alignCentre tinymargintopbottom redText"
        >
          {{ messageLineThree }}
        </p>
        <br />
        <div class="modal-buttons row">
          <slot></slot>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount, watch } from "vue";
import { useStore, mapState } from "vuex";
import { useRouter } from "vue-router";
import { toast } from "vue3-toastify";

export default {
  name: "AlertModalLocal",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    messageLineOne: {
      type: String,
    },
    messageLineTwo: {
      type: String,
    },
    messageLineThree: {
      type: String,
    },
    bgColor: {
      type: String,
      default: "var(--backgroundColourOrg)",
    },
    roleTitles: {
      type: [String, Array],
      required: false,
    },
    showBackArrow: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["confirm", "close", "clickOutside"], // Add clickOutside here
  setup(props, { emit }) {
    const router = useRouter();
    const showLoginModal = computed(() => store.state.showLoginModal);
    const showRegisterModal = computed(() => store.state.showRegisterModal);
    const closeAlertModal = () => {
      emit("clickOutside"); // Emit clickOutside event
    };

    // Register directive in the component
    const directive = {
      beforeMount: (el, binding, vnode) => {
        el.clickOutsideEvent = function (event) {
          if (!(el == event.target || el.contains(event.target))) {
            closeAlertModal();
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
      },
      unmounted: (el) => {
        document.body.removeEventListener("click", el.clickOutsideEvent);
      },
    };

    return {
      directive,
      confirm,
      close,
      showLoginModal,
      showRegisterModal,
    };
  },
};
</script>
<style scoped>
.modal {
  backdrop-filter: blur(10px);
  position: fixed;
  z-index: 2;
  left: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  background-color: transparent;
  color: var(--black);
  background-color: var(--backgroundColourOrgTransp);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: var(--margin);
  opacity: 0;
  pointer-events: none;
  transition: all var(--slideInSpeed);
}

.slide-in {
  opacity: 1;
  pointer-events: auto;
}

.slide-out {
  opacity: 0;
  pointer-events: none;
}

.modal-content {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 5;
  background-color: var(--backgroundColourOrg);
  padding: var(--smallMargin) var(--margin) var(--bigMargin);

  max-height: 90%;
  overflow-y: auto;
  border-radius: var(--borderRadius);
  position: relative;
  transform: translateY(100%);
  transition: all 0.15s;
  animation: slide-in var(--slideInSpeed) forwards;
  box-shadow: var(--dropShadowRegular);
}

.modal-content.red {
  background-color: var(--lightestred);
  box-shadow: var(--neuMorphBoxOuterLighterRed);
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: var(--tinyMargin);
}

.modalHeader .backArrow.hidden {
  visibility: hidden;
}

.modalHeader h2 {
  font-size: var(--smallFontsize);
  font-weight: var(--fontweightlightH2);
  flex-grow: 1;
  text-align: center;
}

.modalHeader .close {
  margin-left: auto;
}

.modal-buttons {
  display: flex;
  justify-content: center;
  width: 100%;
}

.slide-out .modal-content {
  animation: slide-out var(--slideInSpeed) forwards;
}

@keyframes slide-in {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

/* SMALL SCREENS */
@media only screen and (max-width: 450px) {
  .modal-content {
    width: 100%;
  }
}
</style>
