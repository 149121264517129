import {
  collection,
  getDocs,
  query,
  orderBy,
  startAfter,
  limit,
  getDoc,
  doc,
} from "firebase/firestore";
import { db } from "@/firebase";

export default {
  state: {
    services: [],
    service: null,
    loading: false,
    lastVisibleService: null,
    allowedOrgs: [],
  },
  getters: {
    services: (state) => state.services,
    service: (state) => state.service,
    lastVisibleService: (state) => state.lastVisibleService,
  },
  actions: {
    // Fetch allowed organizations
    async fetchAllowedOrganisations({ commit }) {
      try {
        const orgsRef = collection(db, "custom-orgs");
        const orgsSnapshot = await getDocs(orgsRef);

        const allowedOrgs = orgsSnapshot.docs
          .filter((doc) => {
            const orgData = doc.data();
            // Log the data for each organization
            // console.log(`Org Data for ${doc.id}:`, orgData);
            // Check if the org is public or allows trades
            return orgData.public || orgData.allowTrades;
          })
          .map((doc) => doc.id); // Store the org UID for filtering

        // console.log("Allowed Organizations UIDs:", allowedOrgs);
        commit("SET_ALLOWED_ORGANISATIONS", allowedOrgs);
      } catch (error) {
        console.error("Error fetching organizations:", error);
      }
    },

    // Fetch services
    async fetchServices(
      { commit, state, dispatch },
      { batchSize = 10, isInitial = false, refresh = false, orgUID = null } = {}
    ) {
      commit("SET_LOADING", true);

      // console.log("Fetching services...");

      // Wait for allowed organizations to be fetched
      await dispatch("fetchAllowedOrganisations");

      // Log allowed organizations
      // console.log("Allowed Organizations after fetch:", state.allowedOrgs);

      // Reset lastVisibleService if refresh is requested
      if (refresh) {
        commit("SET_LAST_VISIBLE_SERVICE", null);
      }

      try {
        const servicesRef = collection(db, "services");
        let q = query(
          servicesRef,
          orderBy("createdAt", "desc"),
          limit(batchSize)
        );

        if (!isInitial && state.lastVisibleService) {
          q = query(
            servicesRef,
            orderBy("createdAt", "desc"),
            startAfter(state.lastVisibleService),
            limit(batchSize)
          );
        }

        const serviceSnapshot = await getDocs(q);
        // console.log("Fetched services snapshot:", serviceSnapshot);

        const services = serviceSnapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .filter((service) => {
            // Log each service and the filtering process
            const isAllowed =
              state.allowedOrgs.includes(service.organisation) ||
              service.organisation === orgUID;
            // console.log(`Service ${service.id} is allowed:`, isAllowed);
            return isAllowed;
          });

        // console.log("Filtered Services:", services);

        if (serviceSnapshot.docs.length > 0) {
          const lastVisible =
            serviceSnapshot.docs[serviceSnapshot.docs.length - 1];
          commit("SET_LAST_VISIBLE_SERVICE", lastVisible);
        }

        if (isInitial || refresh) {
          commit("SET_SERVICES", services);
        } else {
          commit("ADD_SERVICES", services);
        }
      } catch (error) {
        console.error("Error fetching services:", error);
      } finally {
        commit("SET_LOADING", false);
      }
    },

    // Fetch a specific service
    async fetchService({ commit }, serviceId) {
      commit("SET_LOADING", true);
      try {
        console.log(`Fetching service with ID: ${serviceId}`);
        const serviceDoc = await getDoc(doc(db, "services", serviceId));
        if (serviceDoc.exists()) {
          const service = { id: serviceDoc.id, ...serviceDoc.data() };
          console.log("Fetched service data:", service);
          commit("SET_SERVICE", service);
        } else {
          console.error("No such service!");
        }
      } catch (error) {
        console.error("Error fetching service:", error);
      } finally {
        commit("SET_LOADING", false);
      }
    },

    clearService({ commit }) {
      commit("CLEAR_SERVICE");
    },
  },
  mutations: {
    SET_ALLOWED_ORGANISATIONS(state, allowedOrgs) {
      state.allowedOrgs = allowedOrgs;
    },
    SET_SERVICES(state, services) {
      state.services = services;
    },
    ADD_SERVICES(state, services) {
      state.services = [...state.services, ...services];
    },
    SET_SERVICE(state, service) {
      state.service = service;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_LAST_VISIBLE_SERVICE(state, service) {
      state.lastVisibleService = service;
    },
    CLEAR_SERVICE(state) {
      state.service = null;
    },
  },
};
