// utils/exchangeRateCalculator.js

export function calculatePrice(
  buyerMembersCount,
  sellerMembersCount,
  priceInSellerCoins,
  buyerTotalCoins,
  sellerTotalCoins
) {
  // console.log("buyerMembersCount:", buyerMembersCount);
  // console.log("sellerMembersCount:", sellerMembersCount);

  const buyerPerCapita = buyerTotalCoins / buyerMembersCount;
  const sellerPerCapita = sellerTotalCoins / sellerMembersCount;

  // console.log("BuyerPerCapita:", buyerPerCapita)

  // console.log("SellerPerCapita:", sellerPerCapita);
  // Invert the conversion rate calculation
  const conversionRate = buyerPerCapita / sellerPerCapita;

  const priceInBuyerCoins = priceInSellerCoins * conversionRate;
  // console.log("Conversion Rate:", conversionRate);
  // console.log("Buyer Coin price:", priceInBuyerCoins);
  // console.log(`Converted Price in Buyer's Coins: ${priceInBuyerCoins} ฿`);

  return priceInBuyerCoins;
}

export function calculateReversePrice(
  senderOrgMembersCount,
  receiverOrgMembersCount,
  amountInSenderCoins,
  senderOrgTotalCoins,
  receiverOrgTotalCoins
) {
  console.log(`senderOrgMembersCount: ${senderOrgMembersCount}`);
  console.log(`receiverOrgMembersCount: ${receiverOrgMembersCount}`);
  console.log(`amountInSenderCoins: ${amountInSenderCoins}`);
  console.log(`senderOrgTotalCoins: ${senderOrgTotalCoins}`);
  console.log(`receiverOrgTotalCoins: ${receiverOrgTotalCoins}`);

  const senderPerCapita = senderOrgTotalCoins / senderOrgMembersCount;
  const receiverPerCapita = receiverOrgTotalCoins / receiverOrgMembersCount;

  console.log(`Sender Per Capita: ${senderPerCapita}`);
  console.log(`Receiver Per Capita: ${receiverPerCapita}`);

  // Reverse the conversion rate calculation
  const conversionRate = receiverPerCapita / senderPerCapita;
  console.log(`Conversion Rate: ${conversionRate}`);

  const amountInReceiverCoins = amountInSenderCoins * conversionRate;
  console.log(`Amount in Receiver's Coins: ${amountInReceiverCoins} ₳`);

  return {
    amountInReceiverCoins,
    conversionRate,
  };
}
