export const ProductInfoSchema = {
  productId: "", // ID of the product/service
  itemName: "", // Name of the product/service
  productPrice: "", // Price of the product/service
  productImageUrl: "", // URL of the product/service image
  convertedPrice: "", // Price after conversion (if applicable)
  coinSymbol: "", // Currency or coin symbol
  sellerCoinSymbol: "ɄɄɄ", // Seller's currency or coin symbol, default to Ʉ
  type: "", // 'product', 'service', 'send', 'request'
  bookingDate: "", // Date of the booking if it's a service
  bookingTime: "", // Time of the booking if it's a service
  slotDuration: 0, // Duration of the slot if it's a service
};

export const InteractiveResponseSchema = {
  interactiveResponse: null, // Response data if the message is interactive
  interactiveResponseStatus: "pending", // 'pending', 'accepted', 'revoked, or 'rejected'
  ...ProductInfoSchema, // Include product/service/transfer information for interactive messages
};

export const MessageSchema = {
  id: "", // Unique identifier for the message
  from: "", // Sender's ID
  to: "", // Recipient's ID
  senderDisplayName: "", // Sender's display name
  recipientDisplayName: "", // Recipient's display name
  messageBody: "", // Text of the message
  messageTime: 0, // Timestamp of when the message was sent
  isInteractive: false, // Boolean indicating if the message has an interactive component
  read: false, // New field to track if the message has been read
  messageMedia: "",
  ...InteractiveResponseSchema, // Integrate the interactive response structure
};
