<template>
  <div class="exchangeRate">
    <div class="column_container">
      <div class="column singleColumn">
        <section>
          <p class="shortwidth smallmargintopnomarginbottom">
            Some monetary systems function as social contracts, like fiat
            currency, where value is determined by government regulation and
            public trust. Others, like the Gold Standard once used by the USA
            and Great Britain, linked currency value directly to a physical
            commodity<Tooltip
              message="Fiat money (modern state money) is currency that's valuable because the government says it is and people trust it. It's not backed by a physical commodity. On the other hand, the Gold Standard was a system where each unit of currency was backed by an equivalent amount of gold, which could be exchanged upon request."
            />
          </p>
          <p class="shortwidth smallmargintop">
            These different forms of money follow various rules to set exchange
            rates.

            <!--     <p class="shortwidth smallmargintop">
        For instance, when dealing with metal-based currencies, the exchange
        rate between two types could be calculated as follows:
      </p>
      <p class="smallerText nomargintopbottom mathJax">
        <span
          v-html="
            '\\[ FX_{\\text{Rate }} A \\to B = \\frac{{\\text{Weight of coin B} \\times \\text{Purity of coin B}}}{{\\text{Weight of coin A} \\times \\text{Purity of coin A}}} \\]'
          "
        ></span> 
      </p>-->

            Today, establishing these rates is far from simple. They are shaped
            by a mix of measurable data, like inflation, interest rates, and
            Gross Domestic Product, and softer elements like geopolitics and
            market sentiment. Together, they set a complicated dance that
            determines a currency's worth<Tooltip
              message="For example, Gross Domestic Product (GDP) and Consumer Price Index (CPI) are key economic indicators that influence exchange rates. But even these 'hard numbers' have limits and biases. While GDP measures a country's economy, its creator, Simon Kuznets warned not to use it as a measure of well-being. Similarly, CPI tracks how prices change over time but doesn't account for individual spending habits."
            />
          </p>

          <ToggleButtonContent
            class="exchangeRateTogle shortwidth"
            :showButtonText="`Historical overview`"
            :hideButtonText="'Hide historical overview'"
            @contentToggled="runMathJax"
            ><section>
              <div class="sticker metallism">
                <h3 class="smallmargintopnomarginbottom emphasisNumber">
                  Metallism
                </h3>
                <label class="eraLabel smallerText"
                  >Ancient - Medieval Era</label
                >
                <PercentageSlider
                  :percentage="100"
                  label="Commodity focus: 100%, Social contract focus: 0%"
                />
                <p class="smallerText nomargintopbottom mathJax">
                  <span
                    v-html="
                      '\\[\\frac{{\\text{Coin B weight} \\times \\text{Coin B purity}}}{{\\text{Coin A weight} \\times \\text{Coin A purity}}}\\]'
                    "
                  ></span>
                </p>

                <p class="shortwidth nomargintop less">
                  In the old days we used a system based on metals like gold and
                  silver. Exchange rates were typically a matter of weight and
                  purity. For example, if two groups had silver coins of the
                  same weight and purity, they would be worth the same. This
                  system was straightforward but cumbersome, requiring people to
                  literally weigh coins and assess their purity.
                </p>
              </div>
              <div class="sticker paper_money">
                <h3 class="smallmargintopnomarginbottom emphasisNumber">
                  Paper money
                </h3>
                <label class="eraLabel smallerText"
                  >Medieval - Early Modern Era</label
                >
                <PercentageSlider
                  :percentage="70"
                  label="Commodity focus: 70%, Social contract focus: 30%"
                />
                <p class="smallerText nomargintopbottom mathJax">
                  <span
                    v-html="
                      '\\[\\left( \\frac{Gold\\_Org_{A}}{Currency\\_Org_{A}} \\right) \\div \\left( \\frac{Gold\\_Org_{B}}{Currency\\_Org_{B}} \\right)\\]'
                    "
                  ></span>
                </p>
                <p class="shortwidth nomargintop less">
                  Paper money and low-value metal coins initially served as a
                  convenient substitute for precious metals like gold and
                  silver. The value of this currency was fixed and could be
                  exchanged for a specific amount of precious metals. This
                  system worked because people trusted that the organisation
                  giving out the paper money could also give them the actual
                  metal if they wanted to exchange it.
                </p>
              </div>

              <div class="sticker modern_money">
                <h3 class="smallmargintopnomarginbottom emphasisNumber">
                  Modern Money
                </h3>
                <label class="eraLabel smallerText"
                  >Late Modern - Present</label
                >
                <PercentageSlider
                  :percentage="20"
                  label="Commodity focus: 20%, Social contract focus: 80%"
                />
                <p class="smallerText nomargintopbottom mathJax">
                  <span
                    v-html="
                      '\\[\\frac{PurchasingPower_A \\times GDP_A}{PurchasingPower_B \\times GDP_B}\\]'
                    "
                  ></span>
                </p>
                <p class="shortwidth nomargintop less">
                  Unlike systems tied to metals, modern money has flexible
                  exchange rates. They're determined by trying to measure value
                  generation. This system relies on government support for fiat
                  money and banking practices for credit money. Both, fiat and
                  credit money forms co-exist in today's economies and could be
                  said to be one and the same.
                </p>
              </div>
              <div class="sticker crypto">
                <h3 class="smallmargintopnomarginbottom emphasisNumber">
                  Cryptocurrency
                </h3>
                <label class="eraLabel smallerText">21st Century</label>
                <PercentageSlider
                  :percentage="70"
                  label="Commodity focus: 70%, Social contract focus: 30%"
                />
                <p class="smallerText nomargintopbottom mathJax">
                  <span
                    v-html="
                      '\\[\\frac{f(Supply_A, Demand_A, Technology_A,  ...)}{f(Supply_B, Demand_B, Technology_B, ...)}\\]'
                    "
                  ></span>
                </p>
                <p class="shortwidth nomargintop less">
                  Cryptocurrencies like Bitcoin and Ethereum are strongly
                  commodity-focused, emphasizing scarcity and inherent value
                  much like gold in metallist approaches. While all
                  cryptocurrencies share this focus, some aim to provide a
                  valuable technological framework that serves a specialized
                  community of developers.
                </p>
              </div>
              <p class="shortwidth nomargintop less">
                <small>
                  <em>
                    The formulas shown are simplified and not universally
                    accepted. Modern monetary systems, for example, are
                    influenced by more than just Purchasing Power and GDP. These
                    basic formulas provide a general outline but don't capture
                    all the complexities of how currencies have been or are
                    valued today.
                  </em>
                </small>
              </p>
              <br />
            </section>
          </ToggleButtonContent>
        </section>

        <ExchangeRateSimulator />
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, nextTick } from "vue";

import ExchangeRateSimulator from "@/components/LandingComponents/ExchangeRateSimulator.vue";
import ToggleButtonContent from "@/components/GenericComponents/ToggleButtonContent.vue";
import Tooltip from "@/components/GenericComponents/Tooltip.vue";
import PercentageSlider from "@/components/GenericComponents/PercentageSlider.vue";

export default {
  name: "Exchange Rate",
  components: {
    ExchangeRateSimulator,
    ToggleButtonContent,
    Tooltip,
    PercentageSlider,
  },
  setup() {
    const runMathJax = () => {
      nextTick(() => {
        if (window.MathJax) {
          window.MathJax.typeset();
        }
      });
    };

    onMounted(async () => {
      if (window.MathJax) {
        window.MathJax.typeset();
      } else {
        console.log("No formula library");
      }
    });

    return { runMathJax };
  },
};
</script>

<style scoped>
.exchangeRate {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

/* Extra space between header and labels */
.emphasisNumber {
  margin-bottom: var(--tinyMargin);
}

/* Style for era label */
.eraLabel {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: var(--microMargin);
  font-style: italic;
}

.smallerText {
  text-align: center;
}

.sticker {
  max-width: 65ch;
}

/*Sticker Colours*/
.sticker.metallism {
  background-color: var(--gold);
}

.sticker.paper_money {
  background-color: var(--greenWashed);
}

.sticker.modern_money {
  background-color: var(--gradblueWashed);
}

p.shortwidth.nomargintop.less {
  margin-block-end: var(--smallMargin);
  max-width: calc(65ch - calc(var(--margin) * 2));
}
.sticker.crypto {
  background-color: var(--blue-gray300);
}

.mathJax {
  /* display: none; */
}

/* SMALL SCREENS */
@media only screen and (max-width: 530px) {
}
</style>
