export const WalletSchema = {
  orgName: "",
  currentAccount: {
    balance: 0,
    moneyIn: [],
    moneyOut: [],
  },
  savingsAccount: {
    balance: 0,
    moneyIn: [],
    moneyOut: [],
  },
  loanAccount: {
    balance: 0,
    moneyIn: [],
    moneyOut: [],
  },
  monetary_parameters: {
    basic_income: 0,
    coin_name: "",
    coin_symbol: "",
    currentAccount: {
      cap: 0,
      lifespan: 0,
    },
    max_shared_pool: {
      cap: 0,
    },
    savings_account: {
      cap: 0,
    },
    loans: {
      dti_effortRate: 0,
      maxLoanTerm: 0,
      annualInterest: 0,
      allowIndividual: false,
      allowJoint: false,
    },
  },
};

// An example object for moneyIn and moneyOut
export const MoneyPackSchema = {
  id: "",
  moneyPackType: null,
  value: 0,
  transactionTime: null,
  lastUpdatedTime: null,
  lifespan: 0,
  remainingLifespanByWallet: {},
  optionalFields: {
    fromOrgName: "",
    fromOrgUID: "",
    toOrgName: "",
    toOrgUID: "",
    fromUser: null,
    fromUserName: null,
    toUser: null,
    toUserName: null,
    toAccountName: null,
  },
};
