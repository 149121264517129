<template>
  <button class="compactButton" @click="createCoinInWallet">
    🤑 Create coin
  </button>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { toast } from "vue3-toastify";

export default {
  name: "CreateOneCoinInUserWallet",
  props: {
    orgUID: String,
  },
  setup(props, { emit }) {
    const store = useStore();
    const currentAccountLifespan = ref(365); // Default lifespan

    const fetchMonetaryParameters = async () => {
      const orgData = await store.dispatch("fetchOrgData", props.orgUID);
      if (
        orgData &&
        orgData.monetary_parameters &&
        orgData.monetary_parameters.currentAccount &&
        orgData.monetary_parameters.currentAccount.lifespan
      ) {
        currentAccountLifespan.value =
          orgData.monetary_parameters.currentAccount.lifespan;
      }
    };

    onMounted(() => {
      fetchMonetaryParameters();
    });

    const createCoinInWallet = async () => {
      if (!props.orgUID) {
        console.error("Organization UID is missing.");
        toast.error("Organization UID is missing.");
        return;
      }

      try {
        await store.dispatch("coinCreationActions/createCoin", {
          orgUID: props.orgUID,
          currentAccountLifespan: currentAccountLifespan.value,
        });
        // store.dispatch("updateBuyerOrgHasCoins", true);

        // toast.success("🤑 Coin created successfully in wallet.");
        emit("coinCreated");
      } catch (error) {
        console.error("Error creating coin in wallet:", error);
        toast.error("Error creating coin in wallet.");
      }
    };

    return {
      createCoinInWallet,
    };
  },
};
</script>

<style scoped>
.compactButton {
  /* Button styling */
}
</style>
