<template>
  <div ref="cropContainer" class="crop-container">
    <img ref="image" :src="imageSrc" />
    <div class="row alignCentreRow divMarginTop editPanel">
      <div class="svgCont smaller" @click="cancelCrop">
        <svg class="smaller">
          <use href="@/assets/icons/iconset.svg#arrowleft"></use>
        </svg>
      </div>

      <div class="svgCont smaller" @click="rotateImage">
        <svg class="smaller">
          <use href="@/assets/icons/iconset.svg#rotate"></use>
        </svg>
      </div>
      <div class="svgCont smaller" @click="zoomOut">
        <svg class="smaller">
          <use href="@/assets/icons/iconset.svg#subtract"></use>
        </svg>
      </div>
      <div class="svgCont smaller" @click="zoomIn">
        <svg class="smaller">
          <use href="@/assets/icons/iconset.svg#addCross"></use>
        </svg>
      </div>
      <button @click="applyCrop" :disabled="isProcessing">
        {{ isProcessing ? "Processing..." : "Apply" }}
      </button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import { useStore } from "vuex";

export default {
  name: "ImageCrop",
  props: ["imageSrc", "currentIndex"],
  setup(props, { emit }) {
    const store = useStore();
    const image = ref(null);
    let cropper;
    const isProcessing = ref(false);

    watch(
      () => props.imageSrc,
      (newSrc) => {
        if (cropper) {
          cropper.destroy();
        }
        initializeCropper();
      }
    );

    onMounted(() => {
      if (props.imageSrc) {
        initializeCropper();
      }
    });

    const initializeCropper = (options = {}) => {
      cropper = new Cropper(image.value, {
        viewMode: 0,
        zoomable: true,
        autoCropArea: 1,
        aspectRatio: NaN,
        cropBoxResizable: true,
        ...options,
      });
    };

    const adjustZoomOnRotate = () => {
      const containerData = cropper.getContainerData();
      const imageData = cropper.getImageData();

      if (!containerData || !imageData) {
        console.error("Container data or image data is undefined.");
        return;
      }

      let newZoomRatio;
      if (imageData.rotate % 180 === 0) {
        newZoomRatio = containerData.width / imageData.naturalWidth;
      } else {
        newZoomRatio = containerData.height / imageData.naturalWidth;
      }

      cropper.zoomTo(newZoomRatio);

      const newCanvasLeft =
        (containerData.width - imageData.naturalWidth * newZoomRatio) / 2;
      const newCanvasTop =
        (containerData.height - imageData.naturalWidth * newZoomRatio) / 2;
    };

    const rotateImage = () => {
      if (cropper) {
        cropper.rotate(90);
        adjustZoomOnRotate();
      }
    };

    const applyCrop = () => {
      isProcessing.value = true;
      document.body.classList.remove("no-scroll");

      const croppedCanvas = cropper.getCroppedCanvas();

      const maxWidth = 1920;
      const maxHeight = 1920;

      let width = croppedCanvas.width;
      let height = croppedCanvas.height;

      if (width > maxWidth) {
        height *= maxWidth / width;
        width = maxWidth;
      }

      if (height > maxHeight) {
        width *= maxHeight / height;
        height = maxHeight;
      }

      // Create a canvas with the new dimensions
      const resizedCanvas = document.createElement("canvas");
      resizedCanvas.width = width;
      resizedCanvas.height = height;

      const ctx = resizedCanvas.getContext("2d");
      ctx.drawImage(croppedCanvas, 0, 0, width, height);

      resizedCanvas.toBlob((blob) => {
        const fileName = `cropped-image-${props.currentIndex}.jpg`;
        const croppedFile = new File([blob], fileName, { type: "image/jpeg" });
        const imageUrl = URL.createObjectURL(croppedFile);

        emit(
          "updateImage",
          { file: croppedFile, url: imageUrl },
          props.currentIndex
        );
        emit("closeOverlay");
        isProcessing.value = false;
      }, "image/jpeg");
    };

    const cancelCrop = () => {
      document.body.classList.remove("no-scroll");
      store.dispatch("toggleNavbarVisibility", true);
      emit("cancelCrop");
    };

    const zoomIn = () => {
      if (cropper) {
        cropper.zoom(0.1);
      }
    };

    const zoomOut = () => {
      if (cropper) {
        cropper.zoom(-0.1);
      }
    };

    return {
      image,
      applyCrop,
      cancelCrop,
      rotateImage,
      zoomIn,
      zoomOut,
      isProcessing,
    };
  },
};
</script>

<style scoped>
.crop-container {
  width: 100%;
  height: 65%;
}

.crop-container img {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: 0 auto;
}

.editPanel {
  display: flex;
  gap: var(--bigMargin);
}

.svgCont {
  display: flex;
  align-items: center;
  width: var(--smallClickableHeight);
  height: var(--clickableHeight);
}
</style>
