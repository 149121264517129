<template>
  <div class="UVP_Intro column alignItemsCentre">
    <!-- <section>
      <UVPIntroCoreMessage />
      <div class="separator divBigMarginTop"></div>
      <div class="column alignItemsCentre">
        <img
          v-if="windowWidth > 450"
          class="svgFullWidth noMultiply"
          :src="largeOldBankingSvgSrc"
          alt="oldbankingways SVG"
        />
        <img
          v-else
          class="svgFullWidth noMultiply"
          :src="smallOldBankingSvgSrc"
          alt="oldbankingways SVG"
        />
        <div class="column alignCentreRow">
          <p class="shortwidth nomarginbottomsmallmargintop">
            The monetary system as we know it (Fractional Reserve Banking) was a
            game-changer back in the 17th century.
          </p>
          <p class="shortwidth smallmargintop">
            While it fueled economic growth then, it falls short in today's
            interconnected world. Here are some of the drawbacks:
          </p>
          <ul class="shortwidth smallmargintop">
            <li class="shortwidth">
              <strong>Top-down rigidity:</strong> Limited agility in economic
              stimulation, marked by ineffective trickle-down mechanisms that
              struggle to address micro and macro-level challenges.
            </li>
            <li class="shortwidth">
              <strong>Single financial architecture:</strong> Relies on blunt
              instruments like centralised monetary issuance, interest rate
              adjustments, and flawed economic metrics.
            </li>
            <li class="shortwidth">
              <strong>Unsustainable burden:</strong> Perpetuates an endless
              cycle of debt while exacerbating environmental, social, and
              governance issues.
            </li>
          </ul>
        </div>
      </div>
    </section> -->

    <!-- FOR ALL SCALES -->
    <!-- <div class="separator"></div> -->
    <section>
      <h2 class="shortwidth alignCentre smallmarginbottom">
        Your size, your money, your governance
      </h2>
      <div class="row alignCentreRow spacebetween iconRow iconRow">
        <div class="column alignCentre iconColumn">
          <label class="fixedLabel">Friends</label>
          <svg class="small">
            <use href="../../assets/icons/iconset.svg#friends"></use>
          </svg>
        </div>

        <div class="column alignCentre iconColumn">
          <label class="fixedLabel">Groups</label>
          <svg class="small">
            <use href="../../assets/icons/iconset.svg#groups"></use>
          </svg>
        </div>
        <div class="column alignCentre iconColumn">
          <label class="fixedLabel">Organisations</label>
          <svg class="small">
            <use href="../../assets/icons/iconset.svg#organisation_flat"></use>
          </svg>
        </div>

        <div class="column alignCentre iconColumn">
          <label class="fixedLabel">Nations</label>
          <svg class="small">
            <use href="../../assets/icons/iconset.svg#nation"></use>
          </svg>
        </div>
      </div>
      <p class="shortwidth smallmargintop">
        Within Unit, whether you're a group or an individual, everyone has an
        easy way to manage their monetary system. Whether you're trading with
        others, sharing household responsibilities, or working within a larger
        organisation, Unit grows with you.
      </p>
    </section>
    <!-- FOR ALL TYPES OF ORGANISATIONS -->
    <div class="separator"></div>
    <section>
      <h2 class="shortwidth alignCentre smallmarginbottom">
        For all types of organisations
      </h2>
      <div class="row alignCentreRow noGap iconRow justifyToCentre">
        <div class="column alignCentre iconColumn">
          <label class="fixedLabel">Hierachical</label>
          <img
            class="StructureSvg noMultiply"
            :src="hierarchicalSvgSrc"
            alt="Hierarchical SVG"
          />
        </div>
        <div class="column alignCentre iconColumn">
          <label class="fixedLabel">Flat</label>
          <img
            class="StructureSvg noMultiply"
            :src="flatSvgSrc"
            alt="Flat SVG"
          />
        </div>
      </div>
      <!-- Types org row 1 -->
      <div class="row alignCentreRow spacebetween iconRow">
        <div class="column alignCentre iconColumn">
          <label class="fixedLabel">Educational</label>
          <svg class="small">
            <use href="../../assets/icons/iconset.svg#education"></use>
          </svg>
        </div>
        <div class="column alignCentre iconColumn">
          <label class="fixedLabel">Commercial</label>
          <svg class="small">
            <use href="../../assets/icons/iconset.svg#shop"></use>
          </svg>
        </div>
        <div class="column alignCentre iconColumn">
          <label class="fixedLabel">Public sector</label>
          <svg class="small">
            <use href="../../assets/icons/iconset.svg#publicsector"></use>
          </svg>
        </div>
        <div class="column alignCentre iconColumn">
          <label class="fixedLabel">Private sector</label>
          <svg class="small">
            <use href="../../assets/icons/iconset.svg#privatesector"></use>
          </svg>
        </div>
      </div>
      <!-- Types org row 2 -->
      <div class="row alignCentreRow spacebetween iconRow">
        <div class="column alignCentre iconColumn">
          <label class="fixedLabel">Nonprofit</label>
          <svg class="small">
            <use href="../../assets/icons/iconset.svg#care"></use>
          </svg>
        </div>
        <div class="column alignCentre iconColumn">
          <label class="fixedLabel">Corporative</label>
          <svg class="small">
            <use href="../../assets/icons/iconset.svg#corp"></use>
          </svg>
        </div>
        <div class="column alignCentre iconColumn">
          <label class="fixedLabel">Self-sustaining</label>
          <svg class="small">
            <use href="../../assets/icons/iconset.svg#selfsustainable"></use>
          </svg>
        </div>
        <div class="column alignCentre iconColumn">
          <label class="fixedLabel">Sport clubs</label>
          <svg class="small">
            <use href="../../assets/icons/iconset.svg#football"></use>
          </svg>
        </div>
      </div>
      <!-- Types org row 3 -->
      <div class="row alignCentreRow spacebetween iconRow">
        <div class="column alignCentre iconColumn">
          <label class="fixedLabel">Time banks</label>
          <svg class="small">
            <use href="../../assets/icons/iconset.svg#hour_bank"></use>
          </svg>
        </div>
        <div class="column alignCentre iconColumn">
          <label class="fixedLabel">VouchersSys</label>
          <svg class="small">
            <use href="../../assets/icons/iconset.svg#voucher_systems"></use>
          </svg>
        </div>
        <div class="column alignCentre iconColumn">
          <label class="fixedLabel">Networks</label>
          <svg class="small">
            <use href="../../assets/icons/iconset.svg#creative_network"></use>
          </svg>
        </div>
        <div class="column alignCentre iconColumn">
          <label class="fixedLabel">Startups</label>
          <svg class="small">
            <use href="../../assets/icons/iconset.svg#startup"></use>
          </svg>
        </div>
      </div>
      <p class="shortwidth smallmargintop">
        Unit offers flexible setup options for different needs. Whether the
        organisational culture leans towards accountability or favours
        individual empowerment, Unit can adapt. It's versatile enough to reward
        teens for their accomplishments and tasks, establish a currency system
        for organisations, or create an accounting framework for a network of
        stakeholders. From affinity groups and cohorts to clubs, societies, and
        guilds—wherever there's a shared aspiration or challenge, Unit can be
        configured to meet those needs.
      </p>
    </section>
    <!-- EXCHANGE RATE -->
    <div class="separator"></div>
    <section>
      <h2 class="shortwidth alignCentre smallmarginbottom">
        Exchange with other organisations using Unit
      </h2>
      <div class="row alignCentreRow spacebetween iconRow">
        <img
          class="svgBig noMultiply"
          :src="tradingSvgSrc"
          alt="People exchanging with Unit"
        />
      </div>
      <p class="shortwidth nomargintop">
        Unit helps different groups trade with each other by using a special way
        to set exchange rates, making sure it's fair for everyone. It checks how
        many Units everyone has when trading to keep things balanced. This
        special way makes Unit the only money system that values every person's
        worth, pushing for a fairer way of dealing with money.
      </p>
      <ToggleButtonContent
        class="currentMonetarySystem"
        :showButtonText="`Know more about exchange rate`"
        :hideButtonText="'Hide exchange rate explanation'"
      >
        <ExchangeRate />
      </ToggleButtonContent>
    </section>
    <!-- EVERYONE'S PERSPECTIVE WELCOME -->
    <div class="separator"></div>

    <section>
      <h2 class="smallmarginbottom">Everyone's perspective welcome</h2>

      <!-- Icons beliefs row 1 -->
      <div class="row alignCentreRow spacebetween iconRow">
        <div class="column alignCentre iconColumn">
          <label class="fixedLabel">Anarchism</label>
          <svg class="small">
            <use href="../../assets/icons/iconset.svg#anarchy"></use>
          </svg>
        </div>
        <div class="column alignCentre iconColumn">
          <label class="fixedLabel">Environmentalism</label>
          <svg class="small">
            <use href="../../assets/icons/iconset.svg#environmentalism"></use>
          </svg>
        </div>
        <div class="column alignCentre iconColumn">
          <label class="fixedLabel">Atheism</label>
          <svg class="small">
            <use href="../../assets/icons/iconset.svg#atheism"></use>
          </svg>
        </div>
        <div class="column alignCentre iconColumn">
          <label class="fixedLabel">Islam</label>
          <svg class="small">
            <use href="../../assets/icons/iconset.svg#islam"></use>
          </svg>
        </div>
      </div>
      <!-- Icons beliefs row 2 -->
      <div class="row alignCentreRow spacebetween iconRow">
        <div class="column alignCentre iconColumn">
          <label class="fixedLabel">Judaism</label>
          <svg class="small">
            <use href="../../assets/icons/iconset.svg#judaism"></use>
          </svg>
        </div>
        <div class="column alignCentre iconColumn">
          <label class="fixedLabel">Liberalism</label>
          <svg class="small">
            <use href="../../assets/icons/iconset.svg#capitalism_1"></use>
          </svg>
        </div>
        <div class="column alignCentre iconColumn">
          <label class="fixedLabel">Hinduism</label>
          <svg class="small">
            <use href="../../assets/icons/iconset.svg#hinduism"></use>
          </svg>
        </div>
        <div class="column alignCentre iconColumn">
          <label class="fixedLabel">Socialism</label>
          <svg class="small">
            <use href="../../assets/icons/iconset.svg#socialism"></use>
          </svg>
        </div>
      </div>
      <!-- Icons beliefs row 3 -->
      <div class="row alignCentreRow spacebetween iconRow">
        <div class="column alignCentre iconColumn">
          <label class="fixedLabel">Communism</label>
          <svg class="small">
            <use href="../../assets/icons/iconset.svg#communism"></use>
          </svg>
        </div>
        <div class="column alignCentre iconColumn">
          <label class="fixedLabel">Pacifism</label>
          <svg class="small">
            <use href="../../assets/icons/iconset.svg#peace"></use>
          </svg>
        </div>
        <div class="column alignCentre iconColumn">
          <label class="fixedLabel">Christianism</label>
          <svg class="small">
            <use href="../../assets/icons/iconset.svg#christianism"></use>
          </svg>
        </div>
        <div class="column alignCentre iconColumn">
          <label class="fixedLabel">Buddhism</label>
          <svg class="small">
            <use href="../../assets/icons/iconset.svg#buddhism"></use>
          </svg>
        </div>
      </div>
      <p class="shortwidth smallmargintop">
        Unit was designed to embrace every shade of conviction. Whether your
        community wants to champion the harmonisation of shared resources,
        caring for our Earth, celebrating the dynamism of free markets, or the
        spirit of boundless autonomy, Unit is here to help you craft your path.
      </p>
    </section>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import Tooltip from "@/components/GenericComponents/Tooltip.vue";
import UVPIntroCoreMessage from "@/components/LandingComponents/UVPIntroCoreMessage.vue";

import ToggleButtonContent from "@/components/GenericComponents/ToggleButtonContent.vue";

import EditMonetaryParameters from "@/components/OrgComponents/OrgCustom/EditMonetaryParameters.vue";
import EditCoinName from "@/components/OrgComponents/OrgCustom/EditCoinName.vue";
import ExchangeRate from "@/components/LandingComponents/ExchangeRate.vue";

export default {
  name: "UVPIntro",
  components: {
    Tooltip,
    UVPIntroCoreMessage,
    ToggleButtonContent,
    EditMonetaryParameters,
    EditCoinName,
    ExchangeRate,
  },
  props: {},

  setup() {
    const store = useStore();
    const router = useRouter(); // Using the Vue Router
    const windowWidth = ref(window.innerWidth);
    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    const isDarkMode = computed(() => store.getters["UIState/isDarkMode"]);

    const largeOldBankingSvgSrc = computed(() =>
      isDarkMode.value
        ? require("@/assets/icons/oldbankingways_fullwidth_white.svg")
        : require("@/assets/icons/oldbankingways_fullwidth.svg")
    );
    const smallOldBankingSvgSrc = computed(() =>
      isDarkMode.value
        ? require("@/assets/icons/oldbankingways_fullwidth_mobile_white.svg")
        : require("@/assets/icons/oldbankingways_fullwidth_mobile.svg")
    );

    const hierarchicalSvgSrc = computed(() =>
      isDarkMode.value
        ? require("@/assets/icons/hierarchical_ios_css_white.svg")
        : require("@/assets/icons/hierarchical_ios_css.svg")
    );

    const flatSvgSrc = computed(() =>
      isDarkMode.value
        ? require("@/assets/icons/flat_iOS_css_white.svg")
        : require("@/assets/icons/flat_iOS_css.svg")
    );

    const tradingSvgSrc = computed(() =>
      isDarkMode.value
        ? require("@/assets/icons/trading_css_white.svg")
        : require("@/assets/icons/trading_css.svg")
    );

    const tooltipMessageMoneySupply =
      "Bonds are like IOU notes from the government. If a central bank or you buy bonds, it means the government owes you money. They'll pay you back on a certain date, but until then, they'll give you a little extra money (called interest or yeld) now and then as a thank you for giving them money. The actual money supply can increase beyond this 'original' amount using fractional reserve banking mechanics, where commercial banks lend out a fraction of the deposits they hold, effectively creating new money.";

    const tooltipMessageInterest =
      "To understand what interest is, imagine you lend a big toy to a friend. In return, they give you an extra smaller toy every week until they return the toy. That extra toy is like a thank you, called 'interest', when people borrow money from banks.";

    // Define the functions to redirect
    const goToAbout = () => {
      router.push({ name: "about" }); // Assuming the route name for your exchange rate page is 'exchangerate'
    };
    const goToExchangeRate = () => {
      router.push({ name: "exchangerate" }); // Assuming the route name for your exchange rate page is 'exchangerate'
    };

    // modals logic
    const showLoginModal = computed(() => store.state.showLoginModal);
    const showRegisterModal = computed(() => store.state.showRegisterModal);

    const toggleLoginModal = () => store.dispatch("toggleLoginModal");
    const toggleRegisterModal = () => store.dispatch("toggleRegisterModal");

    onMounted(() => {
      window.addEventListener("resize", handleResize);
      if (window.MathJax) {
        window.MathJax.typeset();
      } else {
        console.log("No formula library");
      }
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    return {
      windowWidth,
      goToAbout,
      goToExchangeRate,
      tooltipMessageMoneySupply,
      tooltipMessageInterest,
      toggleRegisterModal,
      largeOldBankingSvgSrc,
      smallOldBankingSvgSrc,
      hierarchicalSvgSrc,
      flatSvgSrc,
      tradingSvgSrc,
    };
  },
};
</script>

<style scoped>
.UVP_Intro {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.StructureSvg {
  width: 75%;
}

.svgComp {
  width: 70%;
}

.spacebetween {
  width: 100%;
  justify-content: space-around;
}

.singleColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AnimationSVG {
  width: 40%;
  mix-blend-mode: multiply;
}

.fixedLabel {
  min-width: 50px;
  max-width: 50px;
  text-align: center;
  flex-shrink: 0;
  flex-grow: 0;
  white-space: nowrap; /* prevent the text from wrapping */
}

.row.alignCentre {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: var(--margin);
}
.small {
  width: var(--clickableHeight);
  height: var(--clickableHeight);
}

ul {
  padding-inline-start: 0;
}

ul li {
  margin-bottom: var(--margin);
}
@media only screen and (max-width: 450px) {
  .StructureSvg {
    width: 100%;
  }
  .AnimationSVG {
    width: 50%;
  }
  ul {
    padding-inline-start: var(--margin);
  }
}
</style>
