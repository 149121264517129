<template>
  <div class="exchange-rate" v-if="isUserLoggedIn">
    <div
      v-if="
        isRateAvailable && !buyerOrgDetailsMissing && !sellerOrgDetailsMissing
      "
    >
      Exchange rate: {{ exchangeRate.toFixed(2) }}
    </div>

    <div v-else-if="buyerOrgDetailsMissing">
      <!-- <button class="compactButton" @click="createCoin">Create coin</button> -->
      <CreateOneCoinInUserWallet
        :orgUID="currentUserOrgUID"
        :newMoneyPack="{ value: 100 /* Placeholder value */ }"
      />
    </div>
    <div v-else-if="sellerOrgDetailsMissing">
      Viewed organisation details are missing or have total coins of 0.
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { getAuth } from "firebase/auth";
import { useStore } from "vuex";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "@/firebase";
import CreateOneCoinInUserWallet from "@/components/GenericComponents/CreateOneCoinInUserWallet.vue";

export default {
  name: "ExchangeRate",
  components: {
    CreateOneCoinInUserWallet,
  },
  props: {
    viewedOrgUID: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const auth = getAuth();
    const currentUserOrgUID = ref(null);
    const isRateAvailable = ref(false);
    const exchangeRate = ref(0);
    const buyerOrgDetailsMissing = ref(false);
    const sellerOrgDetailsMissing = ref(false);
    const isUserLoggedIn = ref(!!auth.currentUser); // Check if the user is logged in

    const fetchUserOrgUID = async () => {
      const user = auth.currentUser;

      if (user) {
        const userUID = user.uid;
        const walletsRef = collection(db, "users", userUID, "Wallets");
        const q = query(walletsRef, where("isActiveMember", "==", true));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          currentUserOrgUID.value = querySnapshot.docs[0].id; // Assuming the first wallet is the primary one
          console.log("Fetched Current User Org UID:", currentUserOrgUID.value);
          fetchOrgDetails(currentUserOrgUID.value); // Fetch details for the current user's organization
          checkMonetaryParameterPrivileges(currentUserOrgUID.value); // Check monetary parameter privileges
        } else {
          console.log("No active wallet found for the current user.");
        }
      } else {
        console.log("No user is currently signed in.");
      }
    };

    const checkMonetaryParameterPrivileges = async (orgUID) => {
      const user = auth.currentUser;

      if (!user || !orgUID) return;

      const userUID = user.uid;
      const rolesRef = collection(db, "custom-orgs", orgUID, "Roles");
      const q = query(
        rolesRef,
        where("filledBy." + userUID + ".public", "==", true)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          const roleData = doc.data();
          if (roleData.privileges && roleData.privileges.monetaryParameters) {
            // User has monetary parameter privileges
            console.log("User has monetary parameter privileges");
            // Allow user to see "Create coin" button
          } else {
            // User does not have monetary parameter privileges
            console.log("User does not have monetary parameter privileges");
            buyerOrgDetailsMissing.value = true; // Display message instead of "Create coin" button
          }
        });
      } else {
        console.log("User role not found in the organization.");
      }
    };

    const fetchOrgDetails = async (orgUID) => {
      if (!orgUID) {
        console.log(
          `Org UID for ${
            orgUID === currentUserOrgUID.value ? "current user" : "viewed org"
          } is missing, cannot fetch details.`
        );
        return;
      }
      await store.dispatch("fetchAndCommitOrgMembersCount", orgUID);
      await store.dispatch("fetchAndCommitOrgMembersCount", {
        orgUID,
        context: "buyer",
      });
    };

    const computeExchangeRate = () => {
      const buyerOrgDetails = store.getters.getBuyerOrgDetails(
        currentUserOrgUID.value
      );
      const sellerOrgDetails = store.getters.getSellerOrgDetails(
        props.viewedOrgUID
      );

      console.log("Current user Org Details:", buyerOrgDetails);
      console.log("Viewed Org Details:", sellerOrgDetails);

      if (
        buyerOrgDetails &&
        sellerOrgDetails &&
        buyerOrgDetails.membersCount &&
        sellerOrgDetails.membersCount &&
        buyerOrgDetails.totalOrgCoins !== 0 && // Check if buyer's total coins is not 0
        sellerOrgDetails.totalOrgCoins !== 0 // Check if seller's total coins is not 0
      ) {
        const buyerPerCapita =
          buyerOrgDetails.totalOrgCoins / buyerOrgDetails.membersCount;
        const sellerPerCapita =
          sellerOrgDetails.totalOrgCoins / sellerOrgDetails.membersCount;

        exchangeRate.value = buyerPerCapita / sellerPerCapita; // Calculate the exchange rate directly
        isRateAvailable.value = true;
      } else {
        if (!buyerOrgDetails || buyerOrgDetails.totalOrgCoins === 0) {
          console.log(
            "Current user Org details are missing or have total coins of 0."
          );
          buyerOrgDetailsMissing.value = true;
        } else {
          buyerOrgDetailsMissing.value = false;
        }
        if (!sellerOrgDetails || sellerOrgDetails.totalOrgCoins === 0) {
          console.log(
            "Viewed Org details are missing or have total coins of 0."
          );
          sellerOrgDetailsMissing.value = true;
        } else {
          sellerOrgDetailsMissing.value = false;
        }
        isRateAvailable.value = false;
      }
    };

    onMounted(() => {
      if (isUserLoggedIn.value) {
        fetchUserOrgUID().then(() => {
          fetchOrgDetails(props.viewedOrgUID).then(() => {
            computeExchangeRate();
          });
        });
      }
    });

    watch(
      () => props.viewedOrgUID,
      async (newVal) => {
        if (newVal) {
          await fetchOrgDetails(newVal);
          computeExchangeRate();
        }
      },
      { immediate: true }
    );

    return {
      currentUserOrgUID,
      isRateAvailable,
      exchangeRate,
      buyerOrgDetailsMissing,
      sellerOrgDetailsMissing,
      isUserLoggedIn,
    };
  },
};
</script>

<style scoped>
.exchange-rate {
  /* Styles for the exchange rate component */
}
</style>
