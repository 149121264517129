<template>
  <div
    class="thumbnailContainer"
    :class="{ wishlist: wishlist }"
    @click="handleClick"
  >
    <div
      class="arrow leftArrow"
      v-if="!wishlist && reorderedImages.length > 1"
      @click.stop="changeImage(-1)"
      v-haptic
    >
      <svg class="compactImg transparent">
        <use href="@/assets/icons/iconset.svg#arrowleft_carousel_smaller"></use>
      </svg>
    </div>
    <img
      v-if="reorderedImages.length > 0"
      class="productThumbnail"
      :src="reorderedImages[currentImageIndex]"
      :alt="product.itemName"
    />
    <div v-else class="placeholderImage">
      <p>No image available</p>
    </div>
    <div
      class="arrow rightArrow svgButton"
      v-if="!wishlist && reorderedImages.length > 1"
      @click.stop="changeImage(1)"
      v-haptic
    >
      <svg class="compactImg transparent">
        <use
          href="@/assets/icons/iconset.svg#arrowright_carousel_smaller"
        ></use>
      </svg>
    </div>
    <!-- Image Indicator Circles -->
    <div
      class="image-indicators"
      v-if="!wishlist && reorderedImages.length > 1"
    >
      <div
        v-for="(image, index) in limitedImages"
        :key="image"
        :class="['circleInd', { active: currentImageIndex === start + index }]"
        :style="{ transform: getCircleTransform(index) }"
      ></div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "ThumbnailCarousel",
  props: {
    product: {
      type: Object,
      required: true,
    },
    wishlist: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const currentImageIndex = ref(0);
    const maxCircles = 6; // maximum number of circles to display
    const start = ref(0); // Initialize start

    const reorderedImages = computed(() => {
      const images = [...props.product.images];
      const thumbnailIndex = props.product.thumbnailIndex;

      if (thumbnailIndex >= 0 && thumbnailIndex < images.length) {
        const thumbnailImage = images.splice(thumbnailIndex, 1)[0];
        images.unshift(thumbnailImage); // Move the thumbnail to the first position
      }

      return images;
    });

    const changeImage = (direction) => {
      currentImageIndex.value =
        (currentImageIndex.value + direction + reorderedImages.value.length) %
        reorderedImages.value.length;
    };

    const handleClick = (event) => {
      router.push({ name: "ProductView", params: { id: props.product.id } });
      store.dispatch("changeActiveTab", null); // dispatch action to change active tab in Vuex store
    };

    const limitedImages = computed(() => {
      let totalImages = reorderedImages.value.length;
      if (totalImages <= maxCircles) {
        // If fewer images than maxCircles, simply return all images
        start.value = 0;
        return reorderedImages.value;
      } else {
        const half = Math.floor(maxCircles / 2);
        start.value = currentImageIndex.value - half; // Update start
        let end = currentImageIndex.value + half;

        // Adjust start and end if they go out of bounds
        if (start.value < 0) {
          end -= start.value;
          start.value = 0;
        }
        if (end >= totalImages) {
          start.value -= end - totalImages + 1;
          end = totalImages - 1;
        }

        return reorderedImages.value.slice(start.value, end + 1);
      }
    });

    const getCircleTransform = (index) => {
      // Calculate the transform based on the index
      return `translateX(${index * 0}px)`;
    };

    return {
      currentImageIndex,
      changeImage,
      handleClick,
      limitedImages,
      reorderedImages,
      start,
      getCircleTransform,
    };
  },
};
</script>

<style scoped>
.thumbnailContainer {
  position: relative;
  width: 100%;
  display: flex;
  height: 16vh;
  overflow: hidden;
  border-radius: var(--borderRadius);
  border: 1px solid var(--solidMidLightgrey);
}

.thumbnailContainer.wishlist {
  height: 14vh;
}

.productThumbnail {
  width: 100%;
  object-fit: cover;
  border-radius: var(--borderRadius);
  border: 1px solid var(--solidLighterGrey);
  box-shadow: var(--stickerRidge);
}

.thumbnailContainer .arrow {
  position: absolute;
  opacity: 0.8;
  transition: all 0.15s;
  height: 100%;
  display: flex;
  align-items: flex-end;
  bottom: 4px;
}

.thumbnailContainer .arrow svg {
  filter: drop-shadow(0px 0px 0.7px rgb(255 255 255)) !important;
}

.thumbnailContainer .arrow:hover {
  transform: translateY(var(--transY));
}

.thumbnailContainer .arrow.rightArrow {
  cursor: e-resize;
  right: 0;
}
.thumbnailContainer .arrow.leftArrow {
  cursor: w-resize;
  left: 0;
}

.arrow {
  mix-blend-mode: difference;
}

.image-indicators {
  position: absolute;
  bottom: 9px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: var(--microMargin);
}

.circleInd {
  width: 7px;
  height: 7px;
  background-color: var(--backgroundLighterGrayTransp);
  border-radius: 50%;
  opacity: 1;
  transition: all var(--slideInSpeed) ease-in;
  top: 0;
  position: relative;
  border: 1px solid var(--black);
}

.circleInd.active {
  width: 9px;
  height: 9px;
  opacity: 1;
}

.compactImg {
  text-shadow: 1px 1px 1px #000, 3px 3px 5px blue;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all var(--slideInSpeed) ease-in-out;
}
.slide-fade-enter, .slide-fade-leave-to /* leave-active in <2.1.8 */ {
  transform: translateX(5px);
  opacity: 0 !important;
  top: 0 !important;
}

@media (max-width: 590px) {
  .thumbnailContainer {
    height: 19vh;
  }
  .thumbnailContainer.wishlist {
    height: 10vh;
  }
}
</style>
