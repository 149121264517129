<template>
  <div class="badge">
    <small class="alignCentre nomargintopbottom"> {{ message }}</small>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Badge",
  props: {
    message: {
      type: String,
      default: "badge text",
    },
  },
  setup(props, { emit }) {
    return {};
  },
};
</script>

<style scoped>
/* SMALL SCREENS */
@media only screen and (max-width: 450px) {
}
</style>
