<template>
  <div class="card register" @show-register="handleErrorFromLogin">
    <form @submit.prevent="register">
      <button type="button" @click="signInWithGoogle" v-haptic>
        <div class="view compactImg">
          <img src="../../assets/google_large.png" alt="Google" />
        </div>
        {{ $t("buttons.continueWithGoogle") }}
      </button>
      <p class="or">or</p>
      <div class="form-group email">
        <label for="email"> {{ $t("labels.email") }}</label>
        <input
          type="email"
          name="email"
          autocomplete="email"
          v-model="email"
          required
        />
      </div>

      <div class="form-group password">
        <label for="password">{{ $t("labels.password") }}</label>
        <div class="row">
          <div class="row-child">
            <input
              id="password"
              v-model="password"
              autocomplete="current-password"
              :type="showPassword ? 'text' : 'password'"
            />

            <button
              title="Show/Hide password"
              type="button"
              class="show-password-button inputIcon"
              v-haptic
            >
              <svg
                v-if="!showPassword"
                class="verySmall"
                @click="showPassword = !showPassword"
                v-haptic
              >
                <use href="../../assets/icons/iconset.svg#hide"></use>
              </svg>
              <svg
                v-else
                class="verySmall"
                @click="showPassword = !showPassword"
                v-haptic
              >
                <use href="../../assets/icons/iconset.svg#view"></use>
              </svg>
            </button>
          </div>
          <button
            type="submit"
            @click="registerWithEmail"
            v-haptic
            :disabled="isRegistering"
          >
            {{ isRegistering ? "Registering..." : $t("buttons.register") }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  sendEmailVerification,
} from "firebase/auth";

import { getDatabase, ref as firebaseRef, set } from "firebase/database";

import {
  getFirestore,
  collection,
  setDoc,
  doc,
  getDocs,
  query,
  where,
} from "firebase/firestore";

import { db } from "@/firebase";
import store from "@/store"; // Import your Vuex store instance
import router from "@/router"; // Import your router instance
import { useI18n } from "vue-i18n";

import { toast } from "vue3-toastify";
import { WalletSchema, CoinSchema } from "@/schemas/WalletSchema";

export default {
  name: "Register",
  components: {},
  props: {
    redirect: {
      type: Boolean,
      default: true,
    },
    email: {
      type: String,
      default: "", // Default empty, but passed from the login component
    },
  },
  setup(props, { emit }) {
    const email = ref(props.email);
    const password = ref("");
    const error = ref(null);
    const success = ref(null);
    const showPassword = ref(false);
    const showLoginModal = computed(() => store.getters.showLoginModal);
    const showRegisterModal = computed(() => store.state.showRegisterModal);
    const showLoginModalNoRedirect = computed(
      () => store.getters.showLoginModalNoRedirect
    );
    const showRegisterModalNoRedirect = computed(
      () => store.state.showRegisterModalNoRedirect
    );
    const { t } = useI18n();
    const isRegistering = ref(false);

    const registerWithEmail = async () => {
      isRegistering.value = true;
      error.value = null;
      success.value = null;
      const auth = getAuth();
      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email.value,
          password.value
        );
        const user = userCredential.user;
        // Send Email Verification
        sendEmailVerification(user)
          .then(() => {
            // Email verification sent!
            toast.success(
              `A verification email has been sent to ${email.value}`,
              {
                autoClose: 4000,
              }
            );
          })
          .catch((error) => {
            // Handle errors here.
            toast.error(`Error sending verification email: ${error}`, {
              autoClose: 4000,
            });
          });
        if (userCredential.operationType == "signIn") {
          await store.dispatch("logInStateCommit", {
            user: userCredential.user,
          });
          success.value = t("AuthMessages.signInSuccess");
        } else {
          success.value = t("AuthMessages.accountCreatedEmail");
        }
        handleAuthSuccess(success.value, props.redirect);

        await saveUserDataToFirestore(userCredential.user, props.redirect);
      } catch (err) {
        handleAuthError(err);
      } finally {
        isRegistering.value = false; // <-- Done registering
      }
    };

    const auth = getAuth();
    const isSignedIn = ref(!!auth.currentUser);

    onMounted(() => {
      auth.onAuthStateChanged((user) => {
        isSignedIn.value = !!user;
      });
    });

    const signInWithGoogle = async () => {
      error.value = null;
      success.value = null;
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      try {
        const userCredential = await signInWithPopup(auth, provider);
        console.log("signInWithGoogle");
        console.log(userCredential);
        if (userCredential.operationType == "signIn") {
          await store.dispatch("logInStateCommit", {
            user: userCredential.user,
          });
          success.value = t("AuthMessages.signInSuccess");
        } else {
          success.value = t("AuthMessages.accountCreatedGoogle");
        }
        await saveUserDataToFirestore(userCredential.user);

        handleAuthSuccess(success.value, props.redirect);
      } catch (err) {
        handleAuthError(err);
      }
    };

    const handleAuthSuccess = (successMessage, redirect) => {
      console.log("Redirect value: ", redirect);
      if (redirect) {
        store.dispatch("toggleRegisterModal", !showRegisterModal.value);
      } else {
        store.dispatch(
          "toggleRegisterModalNoRedirect",
          !showRegisterModalNoRedirect.value
        );
      }

      setTimeout(toastNote, 1000);
      function toastNote() {
        toast.success(successMessage, {
          autoClose: 2000,
        });
      }
    };
    const handleErrorFromLogin = (value) => {
      if (value) {
        handleAuthError({
          code: "userDoesntExist",
          message: t("AuthMessages.noAccount"),
        });
      }
    };

    const handleAuthError = (err) => {
      console.error(err.message);
      switch (err.code) {
        case "userExistErr":
          error.value = t("AuthMessages.accountExists");
          break;
        case "userDoesntExist":
          error.value = "You don't have an account yet. Please register first.";
          break;
        case "auth/invalid-email":
          error.value = t("AuthMessages.invalidEmail");
          break;
        case "auth/email-already-in-use":
          console.log("register redirect prop");
          console.log(props.redirect);
          if (props.redirect !== false) {
            store.dispatch("toggleLoginModal", !showLoginModal.value);
            store.dispatch("toggleRegisterModal", !showRegisterModal.value);
          } else {
            store.dispatch(
              "toggleLoginModalNoRedirect",
              !showLoginModalNoRedirect.value
            );
            store.dispatch(
              "toggleRegisterModalNoRedirect",
              !showRegisterModalNoRedirect.value
            );
          }

          error.value = t("AuthMessages.emailInUse");
          break;
        case "auth/weak-password":
          error.value = t("AuthMessages.weakPassword");
          break;
        case "auth/invalid-password":
          error.value = t("AuthMessages.weakPassword");
          break;
        case "auth/missing-password":
          error.value = t("AuthMessages.missingPassword");
          break;

        case "auth/network-request-failed":
          error.value = t("AuthMessages.networkError");
          break;
        case "auth/unauthorized-domain":
          error.value = t("AuthMessages.unauthorizedDomain");
          break;
        default:
          error.value = t("AuthMessages.unknownError");
          break;
      }
      // if user already exists don't show eeror message, just login
      if (err.code !== "userExistErr") {
        toast.error(error.value);
      }
    };

    const saveUserDataToFirestore = async (user) => {
      try {
        const usersCollection = collection(db, "users");
        const userQuerySnapshot = await getDocs(
          query(usersCollection, where("email", "==", user.email))
        );
        if (userQuerySnapshot.empty) {
          // User doesn't exist in Firestore, save user data
          const userDoc = doc(usersCollection, user.uid);
          const userData = {
            email: user.email,
            displayName: user.displayName,
            uid: user.uid,
            profile_picture: user.photoURL,
            emailVerified: user.emailVerified,
            isAnonymous: user.isAnonymous,
            created_at: user.metadata.createdAt,
            creationTime: user.metadata.creationTime,
            phoneNumber: user.phoneNumber,
            providerId: user.providerId,
          };

          // Save the user data to Firestore
          await setDoc(userDoc, userData);

          // Create initial wallet for the user under a specific organization
          // Create initial wallet for the user under a specific organization
          const orgId = "InitialWalletData"; // Replace with actual orgId if available

          // Use the imported WalletSchema for initial wallet data
          const initialWalletData = {
            ...WalletSchema,
            orgName: "InitialWalletData",
          }; // Replace with actual orgName if available
          const walletDoc = doc(userDoc, "Wallets", orgId);
          await setDoc(walletDoc, initialWalletData);
        } else {
          // User already exists in Firestore
          handleAuthError({
            code: "userExistErr",
            message: t("AuthMessages.accountExists"),
          });
          // Perform necessary actions, such as displaying an error message
        }
      } catch (error) {
        console.error("Error recording user data to Firestore:", error);
      }
    };

    return {
      email,
      password,
      error,
      success,
      registerWithEmail,
      signInWithGoogle,
      showPassword,
      handleErrorFromLogin,
      isRegistering,
    };
  },
};
</script>
<style scoped>
/* Hide the button */
.form-group button.show-password-button {
  position: absolute;
  width: 0px;
  padding: 0px;
  border: none;
  background: transparent;
  opacity: 0.3;
  transition: all 0.15s;
}

.form-group button.show-password-button:hover {
  opacity: 0.7;
  transform: translateY(var(--transY));
}

.form-group svg {
  position: absolute;
  top: 50%;
  right: var(--smallMargin);
  transform: translateY(calc(var(--smallMargin) / 2));
  cursor: pointer;
}
</style>
