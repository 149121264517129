<template>
  <div class="fullWidth divMarginBottom">
    <form @submit.prevent="save" id="customLoanDetailsForm">
      <div class="form-group">
        <ToggleSwitch
          v-model="loans.allowIndividual"
          label="Allow individual loans"
        />
        <!-- Collective Loans -->
        <ToggleSwitch v-model="loans.allowJoint" label="Allow joint loans" />
        <div class="separator"></div>
        <div v-show="loans.allowIndividual || loans.allowJoint" class="column">
          <div class="formblockCont">
            <div class="form-group">
              <div class="alignCentreRow">
                <label>
                  Annual interest rate %
                  <Tooltip
                    :message="'Annual interest in Unit is calculated on a monthly basis by dividing the annual rate by 12. For example, a 5% annual rate would mean a monthly rate of approximately 0.4167%. If you borrow 2000Ʉ, and commit to pay back 100Ʉ a month, the interest for the first month would be €8.33. As you repay 100Ʉ of the principal each month, the balance—and thus the interest—decreases for the following months. So, the next month\'s interest would be calculated on a €1900 balance, resulting in €7.92 in interest, and so on. This means the interest amount decreases over time as you keep repaying the principal.'"
                  />
                </label>
              </div>
              <StepCounter
                :min="-100"
                :max="100"
                :value="loans.annualInterest"
                :required="true"
                @update:value="(val) => (loans.annualInterest = val)"
              />
            </div>
          </div>
          <div class="row fullWidth alignToBottom">
            <div class="formblockCont">
              <div class="form-group">
                <div class="alignCentreRow">
                  <label>
                    Max. loan term (months)
                    <Tooltip
                      :message="'Loan term is the duration of time a person has to repay a loan.'"
                    />
                  </label>
                </div>
                <StepCounter
                  :min="1"
                  :max="480"
                  :value="loans.maxLoanTerm"
                  :required="true"
                  @update:value="(val) => (loans.maxLoanTerm = val)"
                />
              </div>
            </div>
            <div class="formblockCont">
              <div class="form-group">
                <div class="alignCentreRow">
                  <label>
                    Max. DTI %
                    <Tooltip
                      :message="'Debt-to-income ratio (DTI) is the percentage of monthly income that goes toward paying loans. It\'s crucial for gauging an applicant\'s ability to manage repayments. A high cap increases the risk of loan defaults, while a lower one limits the amount of users who can get a loan. Your cap setting is essential for controlling risk.'"
                    />
                  </label>
                </div>
                <StepCounter
                  :min="1"
                  :max="100"
                  :value="loans.dti_effortRate"
                  :required="true"
                  @update:value="(val) => (loans.dti_effortRate = val)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, reactive, watch } from "vue";
import ToggleSwitch from "@/components/GenericComponents/ToggleSwitch.vue";
import Tooltip from "@/components/GenericComponents/Tooltip.vue";
import StepCounter from "@/components/GenericComponents/StepCounter.vue";

export default {
  name: "CustomLoanDetailsForm",
  components: {
    ToggleSwitch,
    Tooltip,
    StepCounter,
  },
  emits: ["update:loanDeets"],
  setup(props, { emit }) {
    const loans = ref({
      dti_effortRate: 0,
      maxLoanTerm: 1,
      annualInterest: 0,
      allowIndividual: false,
      allowJoint: false,
    });

    const loanDeets = reactive({
      loans: loans.value,
    });

    watch(
      () => loans.value,
      (newVal) => {
        loanDeets.loans = newVal;
        emit("update:loanDeets", loanDeets);
      },
      { deep: true }
    );

    return {
      loans,
      loanDeets,
    };
  },
};
</script>

<style scoped>
.form-group {
  width: 100%;
}
.form-groupHalf {
  min-width: auto;
  width: 100%;
}

.formblockCont {
  width: 100%;
}

.formblock {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.avg.margin4px {
  margin-bottom: 4px;
}
.currencySymbol {
  position: absolute;
  transform: translateX(6px);
}

@media only screen and (max-width: 450px) {
  .form-group {
    min-width: auto;
  }
  .input {
    width: 100%;
  }
}
</style>
