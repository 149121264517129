<template>
  <div class="roles">
    <div class="innerSticker" :style="{ 'background-color': backgroundColor }">
      <div class="row card-header alignCentreRow">
        <div class="column">
          <h1 class="nomargintopbottom">Roles list</h1>
          <!-- Display the coin symbol -->
        </div>
        <div class="editing row" v-if="userRolePrivileges?.roleCreation">
          <button class="compactButton" @click="openOverlay" v-haptic>
            Create role
          </button>
        </div>
      </div>
      <div v-if="roles">
        <div class="separator_sticker noMarginBottom"></div>
        <div
          v-if="roles.length === 0"
          class="row alignCentreRow justifyToStartEnd gray"
        >
          <small class="">No roles created</small>
        </div>
        <div v-else>
          <div
            class="row alignCentreRow justifyToStartEnd gray divMarginBottom"
          >
            <small>Role title</small><small>Position filled</small>
          </div>

          <div v-for="role in roles" :key="role.roleUID" class="roleTable">
            <RoleRow
              :orgUID="orgUID"
              :role="role"
              :roleUID="role.roleUID"
              :privileges="role.privileges"
              :roleTitle="role.roleTitle"
            />
          </div>
        </div>
      </div>

      <Overlay
        v-if="showOverlay"
        :visible="showOverlay"
        :title="'Create role'"
        :smallTextAfterTitle="'You can change this later'"
        :showBackArrow="confirmationFromChild"
        @close="close"
        @previousStep="resetConfirmation"
      >
        <CreateRole
          :orgUID="orgUID"
          @created="handleCreatedSuccess"
          :confirmationFromParent="confirmationFromChild"
          @update-confirmation="updateConfirmation"
        />
      </Overlay>
    </div>
    <div class="separator"></div>
    <h1>The people in these roles</h1>
    <RoleScroll :roles="roles" :orgUID="orgUID" :coinSymbol="coinSymbol" />
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { doc, getDoc } from "firebase/firestore";
import { db } from "@/firebase"; // Import Firestore instance

import RoleRow from "@/components/RoleComponents/RoleRow.vue";
import Overlay from "@/components/GenericComponents/Overlay.vue";
import CreateRole from "@/components/RoleComponents/CreateRole.vue";
import RoleScroll from "@/components/RoleComponents/RoleScroll.vue";

export default {
  name: "OrgRoles",
  components: {
    RoleRow,
    Overlay,
    CreateRole,
    RoleScroll,
  },
  props: {
    roles: {
      type: Array,
      required: true,
    },
    userCredentials: {
      type: Object,
      required: true,
    },
    orgUID: {
      type: [String, Number],
      required: true,
    },
    backgroundColor: {
      type: String,
      default: "",
    },
    userRolePrivileges: {
      type: Object,
      required: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const showOverlay = ref(false);
    const confirmationFromChild = ref(false);
    const coinSymbol = ref(""); // To store the fetched coin symbol

    const openOverlay = () => {
      store.dispatch("toggleNavbarVisibility", false); // Hide the Navbar
      showOverlay.value = true;
    };

    const handleCreatedSuccess = async (orgUID) => {
      showOverlay.value = false;
      emit("role-created"); // Emit event up to parent.
    };

    const close = async () => {
      showOverlay.value = false;
      confirmationFromChild.value = false;
      store.dispatch("toggleNavbarVisibility", true); // Hide the Navbar
    };

    const resetConfirmation = () => {
      confirmationFromChild.value = false;
    };

    const fetchCoinSymbol = async () => {
      try {
        if (!props.orgUID) {
          console.error("Org UID is not provided");
          return;
        }
        const orgRef = doc(db, "custom-orgs", props.orgUID);
        const orgDoc = await getDoc(orgRef);
        if (orgDoc.exists()) {
          const orgData = orgDoc.data();
          coinSymbol.value = orgData.monetary_parameters?.coin_symbol || "N/A";
        } else {
          console.error("No such organization document");
        }
      } catch (error) {
        console.error("Error fetching coin symbol:", error);
      }
    };

    // Fetch coin symbol when component is mounted
    onMounted(() => {
      fetchCoinSymbol();
    });

    // watchers
    watch(
      () => showOverlay.value,
      (newVal) => {
        if (newVal) {
          document.body.classList.add("no-scroll");
        } else {
          document.body.classList.remove("no-scroll");
        }
      }
    );

    return {
      showOverlay,
      openOverlay,
      handleCreatedSuccess,
      close,
      confirmationFromChild,
      resetConfirmation,
      coinSymbol, // Return coinSymbol so it can be used in the template
    };
  },
};
</script>

<style scoped>
.roleTable {
  border-top: 1px solid var(--solidLighteGrey);
}
</style>
