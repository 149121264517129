<template>
  <div class="column singleColumn">
    <div v-if="!user.loggedIn">
      <p class="shortwidth nomargintop alignCentre">
        Sign in or register to explore Unit.
      </p>

      <div
        class="row alignCentreRow justifyCenter divMarginBottom"
        v-if="user.loggedIn !== true"
      >
        <button class="button is-primary" @click="toggleLoginModal" v-haptic>
          Log in
        </button>
        <p class="nomargintopbottom">or</p>
        <button class="button is-primary" @click="toggleRegisterModal" v-haptic>
          Register
        </button>
      </div>
      <AlertCard
        iconId="error_warning"
        id="warning"
        :justifyToCentre="true"
        type="error"
        :canBeDismissed="false"
        class="divNoMarginTopBottom"
      >
        <template #text>
          If you decide to register, please be advised that because Unit is in
          development, there is a possibility that your data may be deleted and
          you may encounter some technical issues within the application. Thank
          you for your understanding.
        </template>
      </AlertCard>
    </div>
    <AlertCard
      id="dev_contactUs"
      :justifyToCentre="true"
      type="null"
      :canBeDismissed="false"
      :gap="false"
      class="divNoMargiTopBottom"
    >
      <template #text>
        We are starting to work with a selected group of organisations.
      </template>
    </AlertCard>

    <p class="alignCentre smallmarginbottomnomargintop">
      Please get in touch if you are interested.
    </p>
    <div class="row alignCentreRow">
      <a
        href="mailto:joaoalvesmarrucho@gmail.com?subject=Inquiry about Unit&body=Hi, I'd like to know more about Unit."
      >
        <button v-haptic>
          <svg class="compactImg">
            <use href="../../assets/icons/iconset.svg#email_compact"></use></svg
          >Email
        </button>
      </a>
      <a href="https://calendly.com/joaoalvesmarrucho/30min" target="_blank">
        <button v-haptic>
          <svg class="compactImg">
            <use
              href="../../assets/icons/iconset.svg#calendar_compact"
            ></use></svg
          >Book initial talk
        </button></a
      >
    </div>
    <p class="or nomarginbottom">or</p>
    <div v-if="!hasJoinedWaitlist" class="column" id="joinWaitlist">
      <p class="alignCentre nomargintopbottom">Join the waitlist.</p>
      <p class="alignCentre nomargintopbottom">
        We will let you know when we release.
      </p>
      <div class="form-group email">
        <!--      <label for="email"> {{ $t("labels.email") }}</label> -->
        <input
          v-model="email"
          type="email"
          id="email-2"
          class="alignCentre"
          autocomplete="email"
          placeholder="Enter your email"
        />
      </div>
    </div>
    <div v-else class="column" :style="{ height: columnHeight }">
      <h1><span role="img" aria-label="Thank you">❤️</span></h1>
      <p>Thank you and talk soon.</p>
    </div>
    <button
      v-if="!hasJoinedWaitlist"
      class="divSmallMarginTop"
      type="submit"
      @click="joinWaitlist"
      v-haptic
    >
      Join waitlist
    </button>
    <br v-if="user.loggedIn" />
  </div>
</template>

<script>
// Import required dependencies and components
import { useStore } from "vuex";
import { computed, ref, onMounted, nextTick } from "vue";
import { auth, db } from "@/firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { toast } from "vue3-toastify";
import Login from "@/components/AuthComponents/Login.vue";
import Register from "@/components/AuthComponents/Register.vue";
import AlertCard from "@/components/GenericComponents/AlertCard.vue";
import Modal from "@/components/AuthComponents/Modal.vue";

export default {
  name: "PreEngagement",
  components: {
    Login,
    Register,
    AlertCard,
    Modal,
  },
  setup() {
    const store = useStore();
    const email = ref("");
    const hasJoinedWaitlist = ref(false);
    const joinWaitlistRef = ref(null);
    const columnHeight = ref(null); // For storing the column height

    const loginModalVisible = computed(() => store.state.showLoginModal);
    const registerModalVisible = computed(() => store.state.showRegisterModal);

    const toggleLoginModal = () => store.dispatch("toggleLoginModal");

    const emailIsValid = computed(() => {
      // Regular expression for email validation
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email.value).toLowerCase());
    });
    const joinWaitlist = async () => {
      if (!emailIsValid.value) {
        toast("Please enter a valid email address.");
        return;
      }
      const timestamp = serverTimestamp(); // If you want to use Firestore's server timestamp
      const waitlistCollection = collection(db, "waitlist");
      console.log(email);
      try {
        await addDoc(waitlistCollection, {
          email: email.value,
          dateAdded: timestamp,
        });
        toast(
          "Thank you for joining the waitlist. We will get in touch as soon as we release the first stable version."
        );
        columnHeight.value = joinWaitlistRef.value
          ? joinWaitlistRef.value.clientHeight + "px"
          : "auto";

        hasJoinedWaitlist.value = true;
      } catch (error) {
        console.error("Error adding document: ", error);
        toast("An error occurred. Please try again later. " + error);
      }
    };
    const toggleRegisterModal = () => store.dispatch("toggleRegisterModal");

    onMounted(async () => {
      await nextTick();
      auth.onAuthStateChanged((user) => {
        store.dispatch("fetchUser", user);
      });
    });

    const user = computed(() => {
      return store.getters.user;
    });

    return {
      joinWaitlist,
      emailIsValid,
      user,
      email,
      toggleLoginModal,
      toggleRegisterModal,
      hasJoinedWaitlist,
      joinWaitlistRef,
      columnHeight,
      loginModalVisible,
      registerModalVisible,
      // Add other variables and methods needed in this component
    };
  },
};
</script>

<style scoped>
#email {
  text-align: center;
}
</style>
