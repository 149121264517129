<template>
  <div
    class="dropdownText-component"
    v-if="textArea"
    v-click-outside="closeDropdown"
  >
    {{ textArea }}
    <span class="dropdown-toggle" @click="toggleDropdown" v-haptic>
      <span class="category">{{ title }}</span>
      <!-- <span @click="toggleDropdown" class="dropdown-arrow"
        ><svg v-if="!isOpen" class="tinyImg">
          <use href="@/assets/icons/iconset.svg#arrowdown_carousel-tiny"></use></svg
        ><svg v-else class="tinyImg">
          <use href="@/assets/icons/iconset.svg#arrowup_carousel-tiny"></use></svg
      ></span> -->
    </span>
    <transition name="slide-fade">
      <div
        @click="toggleDropdown"
        v-show="isOpen"
        class="text-area"
        :class="openFrom"
      >
        {{ textArea }}
      </div>
    </transition>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  props: {
    title: String,
    textArea: String,
    openFrom: {
      type: String,
      default: "leftdown",
      validator: (value) =>
        ["leftdown", "rightdown", "leftup", "rightup"].includes(value),
    },
  },
  setup() {
    const isOpen = ref(false);
    const toggleDropdown = () => {
      isOpen.value = !isOpen.value;
    };
    const closeDropdown = () => {
      isOpen.value = false;
    };

    return { isOpen, toggleDropdown, closeDropdown };
  },
};
</script>

<style scoped>
.dropdownText-component {
  margin-top: var(--tinyMargin);
}

.dropdown-toggle {
  z-index: 3;
  cursor: pointer;
  display: flex;
}
.dropdown-component {
  position: relative;
}

.smallButton {
  z-index: 3;
}

.text-area {
  z-index: 3;
  padding: var(--smallMargin);
  position: absolute;
  max-height: 50vh;
  width: 40vw;
  max-width: 60vw;
  overflow-y: auto;
  transition: all 0.15s ease;
  background-color: var(--glass-bg);
  box-shadow: var(--glass-box-shadow);
  border-radius: var(--borderRadius);
  -webkit-backdrop-filter: var(--glass-backdrop-filter);
  border: var(--glass-border);
}

.leftdown {
  margin-top: calc(var(--biggerMargin) * -1) !important;
  left: 0;
  bottom: 0;
}

.rightdown {
  margin-top: calc(var(--biggerMargin) * -1) !important;
  right: 0;
  bottom: 0;
}

.leftup {
  margin-top: var(--biggerMargin);
  left: 0;
  top: 0;
}

.rightup {
  margin-top: var(--biggerMargin);

  right: 0;
  top: 0;
}

span.dropdown-arrow {
  display: flex;
  align-items: flex-end;
}
</style>
