import { createStore } from "vuex";
import { auth, db } from "@/firebase";
import {
  addDoc,
  deleteDoc,
  getDoc,
  getDocs,
  collection,
  doc,
  setDoc,
  serverTimestamp,
  runTransaction,
} from "firebase/firestore";
import { signInWithEmailAndPassword, signOut } from "firebase/auth"; // Add this line

import messages from "../../locales/loadLocale.js";

// modules
import userActions from "./modules/userActions";
import customOrgs from "./modules/customOrgs";
import extraOrgActions from "./modules/extraOrgActions";
import productActions from "./modules/productActions";
import serviceActions from "./modules/serviceActions";
import UIState from "./modules/UIState";
import coinCreationActions from "./modules/coinCreationActions";
import { coinSymbolStates } from "./modules/coinSymbolStates";

export default createStore({
  // user login and out from the firebase auth object
  state: {
    // user states
    user: {
      loggedIn: false,
      data: null,
    },
    userCredentials: {
      data: null,
    },
    // the actual Units stored in user firestore node (the UNits the user is a member of)
    userUnitsData: {},
    // user Roles
    userRoles: null,
    // user's role privieges when viewing a role, used in the roleView.vue
    userRolePrivileges: null,

    // ORGANISATIONS
    // current user organisation
    currentOrgUID: null,
    currentOrgName: null,
    // UI_STATE
    showLoginModal: false,
    showLoginModalNoRedirect: false,
    showRegisterModal: false,
    showRegisterModalNoRedirect: false,
    menuOpen: false,
    selectedOrg: null,
    activeTab: null,
    isLoading: false,
    navbarVisible: true,
  },
  getters: {
    // getting user object values
    user: (state) => state.user,
    userCredentials: (state) => state.userCredentials,
    userUnitsData: (state) => state.userUnitsData,

    userRoles: (state) => state.userRoles,

    // getting user's role privieges when viewing a role, used in the roleView.vue
    userRolePrivileges: (state) => state.userRolePrivileges,

    // getting the users' only organisation
    currentUserOrgName: (state) => {
      if (state.currentOrgName) {
        return state.currentOrgName; // assuming organisationName is a field in  userUnitsData
      }
      return null;
    },
    currentOrgUID: (state) => state.currentOrgUID,
    // I'm not sure this is being used anywhere in the app
    currentUserOrgId: (state) => {
      if (state.userUnitsData && state.userUnitsData.length > 0) {
        return state.userUnitsData[0].organisationUID; // assuming organisationUID is a field in  userUnitsData
      }
      return null;
    },
    // UI_STATE
    showLoginModal: (state) => state.showLoginModal,
    showLoginModalNoRedirect: (state) => state.showLoginModalNoRedirect,
    showRegisterModalNoRedirect: (state) => state.showRegisterModalNoRedirect,
    showRegisterModal: (state) => state.showRegisterModal,
    menuOpen: (state) => state.menuOpen,
    // creating a new org
    selectedOrg: (state) => state.selectedOrg,
    activeTab: (state) => state.activeTab,
    // loadin animation
    isLoading: (state) => state.isLoading,
  },
  mutations: {
    // set user data objects
    SET_USER(state, data) {
      state.user.data = data;
    },
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER_CREDENTIALS(state, userCredentials) {
      if (userCredentials && userCredentials.data) {
        state.userCredentials = userCredentials;
      } else if (userCredentials && userCredentials.user) {
        state.userCredentials = {
          data: userCredentials.user,
        };
      } else {
        state.userCredentials = { data: userCredentials };
      }
      // console.log("SET_USER_CREDENTIALS");
      // console.log(state.userCredentials);
    },
    // set actual data object on firebase and store the user credential auth object
    SET_USER_UNITS(state, userUnitsData) {
      state.userUnitsData = userUnitsData;
    },
    SET_USER_ROLES(state, userRolesData) {
      state.userRoles = userRolesData;
    },

    // ORGANISATIONS
    SET_CURRENT_ORG_UID(state, orgUID) {
      state.currentOrgUID = orgUID;
    },
    // cretaing a new org
    SET_SELECTED_ORG(state, org) {
      state.selectedOrg = org;
    },
    SET_CURRENT_ORG_NAME(state, orgName) {
      state.currentOrgName = orgName;
    },

    // NAVIGATION & UI STATE
    SET_ACTIVE_TAB(state, tabName) {
      state.activeTab = tabName;
    },
    TOGGLE_LOGIN_MODAL(state) {
      state.showLoginModal = !state.showLoginModal;
    },
    HIDE_LOGIN_MODAL(state) {
      state.showLoginModal = false;
    },
    TOGGLE_LOGIN_MODAL_NO_REDIRECT(state) {
      state.showLoginModalNoRedirect = !state.showLoginModalNoRedirect;
    },
    TOGGLE_REGISTER_MODAL(state) {
      state.showRegisterModal = !state.showRegisterModal;
    },
    TOGGLE_REGISTER_MODAL_NO_REDIRECT(state) {
      state.showRegisterModalNoRedirect = !state.showRegisterModalNoRedirect;
    },
    SET_MENU_OPEN(state, payload) {
      state.menuOpen = payload;
    },

    // LOADING loading state for app wide use
    SET_LOADING_STATE(state, status) {
      state.isLoading = status;
    },
    // setting user's role privieges when view a role, used in the roleView.vue
    SET_ROLE_PRIVILEGES(state, userRolePrivileges) {
      state.userRolePrivileges = userRolePrivileges;
    },
    // clearing user's role privieges when view a role, used in the roleView.vue
    CLEAR_USER_ROLE_PRIVILEGES(state) {
      state.userRolePrivileges = {};
    },
    setNavbarVisibility(state, isVisible) {
      state.navbarVisible = isVisible;
    },
  },

  actions: {
    async logInEmailPassword(context, { email, password }) {
      const response = await signInWithEmailAndPassword(auth, email, password);
      if (response) {
        context.dispatch("logInStateCommit", response);
        return response;
      } else {
        throw new Error("Login failed");
      }
    },
    async logInStateCommit(context, response) {
      context.commit("SET_LOADING_STATE", true);
      context.commit("SET_USER", response.user);
      context.commit("SET_LOGGED_IN", true);
      context.commit("SET_USER_CREDENTIALS", response);
      context.commit("HIDE_LOGIN_MODAL");
      context.commit("SET_LOADING_STATE", false);
    },
    async logOutStateCommit(context) {
      context.commit("SET_LOADING_STATE", true);
      await signOut(auth);
      context.commit("SET_USER", null);
      context.commit("SET_LOGGED_IN", false);
      context.commit("SET_USER_CREDENTIALS", null);
      context.commit("SET_USER_UNITS", {});
      context.commit("SET_CURRENT_ORG_UID", null);
      context.commit("SET_USER_UNITS_CUSTOM_ORGS", null);
      context.commit("SET_CURRENT_ORG_NAME", null);
      context.commit("SET_LOADING_STATE", false);
    },
    async fetchUser({ commit }, user) {
      commit("SET_LOADING_STATE", true);

      if (user) {
        commit("SET_USER", {
          displayName: user.displayName,
          email: user.email,
        });
        commit("SET_LOGGED_IN", true);
        commit("SET_LOADING_STATE", false);
      } else {
        commit("SET_USER", null);
        commit("SET_LOGGED_IN", false);
        commit("SET_LOADING_STATE", false);
      }
    },
    async fetchUserCredentials({ commit }, userCredentials) {
      commit("SET_LOADING_STATE", true);
      if (userCredentials) {
        commit("SET_USER_CREDENTIALS", userCredentials);
        commit("SET_LOADING_STATE", false);
      } else {
        commit("SET_USER_CREDENTIALS", null);
        commit("SET_LOADING_STATE", false);
      }
    },

    // fetch user data from Firestore database user node units sub-collection (all the info we have been recording about the user)
    async fetchAndCommitUserUnits({ commit }, userUID) {
      commit("SET_LOADING_STATE", true);
      // console.log("fetchAndCommitUserUnits");
      // console.log("fetchAndCommitUserUnits");
      try {
        const userUnitsRef = collection(db, "users", userUID, "Units");
        const querySnapshot = await getDocs(userUnitsRef);
        let firstOrgUID;
        const userUnitsData = {};
        querySnapshot.forEach((doc) => {
          if (!firstOrgUID) {
            firstOrgUID = doc.id; // set the firstOrgUID if it's the first iteration
          }
          userUnitsData[doc.id] = doc.data();
        });
        // console.log(userUnitsData);
        commit("SET_USER_UNITS", userUnitsData);
        if (firstOrgUID && userUnitsData[firstOrgUID]) {
          commit(
            "SET_CURRENT_ORG_NAME",
            userUnitsData[firstOrgUID].organisationName
          );
        }
        if (firstOrgUID) {
          commit("SET_CURRENT_ORG_UID", firstOrgUID);
        }
        commit("SET_LOADING_STATE", false);
        // console.log("fetchAndCommitUserUnits");
        // console.log(userUnitsData);
        return userUnitsData;
      } catch (error) {
        commit("SET_LOADING_STATE", false);

        console.error("Error fetching user units: ", error);
        // handle the error appropriately
      }
    },

    async fetchAndCommitUserRoles({ commit }, userId) {
      if (!userId) {
        console.log("userId is not provided");
        return;
      }
      const userRolesCollectionRef = collection(db, "users", userId, "RoleIDs");
      const querySnapshot = await getDocs(userRolesCollectionRef);
      const userRolesData = {};
      querySnapshot.forEach((doc) => {
        userRolesData[doc.id] = doc.data();
      });
      commit("SET_USER_ROLES", userRolesData);
    },

    async fetchUserRolesAndPrivileges({ commit, getters }, userId) {
      try {
        // Fetch user roles
        const userRolesCollectionRef = collection(
          db,
          "users",
          userId,
          "RoleIDs"
        );
        const userRolesSnapshot = await getDocs(userRolesCollectionRef);
        const userRolesData = {};
        const userPrivileges = {};

        // Fetch privileges for each role
        for (const roleDoc of userRolesSnapshot.docs) {
          const roleId = roleDoc.id;
          const roleData = roleDoc.data();
          userRolesData[roleId] = roleData;

          // Now fetch the privileges associated with this role
          const privilegesDocRef = doc(
            db,
            "custom-orgs",
            getters.currentOrgUID,
            "Roles",
            roleId
          );
          const privilegesDocSnapshot = await getDoc(privilegesDocRef);

          if (privilegesDocSnapshot.exists()) {
            userPrivileges[roleId] =
              privilegesDocSnapshot.data().privileges || {};
          }
        }

        // Commit roles and privileges to the store
        commit("SET_USER_ROLES", userRolesData);
        commit("SET_ROLE_PRIVILEGES", userPrivileges);
      } catch (error) {
        console.error("Error fetching user roles and privileges:", error);
        commit("SET_USER_ROLES", {});
        commit("SET_ROLE_PRIVILEGES", {});
      }
    },

    // UI_STATE
    changeActiveTab({ commit }, tabName) {
      commit("SET_ACTIVE_TAB", tabName);
    },
    toggleLoginModal({ commit }) {
      commit("TOGGLE_LOGIN_MODAL");
    },
    toggleLoginModalNoRedirect({ commit }) {
      commit("TOGGLE_LOGIN_MODAL_NO_REDIRECT");
    },
    toggleRegisterModal({ commit }) {
      commit("TOGGLE_REGISTER_MODAL");
    },
    toggleRegisterModalNoRedirect({ commit }) {
      commit("TOGGLE_REGISTER_MODAL_NO_REDIRECT");
    },

    // creating a new org
    setSelectedOrg({ commit }, org) {
      commit("SET_SELECTED_ORG", org);
    },

    // getting user's role privileges when viewing another role, used in the roleView.vue
    // something is not right about this i'm getting the privleges from the role the user is currently viewing, that's not what i want, i want to get the privieges from current user role
    async getUserRolePrivileges({ commit, getters }, { orgUID }) {
      commit("CLEAR_USER_ROLE_PRIVILEGES"); // Clear previous privileges first

      if (
        getters.userCredentials &&
        getters.userCredentials.data &&
        getters.userCredentials.data.uid
      ) {
        const userUID = getters.userCredentials.data.uid; // Get the authenticated user's UID

        try {
          const userRoleIDsCollectionRef = collection(
            db,
            "users",
            userUID,
            "RoleIDs"
          );
          const userRoleIDsQuerySnapshot = await getDocs(
            userRoleIDsCollectionRef
          );
          let userRolePrivileges = {}; // Initialize the privileges object

          for (const docSnapshot of userRoleIDsQuerySnapshot.docs) {
            if (docSnapshot.exists()) {
              const userRoleID = docSnapshot.id; // Get the document's ID, which is the roleID
              const roleDocRef = doc(
                db,
                "custom-orgs",
                orgUID,
                "Roles",
                userRoleID
              );
              const roleDocSnapshot = await getDoc(roleDocRef);

              if (roleDocSnapshot.exists()) {
                const roleData = roleDocSnapshot.data();
                userRolePrivileges = {
                  ...userRolePrivileges,
                  ...roleData.privileges, // Merge privileges
                };
              }
            }
          }

          commit("SET_ROLE_PRIVILEGES", userRolePrivileges); // Commit the merged privileges
        } catch (error) {
          console.error("Error fetching user role privileges:", error);
          commit("SET_ROLE_PRIVILEGES", null);
        }
      } else {
        commit("SET_ROLE_PRIVILEGES", null);
      }
    },

    toggleNavbarVisibility({ commit }, isVisible) {
      commit("setNavbarVisibility", isVisible);
    },
    setLoadingState({ commit }, isLoading) {
      commit("SET_LOADING_STATE", isLoading);
    },
  },
  modules: {
    userActions,
    customOrgs,
    extraOrgActions,
    productActions,
    serviceActions,
    UIState,
    coinCreationActions,
    coinSymbolStates,
  },
});
