<template>
  <div v-if="buyerOrgHasCoins">
    <slot name="transactions-exist"></slot>
  </div>
  <div v-if="!hasTransactions" class="innerSticker divMarginBottom yellow">
    <slot name="no-transactions">No transactions</slot>
  </div>
  <div
    v-if="!currentAccountBalance && buyerOrgHasCoins"
    class="innerSticker divMarginBottom yellow"
  >
    <slot name="no-money">No Money</slot>
  </div>

  <div
    v-if="hasTransactions && !buyerOrgHasCoins"
    class="innerSticker divMarginBottom yellow"
  >
    <slot name="no-coins">No coins</slot>
  </div>
</template>

<script>
import { ref, onMounted, watch, onUnmounted } from "vue";
import { db } from "@/firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  onSnapshot,
} from "firebase/firestore";

export default {
  name: "OrganisationCoinStatus",
  props: {
    currentUserUid: {
      default: null, // Set default as null to handle cases where uid is not available
      required: false, // Marking as not required if it can be null
    },
    orgUID: {
      type: String,
      default: "",
    },
    buyerOrgHasCoins: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const hasTransactions = ref(false);
    const currentAccountBalance = ref(0);
    let unsubscribe = null; // To hold the unsubscribe function for cleanup

    const checkOrgForTransactions = async () => {
      if (!props.orgUID) {
        console.error("orgUID is null or undefined.");
        return;
      }
      const orgDocRef = doc(db, "custom-orgs", props.orgUID);
      const orgDocSnap = await getDoc(orgDocRef);

      if (orgDocSnap.exists()) {
        hasTransactions.value = orgDocSnap.data().hasTransactions || false;
      }
    };

    const fetchActiveWalletBalance = () => {
      if (props.currentUserUid) {
        const walletsRef = collection(
          db,
          "users",
          props.currentUserUid,
          "Wallets"
        );
        const activeWalletQuery = query(
          walletsRef,
          where("isActiveMember", "==", true)
        );

        // Use onSnapshot for real-time updates
        onSnapshot(activeWalletQuery, (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const walletData = doc.data();
            if (walletData.currentAccount) {
              currentAccountBalance.value = walletData.currentAccount.balance; // Update the reactive variable
            }
          });
        });
      }
    };

    watch(
      () => props.orgUID,
      (newVal, oldVal) => {
        if (newVal !== oldVal && newVal !== undefined) {
          checkOrgForTransactions();
          fetchActiveWalletBalance();
        }
      }
    );

    onMounted(() => {
      checkOrgForTransactions();
      fetchActiveWalletBalance();
    });

    onUnmounted(() => {
      if (unsubscribe) {
        unsubscribe();
      }
    });

    return {
      hasTransactions,
      currentAccountBalance,
    };
  },
};
</script>

<style scoped>
.innerStickerCont {
  /* Styles for your inner sticker container */
}
</style>
