import {
  doc,
  getDoc,
  collection,
  getDocs,
  updateDoc,
} from "firebase/firestore";

import { db, auth } from "@/firebase";

export default {
  namespaced: true,
  state: {
    isDarkMode: true, // Default to dark mode
    areMessagesMinimised: false,
    areMessagesOpen: false,
    showInteractiveMessage: false,
    otherUserData: null,
    showWishlist: false,
    productInfo: null,
    interactiveMessageType: null,
    transferAmount: 0,
    unreadMessagesCount: 0,
    showOwnOrganisationOnly: false,
  },
  getters: {
    isDarkMode: (state) => state.isDarkMode,
    areMessagesMinimised: (state) => state.areMessagesMinimised,
    areMessagesOpen: (state) => state.areMessagesOpen,
    showInteractiveMessage: (state) => state.showInteractiveMessage,
    otherUserData: (state) => state.otherUserData,
    showWishlist: (state) => state.showWishlist,
    productInfo: (state) => state.productInfo,
    interactiveMessageType: (state) => state.interactiveMessageType,
    transferAmount: (state) => state.transferAmount,
    unreadMessagesCount: (state) => state.unreadMessagesCount,
    hasUnreadMessages: (state) => state.unreadMessagesCount > 0,
    showOwnOrganisationOnly: (state) => state.showOwnOrganisationOnly,
  },
  mutations: {
    TOGGLE_DARK_MODE(state) {
      state.isDarkMode = !state.isDarkMode;
      localStorage.setItem("isDarkMode", state.isDarkMode);

      const themeColor = state.isDarkMode ? "#2d2d2d" : "#f0f0f5";
      // const faviconUrl = state.isDarkMode
      //   ? "/img/icons/dark-favicon.ico"
      //   : "/img/icons/light-favicon.ico";
      // TODO: Uncomment the above lines when you have the dark and light favicons ready
      document.documentElement.setAttribute(
        "data-theme",
        state.isDarkMode ? "dark" : "light"
      );

      // Update theme color
      const themeColorMetaTag = document.querySelector(
        'meta[name="theme-color"]'
      );
      if (themeColorMetaTag) {
        themeColorMetaTag.setAttribute("content", themeColor);
      }

      // Update favicon
      const faviconElement = document.getElementById("favicon");
      if (faviconElement) {
        faviconElement.setAttribute("href", faviconUrl);
      }
    },
    INIT_DARK_MODE(state, prefersDark) {
      const storedIsDarkMode = localStorage.getItem("isDarkMode");

      if (storedIsDarkMode !== null) {
        state.isDarkMode = storedIsDarkMode === "true";
      } else {
        state.isDarkMode = prefersDark;
      }

      const themeColor = state.isDarkMode ? "#2d2d2d" : "#f0f0f5";
      // const faviconUrl = state.isDarkMode
      //   ? "/img/icons/dark-favicon.ico"
      //   : "/img/icons/light-favicon.ico";
      // TODO: Uncomment the above lines when you have the dark and light favicons ready

      document.documentElement.setAttribute(
        "data-theme",
        state.isDarkMode ? "dark" : "light"
      );

      // Update theme color
      const themeColorMetaTag = document.querySelector(
        'meta[name="theme-color"]'
      );
      if (themeColorMetaTag) {
        themeColorMetaTag.setAttribute("content", themeColor);
      }

      // Update favicon
      const faviconElement = document.getElementById("favicon");
      if (faviconElement) {
        faviconElement.setAttribute("href", faviconUrl);
      }
    },
    TOGGLE_MESSAGES_MINIMISED(state) {
      state.areMessagesMinimised = !state.areMessagesMinimised;
    },
    SET_MESSAGES_MINIMISED(state, value) {
      state.areMessagesMinimised = value;
    },
    SET_MESSAGES_OPEN(state, value) {
      state.areMessagesOpen = value;
    },
    SET_OTHER_USER_DATA(state, userData) {
      state.otherUserData = userData;
    },
    SET_PRODUCT_INFO(state, productInfo) {
      state.productInfo = productInfo;
    },
    CLEAR_PRODUCT_INFO(state) {
      state.productInfo = null;
      state.showInteractiveMessage = false;
    },
    SET_MESSAGES_MAXIMISED(state) {
      state.areMessagesMinimised = false;
    },
    SET_SHOW_INTERACTIVE_MESSAGE(state, value) {
      state.showInteractiveMessage = value;
    },
    TOGGLE_WISHLIST(state) {
      state.showWishlist = !state.showWishlist;
    },
    SET_WISHLIST_VISIBILITY(state, visibility) {
      state.showWishlist = visibility;
    },
    SET_INTERACTIVE_MESSAGE_TYPE(state, type) {
      state.interactiveMessageType = type;
    },
    SET_TRANSFER_AMOUNT(state, amount) {
      state.transferAmount = amount;
    },
    SET_UNREAD_MESSAGES_COUNT(state, count) {
      state.unreadMessagesCount = count;
    },
    DECREMENT_UNREAD_MESSAGES_COUNT(state) {
      if (state.unreadMessagesCount > 0) {
        state.unreadMessagesCount--;
      }
    },
    TOGGLE_SHOW_OWN_ORGANISATION_ONLY(state) {
      state.showOwnOrganisationOnly = !state.showOwnOrganisationOnly;
    },
    SET_SHOW_OWN_ORGANISATION_ONLY(state, value) {
      state.showOwnOrganisationOnly = value;
    },
  },
  actions: {
    toggleDarkMode({ commit, state }) {
      commit("TOGGLE_DARK_MODE");
      document.documentElement.setAttribute(
        "data-theme",
        state.isDarkMode ? "dark" : "light"
      );
    },
    initDarkMode({ commit }) {
      const prefersDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      commit("INIT_DARK_MODE", prefersDark);
      document.documentElement.setAttribute(
        "data-theme",
        prefersDark ? "dark" : "light"
      );
    },
    toggleMessagesMinimised({ commit }) {
      commit("TOGGLE_MESSAGES_MINIMISED");
    },
    setMessagesOpen({ commit }, value) {
      commit("SET_MESSAGES_OPEN", value);
    },
    setMessagesMinimised({ commit }, value) {
      commit("SET_MESSAGES_MINIMISED", value);
    },
    setOtherUserData({ commit }, userData) {
      commit("SET_OTHER_USER_DATA", userData);
    },
    setMessagesMaximised({ commit }) {
      commit("SET_MESSAGES_MAXIMISED");
    },
    setShowInteractiveMessage({ commit }, value) {
      commit("SET_SHOW_INTERACTIVE_MESSAGE", value);
    },
    setProductInfo({ commit }, productInfo) {
      commit("SET_PRODUCT_INFO", productInfo);
    },
    clearProductInfo({ commit }) {
      commit("CLEAR_PRODUCT_INFO");
    },
    setTransferAmount({ commit }, amount) {
      commit("SET_TRANSFER_AMOUNT", amount);
    },
    async fetchUnreadMessagesCount({ commit }, userUid) {
      try {
        // Fallback logic if userUid is not provided
        if (!userUid) {
          const currentUser = await new Promise((resolve) => {
            auth.onAuthStateChanged((user) => {
              resolve(user);
            });
          });

          if (currentUser && currentUser.uid) {
            userUid = currentUser.uid;
          } else {
            console.error(
              "User is not authenticated, cannot fetch unread messages."
            );
            return;
          }
        }

        const messageCollectionRef = collection(
          db,
          "users",
          userUid,
          "Messages"
        );
        const messagesSnapshot = await getDocs(messageCollectionRef);

        let unreadMessagesCount = 0;

        messagesSnapshot.forEach((doc) => {
          const messages = doc.data().messages;

          const unreadMessages = messages.filter((msg) => {
            const isUnread = !msg.read && msg.to === userUid;
            return isUnread;
          });
          unreadMessagesCount += unreadMessages.length;
        });

        commit("SET_UNREAD_MESSAGES_COUNT", unreadMessagesCount);
      } catch (error) {
        console.error("Error fetching unread messages count:", error);
      }
    },
    async markMessageAsRead({ commit }, { messageId, otherUserUid }) {
      try {
        const userUid = auth.currentUser.uid;

        if (!userUid || !otherUserUid) return;

        const updateMessageReadStatus = async (userId, otherUserId) => {
          const messageDocRef = doc(
            db,
            "users",
            userId,
            "Messages",
            otherUserId
          );
          const docSnap = await getDoc(messageDocRef);

          if (docSnap.exists()) {
            const messages = docSnap.data().messages.map((msg) => {
              if (msg.id === messageId) {
                return { ...msg, read: true };
              }
              return msg;
            });

            await updateDoc(messageDocRef, { messages });
          }
        };

        // Update read status for both users
        await updateMessageReadStatus(userUid, otherUserUid);
        await updateMessageReadStatus(otherUserUid, userUid);

        commit("DECREMENT_UNREAD_MESSAGES_COUNT");
      } catch (error) {
        console.error("Error marking message as read:", error);
      }
    },
    async fetchOtherUserData(
      { commit },
      { product, convertedPrice, buyerCoinSymbol, sellerCoinSymbol }
    ) {
      try {
        if (!product || !product.owner) {
          throw new Error("Product or product owner is missing");
        }

        const userRef = doc(db, "users", product.owner);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const userData = userSnap.data();
          commit("SET_OTHER_USER_DATA", userData);

          const productInfo = {
            productId: product.id || "",
            itemName: product.itemName || "",
            productPrice: product.price || 0,
            productImageUrl: product.images ? product.images[0] : "",
            convertedPrice: convertedPrice || 0,
            coinSymbol: sellerCoinSymbol || "",
            buyerCoinSymbol: buyerCoinSymbol.value || "",
            sellerCoinSymbol: sellerCoinSymbol || "",
            type: product.type || "",
            bookingDate: product.bookingDate || "",
            bookingTime: product.bookingTime || "",
            slotDuration: product.slotDuration || 0,
          };
          // console.log("PRODUCT IN UI STATE");
          // console.log(productInfo);

          commit("SET_PRODUCT_INFO", productInfo);
        } else {
          console.log("fetchOtherUserData - No such user found!");
        }
      } catch (error) {
        console.error("fetchOtherUserData - Error fetching user data:", error);
      }
    },
    toggleWishlist({ commit }) {
      commit("TOGGLE_WISHLIST");
    },
    setWishlistVisibility({ commit }, visibility) {
      commit("SET_WISHLIST_VISIBILITY", visibility);
    },
    setInteractiveMessageType({ commit }, type) {
      commit("SET_INTERACTIVE_MESSAGE_TYPE", type);
    },
    toggleShowOwnOrganisationOnly({ commit }) {
      commit("TOGGLE_SHOW_OWN_ORGANISATION_ONLY");
    },
    setShowOwnOrganisationOnly({ commit }, value) {
      commit("SET_SHOW_OWN_ORGANISATION_ONLY", value);
    },
  },
};
