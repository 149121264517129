<template>
  <div class="fullWidth FAQs">
    <h2>Frequently Asked Questions</h2>

    <DropdownPanel :faqItems="faqItems">
      <template v-slot:how_to_start_using_unit>
        <HowToGuide :showOnFAQ="false" />
      </template>
      <template v-slot:what_are_some_potential_applications_of_unit>
        <UseCases :showOnFAQ="false" />
      </template>
      <template v-slot:how_does_unit_exchange_rate_work>
        <ExchangeRateSimulator :showOnFAQ="false" />
      </template>
      <template v-slot:how_do_i_install_the_web_app>
        <InstallWebapp />
      </template>
    </DropdownPanel>
  </div>
</template>

<script>
import { ref } from "vue";
import DropdownPanel from "@/components/GenericComponents/DropdownPanel.vue";
import HowToGuide from "@/components/AboutComponents/HowToGuide.vue";
import UseCases from "@/components/AboutComponents/UseCases.vue";
import InstallWebapp from "@/components/AboutComponents/InstallWebapp.vue";
import ExchangeRateSimulator from "@/components/LandingComponents/ExchangeRateSimulator.vue";

export default {
  name: "FAQPanel",
  components: {
    DropdownPanel,
    HowToGuide,
    UseCases,
    InstallWebapp,
    ExchangeRateSimulator,
  },
  setup() {
    const faqItems = ref([
      // {
      //   question: "How to start using Unit?",
      //   answer: "",
      //   open: false,
      // },

      {
        question: "What gives Unit coins their value?",
        answer: `🫵 The short answer is: you.
The not-so-short answer is: you and the people around you. The value of a Unit coin is essentially what people can offer or sell within their community.
It really doesn't matter if you create 10 coins per person or 100. The important thing is that you offer something of value to other people.`,
        open: false,
      },
      {
        question: "How does Unit exchange rate work?",
        answer: "",
        open: false,
      },
      {
        question: "How do I install the web app?",
        answer: "",
        open: false,
      },
      {
        question: "What are some potential applications of Unit?",
        answer: "",
        open: false,
      },
    ]);

    return { faqItems };
  },
};
</script>

<style scoped>
.FAQs {
  max-width: 65ch;
}

.fullWidth {
  width: 100%;
}

.faqItem {
  margin-bottom: 1rem;
}

.answer {
  margin-top: 0.5rem;
}
</style>
