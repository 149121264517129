<template>
  <div class="RolesRow_mainCont">
    <div class="roleRow_container">
      <div class="row alignCentreRow roleRow">
        <span>
          <a
            class="noUnderline"
            v-if="roleUID"
            @click="handleRoleClick"
            v-haptic
          >
            <h4 class="actionText">{{ roleTitle }}</h4>
          </a>
        </span>

        <div class="row lastGroup">
          <!-- Avatars Loop -->
          <div class="avatars-container">
            <AvatarLightTiny
              v-for="(userDetails, index) in limitedUsers"
              :key="index"
              :user="userDetails.user_details"
              :userUid="userDetailsUid(index)"
            />
            <span v-if="remainingUsersCount > 0" class="more-users">...</span>
          </div>
          <span title="Positions filled / Total positions">
            {{ filledByCount }}/{{ role.slots }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Badge from "@/components/GenericComponents/Badge.vue";
import AvatarLightTiny from "@/components/UserComponents/AvatarLightTiny.vue";

export default {
  name: "RolesRow",
  props: {
    orgUID: { type: String },
    role: {
      type: Object,
    },
    roleTitle: {
      type: String,
    },
    privileges: {
      type: Object,
    },
    roleUID: {
      type: String,
    },
  },
  components: {
    Badge,
    AvatarLightTiny,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const filledByUsers = computed(() => {
      return props.role.filledBy ? Object.entries(props.role.filledBy) : [];
    });

    const limitedUsers = computed(() => {
      return filledByUsers.value.slice(0, 5);
    });

    const remainingUsersCount = computed(() => {
      return filledByUsers.value.length - limitedUsers.value.length;
    });

    const filledByCount = computed(() => {
      return props.role.slotsFilled;
    });

    const userDetailsUid = (index) => {
      return limitedUsers.value[index][0]; // Returns the UID (key) for the user
    };

    const handleRoleClick = () => {
      store.dispatch("changeActiveTab", null);
      router.push({
        name: "RoleView",
        params: { roleUID: props.roleUID, orgUID: props.orgUID },
      });
    };

    return {
      filledByCount,
      limitedUsers,
      remainingUsersCount,
      handleRoleClick,
      userDetailsUid,
    };
  },
};
</script>

<style>
.roleRow {
  height: var(--clickableHeight);
}
.roleRow_container {
  position: relative;
  display: flex;
  align-items: center;
}
.alignCentreRow.roleRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.lastGroup {
  display: flex;
  gap: var(--biggerMargin);
  align-items: center;
}

.avatars-container {
  display: flex;
  gap: var(--smallerMargin);
}

.more-users {
  font-size: var(--smallerFontSize);
  color: var(--solidMidDarkgrey);
}
</style>
