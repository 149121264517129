<template>
  <div class="createRole">
    <div v-if="confirmation">
      <table class="divMarginBottom">
        <tr>
          <td>Role title</td>
          <td>{{ form.roleTitle }}</td>
        </tr>
        <tr>
          <td>Discoverable</td>
          <td>{{ form.public ? "Yes" : "No" }}</td>
        </tr>
        <tr>
          <td>Slots (number of fulltime positions)</td>
          <td>{{ form.slots }}</td>
        </tr>
        <tr>
          <td>Salary</td>
          <td>
            {{ form.baseCompensation }}&nbsp
            {{ basicOrgDetails ? basicOrgDetails.coinSymbol : "Ʉ" }}
          </td>
        </tr>
        <tr v-if="form.roleDescription">
          <td>Role description</td>
          <td>{{ form.roleDescription }}</td>
        </tr>
        <tr v-if="anyPrivilegeSelected">
          <td class="smallMarginBottom alignCentre" colspan="2">
            <small class="smallMarginBottom alignCentre"
              >Colleagues in this role can...</small
            >
          </td>
        </tr>
        <tr v-if="form.privileges.roleCreation">
          <td>Create new roles</td>
          <td>
            <svg id="checkmark" class="compactImg">
              <use
                href="../../assets/icons/iconset.svg#checkmark_compact"
              ></use>
            </svg>
          </td>
        </tr>
        <tr v-if="form.privileges.roleAdministration">
          <td>Edit existing roles</td>
          <td>
            <svg id="checkmark" class="compactImg">
              <use
                href="../../assets/icons/iconset.svg#checkmark_compact"
              ></use>
            </svg>
          </td>
        </tr>
        <tr v-if="form.privileges.userRegistration">
          <td>Register new users</td>
          <td>
            <svg id="checkmark" class="compactImg">
              <use
                href="../../assets/icons/iconset.svg#checkmark_compact"
              ></use>
            </svg>
          </td>
        </tr>
        <tr v-if="form.privileges.userModeration">
          <td>Manage existing users</td>
          <td>
            <svg id="checkmark" class="compactImg">
              <use
                href="../../assets/icons/iconset.svg#checkmark_compact"
              ></use>
            </svg>
          </td>
        </tr>
        <!-- <tr v-if="form.privileges.sub_unitCreation">
          <td>Stakeholder management</td>
          <td>
            <svg id="checkmark" class="compactImg">
              <use
                href="../../assets/icons/iconset.svg#checkmark_compact"
              ></use>
            </svg>
          </td>
        </tr> -->
        <tr v-if="form.privileges.naturalPersonVerification">
          <td>Verify users</td>
          <td>
            <svg id="checkmark" class="compactImg">
              <use
                href="../../assets/icons/iconset.svg#checkmark_compact"
              ></use>
            </svg>
          </td>
        </tr>
        <tr v-if="form.privileges.monetaryParameters">
          <td>Edit monetary rules</td>
          <td>
            <svg id="checkmark" class="compactImg">
              <use
                href="../../assets/icons/iconset.svg#checkmark_compact"
              ></use>
            </svg>
          </td>
        </tr>
      </table>
      <div class="row centredButtons">
        <button
          class="secondaryButton"
          v-if="!processing"
          @click="confirmation = false"
          v-haptic
        >
          Go back
        </button>
        <button :disabled="processing" @click="submitForm" v-haptic>
          {{ processing ? "Processing..." : "Create role" }}
        </button>
      </div>
    </div>
    <form v-else @submit.prevent="previewForm">
      <div class="form-group roleTitle">
        <label for="roleTitle">Role title</label>
        <input
          id="roleTitle"
          v-model="form.roleTitle"
          type="text"
          placeholder="For example: Manager, Farmer, Judge..."
          required
        />
      </div>

      <div class="row form-group-doubleInput alignCentreRow">
        <div
          class="slots doubleInputItem"
          title="Specify the number of colleagues needed for this role. Each slot represents a person you are looking to hire. For example, 1 slot equals 1 person, 2 slots for 2 persons, and so on."
        >
          <label for="slots"
            >Slots<Tooltip
              message="Specify the number of colleagues needed for this role. Each slot represents a person you are looking to hire. For example, 1 slot equals 1 person, 2 slots for 2 persons, and so on."
          /></label>
          <input
            id="slots"
            v-model="form.slots"
            type="number"
            min="1"
            required
          />
        </div>

        <div
          class="baseCompensation doubleInputItem"
          title="The salary that each colleague will receive for performing this role."
        >
          <label for="baseCompensation"
            >Salary in&nbsp;
            {{
              basicOrgDetails ? basicOrgDetails.coinSymbol : "loading..."
            }}</label
          >
          <input
            id="baseCompensation"
            v-model.number="form.baseCompensation"
            type="number"
            min="0"
            required
          />
        </div>
      </div>
      <div class="form-group roleDescription">
        <label for="roleDescription">Role description (optional)</label>
        <textarea
          id="roleDescription"
          v-model="form.roleDescription"
          placeholder="Description of the role (you can do this later)..."
        ></textarea>
      </div>
      <div class="form-group Public">
        <ToggleSwitch
          label="Searchable without magic link?"
          v-model="form.public"
        />
      </div>
      <h3 class="nomarginbottom">Colleagues in this role can...</h3>
      <div class="justified">
        <ToggleSwitch
          label="Create new roles"
          v-model="form.privileges.roleCreation"
        />

        <ToggleSwitch
          label="Register new users"
          v-model="form.privileges.userRegistration"
        />
      </div>
      <!-- <div class="separator" v-if="advancedVisible"></div> -->
      <div v-if="advancedVisible" class="justified">
        <ToggleSwitch
          label="Manage existing users"
          v-model="form.privileges.userModeration"
        />
        <ToggleSwitch
          label="Edit existing roles"
          v-model="form.privileges.roleAdministration"
        />
        <!--
        <ToggleSwitch
          label="Create Sub-Units"
          v-model="form.privileges.sub_unitCreation"
        />
        -->
        <ToggleSwitch
          label="Verify users"
          v-model="form.privileges.naturalPersonVerification"
        />
        <ToggleSwitch
          label="Edit monetary rules"
          v-model="form.privileges.monetaryParameters"
        />
      </div>
      <div class="row">
        <button
          class="secondaryButton"
          @click.prevent="advancedVisible = !advancedVisible"
          v-haptic
        >
          <svg v-if="!advancedVisible" class="compactImg" v-haptic>
            <use href="../../assets/icons/iconset.svg#view_compact"></use>
          </svg>
          <svg v-if="advancedVisible" class="compactImg" v-haptic>
            <use href="../../assets/icons/iconset.svg#hide_compact"></use>
          </svg>
          <span v-if="!advancedVisible"> View advanced privileges</span>
          <span v-if="advancedVisible"> Hide advanced privileges</span>
        </button>

        <button type="submit" v-haptic>Preview</button>
      </div>
    </form>
  </div>
</template>

<script>
import { reactive, ref, watch, onMounted, computed } from "vue";
import { db } from "@/firebase";
import { useStore } from "vuex";

import { collection, addDoc, doc, updateDoc } from "firebase/firestore";
import { toast } from "vue3-toastify"; // add this line

import ToggleSwitch from "@/components/GenericComponents/ToggleSwitch.vue";
import Tooltip from "@/components/GenericComponents/Tooltip.vue";

export default {
  name: "CreateRole",
  components: {
    ToggleSwitch,
    Tooltip,
  },
  props: {
    orgUID: String,
    confirmationFromParent: Boolean,
  },

  setup(props, context) {
    const store = useStore();
    const processing = ref(false);
    const form = reactive({
      roleTitle: "",
      public: false,
      roleDescription: "",
      slots: 1,
      baseCompensation: 0,
      privileges: {
        sub_unitCreation: false,
        monetaryParameters: false,
        naturalPersonVerification: false,
        userModeration: false,
        userRegistration: false,
        roleAdministration: false,
        roleCreation: false,
      },
    });
    const confirmation = ref(false);

    const advancedVisible = ref(false);
    const basicOrgDetails = computed(() =>
      store.getters.getBasicOrgDetails(props.orgUID)
    );

    const formatLabel = (label) => {
      // Add a space before each capitalized word
      const formatted = label.replace(/([A-Z])/g, " $1");
      // Capitalize the first character and return
      return formatted.charAt(0).toUpperCase() + formatted.slice(1);
    };
    const previewForm = () => {
      confirmation.value = true;
    };

    const submitForm = async () => {
      processing.value = true;

      try {
        const orgDocRef = doc(db, "custom-orgs", props.orgUID);
        const rolesCollectionRef = collection(orgDocRef, "Roles");

        // Prepare data
        const roleData = {
          roleTitle: form.roleTitle,
          public: form.public,
          roleDescription: form.roleDescription,
          slots: form.slots,
          slotsFilled: 0,
          baseCompensation: form.baseCompensation,
          editablePrivileges: true,
          filledBy: {},
          privileges: form.privileges,
        };

        const docRef = await addDoc(rolesCollectionRef, roleData);
        const roleUID = docRef.id; // this is the automatically generated unique ID for the new role document

        // Now, update the document with its own ID
        await updateDoc(docRef, { roleUID: roleUID });

        confirmation.value = false;

        // show toast success
        toast.success("Role created!", { autoClose: 2000 });
        context.emit("created");
        store.dispatch("toggleNavbarVisibility", true); // Hide the Navbar
      } catch (error) {
        // show toast error
        toast.error(`Error: ${error.message}`, { autoClose: 2000 });
      } finally {
        processing.value = false;
      }
    };

    const anyPrivilegeSelected = computed(() => {
      return Object.values(form.privileges).some((privilege) => privilege);
    });
    onMounted(async () => {
      await store.dispatch("fetchAllData", props.orgUID);
      console.log(store.getters.getBasicOrgDetails(props.orgUID)); // Debug line
    });

    watch(basicOrgDetails, (newVal, oldVal) => {
      console.log("New:", newVal, "Old:", oldVal);
    });
    watch(confirmation, (newVal) => {
      context.emit("update-confirmation", newVal);
    });
    watch(
      () => props.confirmationFromParent,
      (newVal) => {
        confirmation.value = newVal;
      }
    );

    return {
      form,
      confirmation,
      previewForm,
      submitForm,
      formatLabel,
      advancedVisible,
      basicOrgDetails,
      anyPrivilegeSelected,
      processing,
    };
  },
};
</script>

<style scoped>
textarea {
  resize: none;
}
input {
  text-align: center;
}
.centredButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.createRole {
  width: 100%;
}
.doubleInputItem {
  width: calc(50% - calc(var(--smallMargin) / 2));
}

.privilege-item {
  display: flex;
  justify-content: space-between;
}
.confirmation-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(var(--clickableHeight) / 2);
}

td.smallMarginBottom {
  padding-bottom: 0;
}

td:last-child.alignCentre {
  text-align: center;
}
</style>
