// This is a custom Vue directive to detect click outside of the element
const ClickOutside = {
  beforeMount: (el, binding, vnode) => {
    el.clickOutsideEvent = function(event) {
      if (binding.arg?.skip) return;
      
      if (!(el == event.target || el.contains(event.target))) {
        binding.value();
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted: (el) => {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
};
  
  export default ClickOutside;


  