<template>
  <div class="Create_custom_org">
    <div class="row alignCentreRow justifyToCentre">
      <button
        v-if="user.loggedIn && !isSaveLoading"
        @click="openOverlay"
        class="divNoMarginTopSmallMarginBottom"
        v-haptic
      >
        Create unit
      </button>
      <button
        @click="toggleLoginModal"
        v-if="!user.loggedIn && !isSaveLoading"
        v-haptic
      >
        Login</button
      ><span v-if="!user.loggedIn && !isSaveLoading">or</span>
      <button
        @click="toggleRegisterModal"
        v-if="!user.loggedIn && !isSaveLoading"
        v-haptic
      >
        Register
      </button>
      <span v-if="!user.loggedIn && !isSaveLoading">to create your unit</span>
    </div>
    <Overlay
      :visible="showOverlay"
      :title="dynamicTitle"
      :smallTextAfterTitle="'You can change this later'"
      :showBackArrow="shouldShowBackArrow"
      :currentStep="currentStep"
      @close="closeOverlay"
      @previousStep="goToPreviousStep"
    >
      <template #before-buttons>
        <div v-show="currentStep === 'initial'" class="fullWidth">
          <EditBasicOrgDetails
            v-show="currentStep === 'initial'"
            @save="goToNextStep"
            :orgDetails="orgDetails"
            @update:basicOrgDeets="udpateBasicOrgDetails"
            @description-too-long="handleDescriptionTooLong"
            class="fullWidth"
          />
        </div>
        <div v-show="currentStep === 'editParams'" class="fullWidth">
          <!-- {{ orgDetails }} -->
          <EditMonetaryParameters
            :orgDetails="orgDetails"
            @update:coin-name="updateCoinName"
            @update:allMonetaryDeets="updateMonetaryDetails"
            @update:regularity="updateRegularity"
          />
        </div>
        <div v-show="currentStep === 'preview'" class="fullWidth">
          <PreviewNewCustomOrgDetails :orgDetails="orgDetails" />
        </div>
      </template>
      <div class="row alignCentreRow">
        <button
          class="secondaryButton"
          @click="closeOverlay"
          v-if="!isSaveLoading"
          v-haptic
        >
          Cancel
        </button>
        <button
          @click="toggleLoginModal"
          v-if="!user.loggedIn && !isSaveLoading && currentStep == 'preview'"
          v-haptic
        >
          Login
        </button>
        <button
          @click="toggleRegisterModal"
          v-if="!user.loggedIn && !isSaveLoading && currentStep == 'preview'"
          v-haptic
        >
          Register
        </button>
        <button
          @click="goToNextStep"
          v-if="currentStep !== 'preview'"
          :disabled="isSaveLoading || isDescriptionTooLong"
          v-haptic
        >
          {{ nextButtonText }}
        </button>
        <button
          @click="goToNextStep"
          v-if="user.loggedIn && currentStep === 'preview' && !isSaveLoading"
          :disabled="isSaveLoading || isDescriptionTooLong"
          v-haptic
        >
          Save
        </button>
        <button v-if="isSaveLoading" disable>Processing...</button>
      </div>
    </Overlay>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { getAuth } from "firebase/auth";
import { ref, onMounted, reactive, watch, computed, toRefs } from "vue";
import { useStore } from "vuex";
import {
  collection,
  getDoc,
  getDocs,
  addDoc,
  doc,
  setDoc,
  serverTimestamp,
  updateDoc,
  limit,
  query,
} from "firebase/firestore";
import { toast } from "vue3-toastify";
import { useI18n } from "vue-i18n";
import { createCustomOrganisation } from "@/utils/createCustomOrganisation";

import Overlay from "@/components/GenericComponents/Overlay.vue";
import EditBasicOrgDetails from "@/components/OrgComponents/OrgCustom/EditBasicOrgDetails.vue";
import EditMonetaryParameters from "@/components/OrgComponents/OrgCustom/EditMonetaryParameters.vue";
import EditLoanDetails from "@/components/OrgComponents/OrgCustom/EditLoanDetails.vue";
import PreviewNewCustomOrgDetails from "@/components/OrgComponents/OrgCustom/PreviewNewCustomOrgDetails.vue";

export default {
  name: "CreateOrgCustom",
  components: {
    Overlay,
    EditBasicOrgDetails,
    EditMonetaryParameters,
    EditLoanDetails,
    PreviewNewCustomOrgDetails,
  },
  props: {
    coinName: {
      type: String,
      required: true,
    },
    coinSymbol: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const auth = getAuth();
    let currentUserUid = ref(auth.currentUser ? auth.currentUser.uid : null);
    const currentStep = ref("initial");
    const showOverlay = ref(false);
    const nextButtonText = ref("Next");
    const isProcessing = ref(false);
    const { coinName } = toRefs(props);

    const showLoginModal = computed(() => store.state.showLoginModal);
    const showRegisterModal = computed(() => store.state.showRegisterModal);
    const user = computed(() => store.getters.user);
    const isDescriptionTooLong = ref(false);

    const toggleLoginModal = () =>
      store.dispatch("toggleLoginModal", !showLoginModal.value);
    const toggleRegisterModal = () =>
      store.dispatch("toggleRegisterModal", !showRegisterModal.value);

    const dynamicTitle = computed(() => {
      switch (currentStep.value) {
        case "initial":
          return "Edit organisation details";
        case "editParams":
          return "Edit monetary rules";
        case "editLoans":
          return "Lending policies";
        case "preview":
          return "Preview details";
      }
    });

    const openOverlay = () => {
      showOverlay.value = true;
      currentStep.value = "initial";
      store.dispatch("toggleNavbarVisibility", false);
    };

    const closeOverlay = () => {
      showOverlay.value = false;
      currentStep.value = "initial";
      store.dispatch("toggleNavbarVisibility", true);
    };

    const goToNextStep = () => {
      switch (currentStep.value) {
        case "initial":
          validateForm("customOrgForm", "editParams");
          nextButtonText.value = "Next";
          break;
        case "editParams":
          validateForm("customOrgMonetaryForm", "preview");
          nextButtonText.value = "Next";
          break;
        case "editLoans":
          currentStep.value = "preview";
          nextButtonText.value = "Save";
          break;
        case "preview":
          isProcessing.value = true;
          nextButtonText.value = "Processing...";
          save().finally(() => {
            isProcessing.value = false;
            nextButtonText.value = "Next";
            closeOverlay();
          });
          break;
      }
    };

    const goToPreviousStep = () => {
      switch (currentStep.value) {
        case "preview":
          currentStep.value = "editParams";
          nextButtonText.value = "Next";
          break;
        case "editParams":
          currentStep.value = "initial";
          nextButtonText.value = "Next";
          break;
      }
    };

    const shouldShowBackArrow = computed(() => {
      return (
        currentStep.value === "editParams" || currentStep.value === "preview"
      );
    });

    const validateForm = (formId, nextStep) => {
      const form = document.getElementById(formId);
      if (form.checkValidity()) {
        currentStep.value = nextStep;
      } else {
        form.reportValidity();
      }
    };

    const orgDetails = reactive({
      organisationName: "",
      description: "",
      category: "",
      basicIncome: 0,
      regularity: "30",
      nextTriggerTime: "",
      coinName: props.coinName || "Unit",
      coinSymbol: store.state.customOrgs.monetary_parameters.coinSymbol || "Ʉ",
      baseFounderCompensation: 0,
      currentAccountCap: 0,
      averageSavingsAccountCap: 0,
      allowSavings: false,
      infiniteSavings: false,
      allowTrades: true,
      loans: {
        dti_effortRate: 0,
        maxLoanTerm: 0,
        annualInterest: 0,
        allowIndividual: false,
        allowJoint: false,
      },
    });

    watch(
      () => orgDetails.coinName,
      (newCoinName) => {
        orgDetails.organisationName = `${newCoinName} unit`;
      }
    );

    const updateRegularity = (newRegularity) => {
      orgDetails.regularity = newRegularity;
    };

    const updateCoinName = (details) => {
      orgDetails.coinName = details.coinName;
      orgDetails.coinSymbol = details.coinSymbol;
      emit("update:coin-details", orgDetails);
    };

    const udpateBasicOrgDetails = (updatedOrgDetails) => {
      Object.assign(orgDetails, updatedOrgDetails);
    };

    const updateLoanDetails = (updatedLoanDetails) => {
      orgDetails.loans = {
        ...updatedLoanDetails.loans,
      };
    };

    const updateMonetaryDetails = (allMonetaryDeets) => {
      const details = toRefs(allMonetaryDeets);
      orgDetails.basicIncome = details.averageBasicIncome.value;
      orgDetails.regularity = details.averageRegularity.value;
      orgDetails.currentAccountCap = details.averageCurrentAccountCap.value;
      orgDetails.currentAccountLifespan =
        details.averageCurrentAccountLifespan.value;
      orgDetails.averageSavingsAccountCap =
        details.averageSavingsAccountCap.value;
      orgDetails.allowSavings = details.allowSavings.value;
      orgDetails.infiniteSavings = details.infiniteSavings.value;
    };

    const updateSavingsAccountDetails = (newDetails) => {
      orgDetails.savingsAccount = newDetails;
    };

    let foundationOrgUID = store.getters.foundationOrgUID;

    const skip = async () => {
      await store.dispatch("fetchBasicOrgDetails", foundationOrgUID);
      const foundationOrgDetails = computed(() =>
        store.getters.getBasicOrgDetails(foundationOrgUID)
      );

      if (foundationOrgDetails.value) {
        orgDetails.basicIncome = foundationOrgDetails.value.basicIncome;
        orgDetails.currentAccountCap =
          foundationOrgDetails.value.currentAccountCap;
        orgDetails.savingsAccountCap =
          foundationOrgDetails.value.savingsAccountCap;
        orgDetails.currentAccountLifespan =
          foundationOrgDetails.value.currentAccountLifespan;
        orgDetails.loans = {
          ...foundationOrgDetails.value.loans,
        };
      }

      currentStep.value = "preview";
    };

    const isSaveLoading = ref(false);
    const save = async () => {
      if (!currentUserUid.value) {
        showLoginModal.value = true;
        return;
      } else {
        try {
          isSaveLoading.value = true;

          const now = new Date();
          const regularityDays = parseInt(orgDetails.regularity, 10);
          const nextTriggerTime = new Date(
            Date.now() + regularityDays * 24 * 60 * 60 * 1000
          );
          orgDetails.nextTriggerTime = nextTriggerTime;

          const orgUID = await createCustomOrganisation(store, orgDetails);
          await store.dispatch("coinCreationActions/createCoin", { orgUID });

          showOverlay.value = false;
          Object.keys(orgDetails).forEach((key) => {
            orgDetails[key] = "";
          });
        } catch (error) {
          console.error("Error during save:", error);
        } finally {
          isSaveLoading.value = false;
        }
      }
    };

    watch(showOverlay, (newValue) => {
      if (newValue) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    });

    watch(
      () => props.coinName,
      (newCoinName) => {
        orgDetails.coinName = newCoinName;
      }
    );

    watch(
      () => auth.currentUser,
      (newUser) => {
        currentUserUid.value = newUser ? newUser.uid : null;
      }
    );

    const handleDescriptionTooLong = (isTooLong) => {
      isDescriptionTooLong.value = isTooLong;
    };

    onMounted(async () => {});

    return {
      user,
      showOverlay,
      currentStep,
      dynamicTitle,
      openOverlay,
      closeOverlay,
      goToNextStep,
      goToPreviousStep,
      shouldShowBackArrow,
      validateForm,
      orgDetails,
      isSaveLoading,
      save,
      showLoginModal,
      showRegisterModal,
      updateCoinName,
      udpateBasicOrgDetails,
      updateMonetaryDetails,
      updateRegularity,
      updateLoanDetails,
      createCustomOrganisation,
      nextButtonText,
      isProcessing,
      skip,
      currentUserUid,
      toggleLoginModal,
      toggleRegisterModal,
      updateSavingsAccountDetails,
      handleDescriptionTooLong,
      isDescriptionTooLong,
    };
  },
};
</script>
