<template>
  <teleport to="body">
    <div
      class="overlay-invite"
      :class="{ 'slide-in': visible, 'slide-out': !visible }"
      @click.self="$emit('close')"
    >
      <div class="overlay-content" @click.stop>
        <div class="overlayHeader">
          <svg
            class="backArrow verySmall cursorNotPointer"
            @click="previousStep"
            v-haptic
          >
            <use
              v-if="showBackArrow"
              href="../../assets/icons/iconset.svg#arrowleft"
            ></use>
          </svg>

          <h2 class="">{{ title }}</h2>
          <svg class="close verySmall" @click="$emit('close')" v-haptic>
            <use href="../../assets/icons/iconset.svg#cross_smaller"></use>
          </svg>
        </div>
        <p ref="copyText" class="selectableP wrap nomargintopbottom" readonly>
          {{ message }}
        </p>
        <ToggleSwitch
          :modelValue="oneTimeUse"
          :label="$t('OverlayInvite.Individual_invite')"
          :fullWidth="false"
          @update:model-value="updateOneTimeUseInFirestore"
        />
        <button v-if="!copyFailed" @click="emitCopyEvent">
          {{ $t("OverlayInvite.Copy_link") }}
        </button>

        <p class="backupmessage" v-if="copyFailed">
          {{ $t("OverlayInvite.Copy_failed_message") }}
        </p>

        <div class="overlay-buttons row">
          <slot></slot>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import {
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
  watch,
  nextTick,
} from "vue";

import { useStore } from "vuex";
import { useRouter } from "vue-router";
// import { toast } from "vue3-toastify";
import ToggleSwitch from "@/components/GenericComponents/ToggleSwitch.vue";

export default {
  name: "OverlayInvite",
  components: {
    ToggleSwitch,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    oneTimeUse: {
      type: Boolean,
      required: true,
    },
    copyFailed: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["close", "generateLinkFromOverlay", "update:oneTimeUse", "copyLink"],
  setup(props, { emit }) {
    const router = useRouter();
    const showBackArrow = ref(null);
    const updateOneTimeUseInFirestore = (value) => {
      emit("update:oneTimeUse", value);
    };
    const copyText = ref(null);

    const emitCopyEvent = () => {
      emit("copyLink");
    };

    const overlayContentRef = ref(null);

    watch(
      () => props.visible,
      (newVal) => {
        if (newVal) {
          nextTick(() => {
            if (overlayContentRef.value) {
              const { width, height } =
                overlayContentRef.value.getBoundingClientRect();
              overlayContentRef.value.style.width = width + "px";
              overlayContentRef.value.style.height = height + "px";
            }
          });
        }
      },
      { immediate: true }
    );

    return {
      close,
      emitCopyEvent, // emitting the copy function to the parent element
      updateOneTimeUseInFirestore,
      emitCopyEvent,
      copyText,
      showBackArrow,
      overlayContentRef,
    };
  },
};
</script>

<style scoped>
.overlay-invite p {
  font-weight: ;
}

.overlay-invite {
  backdrop-filter: blur(10px);
  position: fixed;
  z-index: 2;
  left: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  background-color: transparent;
  color: var(--black);
  background-color: var(--backgroundColourOrgTransp);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: var(--margin);
  opacity: 0;
  pointer-events: none;
  transition: all var(--slideInSpeed);
  padding-bottom: 0;
  margin-bottom: 0;
}

.slide-in {
  opacity: 1;
  pointer-events: auto;
}

.slide-out {
  opacity: 0;
  pointer-events: none;
}

.overlay-content {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--smallMargin);
  z-index: 5;
  background-color: var(--backgroundColourOrg);
  padding: var(--smallMargin);
  padding-bottom: var(--biggerMargin);
  max-height: 100%;
  overflow-y: auto;
  border-top-left-radius: var(--borderRadius);
  border-top-right-radius: var(--borderRadius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;
  transform: translateY(100%);
  transition: all var(--slideInSpeed);
  animation: slide-in var(--slideInSpeed) forwards;
  box-shadow: var(--neuMorphBoxOuter);
}
.overlay-content .overlayHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: var(--tinyMargin);
}

.overlay-content h2 {
  font-size: var(--smallFontsize);
  font-weight: var(--fontweightlightH2);
}

.overlay-buttons {
  display: flex;
  justify-content: center;
  width: 100%;
}

.slide-out .overlay-content {
  animation: slide-out var(--slideInSpeed) forwards;
}

@keyframes slide-in {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

textarea.ridge {
  width: 100%;
  height: fit-content;
  resize: none; /* Prevent resizing of the textarea */
}

/* SMALL SCREENS */
@media only screen and (max-width: 450px) {
  .overlay-content {
    width: 100%;
  }
}
</style>
