<template>
  <div>
    <div class="editing row alignCentreRow">
      <button
        class=""
        :class="{ compactButton: !roleUID }"
        @click="generateLink"
        v-haptic
      >
        Invite <span v-if="roleUID">to this role</span>
      </button>
    </div>

    <OverlayInvite
      ref="OverlayInvite"
      :visible="showOverlay"
      title="Invite link"
      :message="link"
      :oneTimeUse="oneTimeUse"
      :copyFailed="copyFailed"
      @close="closeOverlay"
      @generateLinkFromOverlay="generateLink"
      @update:oneTimeUse="oneTimeUse = $event"
      @copyLink="copyToClipboard"
    >
    </OverlayInvite>
  </div>
</template>

<script>
import { computed, ref, onMounted, watch } from "vue";
import { useStore } from "vuex";

import { db } from "@/firebase";
import {
  doc,
  setDoc,
  getDoc,
  serverTimestamp,
  addDoc,
  collection,
} from "firebase/firestore"; // Add the missing import statement

import { toast } from "vue3-toastify";

import OverlayInvite from "@/components/DashboardComponents/OverlayInvite.vue"; // Import the OverlayInvite component

export default {
  name: "InviteMembers",
  props: {
    orgUID: {
      type: String,
      required: true,
    },
    roleUID: {
      type: String,
      default: null,
    },
  },
  components: {
    OverlayInvite,
  },
  emits: ["confirm", "close", "generateLink"],
  setup(props) {
    const store = useStore();
    const showOverlay = ref(false); // declare the showOverlay ref
    const oneTimeUse = ref(false); // declare the oneTimeUse ref
    const copyFailed = ref(false); // ref to track if the copy operation failed

    const closeOverlay = () => {
      showOverlay.value = false;
      store.dispatch("toggleNavbarVisibility", true); // Hide the Navbar
    };
    const userCredentials = computed(() => {
      return store.getters.userCredentials;
    });
    const getMemberName = (member) => {
      return member.isAnonymous
        ? member.user_details.uid || "Anonymous User"
        : member.user_details.displayName;
    };

    // creating variable to store the invite link
    let link = ref("");
    const inviteId = ref("");

    const updateOneTimeUseInFirestore = async () => {
      const inviteDocRef = doc(
        db,
        "custom-orgs",
        props.orgUID,
        "Invites",
        inviteId.value
      );
      await setDoc(
        inviteDocRef,
        { oneTimeUse: oneTimeUse.value },
        { merge: true }
      );
    };

    // Function to generate dynamic link
    const generateLink = async () => {
      try {
        showOverlay.value = true;
        store.dispatch("toggleNavbarVisibility", false); // Hide the Navbar

        // Debugging: Log the types of orgUID and roleUID props
        console.log(
          "Type of orgUID and roleUID:",
          typeof props.orgUID,
          typeof props.roleUID
        );

        // Create an invitation document in Firestore
        const inviteDocRef = await addDoc(
          collection(db, "custom-orgs", props.orgUID, "Invites"),
          {}
        );
        inviteId.value = inviteDocRef.id;

        // Debugging: Log the inviteId value
        console.log("Generated inviteId:", inviteId.value);

        // Debugging: Log the data about to be set
        console.log("About to set doc:", {
          inviteeUID: null,
          dateCreated: serverTimestamp(),
          oneTimeUse: oneTimeUse.value,
          role: props.roleUID || null,
          status: "pending",
        });

        try {
          await setDoc(
            doc(db, "custom-orgs", props.orgUID, "Invites", inviteId.value),
            {
              inviteeUIDs: [], // Initialize as an empty array to hold multiple invitees
              dateCreated: serverTimestamp(),
              oneTimeUse: oneTimeUse.value,
              role: props.roleUID || null,
              status: "pending",
            }
          );
        } catch (setDocError) {
          console.error("Error in setDoc:", setDocError);
        }

        // Always include inviteId in the URL
        let dynamicLinkURL = `https://unit-org.com/invite/${props.orgUID}/${inviteId.value}`;

        // Debugging: Log the dynamicLinkURL
        console.log("Generated dynamicLinkURL:", dynamicLinkURL);

        link.value = dynamicLinkURL;
      } catch (error) {
        console.error("Error in generateLink function:", error);
      }
    };

    // Ccopying the link to clipboard
    const copyToClipboard = () => {
      // Check if navigator.clipboard is available
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(link.value).then(
          () => {
            copyFailed.value = false;
            alert(`Copied to clipboard: ${link.value}`); // Alert on success
          },
          (err) => {
            console.error("Failed to copy: ", err);
            copyFailed.value = true;
            toast.error(
              "Your environment doesn't allow copying to the clipboard by clicking. Please select and copy the invite link manually."
            );
          }
        );
      } else {
        // Fallback: Use a hidden textarea to copy the text
        const textArea = document.createElement("textarea");
        textArea.value = link.value;
        textArea.style.position = "fixed"; // Avoid scrolling to the bottom in some browsers
        textArea.style.opacity = "0";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand("copy");
          copyFailed.value = false;
          alert(`Copied to clipboard: ${link.value}`); // Alert on success for fallback
        } catch (err) {
          console.error("Fallback: Unable to copy", err);
          copyFailed.value = true;
          toast.error(
            "Your environment doesn't allow copying to the clipboard by clicking. Please select and copy the invite link manually."
          );
        } finally {
          document.body.removeChild(textArea);
        }
      }
    };
    watch(oneTimeUse, () => {
      updateOneTimeUseInFirestore(); // Update the Firestore document
    });

    return {
      userCredentials,
      getMemberName,
      generateLink,
      showOverlay,
      closeOverlay,
      link,
      oneTimeUse,
      copyToClipboard,
      copyFailed,
      updateOneTimeUseInFirestore,
    };
  },
};
</script>
