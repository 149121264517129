// utils/priceUtils.js
import {
  calculatePrice,
  calculateReversePrice,
} from "@/utils/exchangeRateCalculator";
import store from "@/store"; // Ensure correct path to your Vuex store
import { getAuth } from "firebase/auth"; // Import Firebase auth

export const getConvertedPrice = async (product, sellerOrgUIDarg, priceArg) => {
  try {
    const buyerOrgUID = store.state.currentOrgUID;
    const sellerOrgUID = product.organisation || sellerOrgUIDarg;

    // console.log("getConvertedPrice called with:", {
    //   buyerOrgUID,
    //   sellerOrgUID,
    //   product,
    //   priceArg,
    // });

    if (!buyerOrgUID || !sellerOrgUID) {
      throw new Error("Organization UIDs are missing");
    }

    let buyerOrgDetails = { membersCount: 1, totalOrgCoins: 0 };
    let sellerOrgDetails = { membersCount: 1, totalOrgCoins: 0 };

    await Promise.all([
      store.dispatch("fetchAndCommitOrgMembersCount", {
        orgUID: buyerOrgUID,
        context: "buyer",
      }),
      store.dispatch("fetchAndCommitOrgTotalCoins", {
        orgUID: buyerOrgUID,
        context: "buyer",
      }),
      store.dispatch("fetchAndCommitOrgMembersCount", {
        orgUID: sellerOrgUID,
        context: "seller",
      }),
      store.dispatch("fetchAndCommitOrgTotalCoins", {
        orgUID: sellerOrgUID,
        context: "seller",
      }),
    ]);

    buyerOrgDetails = store.getters.getBuyerOrgDetails(buyerOrgUID) || {
      membersCount: 1,
      totalOrgCoins: 0,
    };
    sellerOrgDetails = store.getters.getSellerOrgDetails(sellerOrgUID) || {
      membersCount: 1,
      totalOrgCoins: 0,
    };

    // console.log("Buyer Org Details:", buyerOrgDetails);
    // console.log("Seller Org Details:", sellerOrgDetails);

    const buyerMembersCount = buyerOrgDetails.membersCount || 1;
    const buyerTotalCoins = buyerOrgDetails.totalOrgCoins || 0;
    const sellerMembersCount = sellerOrgDetails.membersCount || 1;
    const sellerTotalCoins = sellerOrgDetails.totalOrgCoins || 0;

    let convertedPrice;

    if (product.type === "send" || product.type === "request") {
      // console.log("Using calculateReversePrice");
      const { amountInReceiverCoins } = calculateReversePrice(
        buyerMembersCount,
        sellerMembersCount,
        priceArg,
        buyerTotalCoins,
        sellerTotalCoins
      );
      convertedPrice = amountInReceiverCoins;
    } else {
      // console.log("Using calculatePrice");
      convertedPrice = calculatePrice(
        buyerMembersCount,
        sellerMembersCount,
        priceArg,
        buyerTotalCoins,
        sellerTotalCoins
      );
    }

    // console.log("Calculated Converted Price:", convertedPrice);

    if (isNaN(convertedPrice)) {
      throw new Error("Converted price calculation resulted in NaN");
    }

    const displayConvertedPrice = isFinite(convertedPrice)
      ? convertedPrice.toFixed(4)
      : "Processing...";

    const coinSymbol = store.getters.coinSymbol || "Ʉ";

    // console.log("Final Converted Price and Symbol:", {
    //   displayConvertedPrice,
    //   coinSymbol,
    // });

    return {
      convertedPrice: displayConvertedPrice,
      coinSymbol,
    };
  } catch (error) {
    console.error("Error in getConvertedPrice:", error);
    return {
      convertedPrice: "Error",
      coinSymbol: store.getters.coinSymbol || "Ʉ",
    };
  }
};
