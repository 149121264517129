<template>
  <div :style="containerStyle" class="toggle-container row alignCentreRow">
    <!-- Conditionally render <p> or <label> tag based on useLabelTag prop -->
    <div class="row alignCentreRow">
      <p
        class="nomargintopbottom"
        :class="{ 'active-label': isChecked }"
        v-if="!useLabelTag"
      >
        {{ label }}
      </p>
      <label v-else :class="{ 'active-label': isChecked }">{{ label }}</label>
      <slot name="tooltip"></slot>
    </div>
    <span class="row alignCentreRow">
      <label class="switch">
        <input type="checkbox" v-model="isChecked" />
        <span class="slider round" v-haptic></span>
      </label>
      <!-- Conditionally render status text based on hideStatusText prop -->
      <div
        v-if="!hideStatusText"
        :class="['shortWidth', { purpleText: isChecked }]"
      >
        {{ isChecked ? "Yes" : "No" }}
      </div>
    </span>
  </div>
</template>

<script>
import { ref, watch, computed } from "vue";

export default {
  name: "ToggleSwitch",
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
    hideStatusText: {
      type: Boolean,
      default: false,
    },
    useLabelTag: {
      type: Boolean,
      default: false,
    },
    justifyToEnd: {
      type: Boolean,
      default: false,
    },
    justifyToStart: {
      type: Boolean,
      default: false,
    },
    justifyToStartEnd: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: true,
    },
    alignToCentre: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const isChecked = ref(props.modelValue);

    // Watch for internal value changes and notify the parent
    watch(isChecked, (newValue) => {
      emit("update:modelValue", newValue);
    });

    const containerStyle = computed(() => {
      const styleObject = {};

      if (props.justifyToStart) {
        styleObject["justify-content"] = "flex-start";
      } else if (props.justifyToEnd) {
        styleObject["justify-content"] = "flex-end";
      } else if (props.alignToCentre) {
        styleObject["justify-content"] = "center";
      } else if (props.justifyToStartEnd) {
        styleObject["justify-content"] = "space-between";
      }

      if (props.fullWidth) {
        styleObject["width"] = "100%";
      }

      return styleObject;
    });

    return {
      isChecked,
      containerStyle,
    };
  },
};
</script>
<style scoped>
.shortWidth {
  width: 2.7ch;
  text-align: start;
}

.toggle-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 54px; /* 97% of 56px ~ 3% less wide */
  height: 31px; /* 102% of 30px ~ 2% thicker */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: var(--slideInSpeed) cubic-bezier(0.85, 0.05, 0.18, 1.35);
  box-shadow: var(--neuMorphThinRidge);
}

.active-label {
  color: var(--purplePastel) !important;
}

.slider:before {
  position: absolute;
  content: "";
  height: 21px; /* As the switch is now thicker, inner circle height is 2px less */
  width: 21px; /* As the switch is now thicker, inner circle width is 2px less */
  left: 5px; /* 1px more distance between the inner circle and the container */
  bottom: 5px; /* 1px more distance between the inner circle and the container */
  box-shadow: var(--neuMorphBoxOuterSmall);
  background-color: var(--solidlightestgrey);
  transition: var(--slideInSpeed) cubic-bezier(0.85, 0.05, 0.18, 1.35);
}

input:checked + .slider {
}

input:checked + .slider:before {
  transform: translateX(24px); /* Adjusted for 3% less width */
  background-color: var(--purplePastel);
}

/* Rounded sliders */
.slider.round {
  border-radius: 32px; /* Adjusted to match new height */
}

.slider.round:before {
  border-radius: 50%;
}
</style>
