<template>
  <div class="column alignCentre fullWidth">
    <!-- Drag & Drop and Upload Button Area -->
    <div
      class="drop-area column alignCentre"
      @click="triggerFileInput"
      @dragover.prevent
      @dragenter.prevent="handleDragEnter"
      @dragleave.prevent="handleDragLeave"
      @drop.prevent="handleDrop"
    >
      <svg class="smaller">
        <use href="@/assets/icons/iconset.svg#upload"></use>
      </svg>
      <div class="alignCentre">
        <p class="nomargintopbottom">Drag your file here or click to upload</p>
        <div class="or">or</div>
        <button
          class="smallButton"
          type="button"
          @click.stop="triggerCameraInput"
          v-haptic
        >
          <svg class="compactImg">
            <use href="@/assets/icons/iconset.svg#camera_compact"></use>
          </svg>
          Capture image
        </button>
      </div>
    </div>

    <!-- Preview Area -->
    <div class="previewArea" v-if="imagePreview">
      <div class="image-container">
        <div class="imageActionsCont innerSticker" @click.stop>
          <svg class="smallEdit compactImg" v-haptic @click="editImage">
            <use href="@/assets/icons/iconset.svg#edit_compact"></use>
          </svg>
          <svg class="smallClose compactImg" v-haptic @click="removeImage">
            <use href="@/assets/icons/iconset.svg#trash_compact"></use>
          </svg>
        </div>
        <img class="previewedImg" :src="imagePreview" alt="" />
      </div>
    </div>

    <!-- Cropper -->
    <Overlay
      v-if="isCropping"
      :visible="isCropping"
      :title="'Crop image'"
      @close="closeOverlay"
    >
      <template #before-buttons>
        <ImageCrop
          v-if="currentImageSrc"
          :imageSrc="currentImageSrc"
          @updateImage="updateImage"
          @cancelCrop="isCropping = false"
        />
      </template>
    </Overlay>

    <!-- Hidden Inputs -->
    <input
      type="file"
      accept="image/*"
      @change="handleFileSelection"
      ref="fileInput"
      style="display: none"
    />
    <input
      type="file"
      accept="image/*"
      capture="environment"
      @change="handleFileSelection"
      ref="cameraInput"
      style="display: none"
    />
  </div>
</template>

<script>
import { ref, watch, onMounted, onUnmounted } from "vue";
import ImageCrop from "@/components/GenericComponents/ImageCrop.vue";
import Overlay from "@/components/GenericComponents/Overlay.vue";
import { toast } from "vue3-toastify";

export default {
  name: "ImageUploadSingleImg",
  components: {
    Overlay,
    ImageCrop,
  },
  props: {
    existingImages: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["updateImages", "logoRemoved"],

  setup(props, { emit }) {
    const fileInput = ref(null);
    const cameraInput = ref(null);
    const selectedFile = ref(null);
    const imagePreview = ref(null);
    const isCropping = ref(false);
    const currentImageSrc = ref(null);

    watch(
      () => props.existingImages,
      (newVal) => {
        if (Array.isArray(newVal) && newVal.length > 0) {
          console.log("Existing image found:", newVal[0]);
          imagePreview.value = newVal[0];
        }
      },
      { immediate: true }
    );

    const closeOverlay = async () => {
      console.log("Closing image crop overlay.");
      document.body.classList.remove("no-scroll");
      isCropping.value = false;
    };

    const triggerFileInput = () => {
      console.log("File input triggered.");
      fileInput.value.click();
    };

    const triggerCameraInput = () => {
      console.log("Camera input triggered.");
      cameraInput.value.click();
    };

    const handleFileSelection = async (event) => {
      console.log("File selection event triggered.");
      const newFile = event.target.files ? event.target.files[0] : null;

      if (newFile) {
        console.log("File selected:", newFile.name);
        // Resize the image
        const resized = await resizeImage(newFile, 1920);
        selectedFile.value = resized.file;
        imagePreview.value = resized.url;
        emitUpdateImages();
      } else {
        console.log("No file selected.");
      }

      event.target.value = ""; // Reset file input
    };

    const resizeImage = (file, maxDimension) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        const url = URL.createObjectURL(file);
        img.onload = () => {
          let width = img.width;
          let height = img.height;

          // Calculate the new dimensions
          if (width > height) {
            if (width > maxDimension) {
              height *= maxDimension / width;
              width = maxDimension;
            }
          } else {
            if (height > maxDimension) {
              width *= maxDimension / height;
              height = maxDimension;
            }
          }

          // Create a canvas and draw the image into it
          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          // Convert the canvas back to a Blob
          canvas.toBlob((blob) => {
            const resizedFile = new File([blob], file.name, {
              type: file.type,
            });
            const resizedUrl = URL.createObjectURL(resizedFile);
            resolve({ file: resizedFile, url: resizedUrl });
            URL.revokeObjectURL(url); // Clean up
          }, file.type);
        };
        img.onerror = (err) => {
          URL.revokeObjectURL(url); // Clean up
          reject(err);
        };
        img.src = url;
      });
    };

    const emitUpdateImages = () => {
      console.log("Emitting updateImages event with selected file.");
      emit("updateImages", [
        { file: selectedFile.value, url: imagePreview.value },
      ]);
    };

    const handleDragEnter = (event) => {
      console.log("Drag enter event triggered.");
      event.target.classList.add("drag-over");
    };

    const handleDragLeave = (event) => {
      console.log("Drag leave event triggered.");
      event.target.classList.remove("drag-over");
    };

    const handleDrop = async (event) => {
      console.log("Drop event triggered.");
      const newFile = event.dataTransfer.files
        ? event.dataTransfer.files[0]
        : null;

      if (newFile) {
        console.log("File dropped:", newFile.name);
        // Resize the image
        const resized = await resizeImage(newFile, 1920);
        selectedFile.value = resized.file;
        imagePreview.value = resized.url;
        emitUpdateImages();
      } else {
        console.log("No file dropped.");
      }
    };

    const removeImage = async () => {
      console.log("Remove image triggered.");
      if (imagePreview.value) {
        if (typeof imagePreview.value === "string") {
          console.log(
            "Emitting logoRemoved event for URL:",
            imagePreview.value
          );
          emit("logoRemoved", imagePreview.value);
        } else {
          URL.revokeObjectURL(imagePreview.value);
          console.log("Object URL revoked for:", imagePreview.value);
        }
        imagePreview.value = null;
        selectedFile.value = null;
        emit("updateImages", []);
        console.log("Image removed and updateImages emitted with empty array.");
      }
    };

    const editImage = () => {
      console.log("Edit image triggered.");
      document.body.classList.add("no-scroll");
      isCropping.value = true;
      currentImageSrc.value = imagePreview.value;
      console.log("Image cropping started for:", imagePreview.value);
    };

    const updateImage = async ({ file, url }) => {
      console.log("Updating image after crop.");
      // Resize the cropped image
      const resized = await resizeImage(file, 1920);
      selectedFile.value = resized.file;
      imagePreview.value = resized.url;
      emitUpdateImages();
      isCropping.value = false;
      console.log("Image updated successfully.");
    };

    onMounted(() => {
      if (props.existingImages.length > 0) {
        console.log(
          "On mounted: Existing image detected:",
          props.existingImages[0]
        );
        imagePreview.value = props.existingImages[0];
      }
    });

    onUnmounted(() => {
      if (imagePreview.value && imagePreview.value instanceof File) {
        URL.revokeObjectURL(imagePreview.value);
        console.log(
          "On unmounted: Object URL revoked for:",
          imagePreview.value
        );
      }
    });

    return {
      fileInput,
      cameraInput,
      triggerFileInput,
      triggerCameraInput,
      handleFileSelection,
      imagePreview,
      handleDragEnter,
      handleDragLeave,
      handleDrop,
      removeImage,
      isCropping,
      editImage,
      updateImage,
      currentImageSrc,
      closeOverlay,
    };
  },
};
</script>

<style scoped>
.drop-area {
  border: 1px dashed var(--solidMidDarkgrey);
  padding: var(--margin);
  text-align: center;
  border-radius: var(--borderRadius);
  width: 100%;
}

.drop-area.drag-over {
  border-color: green;
  background-color: var(--lightGreen);
}

.previewArea {
  display: flex;
  width: 100%;
  overflow-x: auto;
  gap: var(--smallMargin);
  padding: var(--smallMargin);
  box-shadow: var(--neuMorphBoxInner);
  border-radius: var(--borderRadiusEvenBigger);
}

.image-container {
  position: relative;
  min-width: 100%;
  width: 100%;
  height: 150px;
  display: flex;
  border-radius: var(--borderRadiusBigger);
  overflow: hidden;
}

svg.smallClose {
  position: initial;
}
.smallEdit {
  top: var(--tinyMargin);
  transition: all 0.15s;
  opacity: 0.8;
}

.smallEdit:hover {
  opacity: 1;
  transform: translateY(1px);
}

.previewedImg {
  object-fit: cover;
  width: 100%;
}
.alignCentre {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 450px) {
  .image-container {
    min-width: 100%;
    width: 100%;
    height: 170px;
  }
}
</style>
