<template>
  <div class="user-avatarSmall">
    <div
      v-if="photoURL"
      :class="{ avatarCircleSmall: true, 'emphasized-border': emphasis }"
    >
      <img :src="photoURL" :alt="displayName" />
    </div>
    <div
      v-else
      :class="{
        avatarCircleSmall: true,
        anonymous: isAnonymous,
        'emphasized-border': emphasis,
      }"
    >
      <h1 class="nomargintopbottom" :class="{ 'emphasized-text': emphasis }">
        {{ fallbackInitial }}
      </h1>
    </div>
    <div class="row alignCentreRow">
      <div class="column tinyGap">
        <span class="display-name">
          <h4
            :class="{ 'emphasized-text': emphasis }"
            class="nomargintopbottom"
          >
            {{ truncatedDisplayName }}
          </h4>
        </span>
        <small class="nomargintopbottom sellerInfo"><slot></slot></small>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  props: {
    user: Object,
    emphasis: Boolean,
  },
  setup(props) {
    const isAnonymous = computed(() => props.user?.isAnonymous ?? true);

    const photoURL = computed(() => {
      return (
        props.user?.photoURL ||
        props.user?.profile_picture ||
        (props.user?.providerData && props.user.providerData[0]?.photoURL) ||
        null
      );
    });

    const email = computed(() => props.user?.email ?? "");

    const displayName = computed(() => {
      // console.log("props.user", props.user);
      return (
        props.user?.displayName ||
        (props.user?.providerData && props.user.providerData[0]?.displayName) ||
        (props.user?.uid
          ? `${props.user.uid.slice(0, 4)}...${props.user.uid.slice(-4)}`
          : "Anonymous user")
      );
    });

    const truncatedDisplayName = computed(() => {
      if (displayName.value) {
        const names = displayName.value.split(" ");
        if (names.length > 1) {
          return `${names[0]} ${names[names.length - 1]}`;
        }
      }
      return displayName.value;
    });

    const fallbackInitial = computed(() => {
      if (isAnonymous.value) {
        return "A";
      }
      if (email.value) {
        return email.value.charAt(0).toUpperCase();
      }
      return "U";
    });

    return {
      isAnonymous,
      photoURL,
      email,
      displayName,
      truncatedDisplayName,
      fallbackInitial,
    };
  },
};
</script>

<style scoped>
/* .user-avatar img,
.avatarCircle img,
.avatarCircle {
  border: 1px solid var(--solidMidDarkgrey);
  box-shadow: none;
} */

.user-avatarSmall img,
.avatarCircleSmall img,
.avatarCircleSmall {
  box-shadow: none;
}

.avatarCircleSmall {
  border: 1px solid var(--solidMidDarkgrey);
}

/* Remove border from the container when it has an img child */
.avatarCircleSmall:has(img) {
  border: none;
}

/* Apply border to the image when it is present */
.avatarCircleSmall img {
  border: 1px solid var(--solidMidDarkgrey);
}
.emphasized-border,
.user-avatarSmall.emphasized-border img,
.avatarCircleSmall.emphasized-border img,
.avatarCircleSmall.emphasized-border {
  border: 1px solid var(--purple400) !important; /* You can adjust the border thickness */
}

.emphasized-text {
  color: var(--purple400);
  /* font-weight: var(--fontweightheavy); */
}
</style>
