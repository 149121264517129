<template>
  <div class="password-page main-container">
    <svg id="unit">
      <use href="../../assets/icons/iconset.svg#logo"></use>
    </svg>
    <br />
    <div class="form-group passwordPage">
      <label for="passwordPage">Password</label>

      <form @submit.prevent="submitPassword">
        <div class="row">
          <div class="row-child">
            <input
              id="passwordPage"
              :type="showPassword ? 'text' : 'password'"
              v-model="passwordPage"
            />
          </div>
          <button type="submit" v-haptic>Sign In</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "PasswordPage",
  setup(_, { emit }) {
    const passwordPage = ref("");
    const showPassword = ref(false);

    const submitPassword = () => {
      emit("passwordEntered", passwordPage.value);
      passwordPage.value = ""; // Reset the passwordPage input field
    };

    return {
      passwordPage,
      showPassword,
      submitPassword,
    };
  },
};
</script>

<style scoped>
.password-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Add this line */
  height: 100vh; /* Add this line */
}

form {
  width: 100% !important;
}
.password-page form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Add this line */
  max-width: var(
    --inputWidth
  ); /* Adjust this line based on your desired max-width */
}

.form-group {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  min-width: var(--inputWidth);
  transition: 0.15s all;
  align-items: center;
}

label {
  font-size: var(--smallFontsize);
  margin-bottom: 4px;
  display: flex;
  justify-content: center;
}

.row-child {
  position: relative;
}

button.show-password-button {
  position: absolute;
  width: 0px;
  padding: 0px;
  border: none;
  background: transparent;
  opacity: 0.3;
  transition: all 0.15s;
}

svg {
  position: absolute;
  top: 50%;
  right: var(--smallMargin);
  transform: translateY(-50%);
  cursor: pointer;
}

svg#unit {
  position: relative;
  right: initial;
  transform: initial;
  top: initial;
  width: var(--clickableHeight);
  height: var(--clickableHeight);
}
</style>
