import { ref, computed } from "vue";
import { db } from "@/firebase"; // import your firebase instance
import { doc, getDoc, updateDoc } from "firebase/firestore"; // import firestore methods

export const coinSymbolStates = {
  state: () => ({
    coinSymbol: localStorage.getItem("coinSymbol") || "",
    coinName: localStorage.getItem("coinName") || "",
    logoUrl: localStorage.getItem("logoUrl") || "",
  }),
  getters: {
    getCoinSymbol: (state) => state.coinSymbol,
    getCoinName: (state) => state.coinName,
    getLogoUrl: (state) => {
      console.log(
        "Getter getLogoUrl called with state.logoUrl:",
        state.logoUrl
      );
      return state.logoUrl;
    },
  },
  mutations: {
    setCoinSymbol(state, symbol) {
      state.coinSymbol = symbol;
      localStorage.setItem("coinSymbol", symbol);
    },
    setCoinName(state, name) {
      state.coinName = name;
      localStorage.setItem("coinName", name);
    },
    setLogoUrl(state, url) {
      state.logoUrl = url;
      localStorage.setItem("logoUrl", url);
    },
  },
  actions: {
    updateCoinSymbol({ commit }, { symbol, name }) {
      // console.log(
      //   "Action updateCoinSymbol called with symbol:",
      //   symbol,
      //   "name:",
      //   name
      // );
      if (name) {
        commit("setCoinName", name);
      }
      commit("setCoinSymbol", symbol);
    },
    async fetchLogoUrl({ commit }, orgUID) {
      try {
        const orgDocRef = doc(db, "custom-orgs", orgUID);
        const orgDoc = await getDoc(orgDocRef);
        if (orgDoc.exists()) {
          const logoUrl = orgDoc.data().logo;
          commit("setLogoUrl", logoUrl);
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error fetching logo URL:", error);
      }
    },
    async updateLogoUrl({ commit }, { orgUID, logoUrl }) {
      try {
        const orgDocRef = doc(db, "custom-orgs", orgUID);
        await updateDoc(orgDocRef, { logo: logoUrl });
        commit("setLogoUrl", logoUrl);
      } catch (error) {
        console.error("Error updating logo URL:", error);
      }
    },
  },
};
