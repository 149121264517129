<template>
  <div class="fullWidth">
    <!-- Header with Organisation Name and Category -->

    <div class="thead">
      <h3 class="smallmarginbottom">{{ orgDetails.organisationName }}</h3>
      <small>{{ orgDetails.category }}</small>
    </div>
    <table class="divSmallMarginBottom">
      <tbody>
        <tr v-if="formattedBasicIncome">
          <td>Basic Income</td>
          <td>{{ formattedBasicIncome }}</td>
        </tr>
        <template v-for="(value, key) in orgDetails" :key="key">
          <!-- Conditionally render the entire row -->
          <tr v-if="!shouldSkipKey(key, value)">
            <td>{{ formatKey(key) }}</td>
            <td :class="{ smallText: key === 'description' }">
              {{ formatValue(key, value) }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { formatNumberTo5Digits } from "@/utils/numberFormattingTo5digits";
import { useStore } from "vuex";

import { ref, toRefs, computed, watch, watchEffect } from "vue";

export default {
  props: ["orgDetails"],
  setup(props) {
    const store = useStore();
    const { orgDetails } = toRefs(props);
    const foundationOrgUID = computed(() => store.state.foundationOrgUID);

    // Computed property for formatted basic income
    const formattedBasicIncome = computed(() => {
      const { basicIncome, coinSymbol, regularity } = orgDetails.value;
      if (basicIncome > 0) {
        return `${basicIncome} ${coinSymbol || "–"} / ${regularity || 30} days`;
      }
      return null;
    });

    watch(orgDetails, (newValue, oldValue) => {
      if (newValue.regularity !== oldValue.regularity) {
        // Trigger the computed property to update
        formattedBasicIncome.value;
      }
    });

    // watch(orgDetails, (newValue) => {
    //   // Trigger reactivity for formattedBasicIncome when orgDetails changes
    //   // You don't need to manually update the computed property; it will recalculate on its own
    // });

    // watchEffect(() => {
    //   console.log("orgDetails.regularity", orgDetails.value.regularity);
    //   console.log("formattedBasicIncome", formattedBasicIncome.value);
    // });

    function formatKey(key) {
      const keyMapping = {
        organisationName: "Organisation Name",
        description: "Description",
        category: "Category",
        basicIncome: "Basic Income",
        coinName: "Coin",
        coinSymbol: "Coin Symbol",
        baseFounderCompensation: "Founder Salary",
        currentAccountCap: "Current Account Cap",
        averageSavingsAccountCap: "Savings Account Cap",
        allowSavings: "Allow Savings",
        infiniteSavings: "Infinite Savings",
        allowTrades: "Open Economy",
        loans: "Loans",
        currentAccountLifespan: "Current Account Lifespan",
        // Additional keys from the loans object
        maxLoanTerm: "Maximum Loan Term",
        dti_effortRate: "DTI Effort Rate",
        annualInterest: "Annual Interest Rate",
        allowIndividual: "Allow Individual Loans",
        allowJoint: "Allow Joint Loans",
      };
      return keyMapping[key] || key;
    }
    function shouldSkipKey(key, value) {
      // Define keys that should not be displayed

      const keysToAlwaysSkip = [
        "organisationName",
        "category",
        "coinSymbol",
        "allowSavings",
        "regularity",
        "nextTriggerTime",
        "basicIncome",
      ];
      // Skip keys that are always excluded
      if (keysToAlwaysSkip.includes(key)) return true;

      // Skip loans information if both allowIndividual and allowJoint are false
      if (key === "loans" && !value.allowIndividual && !value.allowJoint) {
        return true; // Skip rendering loan details
      }

      // Skip keys if their value is falsy (0, '', false, null, or undefined)
      const keysToSkipIfFalsy = [
        "description",
        "baseFounderCompensation",
        "allowSavings",
        "basicIncome",
        "averageSavingsAccountCap",
      ];
      if (keysToSkipIfFalsy.includes(key) && !value) return true;

      // Skip infiniteSavings if it is false
      if (key === "infiniteSavings" && !value) return true;

      // Skip averageSavingsAccountCap if infiniteSavings is true
      if (
        key === "averageSavingsAccountCap" &&
        orgDetails.value.infiniteSavings
      )
        return true;

      return false;
    }
    function formatValue(key, value) {
      // Check for null or empty values
      if (value === null || value === undefined || value === "") {
        if (key === "description") {
          return null;
        }
        return;
      }

      // Run through formatNumberTo5Digits if value is a number - Format numbers to 5 digits
      if (typeof value === "number") {
        value = formatNumberTo5Digits(value);
      }

      // Truncate long descriptions
      if (
        key === "description" &&
        typeof value === "string" &&
        value.length > 100
      ) {
        value = `${value.substring(0, 99)}…`;
      }

      // Special formatting for loans
      if (key === "loans" && typeof value === "object") {
        let loanStrings = [];
        if (value.allowIndividual) {
          loanStrings.push("Individual loans ✓");
        }
        if (value.allowJoint) {
          loanStrings.push("Joint loans ✓");
        }
        return loanStrings.join(", ") || "No"; // Join all strings into one with commas and return "No" if empty
      }
      // Format trades

      if (key === "allowTrades") {
        return value ? "Yes" : "No";
      }

      // Special case for savingsAccountCap
      if (key === "averageSavingsAccountCap") {
        const symbol = orgDetails.value.coinSymbol || "–";
        return `${value} ${symbol}`;
      }
      // Special case for "Allow Savings"
      // if (key === "allowSavings") {
      //   return value ? '✓' : 'No';
      // }

      // Format numeric values with coin symbol
      if (
        [
          "basicIncome",
          "baseFounderCompensation",
          "currentAccountCap",
        ].includes(key)
      ) {
        const symbol = orgDetails.value.coinSymbol || "–";
        return `${value} ${symbol}`;
      }

      // Format account lifespan
      if (key === "currentAccountLifespan") {
        return `${value} day${value !== 1 ? "s" : ""}`;
      }

      // Format coin name and symbol
      if (key === "coinName") {
        const symbol = orgDetails.value.coinSymbol || "–";
        return `${value || "–"} (${symbol})`;
      }

      // Format infinite savings
      if (key === "infiniteSavings") {
        return value ? "✓" : "";
      }

      // Special formatting for basicIncome to include regularity
      // Special formatting for basicIncome to include regularity
      if (key === "basicIncome" && value > 0) {
        const symbol = orgDetails.value.coinSymbol || "–";
        const regularity = orgDetails.value.regularity || 30; // Default to 30 if not provided
        return `${value} ${symbol} / ${regularity} days`;
      }

      return value || "–";
    }

    return {
      formatKey,
      formatValue,
      shouldSkipKey,

      orgDetails,
      foundationOrgUID,
      formattedBasicIncome,
    };
  },
};
</script>

<style scoped>
.smallText {
  text-align: left;
}

.thead {
  text-align: center;
}
.thead h3 {
  width: 100%;
}

td:last-child {
  padding-left: var(--smallMargin);
}
</style>
