import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  runTransaction,
  collection,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "@/firebase";
import moneyPackHandler from "@/utils/moneyPackHandler";
import { MoneyPackSchema } from "@/schemas/WalletSchema";
import { toast } from "vue3-toastify";

export default {
  namespaced: true,
  state: {
    currentAccountBalance: 0,
  },
  getters: {
    getCurrentAccountBalance: (state) => state.currentAccountBalance,
  },
  mutations: {
    UPDATE_ACCOUNT_BALANCE(state, balance) {
      console.log("Updating account balance IN COINCREATION ACTIONS:", balance);
      state.currentAccountBalance = balance;
    },
  },
  actions: {
    async getOrgData(_, orgUID) {
      if (!orgUID) throw new Error("Organization UID is required");
      const orgDocRef = doc(db, "custom-orgs", orgUID);
      const orgDocSnap = await getDoc(orgDocRef);

      if (!orgDocSnap.exists()) {
        throw new Error("Organization data not found.");
      }

      return orgDocSnap.data();
    },

    async getUserWalletData(_, { userUID, orgUID }) {
      if (!userUID || !orgUID)
        throw new Error("User UID and Organization UID are required");
      const walletDocRef = doc(db, "users", userUID, "Wallets", orgUID);
      const walletDocSnap = await getDoc(walletDocRef);
      if (!walletDocSnap.exists()) {
        throw new Error("Wallet document does not exist.");
      }
      return walletDocSnap.data();
    },

    async updateWallet(
      { commit },
      { userUID, orgUID, newBalance, newMoneyPacks }
    ) {
      if (this.updatingWallet) {
        console.warn("Wallet update already in progress, skipping...");
        return;
      }
      this.updatingWallet = true;
      console.log("Updating wallet:", {
        userUID,
        orgUID,
        newBalance,
        newMoneyPacks,
      });
      const walletDocRef = doc(db, "users", userUID, "Wallets", orgUID);
      try {
        await updateDoc(walletDocRef, {
          "currentAccount.balance": newBalance,
          "currentAccount.moneyIn": arrayUnion(...newMoneyPacks),
        });
        commit("UPDATE_ACCOUNT_BALANCE", newBalance);
        toast.success("Wallet updated successfully");
      } catch (error) {
        console.error("Failed to update wallet:", error);
        toast.error("Failed to update wallet. Please try again.");
      } finally {
        this.updatingWallet = false;
      }
    },

    async createCoin(
      { commit, dispatch },
      { orgUID, currentAccountLifespan, coinAmount }
    ) {
      console.log("Creating coin with coinAmount:", coinAmount);

      try {
        const auth = getAuth();
        const userUID = auth.currentUser?.uid;

        if (!userUID) {
          throw new Error("User not logged in.");
        }

        console.log("Fetching organization data for orgUID:", orgUID);
        const orgData = await dispatch("getOrgData", orgUID);

        console.log("Organization data fetched:", orgData);

        let walletData = await dispatch("getUserWalletData", {
          userUID,
          orgUID,
        });

        console.log("User wallet data fetched:", walletData);

        // Create coin ID and transaction time
        const coinId = doc(collection(db, "temp")).id;
        const transactionTime = Date.now();

        // Set default lifespan if not provided
        const lifespan =
          currentAccountLifespan ||
          orgData.monetary_parameters?.current_account?.lifespan ||
          30;
        console.log("Lifespan for current account set to:", lifespan);

        // Create a new MoneyPack for the coin
        const newMoneyPack = {
          ...MoneyPackSchema,
          id: coinId,
          value: coinAmount, // Use the specified coin amount
          transactionTime: transactionTime,
          lastUpdatedTime: transactionTime,
          moneyPackType: "moneyIn",
          lifespan: lifespan,
          remainingLifespanByWallet: { [orgUID]: lifespan },
          transactionType: "🤑 Ad hoc coin",
          optionalFields: {
            fromOrgName: orgData.name,
            fromOrgUID: orgUID,
            toOrgName: orgData.name,
            toOrgUID: orgUID,
          },
        };

        console.log("New MoneyPack created:", newMoneyPack);

        // Handle the money pack creation logic
        walletData = await moneyPackHandler(
          userUID,
          orgUID,
          newMoneyPack,
          orgData,
          walletData,
          "client" // Ensure context is 'client'
        );

        if (
          !walletData ||
          !walletData.currentAccount ||
          walletData.currentAccount.balance === undefined
        ) {
          throw new Error(
            "Failed to process wallet data after moneyPackHandler."
          );
        }

        console.log("Wallet data after moneyPackHandler:", walletData);
        if (!walletData || !walletData.currentAccount) {
          throw new Error(
            "Failed to process wallet data after moneyPackHandler."
          );
        }

        // Commit the updated balance to Vuex store
        commit("UPDATE_ACCOUNT_BALANCE", walletData.currentAccount.balance);
        console.log(
          "UPDATE_ACCOUNT_BALANCE committed with balance:",
          walletData.currentAccount.balance
        );

        // Success toast notification
        toast.success("🤑 Coin created successfully");
      } catch (error) {
        console.error("Failed to create coin:", error);
        toast.error("Failed to create coin. Please try again.");
      }
    },

    async handleTransaction({ dispatch, commit }, transactionDetails) {
      const {
        buyerUID,
        sellerUID,
        buyerOrgUID,
        sellerOrgUID,
        productPrice,
        convertedPrice,
        itemName,
        type,
      } = transactionDetails;

      if (!buyerOrgUID || !sellerOrgUID) {
        toast.error("Buyer and Seller Organization IDs are required");
        return;
      }

      try {
        const transactionTime = Date.now();
        console.log("Transaction time:", transactionTime);

        const buyerOrgData = await dispatch("getOrgData", buyerOrgUID);
        const sellerOrgData = await dispatch("getOrgData", sellerOrgUID);

        const buyerWalletData = await dispatch("getUserWalletData", {
          userUID: buyerUID,
          orgUID: buyerOrgUID,
        });

        const sellerWalletData = await dispatch("getUserWalletData", {
          userUID: sellerUID,
          orgUID: sellerOrgUID,
        });

        let moneyOutValue = convertedPrice;
        let moneyInValue = productPrice;
        let moneyOutTransactionType = `💳 Paid for ${itemName}`;
        let moneyInTransactionType = `💳 Received for ${itemName}`;

        // Swap values if the transaction type is 'product' or 'service'
        if (type === "product" || type === "service") {
          moneyOutValue = productPrice;
          moneyInValue = convertedPrice;
        }

        // If the type is 'send', adjust the transactionType messages
        if (type === "send") {
          moneyOutTransactionType = `Sent: ${moneyOutValue}`;
          moneyInTransactionType = `Received: ${moneyInValue}`;
        }

        const moneyOutTransaction = {
          ...MoneyPackSchema,
          id: doc(collection(db, "temp")).id,
          moneyPackType: "moneyOut",
          value: moneyOutValue,
          transactionTime: transactionTime,
          lastUpdatedTime: transactionTime,
          transactionType: moneyOutTransactionType,
          optionalFields: {
            fromOrgName: buyerOrgData.name,
            fromOrgUID: buyerOrgUID,
            toOrgName: sellerOrgData.name,
            toOrgUID: sellerOrgUID,
            itemName: itemName,
          },
        };

        console.log(
          "Created moneyOutTransaction for buyer:",
          moneyOutTransaction
        );

        const moneyInTransaction = {
          ...MoneyPackSchema,
          id: doc(collection(db, "temp")).id,
          moneyPackType: "moneyIn",
          value: moneyInValue,
          transactionTime: transactionTime,
          lifespan: sellerOrgData.monetary_parameters.current_account.lifespan,
          transactionType: moneyInTransactionType,
          optionalFields: {
            fromOrgName: buyerOrgData.name,
            fromOrgUID: buyerOrgUID,
            toOrgName: sellerOrgData.name,
            toOrgUID: sellerOrgUID,
            itemName: itemName,
          },
        };
        console.log(
          "Created moneyInTransaction for seller:",
          moneyInTransaction
        );

        // Update buyer's wallet with moneyOutTransaction
        const updatedBuyerWalletData = await moneyPackHandler(
          buyerUID,
          buyerOrgUID,
          moneyOutTransaction,
          buyerOrgData,
          buyerWalletData,
          "client" // Ensure context is 'client'
        );
        console.log("Updated buyer's wallet data:", updatedBuyerWalletData);

        if (updatedBuyerWalletData && updatedBuyerWalletData.currentAccount) {
          commit(
            "UPDATE_ACCOUNT_BALANCE",
            updatedBuyerWalletData.currentAccount.balance
          );
        } else {
          console.error("Failed to update buyer's wallet data.");
          throw new Error("Failed to update buyer's wallet data.");
        }

        // Update seller's wallet with moneyInTransaction
        const updatedSellerWalletData = await moneyPackHandler(
          sellerUID,
          sellerOrgUID,
          moneyInTransaction,
          sellerOrgData,
          sellerWalletData,
          "client" // Ensure context is 'client'
        );
        console.log("Updated seller's wallet data:", updatedSellerWalletData);

        if (updatedSellerWalletData && updatedSellerWalletData.currentAccount) {
          commit(
            "UPDATE_ACCOUNT_BALANCE",
            updatedSellerWalletData.currentAccount.balance
          );
        } else {
          console.error("Failed to update seller's wallet data.");
          throw new Error("Failed to update seller's wallet data.");
        }

        toast.success("Transaction completed!");
      } catch (error) {
        console.error("Error handling transaction:", error);
        toast.error("Transaction failed.");
      }
    },
  },
};
