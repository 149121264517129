<template>
  <div>
    <div
      v-if="isVisible && !isMinimised"
      class="backdrop"
      @click="minimise"
    ></div>
    <transition name="slide-right">
      <div
        v-show="isVisible"
        class="messaging-container shadow"
        :class="{ open: isOpen, minimised: isMinimised }"
      >
        <div class="messaging-header row alignCentreRow">
          <template v-if="otherUserData">
            <AvatarLightSmall
              class="clickableAnim"
              :user="otherUserData"
              @click="handleAvatarClick(otherUserData.uid)"
            />
          </template>

          <template v-else>
            <div class="column searchColumn">
              <div class="search-container">
                <input
                  type="text"
                  v-model="searchQuery"
                  placeholder="Search user..."
                  @focus="handleSearchFocus"
                  @blur="handleSearchBlur"
                  @input="onSearch"
                />
                <svg class="search-icon compactImg">
                  <use href="../../assets/icons/iconset.svg#lens-compact"></use>
                </svg>
              </div>
            </div>
          </template>
          <div class="row alignCentreRow">
            <svg
              v-if="otherUserData"
              class="smaller clickableAnim"
              @click="backToNewMessage"
              v-haptic
            >
              <use href="../../assets/icons/iconset.svg#backToMessages"></use>
            </svg>
            <!-- <svg class="smaller" @click="minimise" v-haptic v-if="!isMinimised">
              <use href="../../assets/icons/iconset.svg#arrowdown_carousel"></use>
            </svg>
            <svg
              class="smaller clickableAnim"
              @click="minimise"
              v-haptic
              v-else
            >
              <use href="../../assets/icons/iconset.svg#arrowup_carousel"></use>
            </svg> -->
            <svg class="smaller clickableAnim" @click="close" v-haptic>
              <use href="../../assets/icons/iconset.svg#cross"></use>
            </svg>
          </div>
        </div>

        <div class="separator divNoMarginTopBottom"></div>
        <div
          v-show="!isMinimised && !otherUserData"
          :class="{
            otherUsers: !isMinimised && !otherUserData,
            minimised: isMinimised,
          }"
        >
          <RecentMessages :searchQuery="searchQuery" />
        </div>
        <div
          v-show="!isMinimised && otherUserData"
          :class="{
            'messaging-body': !isMinimised && otherUserData,
            minimised: isMinimised,
          }"
        >
          <div class="messages" ref="messagesContainer">
            <p v-if="messages.length === 0" class="nomargintopbottom">
              <em> Start a conversation </em>
              <em v-if="showInteractiveMessage"> or make an offer.</em>
            </p>
            <div
              v-for="(message, index) in messages"
              :key="index"
              :id="message.id || index"
              :class="{
                'message-from-user': message.from === currentUserUid,
                'message-from-other': message.from !== currentUserUid,
                wideImage: message.messageMedia,
              }"
            >
              <div class="message-content">
                <div
                  class="imgContainer"
                  v-if="message.isInteractive && message.productImageUrl"
                >
                  <img
                    :src="message.productImageUrl"
                    alt="Product"
                    class="product-image"
                  />
                </div>
                <div class="imgContainer" v-if="message.messageMedia">
                  <img
                    :src="message.messageMedia"
                    alt="Image"
                    class="message-image"
                  />
                </div>
                <p class="nomargintopbottom">{{ message.messageBody }}</p>
                <div
                  v-if="message.isInteractive"
                  class="separator_sticker divSmallerMarginTopSmallMarginBottom"
                ></div>
                <div class="row alignCentreRow justifyToStartEnd">
                  <h3
                    v-if="message.isInteractive"
                    class="nomargintopbottom price"
                  >
                    <!-- if it's a product or a service-->
                    <div v-if="message.type !== 'send'">
                      <strong>
                        <span v-if="message.from === currentUserUid">
                          {{ message.productPrice }} {{ message.coinSymbol }}
                        </span>
                        <span v-else>
                          {{ message.convertedPrice }}
                          {{ sellerCoinSymbol }}
                        </span>
                      </strong>
                    </div>
                    <!-- if it's a send -->
                    <div v-if="message.type === 'send'">
                      <strong>
                        <span v-if="message.from === currentUserUid">
                          {{ message.convertedPrice }} {{ message.coinSymbol }}
                        </span>
                        <span v-else>
                          {{ message.productPrice }}
                          {{ sellerCoinSymbol }}
                        </span>
                      </strong>
                    </div>
                  </h3>
                  <div
                    v-if="
                      message.isInteractive &&
                      message.from === currentUserUid &&
                      message.interactiveResponseStatus === 'pending'
                    "
                  >
                    <button
                      class="compactButton red secondaryButton"
                      @click="revokeOffer(message)"
                    >
                      <svg class="compactImg">
                        <use
                          href="../../assets/icons/iconset.svg#compactCross"
                        ></use>
                      </svg>
                      Cancel
                    </button>
                  </div>
                </div>
                <span>
                  <small
                    class="gray"
                    v-if="
                      message.isInteractive && message.from === currentUserUid
                    "
                  >
                    Offer {{ message.interactiveResponseStatus }}</small
                  >
                </span>

                <!-- <span
                  v-if="message.interactiveResponseStatus === 'revoked'"
                  class="gray"
                >
                  <small>Offer revoked</small>
                </span>

                <span
                  v-else-if="
                    message.isInteractive && message.from !== currentUserUid
                  "
                  class="nomargintopbottom"
                >
                  d
                  <small
                    v-if="message.interactiveResponseStatus === 'accepted'"
                    class="gray"
                  >
                    Offer accepted
                  </small>
                  <small
                    v-else-if="message.interactiveResponseStatus === 'rejected'"
                    class="gray"
                  >
                    Offer rejected
                  </small>
                  <small v-else class="gray"> Pending acceptance... </small>
                </span> -->

                <div
                  v-if="
                    message.isInteractive && message.from !== currentUserUid
                  "
                  :class="[
                    'interactive-options',
                    message.interactiveResponseStatus !== 'pending'
                      ? 'divNoMarginTopBottom'
                      : 'divNotSoSmallMarginTopSmallMarginBottom',
                  ]"
                  class="interactive-options divNotSoSmallMarginTopSmallMarginBottom"
                >
                  <template
                    v-if="message.interactiveResponseStatus !== 'pending'"
                  >
                    <small>{{
                      message.interactiveResponseStatus === "accepted"
                        ? "Offer accepted"
                        : "Offer rejected"
                    }}</small>
                  </template>
                  <template
                    v-else-if="
                      message.interactiveResponseStatus === 'pending' &&
                      message.from !== currentUserUid
                    "
                  >
                    <button
                      class="compactButton secondaryButton"
                      @click="handleReject(message)"
                    >
                      <svg class="compactImg">
                        <use
                          href="../../assets/icons/iconset.svg#compactCross"
                        ></use>
                      </svg>
                      Reject
                    </button>
                    <button
                      class="compactButton green"
                      @click="handleAccept(message)"
                    >
                      <svg class="compactImg">
                        <use
                          href="../../assets/icons/iconset.svg#checkmark_compact"
                        ></use>
                      </svg>
                      Accept
                    </button>
                  </template>
                </div>
                <div class="message-time">
                  <div v-if="message.from === currentUserUid">
                    <div class="row microGap">
                      {{ formatMessageTime(message.messageTime) }}

                      <svg v-if="message.read" class="tinyImg">
                        <use
                          href="../../assets/icons/iconset.svg#tick_two_tiny"
                        ></use>
                      </svg>
                    </div>
                  </div>
                  <div v-else>
                    {{ formatMessageTime(message.messageTime) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="separator"></div>
          <div
            v-if="!isLoading && showInteractiveMessage"
            class="interactive-message-container"
          >
            <InteractiveMessage
              :productId="productInfo?.productId || ''"
              :type="productInfo?.type || 'Send'"
              :itemName="productInfo?.itemName || 'Transfer details'"
              :productPrice="productInfo?.productPrice || 0"
              :productImageUrl="productInfo?.productImageUrl || ''"
              :convertedPrice="productInfo?.convertedPrice || 0"
              :coinSymbol="productInfo?.sellerCoinSymbol || 'Ʉ'"
              :sellerCoinSymbol="productInfo?.sellerCoinSymbol || 'Ʉ'"
              :buyerCoinSymbol="productInfo?.buyerCoinSymbol || 'Ʉ'"
              :otherUserData="otherUserData"
              :buyerOrgUID="buyerOrgUID"
              :currentUserWallet="currentUserWallet"
              :isBalanceInsufficient="isBalanceInsufficient"
              :bookingDate="productInfo?.bookingDate || ''"
              :bookingTime="productInfo?.bookingTime || ''"
              :slotDuration="productInfo?.slotDuration || 0"
              :transferAmount="productInfo?.transferAmount || 0"
              @makeOffer="handlemakeOffer"
            />
          </div>
          <div v-if="isLoading">Loading...</div>
          <div class="row alignCentreRow">
            <svg class="smaller clickableAnim" @click="toggleEmojiPicker">
              <use href="@/assets/icons/iconset.svg#smile"></use>
            </svg>
            <!-- Add this image icon, visible when the emoji picker is open and in 'emoji' mode -->
            <svg
              v-if="isEmojiPickerOpen && pickerMode === 'emoji'"
              class="smaller clickableAnim"
              @click="switchToImagePicker"
            >
              <use href="@/assets/icons/iconset.svg#thumbnail"></use>
            </svg>
            <input
              ref="messageInput"
              type="text"
              placeholder="Type a message..."
              v-model="newMessage"
              @keydown.enter="handleEnter"
              @compositionstart="handleCompositionStart"
              @compositionend="handleCompositionEnd"
            />
            <svg class="smaller" alt="send message icon" @click="sendMessage">
              <use href="../../assets/icons/iconset.svg#message"></use>
            </svg>
          </div>
          <EmojiAndMediaPicker
            :isVisible="isEmojiPickerOpen"
            :pickerMode="pickerMode"
            :recent-emojis="recentEmojis"
            :existing-images="existingImages"
            @emojiSelected="handleEmojiSelected"
            @closePicker="closeEmojiPicker"
            @imageSelected="handleImageSelected"
            @removeImage="removeImage"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  computed,
  ref,
  onMounted,
  onUnmounted,
  watch,
  nextTick,
  reactive,
} from "vue";

import { useStore } from "vuex";
import { useRouter } from "vue-router";

import { auth, db } from "@/firebase";
import {
  doc,
  setDoc,
  getDoc,
  arrayUnion,
  onSnapshot,
  updateDoc,
  increment,
  collection,
  getDocs,
} from "firebase/firestore";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";

import { toast } from "vue3-toastify";
import { formatNumberTo4Digits } from "@/utils/numberFormattingTo4digits";
import { getConvertedPrice } from "@/utils/priceUtils";

import AvatarLightSmall from "@/components/UserComponents/AvatarLightSmall.vue";
import InteractiveMessage from "@/components/MessagingComponents/InteractiveMessage.vue";
import RecentMessages from "@/components/MessagingComponents/RecentMessages.vue";
import EmojiAndMediaPicker from "@/components/MessagingComponents/EmojiAndMediaPicker.vue";

export default {
  name: "Messages",
  components: {
    AvatarLightSmall,
    InteractiveMessage,
    RecentMessages,
    EmojiAndMediaPicker,
  },
  props: {
    isOpen: Boolean,
  },
  setup(props) {
    const store = useStore();
    const isLoading = ref(false);
    const router = useRouter();

    const lockBodyScroll = () => {
      if (window.matchMedia("(max-width: 450px)").matches) {
        document.body.classList.add("lock-scroll");
      }
    };

    const unlockBodyScroll = () => {
      document.body.classList.remove("lock-scroll");
    };

    // this  part of the code is for the EmojiAndMediaPicker.vue
    const isEmojiPickerOpen = ref(false);
    const recentEmojis = ref([]);
    const uploadedImage = ref(null);
    const existingImages = ref([]);
    const pickerMode = ref("emoji");
    const messageInput = ref(null);

    const closeEmojiPicker = () => {
      isEmojiPickerOpen.value = false;
      pickerMode.value = "emoji";
    };
    const toggleEmojiPicker = () => {
      if (isEmojiPickerOpen.value && pickerMode.value === "image") {
        // If image picker is open, switch back to emoji picker
        pickerMode.value = "emoji";
      } else if (isEmojiPickerOpen.value && pickerMode.value === "emoji") {
        // If emoji picker is open, close it
        isEmojiPickerOpen.value = false;
      } else {
        // Open the emoji picker
        isEmojiPickerOpen.value = true;
        pickerMode.value = "emoji";
      }
    };

    const switchToImagePicker = () => {
      pickerMode.value = "image";
    };

    const handleEmojiSelected = (emoji) => {
      insertEmojiAtCursor(emoji);
      addEmojiToRecent(emoji);
    };

    const insertEmojiAtCursor = (emoji) => {
      const input = messageInput.value;
      if (input) {
        const start = input.selectionStart;
        const end = input.selectionEnd;
        const textBefore = newMessage.value.substring(0, start);
        const textAfter = newMessage.value.substring(end);
        newMessage.value = textBefore + emoji + textAfter;
        nextTick(() => {
          input.setSelectionRange(start + emoji.length, start + emoji.length);
          input.focus();
        });
      } else {
        newMessage.value += emoji;
      }
    };

    const addEmojiToRecent = (emoji) => {
      recentEmojis.value = recentEmojis.value.filter((e) => e !== emoji);
      recentEmojis.value.unshift(emoji);
      if (recentEmojis.value.length > 20) {
        recentEmojis.value.pop();
      }
      localStorage.setItem("recentEmojis", JSON.stringify(recentEmojis.value));
    };

    const handleImageSelected = (imageData) => {
      uploadedImage.value = imageData;
      existingImages.value = [imageData.url];
    };

    const removeImage = () => {
      uploadedImage.value = null;
      existingImages.value = [];
    };

    onMounted(() => {
      const storedEmojis = localStorage.getItem("recentEmojis");
      if (storedEmojis) {
        recentEmojis.value = JSON.parse(storedEmojis);
      }
    });
    // end of code for the EmojiAndMediaPicker.vue

    const transferAmount = computed(
      () => store.getters["UIState/transferAmount"]
    );

    const otherUserData = computed(
      () => store.getters["UIState/otherUserData"]
    );

    const getCurrentUserDisplayName = async () => {
      const userRef = doc(db, "users", auth.currentUser.uid);
      const userDoc = await getDoc(userRef);
      return userDoc.exists() ? userDoc.data().displayName : null;
    };

    const getRecipientDisplayName = async (recipientId) => {
      const userRef = doc(db, "users", recipientId);
      const userDoc = await getDoc(userRef);
      return userDoc.exists() ? userDoc.data().displayName : null;
    };

    const goToUserProfile = (userUID) => {
      router.push({ path: `/userprofile/${userUID}` });
    };

    const handleAvatarClick = (userUID) => {
      if (window.innerWidth <= 450) {
        minimise();
      }
      goToUserProfile(userUID);
    };

    const unsubscribe = ref(null);
    const messages = reactive([]);
    const currentUserUid = ref(null);
    const messagesContainer = ref(null);
    const generateMessageId = () =>
      `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

    const offerResponses = ref({});
    const getSellerStatus = (messageId) => {
      const sellerMessage = messages.value.find((msg) => msg.id === messageId);
      return sellerMessage
        ? sellerMessage.interactiveResponseStatus
        : "unknown";
    };
    const sellerCoinSymbol = computed(() => {
      const symbol = store.getters.getCoinSymbol;
      if (!symbol) {
        store.dispatch("fetchMonetaryParameters", otherUserData.value?.orgUID);
        return "Ʉ";
      }
      return symbol;
    });

    const fetchMessages = () => {
      if (!otherUserData.value) return;
      const messageDocRef = doc(
        db,
        "users",
        currentUserUid.value,
        "Messages",
        otherUserData.value.uid
      );

      onSnapshot(
        messageDocRef,
        (docSnap) => {
          if (docSnap.exists()) {
            const fetchedMessages = docSnap
              .data()
              .messages.map((message, index) => ({
                ...message,
                id: message.id || `msg-${index}`,
              }));
            // Ensure the reactivity by updating the entire reactive array
            messages.splice(
              0,
              messages.length,
              ...fetchedMessages.sort((a, b) => a.messageTime - b.messageTime)
            );
          } else {
            messages.splice(0, messages.length); // Clear the messages if no data exists
          }
          scrollToBottom(); // Ensure we scroll to the bottom after fetching
        },
        (error) => {
          console.error("Error fetching messages:", error);
        }
      );
    };

    const currentUserWallet = computed(() => {
      const wallets = store.state.wallets;
      console.log("Wallets in messages:", wallets);
      return wallets ? wallets[buyerOrgUID.value] : null;
    });

    const isBalanceInsufficient = computed(() => {
      console.log("Checking balance:", currentUserWallet.value);
      return (
        currentUserWallet.value &&
        currentUserWallet.value.currentAccount.balance <
          productInfo.value.productPrice
      );
    });

    const scrollToBottom = () => {
      nextTick(() => {
        if (messagesContainer.value) {
          const container = messagesContainer.value;
          const threshold = 100; // pixels from the bottom to consider "near the bottom"
          const position = container.scrollTop + container.clientHeight;
          const height = container.scrollHeight;

          if (height - position <= threshold) {
            // Only scroll if the user is near the bottom
            container.scrollTop = height;
          }
        }
      });
    };

    watch(otherUserData, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        fetchMessages();
      }
    });

    const newMessage = ref("");
    const isComposing = ref(false); // Track whether user is interacting with the native input

    const maxMessageLength = 40000;
    const validateMessageLength = () => {
      if (newMessage.value.length > maxMessageLength) {
        newMessage.value = newMessage.value.slice(0, maxMessageLength);
        toast.error("Your message is too long. Please shorten it a little.");
      }
    };

    watch(newMessage, validateMessageLength);

    const isVisible = computed(() => store.state.UIState.areMessagesOpen);
    const isMinimised = computed(
      () => store.state.UIState.areMessagesMinimised
    );
    const showInteractiveMessage = computed(
      () => store.state.UIState.showInteractiveMessage
    );

    const productInfo = computed(() => {
      const info = store.state.UIState.productInfo;
      console.log("messages productInfo");
      console.log(info);
      if (info && typeof info.productPrice === "string") {
        info.productPrice = parseFloat(info.productPrice);
      }
      return info;
    });

    const productPriceType = computed(
      () => typeof productInfo.value?.productPrice
    );
    const isSending = ref(false);
    const sendMessage = async () => {
      if (newMessage.value.length > maxMessageLength) {
        newMessage.value = newMessage.value.slice(0, maxMessageLength);
        toast.error("Your message is too long. Please shorten it a little.");
        return;
      }

      if (newMessage.value.trim() === "" && !uploadedImage.value) {
        return; // Do not send empty messages unless there is an image
      }
      isSending.value = true; // Set flag to prevent multiple sends

      try {
        // Fetch display names with fallback values
        const [senderDisplayName, recipientDisplayName] = await Promise.all([
          getCurrentUserDisplayName().then((name) => name || "Unit User"),
          getRecipientDisplayName(otherUserData.value.uid).then(
            (name) => name || "Unit User"
          ),
        ]);

        const messageId = generateMessageId();
        const message = {
          // ... existing message properties
          id: messageId,
          from: auth.currentUser.uid,
          to: otherUserData.value.uid,
          senderDisplayName,
          recipientDisplayName,
          messageBody: newMessage.value,
          messageTime: Date.now(),
          isInteractive: false,
          read: false,
        };

        // Upload the image to Firebase Storage if present
        // Upload the image to Firebase Storage if present
        if (uploadedImage.value && uploadedImage.value.file) {
          const storage = getStorage(); // Initialize Firebase Storage
          const imageRef = storageRef(
            storage,
            `messages/${messageId}/${uploadedImage.value.file.name}`
          );
          await uploadBytes(imageRef, uploadedImage.value.file);
          const imageUrl = await getDownloadURL(imageRef);
          message.messageMedia = [imageUrl];
        }

        // Mark all unread messages from the other user as read
        const messageDocRef = doc(
          db,
          "users",
          otherUserData.value.uid,
          "Messages",
          auth.currentUser.uid
        );
        const docSnap = await getDoc(messageDocRef);

        if (docSnap.exists()) {
          const updatedMessages = docSnap.data().messages.map((msg) => {
            if (msg.from === otherUserData.value.uid && !msg.read) {
              return { ...msg, read: true };
            }
            return msg;
          });

          await updateDoc(messageDocRef, { messages: updatedMessages });

          // Update the reactive state to reflect the read status
          updatedMessages.forEach((updatedMessage) => {
            const index = messages.findIndex(
              (msg) => msg.id === updatedMessage.id
            );
            if (index !== -1) {
              messages[index] = updatedMessage;
            }
          });
        }

        // Add the new message to Firestore
        await Promise.all([
          setDoc(
            doc(
              db,
              "users",
              auth.currentUser.uid,
              "Messages",
              otherUserData.value.uid
            ),
            { messages: arrayUnion(message) },
            { merge: true }
          ),
          setDoc(
            doc(
              db,
              "users",
              otherUserData.value.uid,
              "Messages",
              auth.currentUser.uid
            ),
            { messages: arrayUnion(message) },
            { merge: true }
          ),
        ]);

        newMessage.value = "";
        uploadedImage.value = null;
        existingImages.value = [];
        isEmojiPickerOpen.value = false;
        pickerMode.value = "emoji"; // Reset picker mode

        scrollToBottom();
      } catch (error) {
        console.error("Error sending message:", error);
      } finally {
        isSending.value = false; // Reset flag
      }
    };

    const handleCompositionStart = () => {
      console.log("Composition started");
      isComposing.value = true;
    };

    const handleCompositionEnd = () => {
      console.log("Composition ended");
      isComposing.value = false;
    };

    const handleEnter = () => {
      if (!isComposing.value) {
        sendMessage(); // Send message if not composing
      } else {
        console.log("Still composing, not sending");
      }
    };

    async function getUserOrganisationUID(userUID) {
      try {
        const unitsCollectionRef = collection(db, "users", userUID, "Units");
        const unitsSnapshot = await getDocs(unitsCollectionRef);

        if (!unitsSnapshot.empty) {
          const firstOrgUID = unitsSnapshot.docs[0].data().organisationUID;
          return firstOrgUID;
        } else {
          console.log("No organisation UIDs found in Units!");
          return null;
        }
      } catch (error) {
        console.error("Error getting organisation UID:", error);
        return null;
      }
    }

    const handlemakeOffer = async (productDetails) => {
      try {
        // Fetch display names with fallback values
        const [senderDisplayName, recipientDisplayName] = await Promise.all([
          getCurrentUserDisplayName().then((name) => name || "Unit User"),
          getRecipientDisplayName(otherUserData.value.uid).then(
            (name) => name || "Unit User"
          ),
        ]);

        const transferAmount =
          productDetails.transferAmount || productDetails.productPrice;
        const messageId = generateMessageId();
        await store.dispatch("UIState/setShowInteractiveMessage", false);

        const sellerOrgUid = await getUserOrganisationUID(
          otherUserData.value.uid
        );
        const { convertedPrice, coinSymbol } = await getConvertedPrice(
          productDetails,
          sellerOrgUid,
          productDetails.type === "send" || productDetails.type === "request"
            ? transferAmount
            : productDetails.productPrice
        );

        productDetails.productPrice = convertedPrice;
        productDetails.convertedPrice = transferAmount; // Ensure transferAmount is set as convertedPrice
        productDetails.coinSymbol = coinSymbol;

        let messageBody = "";
        switch (productDetails.type) {
          case "send":
            messageBody = `I'd like to send you funds.`;
            break;
          case "request":
            messageBody = `I'd like to request some funds.`;
            break;
          case "product":
            messageBody = `I'd like to make an offer for ${productDetails.itemName}.`;
            break;
          case "service":
            messageBody = productDetails.bookingTime
              ? `I'd like to make a booking request for ${productDetails.bookingDate} at ${productDetails.bookingTime}.`
              : `I'd like to make a booking request for ${productDetails.itemName}.`;
            break;
          default:
            messageBody = `I'd like to proceed with ${productDetails.itemName}.`;
        }

        const message = {
          id: messageId,
          from: currentUserUid.value,
          to: otherUserData.value.uid,
          senderDisplayName,
          recipientDisplayName,
          messageBody,
          messageTime: Date.now(),
          isInteractive: true,
          type: productDetails.type || "product",
          productImageUrl: productDetails.productImageUrl || "",
          productId: productDetails.productId || "",
          itemName: productDetails.itemName || "",
          productPrice:
            formatNumberTo4Digits(productDetails.productPrice) || "",
          convertedPrice:
            formatNumberTo4Digits(productDetails.convertedPrice) || "",
          coinSymbol: productDetails.coinSymbol || "",
          sellerCoinSymbol: sellerCoinSymbol.value, // Use the computed sellerCoinSymbol
          bookingDate: productDetails.bookingDate || "",
          bookingTime: productDetails.bookingTime || "",
          slotDuration: productDetails.slotDuration || 0,
          interactiveResponse: null,
          interactiveResponseStatus: "pending",
        };

        await Promise.all([
          setDoc(
            doc(
              db,
              "users",
              otherUserData.value.uid,
              "Messages",
              currentUserUid.value
            ),
            { messages: arrayUnion(message) },
            { merge: true }
          ),
          setDoc(
            doc(
              db,
              "users",
              currentUserUid.value,
              "Messages",
              otherUserData.value.uid
            ),
            { messages: arrayUnion(message) },
            { merge: true }
          ),
        ]);
        console.log(message);
        toast.info("Message sent.");
      } catch (error) {
        await store.dispatch("UIState/setShowInteractiveMessage", true);
        toast.error("Message not sent:", error);
        console.error("Error exchanging message:", error);
      }
    };

    const minimise = () => store.dispatch("UIState/toggleMessagesMinimised");
    const close = () => {
      store.dispatch("UIState/clearProductInfo"); // Clear product info on close
      store.dispatch("UIState/setMessagesOpen", false);
    };
    const handleSearchFocus = () => {
      if (isMinimised.value) {
        store.dispatch("UIState/toggleMessagesMinimised");
      }
      isSearchFocused.value = true;
    };

    const buyerOrgUID = computed(() => store.getters.buyerOrgUID);
    const sellerOrgUID = computed(() => store.getters.sellerOrgUID);

    const handleAccept = async (originalMessage) => {
      const acceptanceMessageId = generateMessageId();

      // Prepare the acceptance message
      const acceptanceMessage = {
        id: acceptanceMessageId,
        from: currentUserUid.value,
        to: originalMessage.from,
        messageBody: "Offer accepted.",
        messageTime: Date.now(),
        interactiveResponseStatus: "accepted",
      };

      try {
        // Update Firestore for both users
        await updateMessageForBothUsers(
          originalMessage,
          acceptanceMessage,
          "accepted"
        );

        // Handle the transaction after the offer is accepted
        const transactionDetails = {
          buyerUID: originalMessage.from,
          sellerUID: currentUserUid.value,
          buyerOrgUID:
            currentUserUid.value === originalMessage.from
              ? buyerOrgUID.value
              : sellerOrgUID.value,
          sellerOrgUID:
            currentUserUid.value === originalMessage.from
              ? sellerOrgUID.value
              : buyerOrgUID.value,
          productPrice: originalMessage.productPrice,
          convertedPrice: originalMessage.convertedPrice,
          itemName: originalMessage.itemName,
          type: originalMessage.type,
        };

        await store.dispatch(
          "coinCreationActions/handleTransaction",
          transactionDetails
        );

        if (productInfo.value && productInfo.value.productId) {
          const productRef = doc(db, "products", productInfo.value.productId);
          await updateDoc(productRef, { existingStock: increment(-1) });
        }

        toast.success("Offer accepted and transaction completed successfully.");
      } catch (error) {
        console.error("Error during the accept process:", error);
        toast.error("Failed to accept the offer. Please try again.");
      }
    };

    const handleReject = async (originalMessage) => {
      const rejectionMessageId = generateMessageId();

      // Prepare the rejection message
      const rejectionMessage = {
        id: rejectionMessageId,
        from: currentUserUid.value,
        to: originalMessage.from,
        messageBody: "Offer not accepted.",
        messageTime: Date.now(),
        interactiveResponseStatus: "rejected",
      };

      try {
        // Update Firestore for both users
        await updateMessageForBothUsers(
          originalMessage,
          rejectionMessage,
          "rejected"
        );

        toast.success("Offer rejected successfully.");
      } catch (error) {
        console.error("Error sending rejection message:", error);
        toast.error("Failed to reject the offer. Please try again.");
      }
    };

    const updateMessageForBothUsers = async (
      originalMessage,
      newMessage,
      status
    ) => {
      const senderMessageRef = doc(
        db,
        "users",
        originalMessage.from,
        "Messages",
        currentUserUid.value
      );

      const recipientMessageRef = doc(
        db,
        "users",
        currentUserUid.value,
        "Messages",
        originalMessage.from
      );

      // Update the original message status for both users
      const updateMessageStatus = async (docRef) => {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const messages = docSnap.data().messages;
          const updatedMessages = messages.map((msg) => {
            if (msg.id === originalMessage.id) {
              return {
                ...msg,
                interactiveResponseStatus: status,
              };
            }
            return msg;
          });

          await updateDoc(docRef, { messages: updatedMessages });
        }
      };

      await updateMessageStatus(senderMessageRef);
      await updateMessageStatus(recipientMessageRef);

      // Add the new message to both users' Firestore documents
      await setDoc(
        senderMessageRef,
        { messages: arrayUnion(newMessage) },
        { merge: true }
      );

      await setDoc(
        recipientMessageRef,
        { messages: arrayUnion(newMessage) },
        { merge: true }
      );
    };

    const hasResponse = (messageId) => {
      if (!messages.value) return false;
      return messages.value.some((msg) => msg.linkedMessageId === messageId);
    };

    const backToNewMessage = () => {
      store.dispatch("UIState/clearProductInfo"); // Clear product info on back
      store.dispatch("UIState/setOtherUserData", null);
    };

    const formatMessageTime = (timestamp) => {
      const messageDate = new Date(timestamp);
      const currentDate = new Date();
      const oneWeekAgo = new Date(
        currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
      );

      const isSameDay =
        messageDate.toDateString() === currentDate.toDateString();
      const isWithinAWeek = messageDate > oneWeekAgo;
      const isSameMonth =
        messageDate.getMonth() === currentDate.getMonth() &&
        messageDate.getFullYear() === currentDate.getFullYear();
      const isSameYear =
        messageDate.getFullYear() === currentDate.getFullYear();

      let options = { hour: "2-digit", minute: "2-digit", hour12: false };

      if (!isSameDay) {
        options = { ...options, weekday: "short", day: "2-digit" };

        if (!isWithinAWeek) {
          options = { ...options, month: "2-digit" };

          if (!isSameMonth || !isSameYear) {
            options = { ...options, year: "numeric" };
          }
        }
      }

      return new Intl.DateTimeFormat("en-GB", options).format(messageDate);
    };

    const searchQuery = ref("");
    const isSearchFocused = ref(false);
    const limitCount = ref(10);

    const handleSearchBlur = () => {
      isSearchFocused.value = false;
    };

    const revokeOffer = async (message) => {
      try {
        const senderMessageRef = doc(
          db,
          "users",
          currentUserUid.value,
          "Messages",
          message.to
        );

        const recipientMessageRef = doc(
          db,
          "users",
          message.to,
          "Messages",
          currentUserUid.value
        );

        const updateMessageStatus = async (docRef) => {
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const messages = docSnap.data().messages;
            const updatedMessages = messages.map((msg) => {
              if (msg.id === message.id) {
                return {
                  ...msg,
                  interactiveResponseStatus: "revoked",
                };
              }
              return msg;
            });

            await updateDoc(docRef, {
              messages: updatedMessages,
            });
          }
        };

        await updateMessageStatus(senderMessageRef);
        await updateMessageStatus(recipientMessageRef);

        toast.success("Offer revoked successfully.");
      } catch (error) {
        console.error("Error revoking offer:", error);
        toast.error("Failed to revoke the offer. Please try again.");
      }
    };

    const markMessageAsRead = async (message) => {
      // Only mark as read if the message thread is open and not minimized
      if (
        !message.read &&
        message.to === currentUserUid.value &&
        isVisible.value &&
        !isMinimised.value
      ) {
        try {
          const messageDocRef = doc(
            db,
            "users",
            currentUserUid.value,
            "Messages",
            otherUserData.value.uid
          );

          const docSnap = await getDoc(messageDocRef);
          if (docSnap.exists()) {
            const updatedMessages = docSnap.data().messages.map((msg) => {
              if (msg.id === message.id) {
                return { ...msg, read: true };
              }
              return msg;
            });

            await updateDoc(messageDocRef, {
              messages: updatedMessages,
            });

            // Reflect the update in the reactive messages array
            const index = messages.findIndex((msg) => msg.id === message.id);
            if (index !== -1) {
              messages[index] = { ...messages[index], read: true };
            }
          }
        } catch (error) {
          console.error("Error marking message as read:", error);
        }
      }
    };
    const markAllMessagesAsRead = async () => {
      if (!otherUserData.value) return;
      try {
        const messageDocRef = doc(
          db,
          "users",
          currentUserUid.value,
          "Messages",
          otherUserData.value.uid
        );

        const docSnap = await getDoc(messageDocRef);
        if (docSnap.exists()) {
          const messages = docSnap.data().messages;
          const updatedMessages = messages.map((msg) => {
            if (!msg.read) {
              return { ...msg, read: true };
            }
            return msg;
          });

          await updateDoc(messageDocRef, {
            messages: updatedMessages,
          });
        }
        await store.dispatch(
          "UIState/fetchUnreadMessagesCount",
          currentUserUid.value
        );
      } catch (error) {
        console.error("Error marking all messages as read:", error);
      }
    };

    watch(otherUserData, async (newVal, oldVal) => {
      if (newVal !== oldVal) {
        fetchMessages(); // fetch the messages of the new user
        await markAllMessagesAsRead(); // mark all messages in this thread as read
      }
    });

    watch([isVisible, isMinimised], async ([newIsVisible, newIsMinimised]) => {
      const isMobile = window.matchMedia("(max-width: 450px)").matches;

      if (newIsVisible && !newIsMinimised) {
        await markAllMessagesAsRead(); // Mark all messages as read when the thread is visible and not minimized
        if (isMobile) {
          lockBodyScroll();
        }
      } else {
        unlockBodyScroll();
      }
    });

    watch(
      messages,
      (newMessages) => {
        // Only mark messages as read if the thread is visible and not minimized
        if (isVisible.value && !isMinimised.value) {
          newMessages.forEach((message) => {
            markMessageAsRead(message);
          });
        }
      },
      { deep: true }
    );

    watch([isVisible, isMinimised], ([newIsVisible, newIsMinimised]) => {
      const isMobile = window.matchMedia("(max-width: 450px)").matches;

      if (newIsVisible && !newIsMinimised && isMobile) {
        lockBodyScroll();
      } else {
        unlockBodyScroll();
      }
    });

    onMounted(() => {
      auth.onAuthStateChanged((user) => {
        if (user) {
          currentUserUid.value = user.uid;
          store.dispatch("fetchAndSetBuyerOrgUID", user.uid);
          if (otherUserData.value && otherUserData.value.uid) {
            store.dispatch("fetchAndSetSellerOrgUID", otherUserData.value.uid);
          }
          fetchMessages(); // Ensure messages are fetched when the component mounts

          watch(
            otherUserData,
            (newOtherUserData) => {
              if (newOtherUserData && newOtherUserData.uid) {
                store
                  .dispatch("fetchAndSetSellerOrgUID", newOtherUserData.uid)
                  .then(() => {
                    isLoading.value = false;
                  })
                  .catch((error) => {
                    console.error("Error loading org UIDs:", error);
                  });
              }
            },
            { immediate: true }
          );
        }
      });
    });

    onUnmounted(() => {
      if (unsubscribe.value) {
        unsubscribe.value();
      }
      unlockBodyScroll();
    });

    watch(isComposing, (newValue, oldValue) => {
      if (newValue) {
        console.log("Watcher: Composition started");
      } else {
        console.log("Watcher: Composition ended");
      }
    });

    return {
      currentUserUid,
      isLoading,
      otherUserData,
      productInfo,
      productPriceType,
      newMessage,
      isComposing,
      isMinimised,
      isVisible,
      sendMessage,
      handleCompositionStart,
      handleCompositionEnd,
      handleEnter,
      minimise,
      close,
      handlemakeOffer,
      handleAccept,
      handleReject,
      hasResponse,
      buyerOrgUID,
      sellerOrgUID,
      messagesContainer,
      messages,
      sellerCoinSymbol,
      formatMessageTime,
      showInteractiveMessage,
      generateMessageId,
      backToNewMessage,
      searchQuery,
      isSearchFocused,
      limitCount,
      onSearch() {
        limitCount.value = 10;
      },
      handleSearchBlur,
      handleSearchFocus,
      goToUserProfile,
      handleAvatarClick,
      isBalanceInsufficient,
      currentUserWallet,
      revokeOffer,
      getSellerStatus,
      transferAmount,
      unlockBodyScroll,
      lockBodyScroll,
      markMessageAsRead,
      markAllMessagesAsRead,
      getUserOrganisationUID,
      isSending,
      updateMessageForBothUsers,
      handleSearchBlur,
      handleSearchFocus,
      searchQuery,
      isSearchFocused,
      limitCount,

      // EmojiAndMediaPicker.vue
      toggleEmojiPicker,
      isEmojiPickerOpen,
      recentEmojis,
      handleEmojiSelected,
      insertEmojiAtCursor,
      addEmojiToRecent,
      handleImageSelected,
      removeImage,
      uploadedImage,
      existingImages,
      switchToImagePicker,
      pickerMode,
      messageInput,

      closeEmojiPicker,
    };
  },
};
</script>

<style scoped>
.messaging-container {
  height: auto;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 41%; /* Adjust the width as necessary */
  background-color: var(--backgroundColourOrg);
  box-shadow: 0 2px 6px var(--darkbluetransplight);
  border-top-left-radius: var(--borderRadius);
  transition: all 0.15s ease-out;
  transform: translateY(0%); /* Start visible */
  opacity: 1; /* Fully visible */
  z-index: 3; /* Ensure it's above other content */
  max-height: 90vh;
}

.messaging-container.open {
  width: 50%;
  min-width: 425px;
  max-width: 475px;
}

.messaging-container:not(.open) {
  transform: translateY(100%); /* Slide out of view when not open */
  opacity: 0; /* Hide when not open */
}

.messaging-container.minimised {
  max-height: calc(var(--clickableHeight) + var(--smallMargin) * 2);
  min-width: 200px;
}

.messaging-header {
  padding: var(--smallMargin);
  background-color: var(--solidlightgrey);
  color: var (--black);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top-left-radius: var(--borderRadiusBigger);
  border-top-right-radius: var(--borderRadiusBigger);
}
.imgContainer {
  width: 100%; /* Adjust the width as necessary, or set a fixed size for a square */
  height: 0; /* Initial height to 0 */
  padding-top: 100%; /* This creates a square aspect ratio */
  position: relative; /* This allows the absolute positioning of the image inside */
  margin: 0 auto; /* Center the container if its width is less than 100% */
  border-radius: var(--borderRadius);
  margin-bottom: var(--smallerMargin);
  border: 0.77px solid var(--solidLighterGrey);
  background-color: var(--backgroundLighterGraySuperTransp);
}

.product-image,
.message-image {
  position: absolute; /* Position the image absolutely within the .imgContainer */
  top: 0;
  left: 0;
  width: 100%; /* Make the image cover the full width of the container */
  height: 100%; /* Make the image cover the full height of the container */
  object-fit: cover; /* This makes the image cover the area, being cropped if necessary */
  border-radius: var(--borderRadius);
}

.wideImage {
  width: 75% !important; /* Ensures this rule takes precedence */
}

.messaging-body {
  padding: var(--smallMargin);
}

.searchContainer {
  padding: var(--smallMargin);
  padding-top: 0;
}
.interactive-options {
  display: flex; /* Use flexbox for horizontal layout */
  justify-content: flex-start; /* Align the buttons to the right */
  gap: var(--smallMargin); /* Add some space between the buttons */
}

.otherUsers {
  padding: var(--smallMargin);
  padding-top: 0;
  min-height: 400px;
  height: 400px;
  max-height: calc(400px + var(--clickableHeight));
  overflow-y: auto;
}

.separator_sticker {
  opacity: 0.6;
}

.messages {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 55vh;
  min-height: 33vh;
  padding: var(--smallMargin);
  box-shadow: var(--neuMorphBoxInnerSmall) !important;
  border-radius: var(--borderRadius);
  padding-bottom: var(--smallMargin);
}

.lock-scroll {
  overflow: hidden;
}

.message-from-user,
.message-from-other {
  padding: var(--smallMargin);
  margin: var(--microMargin) 0;
  border-radius: var(--borderRadiusBigger);
  max-width: 75%;
  word-break: break-word; /* Ensures text breaks to prevent overflow */
}

.message-from-user {
  align-self: flex-end;
  background-color: var(--purple100);
  border-top-right-radius: 0;
}
.message-from-other {
  align-self: flex-start;
  background-color: var(--gray300);
  border-top-left-radius: 0;
}

input[type="text"] {
  width: 100%;
  padding: var(--smallMargin);
  border-radius: var(--borderRadiusBigger);
}

.message-time {
  text-align: right;
  height: var(--smallMargin);
  font-size: var(--smallerFontsize);
  opacity: 0.7;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: var(--microMargin);
}

.search-container {
  position: relative;
  display: flex;
  align-items: center;
}
.searchColumn {
  width: 100%;
}

.search-container input {
  width: 100%;
  padding: var(--smallMargin);
  padding-left: calc(var(--smallClickableHeight) * 0.9);
  border-radius: var(--borderRadius);
}

.search-container input:focus {
  padding-left: var(--smallMargin); /* Adjust padding to account for the icon */
}

.search-container .search-icon {
  position: absolute;
  left: var(--smallerMargin);
  pointer-events: none; /* Allow clicks to pass through to the input */
  display: flex;
  align-items: center;
  opacity: 0.6;
}

.search-container input:focus + .search-icon {
  display: none;
}

.search-container input:not(:focus):not(:placeholder-shown) + .search-icon {
  display: flex;
}

/* Initial state for entering */
.slide-right-enter-from {
  transform: translateX(100%);
}

/* Active state for entering */
.slide-right-enter-active {
  transition: transform 0.15s ease-out;
}

/* Final state for entering */
.slide-right-enter-to {
  transform: translateX(0);
}

/* Initial state for leaving */
.slide-right-leave-from {
  transform: translateX(0);
}

/* Active state for leaving */
.slide-right-leave-active {
  transition: transform 0.15s ease-out;
}

/* Final state for leaving */
.slide-right-leave-to {
  transform: translateX(100%);
}

/* SMALL SCREENS */
@media only screen and (max-width: 450px) {
  body.lock-scroll {
    overflow: hidden;
  }
  .messaging-container.open {
    width: 100vw;
    min-width: 100vw;
  }

  .messaging-container.minimised {
    max-height: calc(var(--clickableHeight) + var(--smallMargin) * 2);
    min-width: 200px;
    width: 260px;
  }

  .messages {
    max-height: calc(65vh - var(--clickableHeight));
    height: calc(65vh - var(--clickableHeight));
  }

  .otherUsers {
    max-height: calc(65vh + var(--clickableHeight) + var(--tinyMargin));
    height: calc(65vh + var(--clickableHeight) + var(--tinyMargin));
  }
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(10px);
    background-color: var(--backgroundColourOrgTransp);
    z-index: 3; /* Ensure it's behind the messaging container but above other content */
  }
}
</style>
