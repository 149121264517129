<template>
  <div class="coinLifecycle">
    <div class="row divMarginBottom alignCentreRow justifyToStartEnd">
      <div class="column">
        <!-- <CreateOneCoinInUserWallet :orgUID="viewedOrgUID" /> -->
        <button v-if="canCreateCoin" @click="createCoin" class="smallButton">
          🤑 Create coin
        </button>
        <!-- <ExchangeRate :viewedOrgUID="viewedOrgUID" /> -->
        <!-- <CoinFallingAnimation
          ref="coinAnimation"
          :numberOfCoins="30"
          :duration="3000"
        /> -->
      </div>

      <button
        @click="toggleLifecycleDropdown"
        class="smallButton fitContent"
        v-haptic
      >
        Coin life cycle
        <svg class="compactImg" v-if="isCoinLifecycleOpen">
          <use href="@/assets/icons/iconset.svg#arrowup_carousel"></use>
        </svg>
        <svg class="compactImg" v-else>
          <use href="@/assets/icons/iconset.svg#arrowdown_carousel"></use>
        </svg>
      </button>
    </div>
    <div class="column">
      <transition name="slide-fade">
        <OrgCoinLifecycleSankeyChart
          v-if="isCoinLifecycleOpen"
          :basicOrgDetails="basicOrgDetails"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { getAuth } from "firebase/auth";
import { db } from "@/firebase";
import { onMounted, ref } from "vue";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";

import OrgCoinLifecycleSankeyChart from "@/components/DashboardComponents/OrgPublicPage/OrgCoinLifecycleSankeyChart.vue";
import ExchangeRate from "@/components/GenericComponents/CentExchangeRate.vue";
import CreateOneCoinInUserWallet from "@/components/GenericComponents/CreateOneCoinInUserWallet.vue";
import CoinFallingAnimation from "@/components/AnimationComponents/CoinFallingAnimation.vue";

import { toast } from "vue3-toastify";

export default {
  name: "Coinlifecycle",
  components: {
    OrgCoinLifecycleSankeyChart,
    ExchangeRate,
    CreateOneCoinInUserWallet,
    CoinFallingAnimation,
  },
  props: {
    basicOrgDetails: {
      type: Object,
      required: true,
    },
    viewedOrgUID: {
      type: String,
      required: true,
    },
    currentUserOrgUID: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const isCoinLifecycleOpen = ref(false);
    const canCreateCoin = ref(false);

    const toggleLifecycleDropdown = () => {
      isCoinLifecycleOpen.value = !isCoinLifecycleOpen.value;
    };
    const checkCreateCoinConditions = async () => {
      const user = getAuth().currentUser;
      if (!user || !props.viewedOrgUID) return;

      const userUID = user.uid;
      const orgRef = doc(db, "custom-orgs", props.viewedOrgUID);
      const orgDoc = await getDoc(orgRef);

      if (orgDoc.exists()) {
        const orgData = orgDoc.data();

        // Check if totalOrgCoins is 0 or less
        const hasFewOrNoCoins = orgData.totalOrgCoins <= 0;

        // Check if user has monetaryParameters privilege
        const rolesRef = collection(
          db,
          "custom-orgs",
          props.viewedOrgUID,
          "Roles"
        );
        const q = query(rolesRef, where(`filledBy.${userUID}`, "!=", null));
        const querySnapshot = await getDocs(q);

        let hasMonetaryPrivileges = false;

        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            const roleData = doc.data();
            if (roleData.privileges && roleData.privileges.monetaryParameters) {
              hasMonetaryPrivileges = true;
            }
          });
        }

        // Update the ref based on the checks
        canCreateCoin.value = hasFewOrNoCoins || hasMonetaryPrivileges;
      }
    };
    const createCoin = async () => {
      if (!props.viewedOrgUID) {
        console.error("Organization UID is missing.");
        toast.error("Organization UID is missing.");
        return;
      }

      try {
        await store.dispatch("coinCreationActions/createCoin", {
          orgUID: props.viewedOrgUID,
          currentAccountLifespan:
            props.basicOrgDetails.currentAccountLifespan.value,
          coinAmount: 1,
        });

        // toast.success("🤑 Coin created successfully in wallet.");
        emit("coinCreated");
      } catch (error) {
        console.error("Error creating coin in wallet:", error);
      }
    };
    onMounted(() => {
      checkCreateCoinConditions();
    });

    return {
      createCoin,
      isCoinLifecycleOpen,
      toggleLifecycleDropdown,
      canCreateCoin,
    };
  },
};
</script>

<style scoped>
.fitContent {
  width: fit-content;
}

.info-container {
  overflow-y: auto;
  max-height: 50vh;
}
</style>
