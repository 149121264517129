<template>
  <div :class="['SellingAction', parentContext]">
    <div class="dropdownMenu" v-click-outside="closeMenu" @click.stop>
      <!-- Product Menu Item -->
      <div
        class="menu-item menu-link"
        @click="openProductOverlay"
        :class="{ pressed: selectedCard === 'Product' }"
      >
        <div class="row alignCentreRow noGap">
          <!-- <svg class="compactImg" alt="product icon">
            <use href="@/assets/icons/iconset.svg#product_icon"></use>
          </svg> -->
          Product
        </div>
      </div>

      <!-- Service Menu Item -->
      <div
        class="menu-item menu-link"
        @click="openServiceOverlay"
        :class="{ pressed: selectedCard === 'Service' }"
      >
        <div class="row alignCentreRow noGap">
          <!-- <svg class="compactImg" alt="service icon">
            <use href="@/assets/icons/iconset.svg#calendar_compact"></use>
          </svg> -->
          Service
        </div>
      </div>
    </div>

    <!-- Add Product Overlay -->
    <Overlay
      :visible="showOverlay && overlayType === 'Product'"
      :title="dynamicTitleProduct"
      :smallTextAfterTitle="'You can change this later'"
      :showBackArrow="shouldShowBackArrow"
      :currentStep="currentStep"
      @close="closeOverlay"
      @previousStep="goToPreviousStep"
    >
      <template #before-buttons>
        <AddProductForm
          ref="addProductFormRef"
          :userUnitsOrg="userUnitsOrg"
          :userCredentials="userCredentials"
          :currentStep="currentStep"
          @productAddedSuccessfully="handleProductAdded"
          @update-title="handleTitleUpdate"
          @save-request="triggerAddProduct"
          @update-canPreview="canPreview = $event"
          @update-currentStep="handleStepUpdate('product')"
        />
      </template>
      <div class="row alignCentreRow">
        <button
          @click="closeOverlay"
          v-if="!isSaveLoading"
          v-haptic
          class="secondaryButton"
        >
          Cancel
        </button>
        <button
          v-if="!canPreview && currentStep !== 'preview'"
          @click="validateFormAndProceed('Product')"
        >
          Next
        </button>
        <button
          @click="validateFormAndProceed('Product', true)"
          v-if="canPreview && !isSaveLoading && currentStep !== 'preview'"
          v-haptic
        >
          Next
        </button>

        <button
          @click="saveProduct"
          v-if="user.loggedIn && currentStep === 'preview' && !isSaveLoading"
          :disabled="isSaveLoading"
          v-haptic
        >
          Save
        </button>
        <button v-if="isSaveLoading" disabled>Processing...</button>
      </div>
    </Overlay>

    <!-- Add Service Overlay -->
    <Overlay
      :visible="showOverlay && overlayType === 'Service'"
      :title="dynamicTitleService"
      :smallTextAfterTitle="'You can change this later'"
      :showBackArrow="shouldShowBackArrow"
      :currentStep="currentStep"
      @close="closeOverlay"
      @previousStep="goToPreviousStep"
    >
      <template #before-buttons>
        <AddServiceForm
          ref="addServiceFormRef"
          :user="user"
          :userCredentials="userCredentials"
          :userUnitsOrg="userUnitsOrg"
          :currentStep="currentStep"
          @serviceAddedSuccessfully="handleServiceAdded"
          @update-title="handleTitleUpdate"
          @save-request="triggerAddService"
          @update-canPreview="canPreview = $event"
          @update-currentStep="handleStepUpdate('service')"
        />
      </template>
      <div class="row alignCentreRow">
        <button
          class="secondaryButton"
          @click="closeOverlay"
          v-if="!isSaveLoading"
          v-haptic
        >
          Cancel
        </button>
        <button
          v-if="
            currentStep !== 'initial' &&
            currentStep !== 'preview' &&
            currentStep !== 'customization'
          "
          class="secondaryButton"
          @click="skipToPreview"
        >
          Adjust later
        </button>
        <button
          v-if="currentStep !== 'preview'"
          @click="validateFormAndProceed('Service', true)"
        >
          Next
        </button>
        <button
          @click="saveService"
          v-if="user.loggedIn && currentStep === 'preview' && !isSaveLoading"
          :disabled="isSaveLoading"
          v-haptic
        >
          Save
        </button>
        <button v-if="isSaveLoading" disabled>Processing...</button>
      </div>
    </Overlay>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed, ref, onMounted, watch } from "vue";
import { auth, db } from "@/firebase";
import { collection, getDocs } from "firebase/firestore";
import { toast } from "vue3-toastify";

import Overlay from "@/components/GenericComponents/Overlay.vue";
import AddProductForm from "@/components/DashboardComponents/EcosystemComponents/AddProductForm.vue";
import AddServiceForm from "@/components/DashboardComponents/EcosystemComponents/AddServiceForm.vue";
import ImageUpload from "@/components/GenericComponents/ImageUpload.vue";

export default {
  name: "SellingAction",
  components: { Overlay, AddProductForm, AddServiceForm, ImageUpload },
  props: {
    userCredentials: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    userUnitsOrg: {
      type: Object,
      required: true,
    },
    userIsInAnOrg: {
      type: Boolean,
      required: true,
    },
    parentContext: {
      type: String,
      default: "Ecosystem",
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const orgs = ref([]);
    const selectedCard = ref(null);
    let triggerAddProduct;
    let triggerAddService;
    const showOverlay = ref(false);
    const overlayType = ref("");
    const currentStep = ref("initial");

    const isSaveLoading = ref(false);
    const addProductFormRef = ref(null);
    const addServiceFormRef = ref(null);
    const canPreview = ref(false);
    const dynamicTitle = ref("Edit details");
    const dynamicTitleProduct = ref("Add Product");
    const dynamicTitleService = ref("Add Service");

    const closeMenu = () => {
      emit("closeSellingAction"); // Emit event to parent to close the dropdown
    };

    const selectCard = (type) => {
      if (type === "Product" || type === "Service") {
        openOverlay(type);
      }
      if (selectedCard.value === type) {
        selectedCard.value = null;
      } else {
        selectedCard.value = type;
      }
    };

    auth.onAuthStateChanged((user) => {
      store.dispatch("fetchUser", user);
    });
    auth.onAuthStateChanged((userCredentials) => {
      store.dispatch("fetchUserCredentials", userCredentials);
    });

    const user = computed(() => {
      return store.getters.user;
    });
    const userCredentials = computed(() => {
      return store.getters.userCredentials;
    });

    const validateForm = () => {
      if (overlayType.value === "Product" && addProductFormRef.value) {
        return addProductFormRef.value.handleFormValidation();
      } else if (overlayType.value === "Service" && addServiceFormRef.value) {
        return addServiceFormRef.value.handleFormValidation();
      }
      return false;
    };

    const validateFormAndProceed = (type, proceed = false) => {
      let isValid = false;
      const formRef =
        type === "Product" ? addProductFormRef.value : addServiceFormRef.value;

      if (formRef) {
        isValid = formRef.handleFormValidation();
      }

      canPreview.value = isValid;

      if (isValid && proceed) {
        goToNextStep(type.toLowerCase());
      }
    };
    const orgUid = Object.keys(props.userUnitsOrg)[0];

    const handleProductAdded = () => {
      isSaveLoading.value = false;
      store.dispatch("fetchProducts", { orgUid: orgUid });
      toast.success("Product added successfully!");

      emit("itemEdited");
      closeOverlay();
    };

    const handleServiceAdded = () => {
      isSaveLoading.value = false;
      store.dispatch("fetchServices", { orgUid: orgUid });
      toast.success("Service added successfully!");
      emit("itemEdited");
      closeOverlay();
    };

    const handleTitleUpdate = (newTitle) => {
      dynamicTitle.value = newTitle;
    };

    const openOverlay = (type) => {
      overlayType.value = type;
      showOverlay.value = true;
      currentStep.value = "initial";
      store.dispatch("toggleNavbarVisibility", false); // Hide the Navbar
    };

    const closeOverlay = () => {
      showOverlay.value = false;
      currentStep.value = "initial";
      selectedCard.value = null;
      store.dispatch("toggleNavbarVisibility", true); // Show the Navbar
      if (addServiceFormRef.value) {
        addServiceFormRef.value.resetForm(); // Reset the form when the overlay is closed
      }
      if (addProductFormRef.value) {
        addProductFormRef.value.resetForm(); // Reset the form when the overlay is closed
      }
      isSaveLoading.value = false;
    };

    const goToNextStep = () => {
      if (overlayType.value === "Product" && addProductFormRef.value) {
        if (!addProductFormRef.value.handleFormValidation()) {
          return;
        }
      } else if (overlayType.value === "Service" && addServiceFormRef.value) {
        if (!addServiceFormRef.value.handleFormValidation()) {
          return;
        }
      }

      if (overlayType.value === "Product" && currentStep.value === "initial") {
        addProductFormRef.value.gatherDataForPreview();
        currentStep.value = "preview";
      } else if (overlayType.value === "Service") {
        if (currentStep.value === "initial") {
          currentStep.value = "availability";
        } else if (currentStep.value === "availability") {
          if (
            addServiceFormRef.value.selectedDays &&
            addServiceFormRef.value.selectedDays.filter((day) => day).length ===
              0
          ) {
            addServiceFormRef.value.gatherServiceDataForPreview();
            currentStep.value = "preview";
          } else {
            currentStep.value = "customization";
          }
        } else if (currentStep.value === "customization") {
          addServiceFormRef.value.gatherServiceDataForPreview();
          currentStep.value = "preview";
        }
      }
    };

    const goToPreviousStep = () => {
      if (overlayType.value === "Product") {
        if (currentStep.value === "preview") {
          currentStep.value = "initial";
        }
      } else if (overlayType.value === "Service") {
        if (currentStep.value === "customization") {
          currentStep.value = "availability";
        } else if (currentStep.value === "availability") {
          currentStep.value = "initial";
        } else if (currentStep.value === "preview") {
          currentStep.value = "availability";
        }
      }
    };

    const shouldShowBackArrow = computed(() => {
      if (overlayType.value === "Product") {
        return currentStep.value !== "initial";
      } else if (overlayType.value === "Service") {
        return currentStep.value !== "initial";
      }
      return false;
    });

    const saveProduct = async () => {
      isSaveLoading.value = true;
      if (addProductFormRef.value) {
        try {
          await addProductFormRef.value.addOrUpdateProduct();
          emit("itemEdited");
        } catch (error) {
          console.error("Error saving product:", error);
        } finally {
          isSaveLoading.value = false;
        }
      } else {
        console.error("Form reference is not correctly set.");
        isSaveLoading.value = false;
      }
    };

    const saveService = async () => {
      isSaveLoading.value = true;
      if (addServiceFormRef.value) {
        try {
          await addServiceFormRef.value.addOrUpdateService();
          emit("itemEdited");
        } catch (error) {
          console.error("Error saving service:", error);
        } finally {
          isSaveLoading.value = false;
        }
      } else {
        console.error("Form reference is not correctly set.");
        isSaveLoading.value = false;
      }
    };

    const handleStepUpdate =
      (formType) =>
      ({ form, step }) => {
        if (form === formType) {
          currentStep.value = step;
        }
      };

    const skipToPreview = () => {
      if (addServiceFormRef.value) {
        if (addServiceFormRef.value.customAvailability) {
          addServiceFormRef.value.customAvailability.value = [];
        }
        if (addServiceFormRef.value.selectedSlots) {
          addServiceFormRef.value.selectedSlots.value = new Array(7)
            .fill([])
            .map(() => []);
        }
      }
      currentStep.value = "preview";
    };

    onMounted(async () => {
      const querySnapshot = await getDocs(collection(db, "custom-orgs"));
      orgs.value = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    });

    watch(showOverlay, (newValue) => {
      if (newValue) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    });

    // Open Product Overlay
    const openProductOverlay = () => {
      openOverlay("Product");
    };

    // Open Service Overlay
    const openServiceOverlay = () => {
      openOverlay("Service");
    };

    return {
      user,
      userCredentials,
      orgs,
      selectedCard,
      selectCard,
      handleProductAdded,
      handleServiceAdded,
      dynamicTitle,
      dynamicTitleProduct,
      dynamicTitleService,
      currentStep,
      showOverlay,
      overlayType,
      openOverlay,
      closeOverlay,
      isSaveLoading,
      goToPreviousStep,
      goToNextStep,
      shouldShowBackArrow,
      handleTitleUpdate,
      saveProduct,
      saveService,
      addProductFormRef,
      addServiceFormRef,
      triggerAddProduct,
      triggerAddService,
      validateForm,
      validateFormAndProceed,
      canPreview,
      handleStepUpdate,
      skipToPreview,
      openProductOverlay, // Exposed modified method
      openServiceOverlay, // Exposed modified method
      closeMenu,
    };
  },
};
</script>

<style scoped>
.SellingAction {
  /* Positioning similar to dropdownMenu */
  position: absolute;

  background: var(--glass-bg);
  box-shadow: var(--glass-box-shadow);
  border-radius: var(--borderRadius);
  overflow: hidden;
  z-index: 2;
  padding: var(--smallerMargin);
  transition: all 0.15s ease;
  backdrop-filter: var(--glass-backdrop-filter);
  -webkit-backdrop-filter: var(--glass-backdrop-filter);
  border: var(--glass-border);
}

/* Positioning for Ecosystem */
.SellingAction.Ecosystem {
  top: calc(100% + var(--smallMargin));
  right: calc(var(--smallClickableHeight) + var(--margin) + var(--margin));
}

/* Positioning for UserProfile */
.SellingAction.UserProfile {
  top: inherit;
  margin-left: var(--smallMargin);
}

.menu-item {
  border-radius: var(--borderRadius);
  padding: var(--smallMargin);
  width: 100%;
  height: var(--clickableHeight);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: all 0.15s;
  cursor: pointer;
  border-bottom: 1px solid var(--borderColour);
  margin-bottom: var(--tinyMargin);
}

.menu-item:first-child {
  border-top: 1px solid var(--borderColour);
}

.menu-item:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.menu-item:hover {
  opacity: 0.8;
  transform: translateY(var(--transY));
}

.menu-link {
  width: 100%;
  text-align: right;
  position: relative;
}

.menu-link.pressed {
  border-bottom: none;
  box-shadow: var(--neuMorphBoxInnerSmall);
  transform: translateY(1px);
}

/* Optional: Adjust SVG sizes if necessary */
.compactImg {
  margin-right: var(--smallerMargin);
  width: 20px; /* Adjust size as needed */
  height: 20px; /* Adjust size as needed */
}

@media (min-width: 590px) {
  .SellingAction.UserProfile {
    top: var(--clickableHeight);
    right: var(--margin);
  }
}
</style>
