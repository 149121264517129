// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, isSupported } from "firebase/analytics";
import { getFirestore, runTransaction } from "firebase/firestore";
import { getAuth } from "firebase/auth";

import firebaseConfig from "../firebaseConfig";

const app = initializeApp(firebaseConfig);
isSupported().then((supported) => {
  if (supported) {
    const analytics = getAnalytics();
  } else {
    console.warn("Analytics is not supported in this environment.");
  }
});
const db = getFirestore(app);

const auth = getAuth();

export { db, auth };
