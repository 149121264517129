import { db } from "@/firebase";
import { getDoc, getDocs, collection, doc } from "firebase/firestore";

export default {
  state: {
    organisations: {},
    roles: null,
    roleData: null,

    members: null,
    stakeholders: null,
    averageBaseCompensation: null,
  },
  getters: {
    getBasicOrgDetails: (state) => (orgUID) =>
      state.organisations[orgUID]?.basicOrgDetails || null,
    getRoles: (state) => state.roles,
    getRoleData: (state) => state.roleData,
    getMembers: (state) => state.members,
    getStakeholders: (state) => state.stakeholders,
    getAverageBaseCompensation: (state) => state.averageBaseCompensation,
  },
  mutations: {
    SET_ORG_DETAILS(state, { orgUID, payload }) {
      if (!state.organisations[orgUID]) {
        state.organisations[orgUID] = {};
      }
      state.organisations[orgUID].basicOrgDetails = payload;
    },
    SET_ROLES(state, payload) {
      state.roles = payload;
    },
    SET_ROLE_DATA(state, payload) {
      state.roleData = payload;
    },
    CLEAR_ROLE_DATA(state) {
      state.roleData = null; // or however you'd like to reset this
    },
    SET_MEMBERS(state, payload) {
      state.members = payload;
    },
    SET_STAKEHOLDERS(state, payload) {
      state.stakeholders = payload;
    },
    SET_AVERAGE_BASE_COMPENSATION(state, payload) {
      state.averageBaseCompensation = payload;
    },
    CLEAR_ALL_STATE(state) {
      state.organisations = {};
      state.roles = null;
      state.members = null;
      state.stakeholders = null;
      state.averageBaseCompensation = null;
    },
  },
  actions: {
    async fetchAllData({ dispatch }, orgUID) {
      await Promise.all([
        dispatch("fetchBasicOrgDetails", orgUID),
        dispatch("fetchRoles", orgUID),
        dispatch("fetchMembers", orgUID),
        dispatch("fetchStakeholders", orgUID),
      ]);
    },
    async fetchBasicOrgDetails({ commit }, orgUID) {
      try {
        const docRef = doc(db, "custom-orgs", orgUID);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          const data = docSnap.data();
          const membersCollectionRef = collection(
            db,
            "custom-orgs",
            orgUID,
            "Members"
          );
          const membersSnap = await getDocs(membersCollectionRef);
          console.log("LOOK HERE JAM!!!");
          console.log(data);
          const basicOrgDetails = {
            organisationName: data.name,
            totalOrgCoins: data.totalOrgCoins,
            description: data.description,
            category: data.category,
            basicIncome: data.monetary_parameters?.basic_income,
            regularity: data.monetary_parameters?.regularity,
            initialCoins: data.monetary_parameters?.initialCoins,
            coinName: data.monetary_parameters?.coin_name,
            coinSymbol: data.monetary_parameters?.coin_symbol,
            logo: data.logo,
            baseFounderCompensation: 0, // Assuming this is a fixed value as it wasn't using any variable
            currentAccountCap: data.monetary_parameters?.current_account?.cap,
            currentAccountLifespan:
              data.monetary_parameters?.current_account?.lifespan,
            savingsAccountCap: data.monetary_parameters?.savings_account?.cap,
            allowSavings:
              data.monetary_parameters?.savings_account?.allowSavings,
            membersCount: membersSnap.size,
            loans: data.monetary_parameters?.loans || {
              dti_effortRate: 0,
              maxLoanTerm: 0,
              annualInterest: 0,
              allowIndividual: false,
              allowJoint: false,
            },
          };
          commit("SET_ORG_DETAILS", { orgUID, payload: basicOrgDetails });
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        // console.error("An error occurred:", error);
      }
    },
    async fetchRoles({ commit }, orgUID) {
      try {
        const rolesSnap = await getDocs(
          collection(db, "custom-orgs", orgUID, "Roles")
        );
        const roles = rolesSnap.docs.map((doc) => doc.data());
        // Calculate the average baseCompensation
        const totalBaseCompensation = roles.reduce(
          (acc, role) => acc + role.baseCompensation,
          0
        );
        const averageBaseCompensation =
          roles.length > 0 ? totalBaseCompensation / roles.length : 0;

        // Use a mutation to update the state
        commit("SET_AVERAGE_BASE_COMPENSATION", averageBaseCompensation);
        commit("SET_ROLES", roles);
      } catch (error) {
        // console.error("An error occurred while fetching roles:", error);
      }
    },

    async fetchRoleData({ commit }, { orgUID, roleUID }) {
      // console.log("Inside fetchRoleData action"); // <-- Add this line
      // console.log(orgUID);
      // console.log(roleUID);
      try {
        // Reference to Firestore for the specific role document
        const roleRef = doc(db, "custom-orgs", orgUID, "Roles", roleUID);

        // Fetch the document
        const roleDoc = await getDoc(roleRef);

        // Check if the document exists
        if (roleDoc.exists()) {
          const roleData = roleDoc.data(); // Extract data
          commit("SET_ROLE_DATA", roleData);
          return roleData; // <-- Important: return the fetched role data
        } else {
          console.log("No such role exists!");
        }
      } catch (error) {
        // console.log("An error occurred while fetching the role data:", error);
      }
    },
    async fetchMembers({ commit }, orgUID) {
      try {
        const membersSnap = await getDocs(
          collection(db, "custom-orgs", orgUID, "Members")
        );
        const members = membersSnap.docs.map((doc) => doc.data());
        commit("SET_MEMBERS", members);
      } catch (error) {
        // console.error("An error occurred while fetching members:", error);
      }
    },
    async fetchStakeholders({ commit }, orgUID) {
      try {
        const stakeholdersSnap = await getDocs(
          collection(db, "custom-orgs", orgUID, "Stakeholders")
        );
        const stakeholders = stakeholdersSnap.docs.map((doc) => doc.data());
        commit("SET_STAKEHOLDERS", stakeholders);
      } catch (error) {
        // console.error("An error occurred while fetching stakeholders:", error);
      }
    },
    async clearAllState({ commit }) {
      commit("CLEAR_ALL_STATE");
    },
  },
};
