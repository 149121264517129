<template>
  <div class="CreateOrJoin column fullWidth">
    <div class="column fullWidth">
      <div class="row alignCentreRow justifyToCentre">
        <button @click="toggleLoginModal" v-if="!userInStore.loggedIn" v-haptic>
          Login</button
        ><span v-if="!userInStore.loggedIn">or</span>
        <button
          @click="toggleRegisterModal"
          v-if="!userInStore.loggedIn"
          v-haptic
        >
          Register
        </button>
        <Tooltip
          v-if="!userInStore.loggedIn"
          :message="`When you create your currency with us you automatically create your organisation. Since coins and organisations come hand-in-hand, the term 'unit' can apply to both the coin and its founding organisation.`"
          icon="What's a unit?"
          size="large"
          class="secondaryButton"
        />
      </div>
      <div class="separator" v-if="!userInStore.loggedIn"></div>
      <p class="alignCentre nomarginbottom">
        Create your own unit or use an invite link to join an existing one.
      </p>
      <h2 class="shortwidth alignCentre nomarginbottom">
        Instantly create a unit<br />
      </h2>
      <p class="alignCentre nomargintopbottom">2 seconds</p>

      <div class="column fullWidth alignCentre">
        <IntroBankCard :user="user" />
        <!-- <div class="separator"></div> -->
      </div>
    </div>
    <p class="or">or</p>

    <section>
      <div class="column secondSection">
        <p class="shortwidth alignCentre nomargintopbottom">
          Use the wizzard to go through all the steps<br />
        </p>
        <p class="alignCentre nomargintopbottom">5-10 min</p>

        <div class="sticker coinDetails green divNoMarginBottom">
          <EditCoinName />
        </div>

        <div class="column alignCentre fullWidth">
          <p class="or">or</p>
        </div>

        <div class="column alignCentre">
          <p class="shortwidth nomargintopbottom">
            Use an invite link to join a specific organisation to start trading.
          </p>
        </div>
        <UseInviteLink />
      </div>
    </section>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import Tooltip from "@/components/GenericComponents/Tooltip.vue";
import ToggleButtonContent from "@/components/GenericComponents/ToggleButtonContent.vue";

import EditCoinName from "@/components/OrgComponents/OrgCustom/EditCoinName.vue";

import IntroBankCard from "@/components/LandingComponents/IntroBankCard.vue";

import JoinOrganisationCard from "@/components/LandingComponents/JoinOrganisationCard.vue";
import UseInviteLink from "@/components/LandingComponents/UseInviteLink.vue";

export default {
  name: "CreateOrJoin",
  components: {
    Tooltip,
    ToggleButtonContent,
    EditCoinName,
    IntroBankCard,
    JoinOrganisationCard,
    UseInviteLink,
  },
  props: {
    user: Object, // Accept the prop
  },

  setup() {
    const store = useStore();
    const router = useRouter(); // Using the Vue Router
    const windowWidth = ref(window.innerWidth);
    const userInStore = computed(() => store.getters.user);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };
    let foundationOrgUID = store.getters.foundationOrgUID;
    const orgDetails = computed(() =>
      store.getters.getBasicOrgDetails(foundationOrgUID)
    );

    const orgMembers = computed(() => store.getters.getMembers);

    // Define the functions to redirect

    // modals logic
    const showLoginModal = computed(() => store.state.showLoginModal);
    const showRegisterModal = computed(() => store.state.showRegisterModal);

    const toggleLoginModal = () => store.dispatch("toggleLoginModal");
    const toggleRegisterModal = () => store.dispatch("toggleRegisterModal");
    const navigateToOrganisationView = () => {
      router.push({
        name: "OrganisationView",
        params: { orgUID: foundationOrgUID },
      });
    };

    onMounted(async () => {
      window.addEventListener("resize", handleResize);
      await store.dispatch("fetchAllData", foundationOrgUID);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    return {
      userInStore,
      windowWidth,
      toggleLoginModal,
      toggleRegisterModal,
      orgDetails,
      orgMembers,
      navigateToOrganisationView,
      foundationOrgUID,
    };
  },
};
</script>

<style scoped>
.Wallet .LandingElement {
  position: relative;
  width: 100%;
}

.Wallet .LandingElement section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--margin);
}
.LandingElement {
  width: 100%;
  height: 60vh;
  left: 0;
  top: 0;
  padding: var(--margin);

  background-image: url("@/assets/stockImg/local_market_phone.jpg");
  background-size: cover;
  background-position: center;
}

.theCard {
  margin-top: calc(var(--clickableHeight) + calc(var(--smallMargin) * 2));
}

.CreateOrJoin {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.secondSection {
  align-items: center;
}

.regularColumns .column {
  max-width: 33%;
  gap: var(--smallMargin);
}

@media only screen and (max-width: 450px) {
  .LandingElement {
    height: 60vh;
  }
  .secondSection {
    /* margin-top: 60vh; */
  }
}

@media only screen and (max-width: 590px) {
  .LandingElement {
    height: 60vh;
  }
  .secondSection {
    /* margin-top: 60vh; */
  }

  .regularColumns .column {
    width: 100%;
    max-width: 100%;
  }
}
</style>
