const firebaseConfig = {
    apiKey: "AIzaSyBR4rauLph3hFrOD7Y6fsT2OaxZ2RyJURU",
    authDomain: "unit-org.firebaseapp.com",
    projectId: "unit-org",
    storageBucket: "unit-org.appspot.com",
    messagingSenderId: "604182279896",
    appId: "1:604182279896:web:f62438c8114145692f49ad",
    measurementId: "G-BHF0ESPGQN"
};

export default firebaseConfig;