<template>
  <div class="row alignCentreRow">
    <router-link
      :to="{ name: 'UserProfile', params: { userUID: userCred.uid } }"
      class="user-avatar-link"
    >
      <div class="user-avatar">
        <div v-if="!isAnonymous && photoURL" class="avatarCircle">
          <img :src="photoURL" :alt="displayName" />
        </div>
        <div v-else class="avatarCircle">
          <h1 class="nomargintopbottom" v-if="!isAnonymous && email">
            {{ email.charAt(0).toUpperCase() }}
          </h1>
          <h1 v-else>A</h1>
        </div>
      </div>
    </router-link>
    <div v-if="showNameAndBalance" class="column tinyGap nameAndBalanceColumn">
      <div class="row alignCentreRow">
        <span class="display-name noWrap">
          <h4 class="nomargintopbottom">{{ truncatedDisplayName }}</h4>
        </span>
      </div>
      <div class="row tinyGap alignCentreRow">
        <!-- Display if currentAccountBalance is greater than 0 -->
        <router-link
          v-if="currentAccountBalance"
          :to="{ name: 'wallet' }"
          class="balance"
        >
          <p class="nomargintopbottom">
            {{ formattedBalance }} {{ coinSymbol }}
          </p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onUnmounted, onMounted, ref } from "vue";
import { db } from "@/firebase";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { useStore } from "vuex";

export default {
  name: "AvatarLightAndBalance",
  props: {
    userCred: Object,
  },
  setup(props) {
    const store = useStore();
    const currentAccountBalance = ref(0);
    const coinSymbol = ref("");
    const showNameAndBalance = ref(window.innerWidth > 450);

    let unsubscribe = null; // To hold the unsubscribe function for cleanup

    const handleResize = () => {
      console.log(window.innerWidth);
      showNameAndBalance.value = window.innerWidth > 450;
    };

    const fetchActiveWalletBalance = () => {
      if (!props.userCred?.isAnonymous && props.userCred?.uid) {
        const walletsRef = collection(
          db,
          "users",
          props.userCred.uid,
          "Wallets"
        );
        const activeWalletQuery = query(
          walletsRef,
          where("isActiveMember", "==", true)
        );

        // Use onSnapshot for real-time updates
        unsubscribe = onSnapshot(activeWalletQuery, (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const walletData = doc.data();
            if (walletData.currentAccount && walletData.monetary_parameters) {
              currentAccountBalance.value =
                walletData.currentAccount.balance || 0;
              coinSymbol.value =
                walletData.monetary_parameters.coin_symbol || "";
            }
          });
        });
      }
    };

    // Call the fetch function when the component is mounted
    fetchActiveWalletBalance();

    // Cleanup the onSnapshot listener when the component is unmounted
    onUnmounted(() => {
      if (unsubscribe) {
        unsubscribe();
      }
    });

    const isAnonymous = computed(() => props.userCred?.isAnonymous ?? true);
    const photoURL = computed(() => props.userCred?.photoURL ?? "");
    const email = computed(() => props.userCred?.email ?? "");
    const displayName = computed(
      () => props.userCred?.displayName || "Unknown User"
    );

    const truncatedDisplayName = computed(() => {
      return displayName.value.split(" ").length > 1
        ? displayName.value.split(" ")[0] +
            " " +
            displayName.value.split(" ").pop()
        : displayName.value;
    });

    const formattedBalance = computed(() => {
      const balance = parseFloat(currentAccountBalance.value) || 0;
      return balance.toFixed(2);
    });

    onMounted(() => {
      window.addEventListener("resize", handleResize);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    return {
      isAnonymous,
      photoURL,
      email,
      displayName,
      truncatedDisplayName,
      currentAccountBalance,
      coinSymbol,
      formattedBalance,
      showNameAndBalance,
      handleResize,
    };
  },
};
</script>

<style scoped>
.avatarCircle {
  border: 1px solid var(--solidMidDarkgrey);
  box-shadow: none;
}
.avatarCircle:hover {
  box-shadow: var(--neuMorphBoxOuterSmall);
}

.nameAndBalanceColumn {
  justify-content: center;
}

.user-avatar-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.user-avatar-link .user-avatar {
  display: flex;
  align-items: center;
}

.user-avatar-link .avatarCircle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-avatar-link .avatarCircle img {
  border-radius: 50%;
}

a.balance {
  white-space: nowrap;
}
@media screen and (max-width: 590px) {
  .display-name.noWrap {
    display: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
