<template>
  <div class="masonry-marketplace">
    <!-- Use the FiltersComponent here -->
    <FiltersComponent
      @update:searchQuery="searchQuery = $event"
      @update:filterType="filterType = $event"
      @update:sortOrder="sortOrder = $event"
      @update:selectedCategory="selectedCategory = $event"
      :categories="categories"
    />
    <!-- <button @click="updateCategoryNames" class="update-button">update</button> -->

    <div v-if="!hasInitialItems">👀 No products or services available</div>

    <div class="masonry-grid-container" @scroll="handleScroll">
      <div class="masonry-grid">
        <div
          v-for="(column, colIndex) in columns"
          :key="`column-${colIndex}`"
          class="masonry-column"
        >
          <transition-group name="fade" tag="div" class="masonry-column-items">
            <figure class="masonry-item" v-for="item in column" :key="item.id">
              <div class="image-container" @click="redirectToProductView(item)">
                <img
                  v-if="item.images?.[item.thumbnailIndex]"
                  :src="item.images?.[item.thumbnailIndex]"
                  alt="item thumbnail"
                  class="itemThumbnail"
                />
                <div
                  class="no-image"
                  v-if="!item.images || !item.images.length"
                >
                  No image available
                </div>
              </div>

              <div class="item-details">
                <div
                  class="row justifyToStartEnd alignCentreRow divMicroMarginTopNoMarginBottom"
                >
                  <div class="row alignCentreRow">
                    <small
                      ><strong>{{
                        truncatedItemName(item.itemName)
                      }}</strong></small
                    >
                  </div>
                  <div v-if="item.type === 'product'">
                    <small v-if="item.existingStock === 0" class="soldout"
                      >Sold</small
                    >
                    <small
                      v-else-if="item.existingStock < 10"
                      :class="{ stockWarningColor: item.existingStock < 2 }"
                    >
                      {{ item.existingStock }} in stock
                    </small>
                    <small v-else class="grayedOut">In stock</small>
                  </div>
                  <small v-if="item.type === 'service'" class="service-label"
                    >Service</small
                  >
                </div>

                <div class="productActions divMicroMarginTop">
                  <div v-if="!currentUserUid">
                    <small
                      class="login-message actionText"
                      @click="toggleLoginModal"
                      >Login to see the price</small
                    >
                  </div>
                  <div
                    class="row alignCentreRow justifyToStartEnd fullWidth"
                    v-else
                  >
                    <div class="price row" v-if="item.type === 'product'">
                      <CentTranslatedPrice
                        :isProductOwner="isItemOwnedByCurrentUser(item)"
                        :product="item"
                        @price-clicked="handlePriceClicked"
                      >
                        <span
                          class="gray"
                          v-if="
                            !isItemOwnedByCurrentUser(item) &&
                            showOtherUserPrice
                          "
                        >
                          ≈ {{ formatNumberTo4Digits(item.price) }}
                          {{ item.coinSymbol }}</span
                        >
                      </CentTranslatedPrice>
                    </div>
                    <template v-if="item.type === 'service'">
                      <CentTranslatedPrice
                        v-if="isItemOwnedByCurrentUser(item)"
                        :isProductOwner="isItemOwnedByCurrentUser(item)"
                        :product="item"
                        @price-clicked="handlePriceClicked"
                      >
                        <span
                          class="gray"
                          v-if="
                            !isItemOwnedByCurrentUser(item) &&
                            showOtherUserPrice
                          "
                          >≈ {{ formatNumberTo4Digits(item.price) }}
                          {{ item.coinSymbol }}</span
                        >
                      </CentTranslatedPrice>
                      <button
                        v-else-if="
                          !isNaN(convertedPrices[item.id]?.convertedPrice)
                        "
                        @click="redirectToProductView(item)"
                        class="smallerClickableHeight"
                      >
                        <span class="nomargintopbottom row alignCentreRow">
                          <strong>
                            {{
                              formatNumberTo4Digits(
                                convertedPrices[item.id]?.convertedPrice
                              )
                            }}
                            {{ convertedPrices[item.id]?.coinSymbol }}</strong
                          ><span
                            class="gray"
                            v-if="
                              !isItemOwnedByCurrentUser(item) &&
                              showOtherUserPrice
                            "
                          >
                            ≈ {{ formatNumberTo4Digits(item.price) }}
                            {{ item.coinSymbol }}</span
                          >

                          <svg class="compactImg" alt="book a slot icon">
                            <use
                              href="@/assets/icons/iconset.svg#calendar_compact"
                            ></use>
                          </svg>
                        </span>
                      </button>
                      <button
                        v-else
                        class="secondaryButton smallerClickableHeight"
                      >
                        <span
                          class="nomargintopbottom"
                          @click="fetchConvertedPrice(item)"
                        >
                          Refresh {{ storeIsReady }}
                        </span>
                      </button>
                    </template>
                    <div
                      v-if="!isItemOwnedByCurrentUser(item)"
                      class="row actionsRow justifyToStartEnd"
                    >
                      <div class="wishlist-button">
                        <div
                          v-if="isWishlisted[item.id]"
                          @click="toggleWishlist(item)"
                          class="secondaryButton innerSticker clickable wishlist-button"
                        >
                          <svg class="compactImg">
                            <use
                              href="@/assets/icons/iconset.svg#addedtowishlist_compact"
                            ></use>
                          </svg>
                        </div>
                        <div
                          v-else
                          @click="toggleWishlist(item)"
                          class="secondaryButton innerSticker clickable wishlist-button"
                        >
                          <svg class="compactImg">
                            <use
                              href="@/assets/icons/iconset.svg#addtowishlist_compact"
                            ></use>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div
                      v-else
                      @click="editItem(item)"
                      class="row alignCentreRow ownedByCurrentUser"
                    >
                      <div class="row alignCentreRow tinyGap noButton">
                        <svg class="compactImg">
                          <use
                            href="@/assets/icons/iconset.svg#edit_compact"
                          ></use>
                        </svg>
                        <small>Edit</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </figure>
          </transition-group>
        </div>
      </div>
    </div>
    <Overlay
      :visible="showOverlay"
      :title="dynamicTitle"
      :smallTextAfterTitle="
        currentStep !== 'delete'
          ? 'You can change this later'
          : 'All information related to this item will be permanently deleted.'
      "
      :showBackArrow="shouldShowBackArrow"
      :currentStep="currentStep"
      :currentProduct="currentProduct"
      @close="closeOverlay"
      @previousStep="goToPreviousStep"
    >
      <template #before-buttons="{ product }">
        <AddProductForm
          v-if="
            !isFetching && currentProduct && currentProduct.type === 'product'
          "
          ref="addFormRef"
          :currentProduct="currentProduct"
          :userUnitsOrg="userUnitsOrg || {}"
          :currentStep="currentStep"
          @productAddedSuccessfully="handleProductAdded"
          @update-title="handleTitleUpdate"
          @update-canPreview="canPreview = $event"
        />
        <AddServiceForm
          v-if="
            !isFetching && currentProduct && currentProduct.type === 'service'
          "
          ref="addFormRef"
          :user="user || {}"
          :currentProduct="currentProduct"
          :userUnitsOrg="userUnitsOrg || {}"
          :currentStep="currentStep"
          @serviceAddedSuccessfully="handleServiceAdded"
          @update-title="handleTitleUpdate"
          @update-canPreview="canPreview = $event"
        />
        <div v-if="isFetching" class="loading-spinner">Loading...</div>
      </template>
      <div class="row alignCentreRow">
        <button
          class="secondaryButton"
          type="button"
          @click="currentStep = 'delete'"
          v-if="currentStep !== 'delete' && currentStep !== 'preview'"
        >
          <svg class="compactImg">
            <use href="@/assets/icons/iconset.svg#trash_compact"></use>
          </svg>
          Delete item
        </button>

        <button
          class="secondaryButton"
          @click="currentStep = 'initial'"
          v-if="!isSaveLoading && currentStep == 'delete'"
          v-haptic
        >
          Go back
        </button>
        <button
          type="button"
          @click="confirmDelete"
          v-if="currentStep == 'delete'"
        >
          <svg class="compactImg">
            <use href="@/assets/icons/iconset.svg#trash_compact"></use>
          </svg>
          Yes, delete
        </button>
        <button
          class="secondaryButton"
          @click="closeOverlay"
          v-if="!isSaveLoading && currentStep !== 'delete'"
          v-haptic
        >
          Cancel
        </button>
        <button
          v-if="
            !canPreview && currentStep !== 'preview' && currentStep !== 'delete'
          "
          @click="validateForm"
        >
          Next
        </button>
        <button
          @click="goToNextStep"
          v-if="
            canPreview &&
            !isSaveLoading &&
            currentStep !== 'preview' &&
            currentStep !== 'delete'
          "
          v-haptic
        >
          Next
        </button>

        <button
          @click="saveItem"
          v-if="currentStep === 'preview' && !isSaveLoading"
          :disabled="isSaveLoading"
          v-haptic
        >
          Save
        </button>
        <button v-if="isSaveLoading" disabled>Processing...</button>
      </div>
    </Overlay>
  </div>
</template>

<script>
import {
  ref,
  computed,
  reactive,
  onMounted,
  watch,
  watchEffect,
  onBeforeUnmount,
} from "vue";
import { useStore } from "vuex";
import {
  collection,
  getDocs,
  getDoc,
  doc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  writeBatch,
} from "firebase/firestore";
import { db, auth } from "@/firebase";
import CentTranslatedPrice from "@/components/DashboardComponents/EcosystemComponents/ProductListComponents/CentTranslatedPrice.vue";
import AvatarLightMicro from "@/components/UserComponents/AvatarLightMicro.vue";
import { useRouter } from "vue-router";
import { toast } from "vue3-toastify";
import { getConvertedPrice } from "@/utils/priceUtils";
import { formatNumberTo4Digits } from "@/utils/numberFormattingTo4digits";

import AddProductForm from "@/components/DashboardComponents/EcosystemComponents/AddProductForm.vue";
import AddServiceForm from "@/components/DashboardComponents/EcosystemComponents/AddServiceForm.vue";
import Overlay from "@/components/GenericComponents/Overlay.vue";
import FiltersComponent from "@/components/DashboardComponents/EcosystemComponents/MasonryMarketplaceComponents/FiltersComponent.vue";

export default {
  name: "MasonryMarketplace",
  components: {
    CentTranslatedPrice,
    AvatarLightMicro,
    AddProductForm,
    AddServiceForm,
    Overlay,
    FiltersComponent, // Register FiltersComponent
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    services: {
      type: Array,
      default: () => [],
    },
    showItems: {
      type: String,
      default: "allUsers",
    },
    currentUserUid: String,
    profileUserUid: String,
    buyerOrgUID: String,
    organisationUid: String,
    storeIsReady: Boolean,
  },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const hasInitialItems = ref(false);
    const searchQuery = ref("");
    const filterType = ref("");
    const sortOrder = ref("desc");
    const itemsToLoad = ref(10);
    const currentUserUid = ref(auth.currentUser ? auth.currentUser.uid : null);
    const isWishlisted = reactive({});
    const convertedPrices = reactive({});
    const isFetching = ref(false);
    const isLoadingMoreItems = ref(false);
    const currentProduct = ref({});
    const showOverlay = ref(false);
    const currentStep = ref("initial");
    const dynamicTitle = ref("Edit product details");
    const isCurrentUserOrg = computed(() => {
      return props.organisationUid === props.currentUserUid;
    });

    const categoryMapping = {
      Baby: "Home & Living",
      "Arts and Collectibles": "Arts & Culture",
      "Toys and Games": "Sports & Outdoors",
      "Health and Beauty": "Health & Wellness",
      "Pet Supplies": "Home & Living",
      "Toys and Hobbies": "Sports & Outdoors",
      "Business and Industry": "Business & Industrial",
      "Electronics & Tech Goods": "Electronics & Technology",
      "Fashion & Accessories": "Fashion & Beauty",
      "Arts & Entertainment": "Arts & Culture",
      "Other/Specialty": "Other",
      "Healthcare and Wellness": "Health & Wellness",
      "Retail and Wholesale": "Business & Industrial",
      "Education, Training, and Coaching": "Education",
      "Hospitality, Events, and Entertainment": "Services",
      "Business and Professional": "Business & Industrial",
      "Finance and Banking": "Business & Industrial",
      "Transportation and Logistics": "Transportation",
      "Government and Security": "Services",
      "Technology and Digital": "Electronics & Technology",
      "Design, and Media": "Arts & Culture",
      "Home and Personal Services": "Services",
      "Environmental and Utilities": "Services",
      "Real Estate and Property": "Home & Living",
      "Agriculture and Fisheries": "Business & Industrial",
      "Animal Care and Services": "Services",
      "Other Services": "Services",
    };

    const organisationUid = computed(() => {
      return isCurrentUserOrg.value
        ? props.organisationUid
        : props.organisationUid;
    });
    const categories = reactive([
      {
        name: "Technology",
        type: "both", // 'product', 'service', or 'both'
        conditions: [
          "New",
          "Open Box",
          "Refurbished",
          "Used",
          "For Parts/Not Working",
        ],
      },
      {
        name: "Fashion & Beauty",
        type: "both",
        conditions: [
          "New with Tags",
          "New without Tags",
          "Used",
          "Like New",
          "Good",
          "Acceptable",
        ],
      },
      {
        name: "Home & Living",
        type: "both",
        conditions: [
          "New",
          "Used",
          "Refurbished",
          "Like New",
          "Good",
          "Acceptable",
        ],
      },
      {
        name: "Arts & Culture",
        type: "both",
        conditions: ["New", "Used", "Collectible", "Digital Goods"],
      },
      {
        name: "Business",
        type: "both",
        conditions: ["New", "Used", "Refurbished"],
      },
      {
        name: "Health & Wellness",
        type: "both",
        conditions: ["New", "Open Box", "Used", "For Parts/Not Working"],
      },
      {
        name: "Sports & Outdoors",
        type: "both",
        conditions: ["New", "Used", "Refurbished"],
      },
      {
        name: "Transportation",
        type: "both",
        conditions: ["New", "Used", "Refurbished", "For Parts/Not Working"],
      },
      {
        name: "Education",
        type: "service",
        // No conditions for services
      },
      {
        name: "Services",
        type: "service",
        // A general category for other services
      },
      {
        name: "Other",
        type: "both",
        conditions: ["New", "Used", "Refurbished"],
      },
    ]);

    const updateCategoryNames = async () => {
      // Define the mapping from old category names to new category names
      const categoryNameMapping = {
        "Electronics & Technology": "Technology",
        "Arts & Entertainment": "Arts & Culture",
        "Business & Industrial": "Business",
        "Automotive & Transportation": "Transportation",
        "Education & Training": "Education",
        // The rest remain the same
        "Fashion & Beauty": "Fashion & Beauty",
        "Home & Living": "Home & Living",
        "Health & Wellness": "Health & Wellness",
        "Sports & Outdoors": "Sports & Outdoors",
        Services: "Services",
        Other: "Other",
      };

      // Confirm the action with the user
      const confirmed = confirm(
        "This action will update the category names of all items in the database. Do you want to proceed?"
      );

      if (!confirmed) {
        return;
      }

      try {
        // Fetch all products and services
        const productsSnapshot = await getDocs(collection(db, "products"));
        const servicesSnapshot = await getDocs(collection(db, "services"));

        // Combine products and services
        const allItems = [...productsSnapshot.docs, ...servicesSnapshot.docs];

        // Prepare batch updates
        const batchSize = 500; // Firestore batch limit
        let batch = writeBatch(db);
        let counter = 0;

        for (const docSnap of allItems) {
          const data = docSnap.data();
          const oldCategory = data.category;

          if (oldCategory && categoryNameMapping[oldCategory]) {
            const newCategory = categoryNameMapping[oldCategory];
            batch.update(docSnap.ref, { category: newCategory });
            counter++;

            // Commit batch if batchSize is reached
            if (counter % batchSize === 0) {
              await batch.commit();
              batch = writeBatch(db);
              console.log(`${counter} documents updated so far...`);
            }
          }
        }

        // Commit any remaining batch operations
        if (counter % batchSize !== 0) {
          await batch.commit();
        }

        alert(
          `Finished updating categories. Total documents updated: ${counter}`
        );

        // Refresh items to reflect changes
        await refreshItems();
      } catch (error) {
        console.error("Error updating categories:", error);
        alert("An error occurred while updating categories.");
      }
    };

    const refreshItems = async () => {
      // Re-fetch products and services
      await store.dispatch("fetchProducts", {
        batchSize: 10,
        refresh: true,
        orgUID: buyerOrgUID.value,
      });
      await store.dispatch("fetchServices", {
        batchSize: 10,
        refresh: true,
        orgUID: buyerOrgUID.value,
      });

      // Re-process items
      await processItems(allItems.value);
    };

    const selectedCategory = ref("");

    const buyerOrgUID = computed(() => {
      return isCurrentUserOrg.value ? props.organisationUid : props.buyerOrgUID;
    });
    const shouldShowBackArrow = computed(() => {
      return currentStep.value === "preview" || currentStep.value === "delete";
    });
    const isSaveLoading = ref(false);
    const canPreview = ref(false);

    const addFormRef = ref(null);

    const user = ref(null);
    const userUnitsOrg = ref({});

    // Column management
    const getNumberOfColumns = () => {
      const width = window.innerWidth;
      if (width < 590) return 2;
      if (width < 1080) return 3; // Set to 3 instead of 4 for medium-sized screens
      return 3; // Set to 3 for larger screens as well
    };

    const numberOfColumns = ref(getNumberOfColumns());

    const columns = ref([]);
    const processedItems = ref([]);

    const filteredItems = computed(() => {
      let items = processedItems.value; // Using processed items directly

      // Check if `showOwnOrganisationOnly` is true and filter by organization UID
      if (store.state.UIState.showOwnOrganisationOnly) {
        items = items.filter(
          (item) => item.organisation === props.organisationUid
        );
      }

      if (props.showItems === "currentUser") {
        items = items.filter((item) => item.owner === currentUserUid.value);
      } else if (props.showItems === "otherUsers") {
        items = items.filter((item) => item.owner !== currentUserUid.value);
      } else if (props.showItems === "profileUser") {
        items = items.filter((item) => item.owner === props.profileUserUid);
      }

      if (filterType.value) {
        items = items.filter((item) => item.type === filterType.value);
      }

      if (searchQuery.value) {
        items = items.filter(
          (item) =>
            item.itemName
              .toLowerCase()
              .includes(searchQuery.value.toLowerCase()) ||
            item.itemDescription
              .toLowerCase()
              .includes(searchQuery.value.toLowerCase())
        );
      }

      // **Add the Category Filter Here**
      if (selectedCategory.value) {
        items = items.filter(
          (item) => item.category === selectedCategory.value
        );
      }

      // Sorting is now handled in distributeItems
      // So here, we just return the sorted items up to itemsToLoad
      const sorted = [...items].sort((a, b) => {
        if (sortOrder.value === "asc") {
          return (
            (parseFloat(convertedPrices[a.id]?.convertedPrice) || 0) -
            (parseFloat(convertedPrices[b.id]?.convertedPrice) || 0)
          );
        } else {
          return (
            (parseFloat(convertedPrices[b.id]?.convertedPrice) || 0) -
            (parseFloat(convertedPrices[a.id]?.convertedPrice) || 0)
          );
        }
      });

      hasInitialItems.value = sorted.length > 0;
      return sorted.slice(0, itemsToLoad.value);
    });

    const distributeItems = () => {
      const cols = Array.from({ length: numberOfColumns.value }, () => []);
      const sorted = [...filteredItems.value].sort((a, b) => {
        if (sortOrder.value === "asc") {
          return (
            (parseFloat(convertedPrices[a.id]?.convertedPrice) || 0) -
            (parseFloat(convertedPrices[b.id]?.convertedPrice) || 0)
          );
        } else {
          return (
            (parseFloat(convertedPrices[b.id]?.convertedPrice) || 0) -
            (parseFloat(convertedPrices[a.id]?.convertedPrice) || 0)
          );
        }
      });
      sorted.forEach((item, index) => {
        cols[index % numberOfColumns.value].push(item);
      });
      columns.value = cols;
      hasInitialItems.value = sorted.length > 0;
    };

    const handleResize = () => {
      const newColumnCount = getNumberOfColumns();
      if (newColumnCount !== numberOfColumns.value) {
        numberOfColumns.value = newColumnCount;
        distributeItems();
      }
    };
    const windowWidth = ref(window.innerWidth);

    const updateWindowWidth = () => {
      windowWidth.value = window.innerWidth;
    };

    // Watchers
    watch(
      () => [filteredItems.value, numberOfColumns.value],
      () => {
        distributeItems();
      },
      { deep: true }
    );

    watch(
      () => sortOrder.value,
      () => {
        distributeItems();
      }
    );

    onMounted(() => {
      distributeItems();
      window.addEventListener("resize", handleResize);
      window.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", updateWindowWidth);

      if (allItems.value.length > 0) {
        processItems(allItems.value);
      }
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", updateWindowWidth);
    });
    const showOtherUserPrice = computed(() => windowWidth.value > 590);

    const openOverlay = () => {
      console.log("Opening overlay...");
      currentStep.value = "initial";
      showOverlay.value = true;
      store.dispatch("toggleNavbarVisibility", false);
    };

    const goToNextStep = async () => {
      if (!addFormRef.value.handleFormValidation()) {
        return;
      }
      if (currentProduct.value.type === "product") {
        if (currentStep.value === "initial") {
          currentStep.value = "preview";
        }
      } else {
        if (currentStep.value === "initial") {
          currentStep.value = "availability";
        } else if (currentStep.value === "availability") {
          if (
            addFormRef.value.selectedDays &&
            addFormRef.value.selectedDays.filter((day) => day).length === 0
          ) {
            await addFormRef.value.gatherServiceDataForPreview();
            currentStep.value = "preview";
          } else {
            currentStep.value = "customization";
          }
        } else if (currentStep.value === "customization") {
          await addFormRef.value.gatherServiceDataForPreview();
          currentStep.value = "preview";
        }
      }
    };

    const goToPreviousStep = () => {
      console.log("Going to previous step...");
      if (currentProduct.value.type === "product") {
        if (currentStep.value === "preview" || currentStep.value === "delete") {
          currentStep.value = "initial";
        }
      } else {
        if (currentStep.value === "customization") {
          currentStep.value = "availability";
        } else if (currentStep.value === "availability") {
          currentStep.value = "initial";
        } else if (currentStep.value === "preview") {
          currentStep.value = "availability";
        }
      }
    };

    const closeOverlay = () => {
      console.log("Closing overlay...");
      showOverlay.value = false;
      currentStep.value = "initial";
      store.dispatch("clearProduct");
      store.dispatch("toggleNavbarVisibility", true);
    };

    const toggleLoginModal = () => {
      store.dispatch("toggleLoginModal", true);
    };

    const fetchConvertedPrice = async (item) => {
      // Fetch buyer and seller UIDs
      const buyerOrgUID =
        props.currentUserUid || store.getters.currentUserOrgUID; // Fallback to store getter if undefined
      const sellerOrgUID = item.itemOwner || item.organisation; // Ensure at least one UID is set

      // Debug logs to track UID values
      console.log("Buyer Org UID:", buyerOrgUID);
      console.log("Seller Org UID:", sellerOrgUID);
      console.log("Item details:", item);

      // Check if both UIDs are available before proceeding
      if (!buyerOrgUID || !sellerOrgUID) {
        console.error("Organization UIDs are missing for item:", item);
        return; // Exit the function early if UIDs are missing
      }

      try {
        // Fetch the converted price from the utility function
        const { convertedPrice, coinSymbol } = await getConvertedPrice(
          item,
          sellerOrgUID,
          item.price
        );

        // Check if the converted price is valid
        if (!isNaN(convertedPrice)) {
          // Store the converted price in the reactive object
          convertedPrices[item.id] = { convertedPrice, coinSymbol };
          distributeItems(); // Re-distribute items after fetching price
        } else {
          console.warn(`Invalid price calculated for item ${item.id}`);
        }
      } catch (error) {
        // Handle errors during the price conversion process
        console.error(
          `Failed to fetch converted price for item ${item.id}:`,
          error
        );
      }
    };

    const fetchOwnerData = async (ownerId) => {
      const userDoc = await getDoc(doc(db, "users", ownerId));
      if (userDoc.exists()) {
        return userDoc.data();
      }
      return null;
    };

    const refreshPage = () => {
      location.reload();
    };

    const allItems = computed(() => {
      return [...props.products, ...props.services];
    });

    const processItems = async (items) => {
      const existingItemIds = new Set(processedItems.value.map((pi) => pi.id));
      const newItems = items.filter((item) => !existingItemIds.has(item.id));

      const promises = newItems.map(async (item) => {
        const ownerData = await fetchOwnerData(item.owner);
        await fetchConvertedPrice(item);
        return { ...item, ownerData };
      });

      const newProcessedItems = await Promise.all(promises);

      processedItems.value = [...processedItems.value, ...newProcessedItems];
    };

    const allItemsWithOwnerData = computed(async () => {
      // Not used in this implementation, can be removed if unnecessary
      const items = allItems.value;
      const promises = items.map(async (item) => {
        const ownerData = await fetchOwnerData(item.owner);
        await fetchConvertedPrice(item);
        return { ...item, ownerData };
      });

      return await Promise.all(promises);
    });

    watchEffect(() => {
      if (allItems.value.length > 0 && props.storeIsReady) {
        processItems(allItems.value);
      }
    });

    const addOwnerDataToItems = async () => {
      const items = allItems.value;
      const promises = items.map(async (item) => {
        const ownerData = await fetchOwnerData(item.owner);
        await fetchConvertedPrice(item);
        return { ...item, ownerData };
      });

      processedItems.value = await Promise.all(promises);
    };

    const handlePriceClicked = async (eventData) => {
      const { product, convertedPrice, coinSymbol } = eventData;
      console.log("EVENT DATA - priceclicked");
      console.log(eventData);
      if (!product || !product.owner) {
        console.error("Product data is missing in eventData:", eventData);
        return;
      }
      const sellerCoinSymbol = eventData.sellerCoinSymbol || "SellerCoinSymbol";
      const buyerCoinSymbol = eventData.buyerCoinSymbol || "BuyerCoinSymbol";

      console.log("SellerCoinSymbol in handlePriceClicked:", eventData);

      const actionPayload = {
        product,
        convertedPrice: convertedPrice || 0,
        buyerCoinSymbol: buyerCoinSymbol || "",
        sellerCoinSymbol: sellerCoinSymbol || "",
      };

      await store.dispatch("UIState/fetchOtherUserData", actionPayload);
      await store.dispatch("UIState/setMessagesOpen", true);
      await store.dispatch("UIState/setMessagesMaximised", true);
      await store.dispatch("UIState/setShowInteractiveMessage", true);
    };

    const redirectToProductView = async (item) => {
      console.log("Redirecting to product view with item:", item);
      if (item.id) {
        router.push({ name: "ProductView", params: { id: item.id } });
      } else {
        console.error("Missing item ID in redirectToProductView", item);
      }
    };

    const handleProductAdded = async (newProduct) => {
      console.log("handleProductAdded", newProduct);
      toast.success("Product edited successfully");

      isSaveLoading.value = false;

      // Refresh the products list
      await store.dispatch("fetchProducts", {
        batchSize: 10,
        refresh: true,
        orgUID: props.buyerOrgUID,
      });

      closeOverlay();
    };

    const handleServiceAdded = async (newService) => {
      console.log("handleServiceAdded", newService);
      toast.success("Service edited successfully");

      isSaveLoading.value = false;

      // Refresh the services list
      await store.dispatch("fetchServices", { batchSize: 10, refresh: true });

      closeOverlay();
    };

    const handleTitleUpdate = (newTitle) => {
      dynamicTitle.value = newTitle;
    };

    const validateForm = async () => {
      if (addFormRef.value) {
        await addFormRef.value.handleFormValidation();
      } else {
        console.log("addFormRef is null");
      }
    };

    const saveItem = async () => {
      isSaveLoading.value = true;
      try {
        if (addFormRef.value) {
          if (currentProduct.value.type === "product") {
            await addFormRef.value.addOrUpdateProduct();
          } else {
            await addFormRef.value.addOrUpdateService();
          }
          emit("itemEdited");
        } else {
          console.error("Form reference is not correctly set.");
        }
      } catch (error) {
        console.error("Error in saveItem:", error);
      } finally {
        isSaveLoading.value = false;
      }
    };

    const deleteSubcollection = async (
      parentCollection,
      parentId,
      subcollection
    ) => {
      const subcollectionRef = collection(
        db,
        parentCollection,
        parentId,
        subcollection
      );
      const snapshot = await getDocs(subcollectionRef);

      if (snapshot.empty) {
        console.log(`No documents found in ${subcollection} subcollection.`);
        return;
      }

      const batch = writeBatch(db);
      snapshot.forEach((docSnap) => {
        batch.delete(docSnap.ref);
      });

      await batch.commit();
      console.log(
        `Deleted all documents in ${subcollection} subcollection for ${parentCollection}/${parentId}.`
      );
    };

    const confirmDelete = async () => {
      try {
        isSaveLoading.value = true; // Start loading indicator

        if (!currentProduct.value || !currentProduct.value.id) {
          throw new Error("No item ID found.");
        }

        const parentCollection =
          currentProduct.value.type === "product" ? "products" : "services";
        const parentId = currentProduct.value.id;

        // Delete the subcollection first if it's a service
        if (currentProduct.value.type === "service") {
          await deleteSubcollection(parentCollection, parentId, "slots");
        }

        // Then delete the main document
        await deleteDoc(doc(db, parentCollection, parentId));

        toast.success("Item deleted successfully.");

        // Refresh the relevant list
        if (currentProduct.value.type === "product") {
          await store.dispatch("fetchProducts", {
            batchSize: 10,
            refresh: true,
            orgUID: props.buyerOrgUID,
          });
        } else {
          await store.dispatch("fetchServices", {
            batchSize: 10,
            refresh: true,
            orgUID: props.buyerOrgUID,
          });
        }

        emit("itemEdited");
        closeOverlay();
      } catch (error) {
        toast.error(
          `Failed to delete ${currentProduct.value.type}: ${error.message}`
        );
        console.error(`Deletion Error:`, error);
      } finally {
        isSaveLoading.value = false; // End loading indicator
      }
    };

    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const docHeight = document.documentElement.scrollHeight;

      const scrollThreshold = 100;

      if (scrollTop + windowHeight + scrollThreshold >= docHeight) {
        console.log("Near bottom, fetching more items");
        itemsToLoad.value += 10;
        fetchMoreItems();
      }
    };

    const fetchMoreItems = async () => {
      if (isLoadingMoreItems.value) {
        return; // Prevent duplicate fetches
      }
      isLoadingMoreItems.value = true;
      console.log("Fetching more items...");

      try {
        const lastVisibleProduct =
          store.state.productActions.lastVisibleProduct;
        const lastVisibleService =
          store.state.serviceActions.lastVisibleService;

        if (!lastVisibleProduct && !lastVisibleService) {
          console.log("No more items to fetch.");
          return;
        }

        await store.dispatch("fetchProducts", {
          batchSize: 10,
          orgUID: props.buyerOrgUID,
        });
        await store.dispatch("fetchServices", {
          batchSize: 10,
          orgUID: props.buyerOrgUID,
        });

        await processItems(allItems.value); // Process and append new items
      } catch (error) {
        console.error("Error fetching more items:", error);
      } finally {
        isLoadingMoreItems.value = false;
        console.log("Finished fetching more items.");
      }
    };

    const toggleWishlist = (item) => {
      if (isWishlisted[item.id]) {
        removeFromWishlist(item);
      } else {
        addToWishlist(item);
      }
    };

    const addToWishlist = async (item) => {
      if (currentUserUid.value) {
        try {
          const wishlistDocRef = doc(
            db,
            "users",
            currentUserUid.value,
            "Wishlist",
            item.id
          );
          await setDoc(wishlistDocRef, {
            ...item,
            createdAt: serverTimestamp(),
          });
          isWishlisted[item.id] = true;
          emit("wishlistUpdated");
          toast.success("Added to wishlist.", { autoClose: 3000 });
        } catch (error) {
          console.error("Error adding to wishlist: ", error);
          toast.error("Error adding to wishlist.", { autoClose: 3000 });
        }
      } else {
        toast.error("You must be logged in to add items to wishlist.", {
          autoClose: 3000,
        });
      }
    };

    const removeFromWishlist = async (item) => {
      if (currentUserUid.value) {
        try {
          const wishlistDocRef = doc(
            db,
            "users",
            currentUserUid.value,
            "Wishlist",
            item.id
          );
          await deleteDoc(wishlistDocRef);
          isWishlisted[item.id] = false;
          emit("wishlistUpdated");
          toast.success("Removed from wishlist.", { autoClose: 3000 });
        } catch (error) {
          isWishlisted[item.id] = true;
          console.error("Error removing from wishlist: ", error);
          toast.error("Error removing from wishlist.", { autoClose: 3000 });
        }
      } else {
        toast.error(
          "You must be logged in to remove items from the wishlist.",
          {
            autoClose: 3000,
          }
        );
      }
    };

    const truncatedItemName = (itemName) => {
      if (itemName && itemName.length > 16) {
        const firstPart = itemName.substring(0, 8).trim();
        const lastPart = itemName.substring(itemName.length - 3).trim();
        return `${firstPart}(…)${lastPart}`;
      }
      return itemName;
    };

    const isItemOwnedByCurrentUser = (item) => {
      return item.owner === currentUserUid.value;
    };

    const editItem = async (item) => {
      isFetching.value = true;
      try {
        if (item.type === "service") {
          await store.dispatch("fetchService", item.id);

          currentProduct.value = { ...item, type: "service" };
        } else {
          await store.dispatch("fetchProduct", item.id);
          currentProduct.value = { ...item, type: "product" };
        }
        openOverlay();
      } catch (error) {
        console.error(`Failed to fetch ${item.type}:`, error);
      } finally {
        isFetching.value = false;
      }
    };

    return {
      hasInitialItems,
      searchQuery,
      filterType,
      sortOrder,
      itemsToLoad,
      filteredItems,
      handleScroll,
      toggleWishlist,
      isWishlisted,
      truncatedItemName,
      isItemOwnedByCurrentUser,
      handlePriceClicked,
      editItem,
      redirectToProductView,
      currentUserUid,
      convertedPrices,
      formatNumberTo4Digits,
      isFetching,
      currentProduct,
      showOverlay,
      currentStep,
      dynamicTitle,
      openOverlay,
      closeOverlay,
      shouldShowBackArrow,
      goToPreviousStep,
      goToNextStep,
      isSaveLoading,
      canPreview,
      addFormRef,
      user,
      userUnitsOrg,
      handleProductAdded,
      handleServiceAdded,
      handleTitleUpdate,
      validateForm,
      saveItem,
      confirmDelete,
      toggleLoginModal,
      isLoadingMoreItems,
      refreshPage,
      columns, // Exposed for template
      selectedCategory,
      categories,
      updateCategoryNames,
      showOtherUserPrice,
    };
  },
};
</script>

<style scoped>
.masonry-grid-container {
  height: calc(100vh - (var(--clickabelHeight) + var(--bigMargin)));
  overflow-y: auto;
  margin-bottom: calc((var(--biggerMargin) * 3 + var(--margin)) * -1);
}

.masonry-marketplace {
  width: 100%;
  padding-bottom: calc(var(--smallClickableHeight) * 2);
}

.filters {
  display: flex;
  margin-bottom: var(--smallMargin);
  gap: var(--smallMargin);
}

.search-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.search-container input {
  width: 100%;
  padding: var(--smallerMargin);
  padding-left: calc(var(--smallClickableHeight) * 0.9);
  border-radius: var(--borderRadius);
}

.search-container .search-icon {
  position: absolute;
  left: var(--smallerMargin);
  pointer-events: none; /* Allow clicks to pass through to the input */
  display: flex;
  align-items: center;
  opacity: 0.6;
}

.masonry-grid {
  display: flex;
  gap: var(--smallerMargin);
}

.masonry-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--smallerMargin);
}

.masonry-item {
  background: var(--backgroundColourOrg);
  border-radius: var(--borderRadius);
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative; /* Ensure the item details are positioned correctly */
}

figure.masonry-item {
  margin: 0;
  margin-bottom: var(--smallerMargin);
}

.masonry-item img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: var(--borderRadius);
  overflow: hidden;
  background: var(--solidMidLightgrey);
}

.no-image {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--solidMidLightgrey);
  border-radius: var(--borderRadius);
  overflow: hidden;
}
.grayedOut,
.soldout,
.service-label {
  color: var(--solidMidgrey);
}

.image-container {
  position: relative;
}

.top-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30%; /* Adjust the height for more gradient */
  background: linear-gradient(
    to bottom,
    var(--backgroundColourOrgTransp) 10%,
    transparent
  );
  z-index: 1;
}

.user-avatar-small {
  top: var(--tinyMargin);
  left: var(--tinyMargin);
  z-index: 2; /* Ensure the avatar is above the gradient */
}

.item-details {
  top: 0px;
  bottom: 0;
  width: 100%;
  background: var(--backgroundColourOrgTransp);
  padding-bottom: var(--tinyMargin);
  box-sizing: border-box;
  z-index: 2;
}

.row {
  display: flex;
  justify-content: space-between;
}

.productActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}

.stockWarningColor {
  color: var(--red);
}

.soldout {
  color: var(--solidMidgrey);
}

.actionsRow {
  align-items: center;
}

.innerSticker {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--smallerClickableHeight);
  height: var(--smallerClickableHeight);
  border-radius: 50%;
  background-color: var(--backgroundColourOrg);
  padding: var(--tinyMargin);
  cursor: pointer;
}

.wishlist-button {
  border: transparent !important;
  padding: 2px 0 2px 4px !important;
}

.ownedByCurrentUser .innerSticker {
  width: fit-content;
  border-radius: var(--borderRadiusButton);
  gap: var(--tinyMargin);
  padding: 0 var(--smallMargin);
}

/* Remove column-count-based styles */

.masonry-grid {
  /* Previously used column-count is removed */
}

.masonry-column {
  /* Flex-based columns */
}

/* Responsive column layout */
@media (max-width: 590px) {
  /* Update based on manual column count */
}

@media (min-width: 591px) and (max-width: 1080px) {
  /* Update based on manual column count */
}

@media (min-width: 1081px) {
  /* Update based on manual column count */
}
</style>
