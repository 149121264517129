<template>
  <div class="UnitLogoReactive">
    <div
      :class="{
        logoBigHor: deviceOrientation === 'landscape-primary',
        logoBigVert: deviceOrientation === 'portrait-primary',
      }"
    >
      <img
        class="logoBigImg"
        :src="logoSrc"
        alt="Animated SVG"
        :style="{ filter: dropShadowStyle, webkitFilter: dropShadowStyle }"
      />
    </div>

    <div
      :class="{
        'banners-horizontal': deviceOrientation === 'landscape-primary',
        'banners-vertical': deviceOrientation === 'portrait-primary',
      }"
    >
      <div class="row alignCentreRow">
        <div class="banner top-left">🌐 A new monetary ecosystem</div>
        <div class="banner top-right">🔄 Trade with other units</div>
      </div>
      <div class="row">
        <div class="banner bottom-left">
          🫘 Create your
          <span class="animated-text-container">
            <span class="animated-text">{{ animatedText }}</span>
          </span>
        </div>
        <div class="banner bottom-right">⚖️ A fair exchange rate</div>
      </div>
    </div>
    <table class="valueTable" v-if="!hide">
      <tr>
        <td>Beta/Pitch:</td>
        <td>{{ orientation.beta.toFixed(2) }}</td>
      </tr>
      <tr>
        <td>Gamma/Roll:</td>
        <td>{{ orientation.gamma.toFixed(2) }}</td>
      </tr>

      <tr>
        <td>X:</td>
        <td>{{ x.toFixed(1) }}</td>
      </tr>
      <tr>
        <td>Y:</td>
        <td>{{ y.toFixed(1) }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "UnitLogoReactive",
  props: {
    msg: String,
  },
  setup() {
    const store = useStore();
    const isDarkMode = computed(() => store.getters["UIState/isDarkMode"]);

    const logoSrc = computed(() => {
      const src = isDarkMode.value
        ? require("@/assets/icons/logo_outline_css_white.svg")
        : require("@/assets/icons/logo_outline_css.svg");
      console.log("Logo source updated to:", src);
      return src;
    });

    const deviceOrientation = ref("landscape-primary");
    const orientation = ref({ alpha: 0, beta: 0, gamma: 0 });
    const baseOrientation = ref({ beta: 0, gamma: 0 });

    const x = ref(0);
    const y = ref(0);
    const blur = ref(3);
    const hide = ref(true);

    const texts = ["unit", "coin", "organisation"];
    const animatedText = ref(texts[0]);
    let textIndex = 0;
    let charIndex = 0;
    let isDeleting = false;

    const typeAnimation = () => {
      const currentText = texts[textIndex];
      if (isDeleting) {
        if (charIndex > 0) {
          charIndex--;
          animatedText.value = currentText.substring(0, charIndex);
          setTimeout(typeAnimation, 100);
        } else {
          isDeleting = false;
          textIndex = (textIndex + 1) % texts.length;
          setTimeout(typeAnimation, 500);
        }
      } else {
        if (charIndex < currentText.length) {
          charIndex++;
          animatedText.value = currentText.substring(0, charIndex);
          setTimeout(typeAnimation, 200);
        } else {
          setTimeout(() => {
            isDeleting = true;
            setTimeout(typeAnimation, 500);
          }, 1000);
        }
      }
    };

    onMounted(() => {
      setTimeout(typeAnimation, 1000);

      window.addEventListener("orientationchange", handleOrientationChange);
      window.addEventListener("resize", handleOrientationChange);
      window.addEventListener("deviceorientation", handleDeviceOrientation);
      handleOrientationChange();
      // console.log("Initial dark mode state:", isDarkMode.value);

      // Ensure the theme is set correctly
      const theme = isDarkMode.value ? "dark" : "light";
      document.documentElement.setAttribute("data-theme", theme);
      // console.log("Document theme set to:", theme);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("orientationchange", handleOrientationChange);
      window.removeEventListener("resize", handleOrientationChange);
      window.removeEventListener("deviceorientation", handleDeviceOrientation);
    });

    const handleDeviceOrientation = (event) => {
      orientation.value = {
        alpha: event.alpha,
        beta: event.beta,
        gamma: event.gamma,
      };

      if (
        baseOrientation.value.beta === 0 &&
        baseOrientation.value.gamma === 0
      ) {
        baseOrientation.value = {
          beta: event.beta,
          gamma: event.gamma,
        };
      }
    };

    const handleOrientationChange = () => {
      let orientationType;
      try {
        if (window.screen.orientation && "type" in window.screen.orientation) {
          orientationType = window.screen.orientation.type;
        } else {
          throw new Error("Screen Orientation API not supported");
        }
      } catch (error) {
        console.error("Screen Orientation API not supported:", error);
        orientationType = "default";
      }
      if (orientationType === "portrait-primary") {
        deviceOrientation.value = "portrait-primary";
      } else if (orientationType === "landscape-primary") {
        deviceOrientation.value = "landscape-primary";
      }
    };

    const dropShadowStyle = computed(() => {
      const betaDifference =
        (orientation.value.beta - baseOrientation.value.beta) / 2;
      const gammaDifference =
        (orientation.value.gamma - baseOrientation.value.gamma) / 2;

      x.value = Math.max(-12, Math.min(12, -betaDifference));
      y.value = Math.max(-12, Math.min(12, gammaDifference));

      return `drop-shadow(${x.value}px ${y.value}px ${blur.value}px var(--darkbluetranspheavy))`;
    });

    // Watch for changes in dark mode and log updates
    watch(isDarkMode, (newVal) => {
      console.log("Dark mode changed to:", newVal);
      const theme = newVal ? "dark" : "light";
      document.documentElement.setAttribute("data-theme", theme);
      console.log("Document theme updated to:", theme);
    });

    return {
      deviceOrientation,
      orientation,
      x,
      y,
      blur,
      hide,
      dropShadowStyle,
      handleOrientationChange,
      handleDeviceOrientation,
      logoSrc,
      animatedText,
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700&display=swap");
@font-face {
  font-family: "EcuyerDAX";
  src: url("@/assets/fonts/EcuyerDAX/EcuyerR.TTF") format("truetype");
}
.UnitLogoReactive {
  width: 100%;
  transform: translateY(-var(--clickableHeight));
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

svg#logo {
  height: inherit;
  padding: var(--margin);
}

svg#logo path {
  stroke-dasharray: 20;
}

.text-container {
  position: relative;
  text-align: center;
}
.text-container h1 {
  text-align: center;
}
.unit {
  font-size: 10rem;
}

.linethrough {
  position: relative;
}

.linethrough::after {
  border-bottom: 1px solid var(--black);
  content: "";
  left: 0;
  margin-top: calc(0.125em / 2 * -1);
  position: absolute;
  right: 0;
  top: 50%;
}

div.logoBigHor,
div.logoBigVert {
  position: relative;
}
div.logoBigHor {
  width: calc(100vw - calc(var(--margin) * 2));
  height: 50vh;
}

div.logoBigVert {
  width: 300px !important;
  height: calc(85vh - (var(--margin) * 3)) !important;
}

.logoBigImg {
  top: 50%;
  left: 50%;
  opacity: 0.9;
  height: auto;
  position: absolute;
  -webkit-filter: drop-shadow(10px 3px 1px var(--darkbluetranspheavy));
  filter: drop-shadow(10px 3px 1px var(--darkbluetranspheavy));
}

div.logoBigHor .logoBigImg {
  width: 100%;
  transform: translate(-50%, -50%);
  height: inherit;
}

div.logoBigVert .logoBigImg {
  width: 82vh;
  transform: translate(-50%, -50%) rotateZ(90deg);
}

.banners-horizontal {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  height: 50vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  text-align: center;
  align-content: space-between;
}

.banners-vertical {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: calc(85vh - (var(--margin) * 3)) !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  align-items: center;
  text-align: center;
}

.banners-vertical .row {
  width: 100%;
  height: 50%;
  align-items: flex-start;
}

.banners-horizontal .row {
  width: 100%;
  align-items: center;
}

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-family: "Cinzel Decorative", var(--fontlight), cursive; */
  /* font-size: calc(var(--pfontsize) * 1); */
  /* font-weight: 900; */
  width: 50%;
  box-sizing: border-box;
  /* -webkit-filter: drop-shadow(0px 0px 50px var(--darkbluetranspheavy));
  filter: drop-shadow(0px 0px 50px var(--darkbluetranspheavy)); */
}

.banner.top-left,
.banner.bottom-left,
.banner.top-right,
.banner.bottom-right {
  writing-mode: vertical-rl;
  display: flex;
}

.banner.top-left,
.banner.bottom-left {
  align-items: flex-end;
}

.banner.top-right,
.banner.bottom-right {
  align-items: flex-start;
}

.valueTable {
  width: 110px;
  background-color: #f0f0f5;
  position: fixed;
  top: 100px;
  left: 0;
}

.banners-vertical .animated-text-container {
  display: inline-flex;
  margin: 0.25em 0;
}
.banners-horizontal .animated-text-container {
  display: inline-flex;
  margin: 0 0.25em;
}

.animated-text {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  border-right: 1px solid var(--black);
  animation: blink-caret 0.75s step-end infinite;
}

.banners-vertical .animated-text-container {
  flex-direction: column;
}

.banners-vertical .animated-text {
  border-right: none;
  border-bottom: 1px solid var(--black);
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: var(--black);
  }
}

.banners-vertical .animated-text span {
  display: inline-block;
  transform: rotate(90deg);
}

@media (orientation: landscape) {
  .banner {
    /* font-size: var(--pfontsizeBig); */
  }

  .banner.top-left,
  .banner.bottom-left {
    align-items: flex-start;
    text-align: left;
  }
  .banner.top-left,
  .banner.bottom-left {
    justify-content: flex-start;
  }
  .banner.top-right,
  .banner.bottom-right {
    justify-content: flex-end;
  }

  .banner.top-right,
  .banner.bottom-right {
    align-items: flex-end;
    text-align: right;
  }

  .banner.top-left .animated-text-container,
  .banner.bottom-left .animated-text-container {
    justify-content: flex-start;
  }

  .banner.top-right .animated-text-container,
  .banner.bottom-right .animated-text-container {
    justify-content: flex-end;
  }

  .banner.top-left,
  .banner.bottom-left,
  .banner.top-right,
  .banner.bottom-right {
    writing-mode: horizontal-tb;
    text-align: center;
  }
}

@media (orientation: portrait) {
  .banner.top-left,
  .banner.bottom-left,
  .banner.top-right,
  .banner.bottom-right {
    writing-mode: vertical-rl;
  }

  .banner.top-left .animated-text-container,
  .banner.bottom-left .animated-text-container {
    justify-content: flex-start;
    flex-direction: column-reverse;
  }

  .banner.top-left .animated-text span,
  .banner.bottom-left .animated-text span {
    transform: rotate(0);
  }
}
</style>
