<template>
  <div class="addServiceForm divMarginTop" ref="imageUploadRef">
    <form ref="formRef" @submit.prevent="addOrUpdateService">
      <!-- Step 1: Initial Form -->
      <div
        class="fullWidth column alignCentre"
        v-show="currentStep === 'initial'"
      >
        <div class="fullWidth">
          <label for="itemName">Service name</label>
          <input
            class="fullWidth alignCentre"
            type="text"
            id="itemName"
            v-model="itemName"
            required
            :class="{ 'highlight-input': highlightName }"
          />
        </div>
        <div class="fullWidth">
          <label for="itemDescription">Service description</label>
          <textarea
            class="fullWidth"
            id="itemDescription"
            v-model="itemDescription"
            required
            :class="{ 'highlight-input': highlightDescription }"
          ></textarea>
        </div>
        <div class="fullWidth">
          <ImageUpload
            @updateImages="handleImageUpdates"
            @updateThumbnail="updateThumbnailIndex"
            :newImages="selectedFiles"
            :existingImages="existingImageUrls"
            :thumbnailIndex.sync="thumbnailIndex"
          />
        </div>
        <div class="fullWidth">
          <label for="price">Price</label>
          <input
            type="number"
            id="price"
            v-model.number="price"
            required
            class="form-control alignCentre"
            :class="{ 'highlight-input': highlightPrice }"
            @blur="validatePrice"
          />
        </div>
        <div class="fullWidth">
          <label for="category">Category (optional)</label>
          <select id="category" v-model="category" class="form-control">
            <option v-for="cat in categories" :key="cat" :value="cat">
              {{ cat }}
            </option>
          </select>
        </div>
        <div class="row alignCentreRow fullWidth justifyToStartEnd">
          <ToggleSwitch
            label="Location"
            v-model="showMap"
            :justifyToStartEnd="true"
            :hideStatusText="true"
          >
            <template v-slot:tooltip>
              <Tooltip
                :message="'Toggle ON to allow customers to see where the service is located. Toggle OFF to hide the location.'"
              />
            </template>
          </ToggleSwitch>
          <!-- <ToggleSwitch
            label="Public"
            v-model="isPublished"
            :justifyToEnd="true"
            :hideStatusText="true"
          >
            <template v-slot:tooltip>
              <Tooltip
                :message="'Toggle ON to publish the service to the public. Toggle OFF to keep the service as a draft and publish later. You can change this later.'"
              />
            </template>
          </ToggleSwitch> -->
        </div>
        <SetLocation
          :showMap="showMap"
          :lastPickedLocation="location"
          @update-location="updateLocation"
        />
      </div>
      <!-- Step 2: Availability -->
      <div
        class="fullWidth alignCentre"
        v-show="currentStep === 'availability'"
      >
        <p class="nomargintop">Select regular availability</p>
        <div class="weekday-container column noGap">
          <div
            v-for="(day, index) in daysOfWeek.slice(0, 5)"
            :key="index"
            @click="selectDay(index)"
            :class="['innerSticker', { purple300: selectedDays[index] }]"
          >
            {{ day }}
          </div>
        </div>
        <div class="weekend-container" style="margin-top: var(--smallMargin)">
          <div
            v-for="(day, index) in daysOfWeek.slice(5)"
            :key="index + 5"
            @click="selectDay(index + 5)"
            :class="['innerSticker', { purple300: selectedDays[index + 5] }]"
          >
            {{ day }}
          </div>
        </div>
      </div>
      <!-- Step 3: Customization -->
      <div class="fullWidth" v-show="currentStep === 'customization'">
        <p class="nomargintopbottom alignCentre">
          Choose your availability for bookings<br />
          <small class="nomargintop alignCentre gray">
            To show availability at all times, no need to click on all slots,
            just leave the full column empty.
          </small>
        </p>
        <table class="selectable">
          <thead>
            <tr>
              <th v-for="(day, index) in selectedDaysOfWeek" :key="index">
                {{ day }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="hour in hours" :key="hour">
              <td
                v-for="(day, index) in selectedDaysOfWeek"
                :key="index"
                @click="toggleSlot(index, hour)"
                :class="{
                  'selected-slot': isSlotSelected(index, hour),
                  innerSticker: isSlotSelected(index, hour),
                  purple300: isSlotSelected(index, hour),
                }"
              >
                {{ hour }}
              </td>
            </tr>
          </tbody>
        </table>
        <p class="nomargintopbottom alignCentre"></p>
      </div>
      <!-- Step 4: Preview -->
      <PreviewItem
        v-show="currentStep === 'preview'"
        :productData="gatheredServiceData"
        :coinSymbol="coinSymbol"
        :thumbnailIndex="thumbnailIndex"
        :userUnitsOrg="userUnitsOrg"
      />
    </form>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed, watch, onMounted, reactive, watchEffect } from "vue";
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  setDoc,
  getDocs,
  deleteDoc,
} from "firebase/firestore";

import { db } from "@/firebase";
import { toast } from "vue3-toastify";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

import ToggleSwitch from "@/components/GenericComponents/ToggleSwitch.vue";
import Tooltip from "@/components/GenericComponents/Tooltip.vue";
import SetLocation from "@/components/DashboardComponents/EcosystemComponents/SetLocation.vue";
import ImageUpload from "@/components/GenericComponents/ImageUpload.vue";
import PreviewItem from "@/components/DashboardComponents/EcosystemComponents/PreviewItem.vue";

export default {
  name: "AddServiceForm",
  components: {
    ToggleSwitch,
    Tooltip,
    SetLocation,
    ImageUpload,
    PreviewItem,
  },
  props: {
    currentProduct: {
      type: Object,
      required: false,
    },
    user: {
      type: Object,
      required: true,
    },
    userCredentials: {
      type: Object,
      required: false,
    },
    currentStep: {
      type: String,
      required: true,
    },
    userUnitsOrg: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    console.log("userUnitsOrg in service form:", props.userUnitsOrg);

    const itemName = ref("");
    const itemDescription = ref("");
    const price = ref(0);
    const category = ref("Services");
    const local = ref(false);
    const isPublished = ref(false);
    const location = ref(null);
    const showMap = ref(false);

    const highlightName = ref(false);
    const highlightDescription = ref(false);
    const highlightPrice = ref(false);

    const formRef = ref(null);
    const selectedDays = ref([true, true, true, true, true, false, false]); // Weekdays selected by default
    const daysOfWeek = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const abbreviatedDaysOfWeek = [
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat",
      "Sun",
    ];
    const hours = Array.from(
      { length: 24 },
      (_, i) => `${i.toString().padStart(2, "0")}:00`
    );
    const customAvailability = ref([]);
    const selectedSlots = ref(new Array(7).fill([]).map(() => []));

    const selectedFiles = ref([]);
    const existingImageUrls = ref([]);
    const thumbnailIndex = ref(0);
    const gatheredServiceData = ref({});

    // New additions for image handling
    const editedImages = ref([]); // Stores objects { oldUrl, newFile }
    const removedImageUrls = ref([]); // Stores URLs of images to delete

    const categories = reactive([
      "Technology",
      "Fashion & Beauty",
      "Home & Living",
      "Arts & Culture",
      "Business",
      "Health & Wellness",
      "Sports & Outdoors",
      "Transportation",
      "Education",
      "Services",
      "Other",
    ]);

    const isSlotSelected = (dayIndex, hour) => {
      return selectedSlots.value[dayIndex].includes(hour);
    };

    const toggleSlot = (dayIndex, hour) => {
      console.log(`Toggling slot: DayIndex=${dayIndex}, Hour=${hour}`);
      const slots = selectedSlots.value[dayIndex];
      const slotIndex = slots.indexOf(hour);
      if (slotIndex === -1) {
        console.log(`Adding slot: ${hour} to day index ${dayIndex}`);
        // Add slot
        selectedSlots.value[dayIndex] = [...slots, hour];
      } else {
        console.log(`Removing slot: ${hour} from day index ${dayIndex}`);
        // Remove slot
        selectedSlots.value[dayIndex] = [
          ...slots.slice(0, slotIndex),
          ...slots.slice(slotIndex + 1),
        ];
      }
      console.log("Updated selectedSlots:", selectedSlots.value);
    };

    // Updated handleImageUpdates function
    const handleImageUpdates = (
      updatedImages,
      removedUrls,
      editedImagesList,
      imagePreviewUrls // Add this parameter
    ) => {
      console.log("handleImageUpdates called");
      console.log("Before update:", {
        existingImageUrls: existingImageUrls.value,
        selectedFiles: selectedFiles.value,
      });

      // Separate updatedImages into existing URLs and new files
      existingImageUrls.value = updatedImages.filter(
        (image) => typeof image === "string"
      );

      selectedFiles.value = updatedImages.filter(
        (image) => image instanceof File
      );

      // Collect URLs of removed images
      removedImageUrls.value = removedUrls;

      // Collect edited images
      editedImages.value = editedImagesList;

      // Update gatheredServiceData with the updated list of image previews
      gatheredServiceData.value.imagePreviews = imagePreviewUrls;

      console.log("After update:", {
        existingImageUrls: existingImageUrls.value,
        selectedFiles: selectedFiles.value,
      });
    };

    const updateThumbnailIndex = (index) => {
      thumbnailIndex.value = index !== null && index !== undefined ? index : 0;
    };

    // Function to get Storage Reference from URL

    // Function to replace an image in Firebase Storage
    const replaceImage = async (oldImageUrl, newFile) => {
      try {
        const storage = getStorage();
        const fileRef = storageRef(storage, oldImageUrl); // Use storageRef
        await uploadBytes(fileRef, newFile);
        const newUrl = await getDownloadURL(fileRef);
        return newUrl;
      } catch (error) {
        console.error(`Failed to replace image at URL: ${oldImageUrl}`, error);
        throw error;
      }
    };

    // Function to delete an image from Firebase Storage using its URL
    const deleteImageByUrl = async (url) => {
      try {
        const fileRef = getStorageRefFromUrl(url);
        await deleteObject(fileRef);
        console.log(`Deleted image at URL: ${url}`);
      } catch (error) {
        console.error(`Failed to delete image at URL: ${url}`, error);
      }
    };

    const handleFormValidation = () => {
      resetHighlights();
      let firstInvalidField = null;
      let errors = [];

      if (!itemName.value.trim()) {
        highlightName.value = true;
        firstInvalidField = firstInvalidField || "itemName";
        errors.push("Service name is required.");
      }

      if (!itemDescription.value.trim()) {
        highlightDescription.value = true;
        firstInvalidField = firstInvalidField || "itemDescription";
        errors.push("Description is required.");
      }

      if (
        price.value === null ||
        price.value === undefined ||
        price.value === ""
      ) {
        highlightPrice.value = true;
        firstInvalidField = firstInvalidField || "price";
        errors.push("Price cannot be empty.");
      } else if (isNaN(parseFloat(price.value))) {
        highlightPrice.value = true;
        firstInvalidField = firstInvalidField || "price";
        errors.push("Please enter a valid number for the price.");
      } else if (parseFloat(price.value) <= 0) {
        highlightPrice.value = true;
        firstInvalidField = firstInvalidField || "price";
        errors.push(
          parseFloat(price.value) < 0
            ? "Price cannot be negative."
            : "Price must be greater than zero."
        );
      }

      if (errors.length > 0) {
        if (firstInvalidField) {
          document.getElementById(firstInvalidField).focus();
          document
            .getElementById(firstInvalidField)
            .scrollIntoView({ behavior: "smooth", block: "center" });
        }
        toast.error(errors.join(" "));
        emit("update-canPreview", false);
        return false;
      } else {
        resetHighlights();
        emit("update-canPreview", true);
        gatherServiceDataForPreview();
        return true;
      }
    };

    const resetHighlights = () => {
      highlightName.value = false;
      highlightDescription.value = false;
      highlightPrice.value = false;
    };

    const resetForm = () => {
      itemName.value = "";
      itemDescription.value = "";
      price.value = 0;
      category.value = "Services";
      selectedDays.value = [true, true, true, true, true, false, false];
      customAvailability.value = [];
      selectedSlots.value = new Array(7).fill([]).map(() => []);
      resetHighlights();
      selectedFiles.value = [];
      existingImageUrls.value = [];
      thumbnailIndex.value = 0;
      emit("update-canPreview", false);
    };

    // Updated addOrUpdateService function
    const addOrUpdateService = async () => {
      try {
        if (!handleFormValidation()) {
          return;
        }

        // Handle edited images
        for (const { oldUrl, newFile } of editedImages.value) {
          const newUrl = await replaceImage(oldUrl, newFile);
          // Update existingImageUrls with the new URL
          const index = existingImageUrls.value.indexOf(oldUrl);
          if (index !== -1) {
            existingImageUrls.value[index] = newUrl;
          }
        }

        // Handle removed images
        for (const url of removedImageUrls.value) {
          await deleteImageByUrl(url);
          // Remove the URL from existingImageUrls
          const index = existingImageUrls.value.indexOf(url);
          if (index !== -1) {
            existingImageUrls.value.splice(index, 1);
          }
        }

        // Upload new images
        console.log("Uploading new images");
        const newImageUrls = await uploadNewImages(selectedFiles.value);
        console.log("New Image URLs received:", newImageUrls);

        // Prepare the images array
        const imageUrls = [...existingImageUrls.value, ...newImageUrls];

        const serviceData = {
          organisation: organisationUID.value, // Use computed organisationUID
          itemName: itemName.value,
          itemDescription: itemDescription.value,
          price: price.value,
          category: category.value || "Services",
          isPublished: isPublished.value,
          owner: ownerUID.value, // Use computed ownerUID
          createdAt: serverTimestamp(),
          ...(location.value && location.value.lat && location.value.lng
            ? { location: { lat: location.value.lat, lng: location.value.lng } }
            : {}),
          images: imageUrls, // Use the combined image URLs
          thumbnailIndex: thumbnailIndex.value,
          coinName:
            gatheredServiceData.value.coinName || coinName.value || "Unit",
          coinSymbol:
            gatheredServiceData.value.coinSymbol || coinSymbol.value || "Ʉ",
          type: "service",
        };

        let serviceRef;
        if (props.currentProduct && props.currentProduct.id) {
          // Update existing service
          serviceRef = doc(db, "services", props.currentProduct.id);
          await setDoc(serviceRef, serviceData, { merge: true });
        } else {
          // Add a new service
          serviceRef = await addDoc(collection(db, "services"), serviceData);
        }

        // Step 1: Fetch existing slots and delete them
        const slotsCollectionRef = collection(serviceRef, "slots");
        const existingSlotsSnapshot = await getDocs(slotsCollectionRef);

        const deleteSlotPromises = existingSlotsSnapshot.docs.map((doc) =>
          deleteDoc(doc.ref)
        );
        await Promise.all(deleteSlotPromises);

        // Step 2: Save the new slots to the subcollection
        const saveSlotsPromises = daysOfWeek.reduce((acc, day, dayIndex) => {
          if (selectedDays.value[dayIndex]) {
            const daySlots = selectedSlots.value[dayIndex]; // Get the slots for that day
            if (daySlots.length > 0) {
              // Save selected time slots for the day
              const dayDocRef = doc(slotsCollectionRef, day); // Day name as the document ID
              const slotsData = {
                day: day,
                slots: daySlots.map((slot) => ({
                  start: slot,
                  end: addOneHour(slot), // 1-hour slots
                })),
              };
              acc.push(setDoc(dayDocRef, slotsData));
            }
          }
          return acc;
        }, []);

        await Promise.all(saveSlotsPromises); // Save all slot data

        // Reset the editedImages and removedImageUrls arrays
        editedImages.value = [];
        removedImageUrls.value = [];

        store.dispatch("updateCoinSymbol", {
          symbol: gatheredServiceData.value.coinSymbol,
          name: gatheredServiceData.value.coinName,
        });
        console.log("Service data being saved:", serviceData);

        emit("serviceAddedSuccessfully");
      } catch (error) {
        console.error("Error adding service: ", error);
        toast.error("Error adding service: " + error.message);
      }
    };

    const uploadNewImages = async (files) => {
      const storage = getStorage();
      const imageUrls = [];
      for (const file of files) {
        const storagePath = `services/${itemName.value}/${file.name}`;
        const fileRef = storageRef(storage, storagePath);
        await uploadBytes(fileRef, file);
        const url = await getDownloadURL(fileRef);
        imageUrls.push(url);
      }
      return imageUrls;
    };

    const validatePrice = async () => {
      if (!organisationUID.value) {
        console.error("Organisation UID not found.");
        return;
      }

      await store.dispatch("fetchAndCommitOrgTotalCoins", {
        orgUID: organisationUID.value,
        context: "seller",
      });

      const totalOrgCoins = store.getters.getSellerOrgTotalCoins(
        organisationUID.value
      );

      if (parseFloat(price.value) > totalOrgCoins) {
        toast.error(
          `The price cannot exceed the sum of all ${totalOrgCoins} existing ${coinName.value}s (${coinSymbol.value}).`
        );
        price.value = totalOrgCoins;
      }

      highlightPrice.value = false; // Ensure highlight is reset if corrected
      gatherServiceDataForPreview(); // Ensure the preview data is updated after price validation
    };

    const selectDay = (index) => {
      selectedDays.value[index] = !selectedDays.value[index];
    };

    const updateLocation = (newLocation) => {
      location.value = newLocation;
      console.log("Location updated:", location.value); // Add this log for debugging
    };

    const coinSymbol = computed(() => {
      if (!props.userUnitsOrg) {
        // Fall back to Vuex state if userUnitsOrg is not available
        return store.getters.getCoinSymbol || "Ʉ";
      }

      const firstKeyData = Object.values(props.userUnitsOrg)[0];
      // Check if coin_symbol exists in userUnitsOrg, otherwise fall back to Vuex or final default
      return (
        firstKeyData?.monetary_parameters?.coin_symbol ||
        store.getters.getCoinSymbol ||
        "Ʉ"
      );
    });

    const coinName = computed(() => {
      if (!props.userUnitsOrg) {
        // Fall back to Vuex state if userUnitsOrg is not available
        return store.getters.getCoinName || "Unit";
      }

      const firstKeyData = Object.values(props.userUnitsOrg)[0];
      // Check if coin_name exists in userUnitsOrg, otherwise fall back to Vuex or final default
      return (
        firstKeyData?.monetary_parameters?.coin_name ||
        store.getters.getCoinName ||
        "Unit"
      );
    });

    const availabilitySummary = computed(() => {
      const regularDays = selectedDays.value
        .map((isSelected, index) => (isSelected ? daysOfWeek[index] : null))
        .filter((day) => day !== null)
        .join(", ");
      const customDays = customAvailability.value.length
        ? "Custom availability set"
        : "No custom availability";
      const alwaysAvailable =
        selectedDays.value.filter((day) => day).length === 0
          ? "Always available"
          : "";
      return `${regularDays}. ${customDays}. ${alwaysAvailable}`;
    });

    const addOneHour = (time) => {
      let [hours, minutes] = time.split(":").map(Number);
      hours += 1;
      if (hours === 24) {
        hours = 23; // Prevent overflow to next day
        minutes = 59; // Set to end of day
      }
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
    };

    const getStorageRefFromUrl = (url) => {
      const storage = getStorage();
      const decodedUrl = decodeURIComponent(url);
      const baseUrl = `https://firebasestorage.googleapis.com/v0/b/${storage.app.options.storageBucket}/o/`;
      const path = decodedUrl.replace(baseUrl, "").split("?alt=media")[0];
      return storageRef(storage, path);
    };

    const gatherServiceDataForPreview = () => {
      console.log("gatherServiceDataForPreview called");
      console.log("Current state before gathering:", {
        itemName: itemName.value,
        itemDescription: itemDescription.value,
        price: price.value,
        category: category.value,
        existingImageUrls: existingImageUrls.value,
        selectedFiles: selectedFiles.value,
        thumbnailIndex: thumbnailIndex.value,
      });

      const fallbackServiceName = "Unnamed service";
      const fallbackDescription = "No description provided.";
      gatheredServiceData.value = {
        ...gatheredServiceData.value, // Preserve existing properties, including imagePreviews
        itemName: itemName.value.trim() || fallbackServiceName,
        itemDescription: itemDescription.value.trim() || fallbackDescription,
        price: price.value,
        category: category.value,
        coinName: coinName.value,
        coinSymbol: coinSymbol.value,
        location: location.value
          ? { lat: location.value.lat, lng: location.value.lng }
          : null, // Ensure the object structure

        // imagePreviews property is preserved
      };

      console.log("Gathered service data:", gatheredServiceData.value);

      emit("update-canPreview", true);
    };

    const fetchSlotsData = async (serviceId) => {
      console.log("Fetching slots data for service ID:", serviceId);
      const slotsCollectionRef = collection(db, `services/${serviceId}/slots`);
      const slotsSnapshot = await getDocs(slotsCollectionRef);
      const fetchedSlots = [];

      slotsSnapshot.forEach((doc) => {
        fetchedSlots.push(doc.data());
      });

      return fetchedSlots;
    };

    const selectedDaysOfWeek = computed(() => {
      return abbreviatedDaysOfWeek.filter(
        (day, index) => selectedDays.value[index]
      );
    });

    const organisationUID = computed(() => {
      // If currentProduct (which is your service in this context) exists and has an organisation set
      if (props.currentProduct && props.currentProduct.organisation) {
        return props.currentProduct.organisation;
      }

      // If currentProduct does not have an organisation, fall back to userUnitsOrg
      const keys = Object.keys(props.userUnitsOrg);
      return keys.length > 0 ? keys[0] : null;
    });

    const ownerUID = computed(() => {
      // If currentProduct (service) exists and has an owner set
      if (props.currentProduct && props.currentProduct.owner) {
        return props.currentProduct.owner;
      }

      // If currentProduct does not have an owner, fall back to userCredentials
      return props.userCredentials?.data?.uid || null;
    });

    watch(
      () => props.currentStep,
      (newStep) => {
        if (newStep === "initial") {
          emit("update-title", "Edit Service Details");
        } else if (newStep === "preview") {
          emit("update-title", "Preview");
          gatherServiceDataForPreview();
        }
      }
    );

    watch(price, (newValue, oldValue) => {
      validatePrice();
      gatherServiceDataForPreview();
    });

    watch(
      [itemName, itemDescription, price, selectedFiles, existingImageUrls],
      gatherServiceDataForPreview,
      { immediate: true }
    );

    // Watch for changes in the currentProduct prop and update form fields accordingly
    watch(
      () => props.currentProduct,
      async (newProduct) => {
        if (newProduct && newProduct.id) {
          console.log("Initializing form with currentService:", newProduct);

          itemName.value = newProduct.itemName || "";
          itemDescription.value = newProduct.itemDescription || "";
          price.value = newProduct.price || 0;
          category.value = newProduct.category || "Services";
          isPublished.value = newProduct.isPublished || false;

          // Pre-populate the location and show map if location exists
          if (newProduct.location) {
            location.value = {
              lat: newProduct.location.lat,
              lng: newProduct.location.lng,
            }; // Update to read as an object
            showMap.value = true; // Automatically toggle map visibility if location exists
          } else {
            location.value = null;
            showMap.value = false;
          }

          selectedFiles.value = [];
          existingImageUrls.value = newProduct.images || [];
          thumbnailIndex.value = newProduct.thumbnailIndex || 0;

          // Fetch and update slots data
          const slotsData = await fetchSlotsData(newProduct.id);
          selectedSlots.value = new Array(7).fill([]).map(() => []);

          slotsData.forEach((slot) => {
            const dayIndex = daysOfWeek.indexOf(slot.day);
            if (dayIndex !== -1) {
              selectedSlots.value[dayIndex] = slot.slots.map((s) => s.start);
            }
          });

          gatherServiceDataForPreview();
        } else {
          resetForm();
        }
      },
      { immediate: true }
    );

    onMounted(async () => {
      if (organisationUID.value) {
        await store.dispatch("fetchAndCommitOrgTotalCoins", {
          orgUID: organisationUID.value,
          context: "seller",
        });
      }
    });

    return {
      itemName,
      itemDescription,
      price,
      category,
      local,
      isPublished,
      categories,
      highlightName,
      highlightDescription,
      highlightPrice,
      formRef,
      handleFormValidation,
      addOrUpdateService,
      validatePrice,
      selectDay,
      selectedDays,
      daysOfWeek,
      abbreviatedDaysOfWeek,
      customAvailability,
      selectedDaysOfWeek,
      hours,
      isSlotSelected,
      toggleSlot,
      coinName,
      coinSymbol,
      resetForm,
      availabilitySummary,
      addOneHour,
      showMap,
      location,
      updateLocation,
      selectedFiles,
      existingImageUrls,
      handleImageUpdates,
      updateThumbnailIndex,
      thumbnailIndex,
      gatherServiceDataForPreview,
      gatheredServiceData,
      organisationUID,
    };
  },
};
</script>

<style scoped>
.highlight-input {
  transition: background-color 0.3s ease-in-out;
  background-color: var(--lightestred);
}

.addServiceForm {
  width: 100%;
}

.form-group {
  margin-bottom: 1rem;
}

.form-control {
  width: 100%;
}

.selected-day {
  background-color: var(--lightGreenWashed);
}

table.selectable td,
table.selectable th {
  text-align: center !important;
}

.selected-slot.innerSticker {
  background-color: var(--purple300);
  width: initial !important;
  padding: initial !important;
  height: var(--clickableHeight);
}

.weekday-container,
.weekend-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--microMargin);
  margin-bottom: var(--margin);
}
</style>
