import { toast } from "vue3-toastify";

export function formatNumberTo5Digits(num) {
  let divisor = 1;
  let suffix = "";
  let isNegative = num < 0;

  try {
    if (num >= 1e30) {
      throw new Error("Number too high to be abbreviated");
    }
    if (num >= 1e24) {
      divisor = 1e24;
      suffix = "Y";
    } else if (num >= 1e21) {
      divisor = 1e21;
      suffix = "Z";
    } else if (num >= 1e18) {
      divisor = 1e18;
      suffix = "E";
    } else if (num >= 1e15) {
      divisor = 1e15;
      suffix = "P";
    } else if (num >= 1e12) {
      divisor = 1e12;
      suffix = "T";
    } else if (num >= 1e9) {
      divisor = 1e9;
      suffix = "G";
    } else if (num >= 1e6) {
      divisor = 1e6;
      suffix = "M";
    } else if (num >= 1000) {
      divisor = 1000;
      suffix = "K";
    }

  } catch (error) {
    toast.error("Number too high to be abbreviated");
    return "999.99Y";  // Return the highest possible abbreviated number
  }
  
  let formatted = num / divisor;
  let integerPartLength = Math.floor(formatted).toString().length;

  // Calculate how many decimal places we can have so the total length remains 5
  let decimalPlaces = 5 - integerPartLength;

  // Fix the number to the appropriate number of decimal places
  formatted = formatted.toFixed(decimalPlaces);

  // Remove trailing zeros after the decimal point
  formatted = parseFloat(formatted).toString();
  return isNegative ? `-${formatted}${suffix}` : `${formatted}${suffix}`;

  
}