import { toast } from "vue3-toastify";

export function formatNumberTo4Digits(num) {
  let divisor = 1;
  let suffix = "";
  let isNegative = num < 0;

  try {
    if (Math.abs(num) >= 1e30) {
      // Use absolute value to handle negative numbers properly
      throw new Error("Number too high to be abbreviated");
    }

    // Define ranges and suffixes
    if (Math.abs(num) >= 1e24) {
      divisor = 1e24;
      suffix = "Y";
    } else if (Math.abs(num) >= 1e21) {
      divisor = 1e21;
      suffix = "Z";
    } else if (Math.abs(num) >= 1e18) {
      divisor = 1e18;
      suffix = "E";
    } else if (Math.abs(num) >= 1e15) {
      divisor = 1e15;
      suffix = "P";
    } else if (Math.abs(num) >= 1e12) {
      divisor = 1e12;
      suffix = "T";
    } else if (Math.abs(num) >= 1e9) {
      divisor = 1e9;
      suffix = "G";
    } else if (Math.abs(num) >= 1e6) {
      divisor = 1e6;
      suffix = "M";
    } else if (Math.abs(num) >= 1000) {
      divisor = 1000;
      suffix = "K";
    }
  } catch (error) {
    toast.error("Number too high to be abbreviated", { multiple: false });
    return isNegative ? "-9999Y" : "9999Y"; // Return the highest possible abbreviated number with four digits
  }

  let formatted = num / divisor;
  let integerPartLength = Math.floor(Math.abs(formatted)).toString().length; // Use absolute value to calculate length

  // Adjust the number of decimal places based on the length of the integer part
  let decimalPlaces = Math.max(0, 4 - integerPartLength);

  formatted = formatted.toFixed(decimalPlaces);

  // Remove trailing zeros after the decimal point and ensure the total length doesn't exceed 4
  formatted = parseFloat(formatted).toString();

  // If rounding caused the integer part to exceed its limit, adjust the formatted number
  if (formatted.replace(".", "").length > 4) {
    formatted = formatted.slice(0, 4);
  }

  return isNegative ? `-${formatted}${suffix}` : `${formatted}${suffix}`;
}
