<template>
  <div class="JoinOrganisationCard">
    <div
      class="sticker divNoMarginBottom"
      :class="stickerColor"
      v-if="joinOrgDetails"
    >
      <!-- Organisation details and logo -->
      <div class="card-header fullWidth row alignTopRow">
        <div class="row">
          <img
            v-if="joinOrgDetails.logo && isLogoValid"
            :src="joinOrgDetails.logo"
            class="header-logo"
            alt="Logo"
            @error="handleImageError"
          />
          <div v-else class="coinSymbol nomargintopbottom">
            <CoinSymbol
              v-if="!joinOrgDetails.logo || !isLogoValid"
              class="header-coin-symbol circle"
              size="bigger"
              :customCoinSymbol="joinOrgDetails.coinSymbol"
            />
          </div>
          <div class="column alignLeftColumn noGap">
            <h2 class="nomargintopbottom alignLeft">
              {{ joinOrgDetails.organisationName }}
            </h2>
            <p class="alignLeft nomargintopbottom">
              <small
                ><span v-if="org.allowTrades">Open economy</span
                ><span v-else>Closed economy</span></small
              >
            </p>
            <p class="alignLeft nomargintopbottom">
              <small class="actionText link" @click="navigateToOrganisationView"
                >View</small
              >
            </p>
          </div>
        </div>
        <!-- Exchange rate and members count -->
        <div class="row tinyGap alignTopRow">
          <div class="column alignRightColumn microGap">
            <transition name="fade" mode="out-in">
              <div class="exchange-rate-container alignRight">
                <div
                  v-if="showExchangeRate && exchangeRate !== null"
                  key="exchange-rate"
                >
                  <small class="nomargintopbottom gray smallTechLabel"
                    ><em>Exchange rate</em></small
                  >
                  <p class="nomargintopbottom">
                    1 {{ joinOrgDetails.coinSymbol }} ≈
                    {{ exchangeRate.toFixed(2) }}
                    {{ props.currentOrg?.monetary_parameters.coin_symbol }}
                  </p>
                </div>
                <div v-else key="coinspercapita">
                  <small class="nomargintopbottom purpleText smallTechLabel"
                    ><em>Coins/Capita</em></small
                  >
                  <p class="nomargintopbottom">{{ coinsPerCapita }}</p>
                </div>
              </div>
            </transition>
            <p class="nomargintopbottom">
              <small
                ><span v-if="joinOrgDetails.membersCount"
                  >{{ joinOrgDetails.membersCount }} member<span
                    v-if="joinOrgDetails.membersCount !== 1"
                    >s</span
                  ></span
                ></small
              >
            </p>
          </div>
        </div>
      </div>
      <div
        class="separator_sticker"
        v-if="
          joinOrgDetails.description &&
          joinOrgDetails.description !== 'No description'
        "
      ></div>
      <div
        v-if="
          joinOrgDetails.description &&
          joinOrgDetails.description !== 'No description'
        "
        class="orgDescription"
      >
        {{ joinOrgDetails.description }}
      </div>

      <!-- Invited role section -->
      <div
        class="separator_sticker"
        v-if="invitedRole || userRoleDetails"
      ></div>
      <div
        class="fullWidth smallText darkerGray divSmallerMarginBottom"
        v-if="invitedRole"
      >
        You've been invited to this role
      </div>
      <div v-if="invitedRole" class="invited-role-section">
        <div class="row justifyToStartEnd alignBaselineRow">
          <strong>{{ invitedRole.roleTitle }}</strong>
          <div class="compensation">
            Salary:
            <strong
              >{{ invitedRole.baseCompensation }}
              {{ org.monetary_parameters.coin_symbol }}</strong
            >
          </div>
        </div>
        <div class="role-description-section">
          <div class="smallText darkerGray divTinyMarginBottom">
            Role description
          </div>
          <p
            v-if="invitedRole.roleDescription"
            class="role-description nomargintopsmallmarginbottom"
          >
            {{ invitedRole.roleDescription }}
          </p>
          <p class="role-description nomargintopsmallmarginbottom" v-else>
            No role description available.
          </p>
        </div>
        <div class="privileges-list-section">
          <div class="smallText darkerGray divTinyMarginBottom">
            Role's admin privileges
          </div>
          <div class="privileges-list">
            <TileInfo
              v-for="(privilege, key) in filteredPrivileges"
              :key="key"
              :title="formatPrivilegeTitle(key)"
              :description="'Granted'"
              :active="true"
            />
          </div>
        </div>
      </div>

      <!-- User's current role section -->
      <div
        v-if="userRoleDetails && !isLoadingRole"
        class="invited-role-section"
      >
        <div class="row justifyToStartEnd alignBaselineRow">
          <strong>{{ userRoleDetails.roleTitle }}</strong>
          <div class="compensation">
            Salary:
            <strong
              >{{ userRoleDetails.baseCompensation }}
              {{ org.monetary_parameters.coin_symbol }}</strong
            >
          </div>
        </div>
        <div class="role-description-section">
          <div class="smallText darkerGray divTinyMarginBottom">
            Role description
          </div>
          <p
            v-if="userRoleDetails.roleDescription"
            class="role-description nomargintopsmallmarginbottom"
          >
            {{ userRoleDetails.roleDescription }}
          </p>
          <p class="role-description nomargintopsmallmarginbottom" v-else>
            No role description available.
          </p>
        </div>
        <div class="privileges-list-section">
          <div class="smallText darkerGray divTinyMarginBottom">
            Role's admin privileges
          </div>
          <div class="privileges-list">
            <TileInfo
              v-for="(privilege, key) in filteredUserPrivileges"
              :key="key"
              :title="formatPrivilegeTitle(key)"
              :description="'Granted'"
              :active="true"
            />
          </div>
        </div>
      </div>

      <!-- Action buttons section -->
      <div
        class="separator_sticker"
        v-if="!user.loggedIn && showActionButtons"
      ></div>
      <div
        class="row alignCentreRow divNoMarginTopSmallMarginBottom"
        v-if="showActionButtons"
      >
        <button
          @click="toggleLoginModal"
          v-if="!user.loggedIn && showActionButtons"
          v-haptic
        >
          Login
        </button>
        <p
          class="nomarginsmallbottom"
          v-if="!user.loggedIn && showActionButtons"
        >
          or
        </p>
        <button
          @click="toggleRegisterModal"
          v-if="!user.loggedIn && showActionButtons"
          v-haptic
        >
          Register
        </button>
        <p class="nomargintopbottom" v-if="!user.loggedIn && showActionButtons">
          to join
        </p>
        <button
          v-if="user.loggedIn && showActionButtons"
          v-haptic
          @click="joinOrganisation"
        >
          Join organisation
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getAuth } from "firebase/auth";
import { toast } from "vue3-toastify";

import Tooltip from "@/components/GenericComponents/Tooltip.vue";
import ToggleButtonContent from "@/components/GenericComponents/ToggleButtonContent.vue";
import TileInfo from "@/components/GenericComponents/TileInfo.vue";
import MainMarketplace from "@/components/DashboardComponents/EcosystemComponents/MainMarketplace.vue";
import CoinSymbol from "@/components/GenericComponents/CoinSymbol.vue";
import DropdownTextSmall from "@/components/GenericComponents/DropdownTextSmall.vue";

import { formatNumberTo5Digits } from "@/utils/numberFormattingTo5digits";

export default {
  name: "JoinOrganisationCard",
  components: {
    Tooltip,
    ToggleButtonContent,
    TileInfo,
    MainMarketplace,
    CoinSymbol,
    DropdownTextSmall,
  },
  props: {
    org: {
      type: Object,
      required: true,
    },
    orgUID: {
      type: [String, Number],
      required: true,
    },
    stickerColor: {
      type: String,
      default: "lightPink",
    },
    showActionButtons: {
      type: Boolean,
      default: true,
    },
    currentOrg: {
      type: Object,
      default: null,
    },
    invitedRole: {
      type: Object,
      default: null,
    },
    userRoleDetails: {
      type: Object,
      default: null,
    },
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();
    const auth = getAuth();
    const isLoadingRole = ref(true);
    const windowWidth = ref(window.innerWidth);

    const isLogoValid = ref(true);

    const handleImageError = () => {
      isLogoValid.value = false;
    };

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    const joinOrgDetails = computed(() =>
      store.getters.getBasicOrgDetails(props.orgUID)
    );

    const user = computed(() => store.getters.user);

    // Filtered privileges for invitedRole
    const filteredPrivileges = computed(() => {
      return Object.fromEntries(
        Object.entries(props.invitedRole?.privileges || {}).filter(
          ([_, value]) => value
        )
      );
    });

    // Filtered privileges for userRoleDetails
    const filteredUserPrivileges = computed(() => {
      return Object.fromEntries(
        Object.entries(props.userRoleDetails?.privileges || {}).filter(
          ([_, value]) => value
        )
      );
    });

    const showLoginModal = computed(() => store.state.showLoginModal);
    const showRegisterModal = computed(() => store.state.showRegisterModal);

    const toggleLoginModal = () => {
      store.dispatch("toggleLoginModal");
    };

    const toggleRegisterModal = () => store.dispatch("toggleRegisterModal");

    const navigateToOrganisationView = () => {
      router.push({
        name: "OrganisationView",
        params: { orgUID: props.orgUID },
      });
    };

    const formatPrivilegeTitle = (privilegeKey) => {
      return typeof privilegeKey === "string"
        ? privilegeKey
            .replace(/([A-Z])/g, " $1")
            .replace(/^./, (str) => str.toUpperCase())
        : "";
    };

    const exchangeRate = computed(() => {
      if (
        !props.currentOrg ||
        props.orgUID === props.currentOrgUID ||
        !props.currentOrg.totalOrgCoins ||
        !props.currentOrg.membersCount ||
        !props.org.totalOrgCoins ||
        !props.org.membersCount
      ) {
        return null;
      }

      return (
        props.currentOrg.totalOrgCoins /
        props.currentOrg.membersCount /
        (props.org.totalOrgCoins / joinOrgDetails.value.membersCount)
      );
    });

    const showExchangeRate = ref(true);
    const toggleView = () => {
      showExchangeRate.value = !showExchangeRate.value;
    };

    const coinsPerCapita = computed(() => {
      const totalCoins = props.org.totalOrgCoins;
      const membersCount = joinOrgDetails.value.membersCount;
      return membersCount > 0 ? (totalCoins / membersCount).toFixed(2) : 0;
    });

    const joinOrganisation = async () => {
      const userUID = auth.currentUser?.uid;
      const newOrgUID = props.orgUID;
      const orgNameArg = joinOrgDetails.value.organisationName;

      try {
        await store.dispatch("joinCustomOrg", {
          userUID,
          newOrgUID,
          orgNameArg,
        });
        await store.dispatch("fetchAndCommitUserUnitsCustomOrgs", userUID);

        // Successfully joined the organisation, now go to home
        goToEcosystem();
      } catch (error) {
        console.error("Error joining organisation: ", error);
        // Handle the error accordingly
      }
    };

    const goToEcosystem = () => {
      toast.success(`🎉 You're joining a new organisation!`, {
        autoClose: 3000,
      });
      setTimeout(() => {
        router.push({ name: "ecosystem" });
        store.dispatch("changeActiveTab", "ecosystem");
      }, 2000);
    };

    onMounted(async () => {
      window.addEventListener("resize", handleResize);
      await store.dispatch("fetchAllData", props.orgUID);
      isLoadingRole.value = false;
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    return {
      user,
      windowWidth,
      joinOrgDetails,
      filteredPrivileges,
      filteredUserPrivileges,
      navigateToOrganisationView,
      toggleLoginModal,
      toggleRegisterModal,
      formatPrivilegeTitle,
      isLoadingRole,
      exchangeRate,
      showExchangeRate,
      toggleView,
      coinsPerCapita,
      joinOrganisation,
      handleImageError,
      isLogoValid,
    };
  },
};
</script>

<style scoped>
.sticker {
  border-radius: var(--borderRadiusBigger);
}

.card-header {
  margin-bottom: 0;
}

.privileges-list {
  display: flex;
  flex-wrap: wrap;
  gap: var(--smallMargin);
}

.coinDetails {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.JoinOrganisationCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.link {
  cursor: pointer;
  transition: all var(--slideInSpeed) ease-in-out;
}
.link:hover {
  transform: translateY(1px);
}

.tileInfo-cont {
  display: flex;
  flex-wrap: wrap;
  gap: var(--smallMargin);
  margin-bottom: var(--smallMargin);
}

.header-logo {
  max-height: calc(var(--clickableHeight));
  width: auto;
  max-width: calc(var(--clickableHeight) * 1.4);
  border-radius: var(--borderRadius);
  overflow: hidden;
}

.header-coin-symbol {
  height: calc(var(--clickableHeight) * 1.4);
  width: calc(var(--clickableHeight) * 1.4);
  min-width: calc(var(--clickableHeight) * 1.4);
}

.badge {
  display: inline-block;
}

.exchange-rate-container {
  background-color: var(--backgroundLighterGraySuperTransp);
  padding: var(--microMargin) var(--smallerMargin) var(--smallerMargin);
  border-radius: var(--borderRadius);
  transition: all 0.7s ease-in-out;
  overflow: auto;
}

.coinspercapita,
.exchange-rate {
  width: max-content;
}

.invited-role-section {
  padding: var(--smallMargin);
  background-color: var(--darkbluetransplighter);
  border-radius: var(--borderRadius);
  width: 100%;
}

.role-description-section {
  margin-top: var(--tinyMargin);
}

.privileges-list-section {
  margin-top: var(--tinyMargin);
}
</style>
