import { toast } from "vue3-toastify";
import { db, auth } from "@/firebase";
import { doc, updateDoc, collection, addDoc, setDoc } from "firebase/firestore";

export const createOverlayHandler = (
  fullPath,
  propPath,
  overlayRef,
  valueRef,
  userUID,
  orgUID,
  walletFieldToUpdate,
  extraNodeInfo
) => {
  let fullPathSplit = doc(db, ...fullPath.split("/"));

  return async () => {
    try {
      console.log("Updating Firestore:", fullPathSplit.path, {
        [propPath]: valueRef.value,
      });
      await updateDoc(fullPathSplit, {
        [propPath]: valueRef.value,
      });

      // New code to update the user's wallet
      if (userUID && orgUID && walletFieldToUpdate) {
        const userWalletPath = doc(db, `users/${userUID}/Wallets/${orgUID}`);
        await updateDoc(userWalletPath, {
          [walletFieldToUpdate]: valueRef.value,
        });
      }

      if (extraNodeInfo) {
        const { subCollection, docId, extraData } = extraNodeInfo;
        const subCollectionRef = collection(fullPathSplit, subCollection);

        // Evaluate docId if it is a function
        const actualDocId = typeof docId === "function" ? docId() : docId;

        // Evaluate extraData if it is a function
        const actualExtraData =
          typeof extraData === "function" ? extraData() : extraData;

        if (actualDocId) {
          const docRef = doc(subCollectionRef, actualDocId);
          await setDoc(docRef, actualExtraData);
        } else {
          await addDoc(subCollectionRef, actualExtraData);
        }
      }

      overlayRef.value = false; // Close the overlay
      // toast.success("Successfully updated!"); // Show a success toast
      store.dispatch("fetchAndCommitUserUnitsCustomOrgs", auth.currentUser.uid);
    } catch (error) {
      overlayRef.value = false; // Optionally close the overlay on error as well
      // toast.error("An error occurred while updating: " + error); // Show an error toast
    }
  };
};
