<template>
  <div class="loading">
    <!-- Insert your loading animation here -->
    <p>Loading...</p>
  </div>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<style scoped>
.loading {
  /* Insert your loading animation styles here */
}
</style>
