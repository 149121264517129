<template>
  <div>
    <div class="row alignCentre">
      <button @click="toggleContent" v-haptic>
        <svg v-if="!showContent" id="checkmark" class="compactImg">
          <use href="../../assets/icons/iconset.svg#view_compact"></use>
        </svg>
        <svg v-if="showContent" id="checkmark" class="compactImg">
          <use href="../../assets/icons/iconset.svg#hide_compact"></use>
        </svg>
        {{ showContent ? hideButtonText : showButtonText }}
      </button>
    </div>
    <transition name="slide-fade" mode="out-in">
      <div v-if="showContent" class="toggle-content exchangeRate">
        <slot></slot>
        <button ref="buttonRef" @click="toggleContent" v-haptic>
          {{ showContent ? hideButtonText : showButtonText }}
        </button>
        <br />
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, nextTick } from "vue";

export default {
  name: "ToggleButtonContent",
  props: {
    showButtonText: {
      type: String,
      default: "Show Content",
    },
    hideButtonText: {
      type: String,
      default: "Hide Content",
    },
  },
  setup(props, { emit }) {
    const showContent = ref(false);

    const toggleContent = () => {
      showContent.value = !showContent.value;

      // Emit an event to tell the parent to re-run MathJax
      emit("contentToggled");

      // Scroll to button after content is toggled
      nextTick(() => {
        const buttonElement = document.querySelector("button");
        if (buttonElement) {
          buttonElement.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
        }
      });
    };

    return {
      showContent,
      toggleContent,
    };
  },
};
</script>

<style scoped>
.toggle-content {
  display: block;
}
.row.alignCentre {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: var(--margin);
}

.exchangeRate {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}
</style>
